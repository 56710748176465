import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";
import $ from "jquery";
import { FirebaseOptions, WebPushCertificates } from "../config";

// Constants
const $window = $(window);
const $body = $(document.body);

// Variables
let initialized = false;
let swRegistration;
let firebaseApp;
let firebaseMessaging;

// Topics
const topics = {
  episode: "episodes",
  podcast: "podcasts",
};

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// COMMONS METHODS                                                                                             //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Update Push Buttons
function updateButton(topic, status) {
  // Get Push Buttons
  const $pushBtns = $(`.js-toggle-push[data-topic=${topic}]`);

  // Hide all Buttons
  $pushBtns.children().addClass("hidden");

  // Show the Correct Button
  $pushBtns.find(`[data-toggle-push="${status}"]`).removeClass("hidden");
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SAFARI WEBPUSH METHODS                                                                                             //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Reset the UI to Show/Hide the Notification Button
function resetSafariWebPush() {
  // Get the Current Permission
  const permissionData = window.safari.pushNotification.permission("web.fr.lesjours.reader");

  // Check if we have a Permission
  console.log("Safari current permission : " + permissionData.permission);
  switch (permissionData.permission) {
    case "default":
      // Show permission UI
      updateButton(topics.episode, "enable");
      break;
    case "denied":
      // Show permission UI
      updateButton(topics.episode, "enable");
      break;
    case "granted":
      // Show unsubscribe UI
      updateButton(topics.episode, "disable");
      break;
  }
}

function requestSafariWebPushPermission() {
  // Get the current WebService URL
  const webServiceURL = window.location.protocol + "//" + window.location.hostname + "/ws-apple-push";

  // Request Permission to the User
  window.safari.pushNotification.requestPermission(webServiceURL, "web.fr.lesjours.reader", {}, function () {
    resetSafariWebPush();
  });
}

// Show a popup to inform the user about the process
function showSafariRevokePermissionPopup() {
  // Show the modal
  window.location.hash = "safari-revoke";
}

// Push Buttons Click Listener
function toogleSafariWebPush(e) {
  // Get the Button Action
  const btnAction = $(e.currentTarget).data("togglePush");
  if (btnAction === "denied") {
    // Do nothing. Let the browser show the modal
    return;
  }

  // Prevent Default
  e.preventDefault();

  // Check the Action
  if (btnAction === "enable") {
    // Request Permission
    requestSafariWebPushPermission();
  } else {
    // Unsubscribe the User
    showSafariRevokePermissionPopup();
  }
}

// Initialize Safari WebPush
function initSafariWebPush() {
  // Listen Clicks on Buttons
  $body.on("click.lesjours.push", ".js-toggle-push[data-topic='episodes'] [data-toggle-push]", toogleSafariWebPush);

  // Update flag
  initialized = true;
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FIREBASE MESSAGING METHODS                                                                                         //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Get the Firebase Cloud Messagin Token
function getFCMToken() {
  return getToken(firebaseMessaging, {
    serviceWorkerRegistration: swRegistration,
    vapidKey: WebPushCertificates.vapidKey,
  });
}

// Get the Storage Key for a given topic
function getStorageKeyForTopic(topic) {
  switch (topic) {
    case topics.episode:
      return "subscribedToEpisodes";
    case topics.podcast:
      return "subscribedToPodcasts";
    default:
      throw new Error(`Unknown topic : ${topic}`);
  }
}

// Check if the Token has Subscribed To Topic
function isTokenSubscribedToTopic(topic) {
  return window.localStorage.getItem(getStorageKeyForTopic(topic)) === "1";
}

// Update the Token Subscription Status To Topic
function setTokenSubscriptionToTopic(topic, subscribed) {
  window.localStorage.setItem(getStorageKeyForTopic(topic), subscribed ? "1" : "0");
  window.localStorage.setItem("fcmSubscriptionUpdateDate", Date.now());
}

// Check if the last FCM subscription update is older than 24 hours
function isFcmSubscriptionUpdateDateTooOld() {
  const updateTime = window.localStorage.getItem("fcmSubscriptionUpdateDate");
  if (updateTime === null) {
    return true;
  }
  return 24 * 60 * 60 * 1000 < Math.abs(Date.now() - parseInt(updateTime, 10));
}

// Subscribe a Token to the Topic
function subscribeToTopic(topic, iidToken, callback) {
  // Save the Change
  $.ajax("/ws-push/subscribe", {
    method: "POST",
    data: {
      topic: topic,
      iidToken: iidToken,
    },
    dataType: "json",
  })
    .then(function () {
      // Update the Status
      setTokenSubscriptionToTopic(topic, true);

      // Call the Callback
      callback();
    })
    .catch(function () {
      // Call the Callback
      callback();
    });
}

// Unsubscribe a Token from the Topic
function unsubscribeFromTopic(topic, iidToken, callback) {
  // Save the Change
  $.ajax("/ws-push/unsubscribe", {
    method: "POST",
    data: {
      topic: topic,
      iidToken: iidToken,
    },
    dataType: "json",
  })
    .then(function () {
      // Update the Status
      setTokenSubscriptionToTopic(topic, false);

      // Call the Callback
      callback();
    })
    .catch(function () {
      // Call the Callback
      callback();
    });
}

// Reset the UI to Show/Hide the Notification Button
function resetFirebaseMessaging() {
  // Get the Current Token
  getFCMToken()
    .then(function (currentToken) {
      // Check if we have a Token
      if (currentToken) {
        // Check if the Token is Subscribed to the Episodes Topic
        if (isTokenSubscribedToTopic(topics.episode)) {
          updateButton(topics.episode, "disable");
        } else {
          updateButton(topics.episode, "enable");
        }
        // Check if the Token is Subscribed to the Podcasts Topic
        if (isTokenSubscribedToTopic(topics.podcast)) {
          updateButton(topics.podcast, "disable");
        } else {
          updateButton(topics.podcast, "enable");
        }
      } else {
        updateButton(topics.episode, "enable");
        updateButton(topics.podcast, "enable");
      }
    })
    .catch(function () {
      updateButton(topics.episode, "enable");
      updateButton(topics.podcast, "enable");
    });
}

// Request Notification Permission to the User
function requestFirebaseMessagingPermission(topic) {
  // Request Notification Permission to the User
  Notification.requestPermission()
    .then(function () {
      // Update the Status
      setTokenSubscriptionToTopic(topic, false);

      // Get the Current Token
      getFCMToken()
        .then(function (currentToken) {
          // Subscribe the Token to the Topic
          subscribeToTopic(topic, currentToken, resetFirebaseMessaging);
        })
        .catch(function () {
          // Reset the UI
          resetFirebaseMessaging();
        });
    })
    .catch(function () {
      // Update the Status
      setTokenSubscriptionToTopic(topic, false);

      // Reset the UI
      resetFirebaseMessaging();
    });
}

// Delete the Instance ID token
function deleteFirebaseMessagingToken(topic) {
  // Get the Current Token
  getFCMToken()
    .then(function (currentToken) {
      // Unsubscribe the Token from the Topic
      unsubscribeFromTopic(topic, currentToken, resetFirebaseMessaging);
    })
    .catch(function () {
      // Reset the UI
      resetFirebaseMessaging();
    });
}

// Push Buttons Click Listener
function toogleFirebaseMessagingPush(e) {
  // Get the Button Action
  const $button = $(e.currentTarget);
  const btnTopic = $button.parents(".js-toggle-push").data("topic");
  const btnAction = $button.data("togglePush");
  if (btnAction === "denied") {
    // Do nothing. Let the browser show the modal
    return;
  }

  // Prevent Default
  e.preventDefault();

  // Check the Action
  if (btnAction === "enable") {
    // Request Permission
    requestFirebaseMessagingPermission(btnTopic);
  } else {
    // Unsubscribe the User
    deleteFirebaseMessagingToken(btnTopic);
  }
}

// Initialize Firebase Messaging
function initFirebaseMessaging(serviceWorker) {
  // Save the Service Worker Registration
  swRegistration = serviceWorker;

  // Initialize Firebase Messaging
  firebaseApp = initializeApp(FirebaseOptions);

  // Retrieve Firebase Messaging object.
  firebaseMessaging = getMessaging(firebaseApp);

  // Handle incoming messages.
  onMessage(firebaseMessaging, (payload) => {
    const data = payload.data;
    console.log("[push.js] Received message. ", data);

    // Show the Notification
    swRegistration.showNotification(data.title, {
      body: data.body,
      icon: "/img/favicon/favicon-192x192.png",
      badge: "/img/favicon/favicon-192x192.png",
      data: data,
    });
  });

  // Listen Clicks on Buttons
  $body.on("click.lesjours.push", ".js-toggle-push [data-toggle-push]", toogleFirebaseMessagingPush);

  // Update flag
  initialized = true;

  // Refresh the Token if required
  setTimeout(function () {
    // Check if we have to refresh the Token
    if (
      isFcmSubscriptionUpdateDateTooOld() &&
      (isTokenSubscribedToTopic(topics.episode) || isTokenSubscribedToTopic(topics.podcast))
    ) {
      getFCMToken()
        .then(function (refreshedToken) {
          // Subscribe the new Token to Episodes Topic
          if (isTokenSubscribedToTopic(topics.episode)) {
            subscribeToTopic(topics.episode, refreshedToken, resetFirebaseMessaging);
          }
          // Subscribe the new Token to Podcasts Topic
          if (isTokenSubscribedToTopic(topics.podcast)) {
            subscribeToTopic(topics.podcast, refreshedToken, resetFirebaseMessaging);
          }
        })
        .catch(function (err) {
          // Log the Error
          console.error("Unable to retrieve refreshed token ", err);
        });
    }
  }, 5 * 1000);
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// GLOBAL METHODS                                                                                                     //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Listen Service Worker registration
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then(function (swRegistration) {
    // The ServiceWorker is active
    // Check if Firebase Messaging is Supported
    isSupported().then((supported) => {
      if (supported) {
        // Initialize Firebase Messaging
        initFirebaseMessaging(swRegistration);
      } else if ("safari" in window && "pushNotification" in window.safari) {
        // Initialize Safari WebPush
        initSafariWebPush();
      }
    });
  });
}

// Listen burger menu events
$window.on("ljBurgerEvent", function (event, action) {
  // Check if the burger menu has been opened
  if (action !== "opened") {
    return;
  }

  // Check if the module is initialized
  if (initialized !== true) {
    return;
  }

  // Get the Current Subscription Status
  if (firebaseMessaging) {
    resetFirebaseMessaging();
  } else {
    resetSafariWebPush();
  }
});
