(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["modals/download.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "modals/download.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<div id=\"download\" class=\"modal centered\" role=\"dialog\" tabindex=\"-1\">\n\t<div role=\"document\">\n\t\t<div class=\"row row-center\">\n\t\t\t<div class=\"col m-0 ph-0 pb-2 h5 fw-bold row row-center row-justify color-darkest\">\n\t\t\t\t<span class=\"pr-1\">";
output += runtime.suppressValue((lineno = 5, colno = 30, runtime.callWrap(t_4, "icon", context, ["download"])), env.opts.autoescape);
output += "</span><span>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
output += "</span>\n\t\t\t</div>\n\t\t\t<span class=\"a btn close\" data-href=\"#\">\n\t\t\t\t<span class=\"sr\">Fermer cette fenêtre</span>\n\t\t\t\t";
output += runtime.suppressValue((lineno = 9, colno = 11, runtime.callWrap(t_4, "icon", context, ["close"])), env.opts.autoescape);
output += "\n\t\t\t</span>\n\t\t\t<ul class=\"col\">\n";
frame = frame.push();
var t_7 = runtime.contextOrFrameLookup(context, frame, "buttons");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("button", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\t\t\t\t<li class=\"lg-w-100 mb-1\">\n\t\t\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"url"), env.opts.autoescape);
output += "\" target=\"_blank\" title=\"Télécharger en ";
output += runtime.suppressValue(runtime.memberLookup((t_8),"filetype"), env.opts.autoescape);
output += "\" data-mtm-type=\"click\" data-mtm-nofollow=\"true\" data-mtm-category=\"Sharing Buttons\" data-mtm-action=\"Download\" data-mtm-label=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"filetype"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t<span class=\"pr-1\">";
output += runtime.suppressValue((lineno = 15, colno = 32, runtime.callWrap(t_4, "icon", context, [runtime.memberLookup((t_8),"icon"),"Télécharger en " + runtime.memberLookup((t_8),"filetype")])), env.opts.autoescape);
output += "</span><span>au format ";
output += runtime.suppressValue(runtime.memberLookup((t_8),"filetype"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t</a>\n\t\t\t\t</li>\n";
;
}
}
frame = frame.pop();
output += "\t\t\t</ul>\n\t\t</div>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("modals/download.html", ctx, cb); }
})();
