(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["ctas/home-banner.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "ctas/home-banner.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "\n<div id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ctaId"), env.opts.autoescape);
output += "\" class=\"cta";
if(runtime.contextOrFrameLookup(context, frame, "hidden")) {
frame = frame.push();
var t_7 = runtime.contextOrFrameLookup(context, frame, "hidden");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("device", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += " ";
output += runtime.suppressValue(t_8, env.opts.autoescape);
output += "-hidden";
;
}
}
frame = frame.pop();
;
}
output += "\"";
if(runtime.contextOrFrameLookup(context, frame, "mtmContentPiece")) {
output += " data-track-content data-content-name=\"CTA - Home\" data-content-piece=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmContentPiece"), env.opts.autoescape);
output += "\"";
;
}
output += ">\n";
if(runtime.contextOrFrameLookup(context, frame, "colors")) {
output += "\t<style>\n\t\t#";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ctaId"), env.opts.autoescape);
output += " .cta-custom-light-bg {\n\t\t\tbackground-color: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"lightBgColor"), env.opts.autoescape);
output += ";\n\t\t}\n\n\t\t#";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ctaId"), env.opts.autoescape);
output += " .cta-custom-bg {\n\t\t\tbackground-color: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"bgColor"), env.opts.autoescape);
output += ";\n\t\t\tcolor: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"textOnBgColor"), env.opts.autoescape);
output += ";\n\t\t}\n\n\t\t#";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ctaId"), env.opts.autoescape);
output += " .btn-svg circle[fill] {\n\t\t\tfill: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"textOnBgColor"), env.opts.autoescape);
output += " !important;\n\t\t}\n\n\t\t#";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ctaId"), env.opts.autoescape);
output += " .btn-svg path[fill],\n\t\t#";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ctaId"), env.opts.autoescape);
output += " .btn-svg:hover svg circle {\n\t\t\tfill: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"bgColor"), env.opts.autoescape);
output += " !important;\n\t\t}\n\n\t\t#";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ctaId"), env.opts.autoescape);
output += " .btn-svg path[stroke] {\n\t\t\tstroke: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"bgColor"), env.opts.autoescape);
output += " !important;\n\t\t\tfill: none !important;\n\t\t}\n\n\t\t#";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ctaId"), env.opts.autoescape);
output += " .btn-svg:hover path[stroke] {\n\t\t\tstroke: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"textOnBgColor"), env.opts.autoescape);
output += " !important;\n\t\t}\n\t</style>\n";
;
}
output += "\t<div class=\"";
if(runtime.contextOrFrameLookup(context, frame, "colors") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"lightBgColor")) {
output += "cta-custom-light-bg";
;
}
else {
if(!runtime.contextOrFrameLookup(context, frame, "colors")) {
output += "bg-lesjours-light";
;
}
;
}
output += "\">\n\t\t<div class=\"homepage-container fw-bold ";
if(runtime.contextOrFrameLookup(context, frame, "colors")) {
output += "cta-custom-bg";
;
}
else {
output += "bg-lesjours color-lightest";
;
}
output += "\">\n\t\t\t<div class=\"p-3 flex row-center row-space\">\n\t\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
output += "\" title=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.contextOrFrameLookup(context, frame, "title"))), env.opts.autoescape);
output += "\" class=\"block cta-link";
if(runtime.contextOrFrameLookup(context, frame, "linkClass")) {
output += " ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "linkClass"), env.opts.autoescape);
;
}
output += "\"";
if(runtime.contextOrFrameLookup(context, frame, "mtmContentPiece")) {
output += " data-content-target=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
output += "\"";
;
}
output += ">\n\t\t\t\t\t<div class=\"h4 text-upper mb-0\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
output += "</div>\n";
if(runtime.contextOrFrameLookup(context, frame, "button")) {
output += "\t\t\t\t\t<div class=\"mt-2 lj-color-0\"><span class=\"btn-obsession-reverse\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "button"), env.opts.autoescape);
output += "</span></div>\n";
;
}
if(runtime.contextOrFrameLookup(context, frame, "subtitle")) {
output += "\t\t\t\t\t<div class=\"cta-subtitle mt-2\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subtitle"), env.opts.autoescape);
output += "</div>\n";
;
}
output += "\t\t\t\t</a>\n";
if(!runtime.contextOrFrameLookup(context, frame, "button")) {
output += "\t\t\t\t<div class=\"pl-2\">\n\t\t\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
output += "\" title=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.contextOrFrameLookup(context, frame, "title"))), env.opts.autoescape);
output += "\" class=\"btn-svg btn-large btn-hover-lesjours";
if(runtime.contextOrFrameLookup(context, frame, "linkClass")) {
output += " ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "linkClass"), env.opts.autoescape);
;
}
output += "\"";
if(runtime.contextOrFrameLookup(context, frame, "mtmContentPiece")) {
output += " data-content-target=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
output += "\"";
;
}
output += ">";
output += runtime.suppressValue((lineno = 59, colno = 231, runtime.callWrap(t_4, "icon", context, ["next"])), env.opts.autoescape);
output += "</a>\n\t\t\t\t</div>\n";
;
}
output += "\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class=\"cta-note p-1 text-center\">\n\t\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "note"), env.opts.autoescape);
output += "\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("ctas/home-banner.html", ctx, cb); }
})();
