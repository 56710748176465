import $ from "jquery";
import Hammer from "hammerjs";
import uniqueId from "lodash/uniqueId";

// Constants
const $window = $(window);

// draggable funtionality
// - credits to https://codyhouse.co/gem/css-jquery-image-comparison-slider/
// - credits to http://css-tricks.com/snippets/jquery/draggable-without-jquery-ui/
function moveElements(dragElement, resizeElement, container, dragParams, e) {
  // Variables
  let leftValue, topValue, widthValue, heightValue;

  // Check comparision type
  switch (dragParams.direction) {
    case "right":
    case "left":
      // Compute the new left value
      leftValue = e.gesture.deltaX + dragParams.xPosition - dragParams.dragWidth;

      // Constrain the draggable element to move inside its container
      if (leftValue < dragParams.xMinBound) {
        leftValue = dragParams.xMinBound;
      } else if (leftValue > dragParams.xMaxBound) {
        leftValue = dragParams.xMaxBound;
      }

      // Compute the width
      widthValue =
        ((leftValue + dragParams.dragWidth / 2 - dragParams.containerOffset.left) * 100) / dragParams.containerWidth;

      // Move the Dragging Button
      dragElement.css("left", widthValue + "%");

      // Update the After Image
      if (dragParams.direction === "left") {
        resizeElement.css("width", 100 - widthValue + "%");
      } else {
        resizeElement.css("width", widthValue + "%");
      }
      break;
    case "top":
    case "bottom":
      // Compute the new top value
      topValue = e.gesture.deltaY + dragParams.yPosition - dragParams.dragHeight;

      // Constrain the draggable element to move inside its container
      if (topValue < dragParams.yMinBound) {
        topValue = dragParams.yMinBound;
      } else if (topValue > dragParams.yMaxBound) {
        topValue = dragParams.yMaxBound;
      }

      // Compute the width
      heightValue =
        ((topValue + dragParams.dragHeight / 2 - dragParams.containerOffset.top) * 100) / dragParams.containerHeight;

      // Move the Dragging Button
      dragElement.css("top", heightValue + "%");

      // Update the After Image
      if (dragParams.direction === "top") {
        resizeElement.css("height", 100 - heightValue + "%");
      } else {
        resizeElement.css("height", heightValue + "%");
      }
      break;
  }
}

function initializeComparison(dragElement, resizeElement, container) {
  // Initialize a Comparison Element
  dragElement
    .hammer({ direction: Hammer.DIRECTION_ALL })
    .on("panstart.lesjours.comparison", function (e) {
      // Get Object Parameters
      e.gesture.srcEvent.preventDefault();
      const dragParams = {
        direction: container.data("direction"),
        dragWidth: dragElement.outerWidth(),
        dragHeight: dragElement.outerHeight(),
        xPosition: dragElement.offset().left + dragElement.outerWidth(),
        yPosition: dragElement.offset().top + dragElement.outerHeight(),
        containerOffset: container.offset(),
        containerWidth: container.outerWidth(),
        containerHeight: container.outerHeight(),
        xMinBound: container.offset().left - dragElement.outerWidth() / 2,
        xMaxBound: container.offset().left + container.outerWidth() - dragElement.outerWidth() / 2,
        yMinBound: container.offset().top - dragElement.outerHeight() / 2,
        yMaxBound: container.offset().top + container.outerHeight() - dragElement.outerHeight() / 2,
      };

      // Start Dragging
      dragElement.hammer().on("panmove.lesjours.comparison", function (e) {
        // Move Elements
        e.gesture.srcEvent.preventDefault();
        moveElements(dragElement, resizeElement, container, dragParams, e);
      });
    })
    .on("panend.lesjours.comparison", function (e) {
      // Stop Dragging
      e.gesture.srcEvent.preventDefault();
      dragElement.hammer().off("panmove.lesjours.comparison");
    });
}

function layout() {
  // Update slides sizes
  $(".comparison-container").each(function (index, element) {
    // Get the Element
    const actual = $(element);

    // Check if the Element is already Loaded
    if (actual.data("comparisonId") !== undefined) {
      return;
    }

    // Initialize the Comparaison Element
    actual.data("comparisonId", uniqueId("ljComparison"));
    initializeComparison(actual.find(".comparison-slider-btn"), actual.find(".comparison-after"), actual);
  });
}

// Listen Component Reload Events
$window.on("ljComponentsReload", function (event, component) {
  if (component === "comparison") {
    // Check if a hash is present so we could check if there is slideshow to display
    if (window.location.hash && window.location.hash !== "#" && !/\s+/.test(window.location.hash)) {
      // Call the layout function
      layout();
    }
  }
});

// Bind Hash Change Listeners
$window.on("hashchange.lesjours.comparison", layout);

// Initialize Elements
layout();
