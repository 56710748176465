import $ from "jquery";

// RegExp
const safari = /safari/;
const ios = /iphone|ipod|ipad/;

// Add jQuery Function to Check if the current Browser is an iOS WebView
$.isIOSWebView = function () {
  // Get the Brower User-Agent
  const userAgent = window.navigator.userAgent.toLowerCase();

  // Check if this is an iOS Device
  if (ios.test(userAgent)) {
    // This is an iOS Device
    // Check if it's the Safari Browser
    if (safari.test(userAgent)) {
      // This is not a Safari Browser
      return false;
    } else if (!safari.test(userAgent)) {
      // This is a WebView
      return true;
    } else {
      // ?
      return false;
    }
  } else {
    // This is not an iOS Device
    return false;
  }
};
