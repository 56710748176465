(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["mes-jours/slider/readings.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "mes-jours/slider/readings.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<div id=\"home-reading-episodes\" class=\"home-mes-jours-container mv-3\">\n";
var t_5;
t_5 = {"title": "Mes lectures en cours","titleLink": "/mon-compte/lectures-en-cours","episodes": runtime.contextOrFrameLookup(context, frame, "readings"),"mesjours": true};
frame.set("slider", t_5, true);
if(frame.topLevel) {
context.setVariable("slider", t_5);
}
if(frame.topLevel) {
context.addExport("slider", t_5);
}
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("slider/episodes.html", false, "mes-jours/slider/readings.html", false, function(t_7,t_6) {
if(t_7) { cb(t_7); return; }
callback(null,t_6);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
callback(null,t_8);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("mes-jours/slider/readings.html", ctx, cb); }
})();
