import $ from "jquery";

$(document.body).one("click", ".lj-dbox-button", function (event) {
  // Prevent default
  event.preventDefault();
  event.stopPropagation();

  // Set the DonorBox configuration
  window.DonorBox = { widgetLinkClassName: "lj-dbox-button" };

  // Callback
  let done = false;
  const callback = function () {
    // Click again on the button
    setTimeout(() => event.target.click(), 200);
  };

  // Load the DonorBox JS script
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.true = true;
  script.src = "https://donorbox.org/install-popup-button.js";
  script.onreadystatechange = script.onload = function () {
    const state = script.readyState;
    if (!done && (!state || /loaded|complete/.test(state))) {
      done = true;
      callback();
    }
  };
  document.getElementsByTagName("head")[0].appendChild(script);
});
