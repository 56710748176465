import $ from "jquery";
import throttle from "lodash/throttle";
import * as MesJours from "../libs/mesjours";

// Variables
const $window = $(window);
const $body = $(document.body);
const $cover = $("#master .cover");

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// INITIALIZE MESJOURS ELEMENTS ON HOMEPAGE                                                                           //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Initialize elements
async function initializeHomePageMesJours() {
  // Get the reading Episodes
  const readings = await MesJours.getReadingEpisodes(0, 12);
  // Get the followed Obsessions
  const followed = await MesJours.getFollowedSeries();

  if (readings.length !== 0) {
    // Append the Reading Episodes slider
    $("#home-mes-jours").append(window.nunjucks.render("mes-jours/slider/readings.html", { readings: readings }));

    $window.trigger("ljComponentsInit", [
      "slider",
      $("#home-reading-episodes .slider"),
      $("#home-reading-episodes .slider").innerWidth(),
    ]);
  }

  // Show the last published series if the user doesn't follow any obsession yet
  const slider =
    followed.length !== 0
      ? { series: followed, followed: true }
      : { series: window.LesJours.series || (await $.loadJsonData("/series.json")) };

  // Append the Series slider
  $("#home-mes-jours").append(window.nunjucks.render("mes-jours/slider/followed.html", slider));

  $window.trigger("ljComponentsInit", [
    "slider",
    $("#home-followed-series .slider"),
    $("#home-followed-series .slider").innerWidth(),
  ]);

  // Refresh Mes Jours
  MesJours.refreshMesJours();
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// RESPONSIVE COVER HEIGHT                                                                                            //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Define the Minimum Cover Height
function defineMinimumCoverHeight() {
  // Reset min-height
  $cover.css("min-height", "0px");

  // Compute the new min-height
  const minHeight = $cover.find(".cover-container").outerHeight(true);

  // Define the new min-height
  $cover.css("min-height", minHeight + "px");
}

// Change the cover height on master double
function doubleMasterHeight() {
  const $masterRight = $("#master .master-right-desktop");
  const $cover = $("#master .master-left .cover-master");

  $cover.height($masterRight.outerHeight());
}

// Check if the user is a subscriber
if (
  $("#home-mes-jours").length > 0 &&
  !$body.hasClass(".user-is-ipaccount") &&
  !$body.hasClass(".user-is-ssolibrary")
) {
  // Initialize Mes Jours
  initializeHomePageMesJours();
}

// Bind Resize & Page Load Listeners
$window.on("resize", throttle(defineMinimumCoverHeight, 1000, { leading: true, trailing: true }));
$.triggerOnLoad("lesjours.home", defineMinimumCoverHeight);
if ($("#master").hasClass("master-double") && $("#master .master-right-desktop").is(":visible")) {
  doubleMasterHeight();
}

// Initialize sliders
$(".slider").each(function (idx, el) {
  $window.trigger("ljComponentsInit", ["slider", $(el), $(el).innerWidth()]);
});

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// LIVE ELEMENT
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Check if we have to disable the Live Button
function hideLiveButtonsIfStopped() {
  // Get the Live data
  const $live = $("#master .cover .cover-live");
  const { liveFrom, liveTo } = $live.data();

  // Check if we need to hide the buttons
  let curTime = Date.now();
  if (curTime < Date.parse(liveFrom) || Date.parse(liveTo) < curTime) {
    // Remove Live Buttons
    $("#master .cover .cover-live").remove();
  }
}
if ($("#master .cover .cover-live").length > 0) {
  hideLiveButtonsIfStopped();
}

// Listen Clicks on Live Button
$("#master .cover .cover-live .btn").on("click.lesjours.home", function (event) {
  // Open the iFrame
  const $btn = $(event.currentTarget);
  const $live = $btn.parents(".cover-live");
  const $cover = $btn.parents(".cover");

  // Get the Live data
  const { livePlatform, liveVideoId } = $live.data();
  if (livePlatform === "youtube") {
    // Add the iFrame to the DOM
    $live.append(
      `<iframe class="w-100 h-100" src="https://www.youtube.com/embed/${liveVideoId}?autoplay=1" style="border:none;overflow:hidden" scrolling="no" frameBorder="0" allow="autoplay; fullscreen; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>`
    );
    $cover.addClass("live-opened");
  }
});
