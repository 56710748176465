import throttle from "lodash/throttle";
import $ from "jquery";
import * as MesJours from "../libs/mesjours";

const namespace = "lesjours.player";
const PlayerTypes = {
  circle: "circle",
  bar: "bar",
};
const Selectors = {
  player: ".js-audio-player",
  buffer: ".js-player-buffer",
  progress: ".js-player-progress",
  position: ".js-player-position",
  rate: ".js-playback-rate",
  rate1: ".js-rate-1",
  rate1p5: ".js-rate-1p5",
};

function humanDuration(duration) {
  const minutes = Math.floor(duration / 60);
  let seconds = Math.round(duration % 60);

  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return `${minutes}:${seconds}`;
}

function updateCircleProgress($circle, progress) {
  $circle.css("stroke-dashoffset", 339.292 * (1 - progress));
}

function updateLinearProgress($bar, progress) {
  $bar.css("width", `${progress * 100}%`);
}

function getPositionFromEventOnCircle($circle, event) {
  // CF : https://stackoverflow.com/questions/18588017/canvas-circle-detect-click-position
  // Get Click Position from the Center
  const $svg = $circle.parent();
  const offset = $svg.offset();
  const x = event.pageX - offset.left - $svg.width() / 2;
  const y = event.pageY - offset.top - $svg.height() / 2;

  // Compute Click Angle
  let mAngle = Math.atan2(y, x);

  // Correct the Angle
  if (mAngle > -1 * Math.PI && mAngle < -0.5 * Math.PI) {
    mAngle = 2 * Math.PI + mAngle;
  }

  // Return the Percentage divided by 100 because we want a number from 0 to 1
  return (((mAngle + Math.PI / 2) / 2) * Math.PI * 10) / 100;
}

function getPositionFromEventOnBar($bar, event) {
  // Get the Progress Bar Offset
  const offset = $bar.offset();

  // Return the Percentage
  return (event.pageX - offset.left) / $bar.width();
}

class LesJoursPlayer {
  constructor($player) {
    // Initialize the Player
    this.$player = $player;
    this.type = this.$player.data("type");
    this.$buffer = this.$player.find(Selectors.buffer);
    this.$progress = this.$player.find(Selectors.progress);
    this.$position = this.$player.find(Selectors.position);
    this.$rate = this.$player.find(Selectors.rate);
    this.$audio = this.$player.find("audio");
    this.audio = this.$audio[0];

    // Check if it's a Podcast with activated progress recording
    if (this.$player.data("mesjoursRecord") === true) {
      this.podcast = {
        programme: this.$player.data("programme"),
        podcast: this.$player.data("podcast"),
        duration: this.$player.data("podcastDuration"),
        previous: 0,
        firstLaunch: true,
      };
    }

    // Attach the Player to the jQuery Element
    $player.data(namespace, this);

    // Check the type to select the appropriate progress updater
    switch (this.type) {
      case PlayerTypes.circle:
        this.hasProgressBar = true;
        this.progressUpdater = updateCircleProgress;
        this.eventToPosition = getPositionFromEventOnCircle;
        break;
      case PlayerTypes.bar:
        this.hasProgressBar = true;
        this.progressUpdater = updateLinearProgress;
        this.eventToPosition = getPositionFromEventOnBar;
        break;
      default:
        this.hasProgressBar = false;
        break;
    }

    // Start Listening events
    this.startListening();
  }

  startListening() {
    // Check if there is a progress bar
    if (this.hasProgressBar === true) {
      // We need progress & timeupdate events listeners
      this.$audio
        .on(`progress.${namespace}`, this.onProgressEvent.bind(this))
        .on(`timeupdate.${namespace}`, this.onTimeUpdateEvent.bind(this));
    }

    // Add playing, pause & ended events listeners
    this.$audio
      .on(`playing.${namespace}`, this.onPlayingEvent.bind(this))
      .on(`pause.${namespace}`, this.onPauseEvent.bind(this))
      .on(`ended.${namespace}`, this.onEndedEvent.bind(this));

    // Add an extra timeupdate events listener if it's a Podcast
    if (this.podcast !== undefined) {
      this.$audio.on(
        `timeupdate.${namespace}`,
        throttle(this.onPodcastProgressChange.bind(this), 5000, { leading: true, trailing: true })
      );
    }
  }

  stopListening() {
    // Stop all listeners
    this.$audio
      .off(`progress.${namespace}`)
      .off(`timeupdate.${namespace}`)
      .off(`playing.${namespace}`)
      .off(`pause.${namespace}`)
      .off(`ended.${namespace}`);
  }

  updateProgressBar(currentTime, duration) {
    // Show the current progress
    this.progressUpdater(this.$progress, currentTime / duration);

    // Update the current position
    this.$position.html(humanDuration(currentTime));
  }

  onProgressEvent() {
    // TimeRanges
    const ranges = this.audio.buffered;

    // Check if there is TimeRanges
    if (ranges === undefined || ranges.length === 0) {
      return;
    }

    // Iterate through ranges
    for (let i = 0; i < ranges.length; i++) {
      // Get TimeRanges Bounds
      const startTime = this.audio.buffered.start(i);
      const endTime = this.audio.buffered.end(i);

      // Check if it's the first frame
      if (startTime !== 0) {
        continue;
      }

      // Check if the download is complete
      if (endTime === this.audio.duration) {
        // Hide the buffer circle but only after display shortly the 100% status
        const self = this;
        self.progressUpdater(self.$buffer, 1);
        setTimeout(() => self.progressUpdater(self.$buffer, 0), 250);
      } else {
        // Show the current progress
        this.progressUpdater(this.$buffer, endTime / this.audio.duration);
      }
    }
  }

  onTimeUpdateEvent() {
    // Update the progress bar
    this.updateProgressBar(this.audio.currentTime, this.audio.duration);
  }

  async onPodcastProgressChange() {
    // Check if MesJours is loaded
    if (!MesJours.isMesJoursLoaded()) {
      return;
    }

    // Variables
    const previous = this.podcast.previous;
    const currentTime = this.audio.currentTime;
    const duration = this.audio.duration;
    let progress;

    // Check if the user has start listening the Podcast
    // or if the current progress is lower than the previous
    // or if the podcast is already listened
    if (currentTime === 0 || Math.floor(currentTime) <= previous || previous === true) {
      // Don't Send the Progression
      return;
    }

    // Check if the user has terminated to listen the podcast
    // When the user is on the last seconds we considere that he has finished the podcast
    if (currentTime / duration >= 0.98) {
      // Flag the Podcast has Listened
      progress = true;
    } else {
      // Get the current Progression
      progress = Math.floor(currentTime);
    }

    // Update the Listening Progress
    await MesJours.updateArticleProgress(
      MesJours.models.podcast,
      this.podcast.programme,
      this.podcast.podcast,
      progress
    );

    // Get the Podcast
    const mesJoursDoc = await MesJours.getPodcast(this.podcast.programme, this.podcast.podcast);

    // Update the Previous Listening Progress
    this.podcast.previous = mesJoursDoc.progress;
  }

  onPlayingEvent() {
    // Update player classes
    this.$player.removeClass("loading").addClass("playing");

    // Send event to figure.audio parent element if there is one
    this.$player.parents("figure.audio").trigger("lesjours.slideshow.play");
  }

  onPauseEvent() {
    // Update player classes
    this.$player.removeClass("playing loading");

    // Send event to figure.audio parent element if there is one
    this.$player.parents("figure.audio").trigger("lesjours.slideshow.pause");
  }

  onEndedEvent() {
    // Update player classes
    this.$player.removeClass("playing loading");
  }

  play() {
    // Add loading class
    this.$player.addClass("loading");

    // Check if it's a Podcast
    // if it's the first click on the play button
    // and if MesJours is loaded
    if (this.podcast?.firstLaunch === true && MesJours.isMesJoursLoaded()) {
      // Get the Podcast
      MesJours.getPodcast(this.podcast.programme, this.podcast.podcast).then((mesJoursDoc) => {
        // Update the audio current time
        if (mesJoursDoc?.progress !== undefined) {
          this.audio.currentTime = mesJoursDoc.progress;
        }

        // Play the media
        this.audio.play();
      });
      return;
    }

    // Play the media
    this.audio.play();
  }

  pause() {
    // Pause the media
    this.audio.pause();
  }

  toggle() {
    // Check if the player is loading
    if (this.$player.hasClass("loading")) {
      return;
    }

    // Check if the player is paused
    if (this.audio.paused) {
      // Start the media
      this.play();
    } else {
      // Pause the media
      this.pause();
    }
  }

  moveToPosition(currentTime) {
    // Check if the player is paused
    if (this.audio.paused) {
      // Add the loading class
      this.$player.addClass("loading");

      // Update the audio current time
      this.audio.currentTime = currentTime;

      // Play the media
      this.audio.play();
    } else {
      // Update the audio current time
      this.audio.currentTime = currentTime;
    }
  }

  jumpTo(percentage) {
    // Move the position using the audio duration or the available podcast duration
    if (Number.isFinite(this.audio.duration)) {
      this.moveToPosition(this.audio.duration * percentage);
    } else if (Number.isFinite(this.podcast?.duration)) {
      this.moveToPosition(this.podcast.duration * percentage);
    }
  }

  moveBy(duration) {
    // We need to check bounds
    let newCurrentTime = this.audio.currentTime + duration;
    if (newCurrentTime < 0) {
      newCurrentTime = 0;
    }
    if (newCurrentTime > this.audio.duration) {
      newCurrentTime = this.audio.duration;
    }

    // Move the position
    this.moveToPosition(newCurrentTime);
  }

  changeRate() {
    // Change the playback rate
    this.audio.playbackRate = this.audio.playbackRate === 1.0 ? 1.5 : 1.0;

    // Update the button
    if (this.audio.playbackRate === 1.0) {
      this.$rate.find(Selectors.rate1p5).addClass("hidden");
      this.$rate.find(Selectors.rate1).removeClass("hidden");
    } else {
      this.$rate.find(Selectors.rate1).addClass("hidden");
      this.$rate.find(Selectors.rate1p5).removeClass("hidden");
    }
  }

  async showSavedProgress() {
    // Check if we know the podcast duration & if there is progress bar
    if (!Number.isFinite(this.podcast?.duration) || this.hasProgressBar !== true) {
      // Nothing to do
      return;
    }

    // Check if MesJours is loaded
    if (MesJours.isMesJoursLoaded()) {
      // Get the Podcast
      const podcast = await MesJours.getPodcast(this.podcast.programme, this.podcast.podcast);

      // Check if we can reload the progress
      if (Number.isFinite(podcast?.progress)) {
        // Update the progress bar
        this.updateProgressBar(podcast.progress, this.podcast.duration);
      }
    }
  }
}

$.fn.getLesJoursPlayer = function () {
  // Check if we have elements
  if (this.length === 0) {
    return null;
  }

  // Map elements
  let players = this.map((idx, el) => {
    // Wrap the element
    const $el = $(el);

    // Check if it's a valid Player
    if (!$el.is(Selectors.player)) {
      // Return null
      return null;
    }

    // Get the existing Player or create a new one
    return $el.data(namespace) || new LesJoursPlayer($el);
  });

  // Remove null values
  players = players.filter((player) => player !== null);

  // Return players
  return players.length === 1 ? players[0] : players;
};

function onQuickJumpBtnClick(e) {
  // Get the player
  const $button = $(e.currentTarget);
  const $player = $button.parents(Selectors.player);
  const player = $player.getLesJoursPlayer();

  // Jump playing time to the clicked position
  player.moveBy($button.data("seconds"));
}

function onJumpBtnClick(e) {
  // Get the player
  const $button = $(e.currentTarget);
  const $player = $button.parents(Selectors.player);
  const player = $player.getLesJoursPlayer();

  // Jump playing time to the clicked position
  player.jumpTo(player.eventToPosition($button, e));
}

function onPlaybackRateClick(e) {
  // Get the player
  const $button = $(e.currentTarget);
  const $player = $button.parents(Selectors.player);
  const player = $player.getLesJoursPlayer();

  // Change the rate
  player.changeRate();
}

function onPlayBtnClick(e) {
  // Get the player
  const $button = $(e.currentTarget);
  const $player = $button.parents(Selectors.player);

  // Play or Pause the media
  $player.getLesJoursPlayer().toggle();
}

function openAppPodcastPlayer(e) {
  e.stopPropagation();

  window.LesJoursWebViewChannels.postMessage(
    JSON.stringify({
      action: "playPodcast",
      value: window.LesJours.podcast.href,
    })
  );
}

// Bind Clicks Listeners
$("body")
  .on(`click.${namespace}.play`, ".js-audio-player .js-play-btn", onPlayBtnClick)
  .on(`click.${namespace}.jump`, ".js-audio-player .js-jump-btn", onJumpBtnClick)
  .on(`click.${namespace}.jump`, ".js-audio-player .js-quickjump-btn", onQuickJumpBtnClick)
  .on(`click.${namespace}.jump`, ".js-audio-player .js-playback-rate", onPlaybackRateClick);
if (typeof window.LesJours.mobileApplication === "object" && window.LesJours.mobileApplication.build > 6513) {
  $("#podcast-media")
    .on(`click.${namespace}.play`, ".js-play-btn", openAppPodcastPlayer)
    .on(`click.${namespace}.jump`, ".js-jump-btn, .js-quickjump-btn, .js-playback-rate", openAppPodcastPlayer);
}
