(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["actions/share.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "actions/share.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<div class=\"action share\">\n\t<div id=\"share-buttons\" class=\"text-center collapse\" aria-expanded=\"false\">\n\t\t<div class=\"share-button\">\n\t\t\t<a class=\"btn-svg btn-large btn-hover-lesjours btn-no-shadow\" href=\"https://bsky.app/intent/compose?text=";
output += runtime.suppressValue(env.getFilter("urlencode").call(context, ((env.getFilter("unescape").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "share")),"title"))) + " " + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "share")),"url") + "?mtm_source=bluesky&mtm_medium=social&mtm_campaign=" + runtime.contextOrFrameLookup(context, frame, "mtmCampaign") + "&mtm_content=" + runtime.contextOrFrameLookup(context, frame, "mtmContent"))), env.opts.autoescape);
output += "\" target=\"_blank\" title=\"Partager sur Bluesky\" data-mtm-type=\"click\" data-mtm-nofollow=\"true\" data-mtm-category=\"Sharing Buttons\" data-mtm-action=\"Partage\" data-mtm-label=\"Bluesky\">\n\t\t\t\t";
output += runtime.suppressValue((lineno = 5, colno = 11, runtime.callWrap(t_4, "icon", context, ["sn-red-bluesky","Partager sur Bluesky"])), env.opts.autoescape);
output += "\n\t\t\t</a>\n\t\t</div>\n\t\t<div class=\"share-button\">\n\t\t\t<a class=\"btn-svg btn-large btn-hover-lesjours btn-no-shadow\" href=\"#\" mastodon-sharing-text=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "share")),"title")), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "share")),"url"), env.opts.autoescape);
output += "?mtm_source=mastodon&mtm_medium=social&mtm_campaign=";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmCampaign"), env.opts.autoescape);
output += "&mtm_content=";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmContent"), env.opts.autoescape);
output += "\" title=\"Partager sur Mastodon\" data-mtm-type=\"click\" data-mtm-nofollow=\"true\" data-mtm-category=\"Sharing Buttons\" data-mtm-action=\"Partage\" data-mtm-label=\"Mastodon\">\n\t\t\t\t";
output += runtime.suppressValue((lineno = 10, colno = 11, runtime.callWrap(t_4, "icon", context, ["sn-red-mastodon","Partager sur Mastodon"])), env.opts.autoescape);
output += "\n\t\t\t</a>\n\t\t</div>\n\t\t<div class=\"share-button\">\n\t\t\t<a class=\"btn-svg btn-large btn-hover-lesjours btn-no-shadow\" href=\"https://twitter.com/intent/tweet?text=";
output += runtime.suppressValue(env.getFilter("urlencode").call(context, env.getFilter("unescape").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "share")),"title"))), env.opts.autoescape);
output += "&url=";
output += runtime.suppressValue(env.getFilter("urlencode").call(context, (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "share")),"url") + "?mtm_source=twitter&mtm_medium=social&mtm_campaign=" + runtime.contextOrFrameLookup(context, frame, "mtmCampaign") + "&mtm_content=" + runtime.contextOrFrameLookup(context, frame, "mtmContent"))), env.opts.autoescape);
output += "&via=Lesjoursfr\" target=\"_blank\" title=\"Partager sur Twitter\" data-mtm-type=\"click\" data-mtm-nofollow=\"true\" data-mtm-category=\"Sharing Buttons\" data-mtm-action=\"Partage\" data-mtm-label=\"Twitter\">\n\t\t\t\t";
output += runtime.suppressValue((lineno = 15, colno = 11, runtime.callWrap(t_4, "icon", context, ["sn-red-twitter","Partager sur Twitter"])), env.opts.autoescape);
output += "\n\t\t\t</a>\n\t\t</div>\n\t\t<div class=\"share-button\">\n\t\t\t<a class=\"btn-svg btn-large btn-hover-lesjours btn-no-shadow\" href=\"https://www.facebook.com/dialog/share?app_id=1716167151957741&display=page&href=";
output += runtime.suppressValue(env.getFilter("urlencode").call(context, (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "share")),"url") + "?mtm_source=facebook&mtm_medium=social&mtm_campaign=" + runtime.contextOrFrameLookup(context, frame, "mtmCampaign") + "&mtm_content=" + runtime.contextOrFrameLookup(context, frame, "mtmContent"))), env.opts.autoescape);
output += "\" target=\"_blank\" onclick=\"javascript:if(typeof FB !== 'undefined'){FB.ui({method:'share',href:'";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "share")),"url") + "?mtm_source=facebook&mtm_medium=social&mtm_campaign=" + runtime.contextOrFrameLookup(context, frame, "mtmCampaign") + "&mtm_content=" + runtime.contextOrFrameLookup(context, frame, "mtmContent")), env.opts.autoescape);
output += "'});return false;}\" title=\"Partager sur Facebook\" data-mtm-type=\"click\" data-mtm-nofollow=\"true\" data-mtm-category=\"Sharing Buttons\" data-mtm-action=\"Partage\" data-mtm-label=\"Facebook\">\n\t\t\t\t";
output += runtime.suppressValue((lineno = 20, colno = 11, runtime.callWrap(t_4, "icon", context, ["sn-red-facebook","Partager sur Facebook"])), env.opts.autoescape);
output += "\n\t\t\t</a>\n\t\t</div>\n";
if(runtime.contextOrFrameLookup(context, frame, "withGift")) {
output += "\t\t<div class=\"share-button subscriber-visible\">\n\t\t\t<a class=\"btn-svg btn-large btn-hover-lesjours btn-no-shadow\" href=\"#offered-episode\" title=\"Offrir l’Article\">\n\t\t\t\t";
output += runtime.suppressValue((lineno = 26, colno = 11, runtime.callWrap(t_4, "icon", context, ["gift","Offrir l’Article"])), env.opts.autoescape);
output += "\n\t\t\t</a>\n\t\t</div>\n";
;
}
if(runtime.contextOrFrameLookup(context, frame, "withDownload")) {
output += "\t\t<div class=\"share-button subscriber-visible\">\n\t\t\t<a class=\"btn-svg btn-large btn-hover-lesjours btn-no-shadow\" href=\"#\" title=\"Imprimer l’Article\" onclick=\"javascript:window.print();\" data-mtm-type=\"click\" data-mtm-nofollow=\"true\" data-mtm-category=\"Sharing Buttons\" data-mtm-action=\"Download\" data-mtm-label=\"Print\">\n\t\t\t\t";
output += runtime.suppressValue((lineno = 33, colno = 11, runtime.callWrap(t_4, "icon", context, ["print","Imprimer l’Article"])), env.opts.autoescape);
output += "\n\t\t\t</a>\n\t\t</div>\n\t\t<div class=\"share-button subscriber-visible\">\n\t\t\t<a class=\"btn-svg btn-large btn-hover-lesjours btn-no-shadow\" href=\"#download\" title=\"Télécharger\">\n\t\t\t\t";
output += runtime.suppressValue((lineno = 38, colno = 11, runtime.callWrap(t_4, "icon", context, ["download","Télécharger"])), env.opts.autoescape);
output += "\n\t\t\t</a>\n\t\t</div>\n";
;
}
output += "\t\t<div class=\"share-button visitor-visible\">\n\t\t\t<a class=\"btn-svg btn-large btn-hover-lesjours btn-no-shadow\" href=\"mailto:?subject=";
output += runtime.suppressValue(env.getFilter("urlencode").call(context, env.getFilter("unescape").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "share")),"title"))), env.opts.autoescape);
output += "&body=";
output += runtime.suppressValue(env.getFilter("urlencode").call(context, env.getFilter("unescape").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "share")),"description"))), env.opts.autoescape);
output += "%0D%0A";
output += runtime.suppressValue(env.getFilter("urlencode").call(context, (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "share")),"url") + "?mtm_source=sharing-email&mtm_medium=email&mtm_campaign=" + runtime.contextOrFrameLookup(context, frame, "mtmCampaign") + "&mtm_content=" + runtime.contextOrFrameLookup(context, frame, "mtmContent"))), env.opts.autoescape);
output += "\" title=\"Partager par Email\" data-mtm-type=\"click\" data-mtm-nofollow=\"true\" data-mtm-category=\"Sharing Buttons\" data-mtm-action=\"Partage\" data-mtm-label=\"Email\">\n\t\t\t\t";
output += runtime.suppressValue((lineno = 44, colno = 11, runtime.callWrap(t_4, "icon", context, ["sn-red-email","Partager par Email"])), env.opts.autoescape);
output += "\n\t\t\t</a>\n\t\t</div>\n\t</div>\n\t<div class=\"share-button mb-0\">\n\t\t<a class=\"btn-svg action-btn btn-hover-lesjours btn-no-shadow collapsed\" href=\"#\" data-toggle=\"collapse\" data-target=\"#share-buttons\" aria-expanded=\"false\" title=\"Partager\">\n\t\t\t";
output += runtime.suppressValue((lineno = 50, colno = 10, runtime.callWrap(t_4, "icon", context, ["share","Partager"])), env.opts.autoescape);
output += "\n\t\t</a>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("actions/share.html", ctx, cb); }
})();
