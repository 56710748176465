import $ from "jquery";
import throttle from "lodash/throttle";
import * as MesJours from "../libs/mesjours";

// Resume Elements Holder
const $window = $(window);
const $document = $(document);
const $footer = $("footer#footer");
const scrollLoading = {};

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SEARCH LISTENER                                                                                                    //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Hide Search Results
function hideSearchResults() {
  // Clear Search Results Elements
  $(".search-results").empty();
}

// Send the Search Request
function doSearchRequest($query, $results, action) {
  $.getJSON("/recherche/obsession", {
    query: $query.val(),
  }).done(function (json) {
    // Render Search Results
    $results.html(window.nunjucks.render("mes-jours/search-results.html", { results: json, action: action }));
  });
}

// Handle Keydown/Keyup Events in the Input Search
function keySearchHandler(event, action) {
  // Variables
  const $form = $(event.target).parents(".search-form");
  const $query = $form.find(".search-input input[name=query]");
  const $results = $form.find(".search-results");

  // Check the Event Type
  switch (event.type) {
    case "keyup":
      // Check if there is Something to Search
      if ($query.val().length === 0) {
        break;
      }

      // Send the Search Request
      doSearchRequest($query, $results, action);
      break;
    default:
      break;
  }
}

// Keydown/Keyup Events Wrappers
function keySearchHandlerFollow(e) {
  keySearchHandler(e, "Subscribe Serie");
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// INITIALIZE A SLIDER                                                                                                //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function initializeSlider($slider) {
  // Initialize the Slider
  $window.trigger("ljComponentsInit", ["slider", $slider, $slider.innerWidth()]);
}

// Initialize the Menu Slider
initializeSlider($(".slider[role=tablist]"));

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FOLLOW AN OBSESSION                                                                                                //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function followSerie(e) {
  // Get Clicked Element
  const $result = $(e.currentTarget);
  const serieModel = $result.data("model");

  // Prevent Default
  e.preventDefault();

  // Save the Change
  MesJours.followSerie(serieModel, $result.data(serieModel.toLowerCase())).then(function () {
    // Get Follows
    MesJours.getFollowedSeries().then(function (follows) {
      // Clear existing content
      $("#follows").empty();

      // Sort by title
      follows.sort((a, b) => (a[a.model.toLowerCase()].title < b[b.model.toLowerCase()].title ? -1 : 1));

      // Load Follows
      $("#follows").append(window.nunjucks.render("mes-jours/follows.html", { elements: follows }));
    });
  });
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// CHECK & REMOVE BUTTONS LISTENERS                                                                                   //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function handleBtnClick(action, e) {
  // Prevent Default
  e.preventDefault();

  // Variables
  const $eventBtn = $(e.target);
  const $resume = $eventBtn.parents("[data-mesjours-type]");
  const $article = $eventBtn.parents(".article");

  // Check the Action
  switch (action) {
    case "check":
      // Flag the Episode as Read/Listened
      MesJours.flagArticleAsTerminated($resume.data()).then(function () {
        // Remove the Episode Article from the DOM
        $article.parent().remove();
      });
      break;
    case "remove":
      // Remove the Episode Reading/Listening Progression
      MesJours.removeArticleProgress($resume.data()).then(function () {
        // Remove the Episode Article from the DOM
        $article.parent().remove();
      });
      break;
  }
}

// Bind Events Listeners
$("#resume")
  .on("click.lesjours.mesjours", "[data-mesjours-type] .check", function (e) {
    handleBtnClick("check", e);
  })
  .on("click.lesjours.mesjours", "[data-mesjours-type] .remove", function (e) {
    handleBtnClick("remove", e);
  });

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SCROLL AUTO-LOADING                                                                                                //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function scrollEventHandler() {
  // Check if we have reach the End
  if ($window.scrollTop() <= $document.height() - $window.height() - $footer.height()) {
    return;
  }

  // Check if Scroll Auto-loading is Enable for the Current Tab
  if (scrollLoading.readings === false) {
    return;
  }
  MesJours.getReadingEpisodes($("#resume .article").length, 10).then(function (readings) {
    // Append Episodes
    readings.forEach(function (element) {
      $("<div/>", { class: "col sm-w-6c md-w-12c lg-w-12c p-2" })
        .append(
          window.nunjucks.render(`mes-jours/${element.model.toLowerCase()}.html`, {
            element: element,
          })
        )
        .appendTo("#resume > .container > .row");
    });

    // Check if we have to disable Scroll Loading
    if (readings.length < 10) {
      scrollLoading.readings = false;
    }
  });
}

// Listen Scroll Events
$window.on("scroll", throttle(scrollEventHandler, 1000, { leading: true, trailing: true }));

// Listen Click Events to Hide Search Results
$window.on("click.lesjours.mesjours", hideSearchResults);

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// INITIALIZE THE PAGE                                                                                                //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function initializePage() {
  const $pageId = $("#follows, #resume");
  if ($pageId.length === 0) {
    return;
  }

  // Switch
  switch ($pageId.attr("id")) {
    case "follows":
      if ($("#follows").children().length > 0) {
        break;
      }
      MesJours.getFollowedSeries().then(function (follows) {
        // Sort by title
        follows.sort((a, b) => (a[a.model.toLowerCase()].title < b[b.model.toLowerCase()].title ? -1 : 1));

        // Load Follows
        $("#follows").append(window.nunjucks.render("mes-jours/follows.html", { elements: follows }));

        // Bind Event Listeners
        $("#follows").on("keydown.lesjours.follows", ".search-input input[name=query]", keySearchHandlerFollow);
        $("#follows").on(
          "keyup.lesjours.follows",
          ".search-input input[name=query]",
          throttle(keySearchHandlerFollow, 250, { leading: true, trailing: true })
        );
        $("#follows").on("click.lesjours.follows", ".search-results .search-result", followSerie);
      });
      break;
    case "resume":
      if ($("#resume").children().length > 0) {
        break;
      }
      MesJours.getReadingEpisodes(0, 10)
        .then(function (readings) {
          // Load Readings
          $("#resume").append(window.nunjucks.render("mes-jours/resume.html", { elements: readings }));

          // Check if we have to disable Scroll Loading
          if (readings.length < 10) {
            scrollLoading.readings = false;
          }

          // Check if we have no readings episodes
          if (readings.length <= 0) {
            // Load the Obsessions JSON File
            return window.LesJours.series !== undefined
              ? $.Deferred().resolve(window.LesJours.series).promise()
              : $.loadJsonData("/series.json");
          }
        })
        .then(function (series) {
          // Check if we have readings
          if (series && series.length > 0) {
            // Load Series
            $("section#series-slider").append(
              window.nunjucks.render("series/recent-series-slider.html", { series: series })
            );

            // Initialize Slider
            initializeSlider($("section#series-slider .slider"));
          }
        });
      break;
    default:
      break;
  }
}
initializePage();
