import throttle from "lodash/throttle";
import $ from "jquery";

// Variables
const $window = $(window);
const $cover = $("#programme .programme-header .cover");
const $coverOverlay = $("#programme .programme-header .overlay.bg-black");
const $coverImage = $cover.find(".responsive-cover");
const $coverSnake = $cover.find(".snake-2");
const $series = $("section#series");
const $changeOrderBtn = $("#podcasts-order");

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// OBSESSIONS SLIDER
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function initializeSeriesSlider() {
  const $slider = $("section#series .slider");
  const cntWidth = $slider.innerWidth();

  // Initialize the Slider
  $window.trigger("ljComponentsInit", ["slider", $slider, cntWidth]);
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// LOAD REQUIRED JSONS                                                                                                //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$.when(
  // Load the Series JSON File
  window.LesJours.series !== undefined
    ? $.Deferred().resolve(window.LesJours.series).promise()
    : $.loadJsonData("/series.json")
).then(function (series) {
  // Load Series Covers
  $series.append(window.nunjucks.render("series/recent-series-slider.html", { series: series }));
  initializeSeriesSlider();
});

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FADE OUT COVER EFFECT                                                                                              //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function fadeOutEffect() {
  // Get Important Values
  const currentScroll = $window.scrollTop();
  const coverHeight = $cover.height();

  // Update the Opacity and the Transorm of the Cover
  if (currentScroll >= 0 && currentScroll <= coverHeight) {
    $coverOverlay.css("opacity", Math.round(100 * (currentScroll / coverHeight)) / 100);
    $coverImage.css("transform", "translate3d(0, -" + Math.round(currentScroll / 2) + "px, 0)");
    $coverSnake.css("opacity", 1 - Math.round(100 * (currentScroll / coverHeight)) / 100);
  }
}

$window.on("scroll", throttle(fadeOutEffect, 10, { leading: true, trailing: true }));
$.triggerOnLoad("lesjours.fadeouteffect", fadeOutEffect);

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// CHANGE EPISODES ORDER                                                                                              //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function changeOrder(event) {
  // Get Important Values
  const $changed = $(event.currentTarget);
  const $target = $($changed.data("target"));

  // Reverse Order
  $target.append($target.children().get().reverse());
}

$changeOrderBtn.on("change.lesjours.changeOrder", changeOrder);

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// RELOAD THE PLAYER PROGRESSION                                                                                      //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
if (window.LesJours.isMobileApplication !== true) {
  $window.on("ljMesJoursLoaded", () => {
    $(".js-audio-player").each((idx, el) => {
      $(el).getLesJoursPlayer().showSavedProgress();
    });
  });
}
