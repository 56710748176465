(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["ctas/home-newsletter.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "ctas/home-newsletter.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "\n<div id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ctaId"), env.opts.autoescape);
output += "\" class=\"cta cta-newsletter bg-lightest";
if(runtime.contextOrFrameLookup(context, frame, "hidden")) {
frame = frame.push();
var t_7 = runtime.contextOrFrameLookup(context, frame, "hidden");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("device", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += " ";
output += runtime.suppressValue(t_8, env.opts.autoescape);
output += "-hidden";
;
}
}
frame = frame.pop();
;
}
output += "\"";
if(runtime.contextOrFrameLookup(context, frame, "mtmContentPiece")) {
output += " data-track-content data-content-ignoreinteraction data-content-name=\"CTA - Home newsletter\" data-content-piece=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmContentPiece"), env.opts.autoescape);
output += "\"";
;
}
output += ">\n";
if(runtime.contextOrFrameLookup(context, frame, "colors")) {
output += "\t<style>\n\t\t#";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ctaId"), env.opts.autoescape);
output += " .cta-custom-bg {\n\t\t\tbackground-color: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"bgColor"), env.opts.autoescape);
output += ";\n\t\t\tcolor: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"textOnBgColor"), env.opts.autoescape);
output += ";\n\t\t}\n\n\t\t#";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ctaId"), env.opts.autoescape);
output += " .cta-custom-light-bg {\n\t\t\tbackground-color: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"lightBgColor"), env.opts.autoescape);
output += ";\n\t\t\tcolor: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"bgColor"), env.opts.autoescape);
output += ";\n\t\t}\n\n\t\t#";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ctaId"), env.opts.autoescape);
output += " .cta-custom-text {\n\t\t\tcolor: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"bgColor"), env.opts.autoescape);
output += ";\n\t\t}\n\n\t\t#";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ctaId"), env.opts.autoescape);
output += ".cta-newsletter form button[type=submit] {\n\t\t\tbackground-color: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"textOnBgColor"), env.opts.autoescape);
output += ";\n\t\t\tcolor: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"bgColor"), env.opts.autoescape);
output += " !important;\n\t\t}\n\t</style>\n";
;
}
output += "\t<div class=\"pv-2 ph-1 ";
if(runtime.contextOrFrameLookup(context, frame, "colors") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"lightBgColor")) {
output += "cta-custom-light-bg";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "colors")) {
output += "cta-custom-text";
;
}
else {
output += "bg-lesjours-light color-lesjours";
;
}
;
}
output += "\">\n\t\t<div class=\"h4 mb-0 text-center\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
output += "</div>\n\t</div>\n\t<div class=\"pv-2 ph-1 ";
if(runtime.contextOrFrameLookup(context, frame, "colors")) {
output += "cta-custom-bg";
;
}
else {
output += "bg-lesjours color-white";
;
}
output += "\">\n\t\t<div class=\"homepage-container\">\n\t\t\t<div class=\"cta-subtitle\" style=\"line-height: 1.6;\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subtitle"), env.opts.autoescape);
output += "</div>\n\t\t\t<div class=\"mt-1\">\n\t\t\t\t<form action=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "formAction"), env.opts.autoescape);
output += "\" method=\"POST\" class=\"mh-auto\">\n\t\t\t\t\t<div style=\"position: absolute; left: -5000px;\" aria-hidden=\"true\"><input type=\"text\" name=\"b_f6da3a0f0a1b597d37316a9ba_40a995c59d\" tabindex=\"-1\" value=\"\"></div>\n";
frame = frame.push();
var t_11 = runtime.contextOrFrameLookup(context, frame, "mceInputs");
if(t_11) {t_11 = runtime.fromIterator(t_11);
var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("input", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\t\t\t\t\t\t<input type=\"checkbox\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_12),"mceValue"), env.opts.autoescape);
output += "\" name=\"";
output += runtime.suppressValue(runtime.memberLookup((t_12),"mceName"), env.opts.autoescape);
output += "\" checked=\"checked\" />\n";
;
}
}
frame = frame.pop();
output += "\t\t\t\t\t<div class=\"row row-center\">\n\t\t\t\t\t\t<div class=\"col w-auto flex-1 mt-1\">\n\t\t\t\t\t\t\t<input class=\"input bg-lightest w-100 box-border\" name=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mceNameEmail"), env.opts.autoescape);
output += "\" type=\"email\" placeholder=\"votre email\" autocomplete=\"off\" required=\"required\" />\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class=\"col w-auto flex-0 mt-1\">\n\t\t\t\t\t\t\t<button type=\"submit\" name=\"subscribe\" class=\"text-upper pointer";
if(!runtime.contextOrFrameLookup(context, frame, "colors")) {
output += " bg-lightest color-lesjours";
;
}
output += "\" onclick=\"Cookies.set('mc_subscribed_nl', '";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mceSubscribedNl"), env.opts.autoescape);
output += "', { expires: 1 });\">Je m’inscris</button>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</form>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class=\"cta-note p-1 text-center\">\n\t\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "note"), env.opts.autoescape);
output += "\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("ctas/home-newsletter.html", ctx, cb); }
})();
