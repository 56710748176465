/* globals Modernizr:true */
import $ from "jquery";
import { sendMatomoTrackEvent } from "../libs/analytics";

// Constants
const $window = $(window);
const $body = $(document.body);

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SEND EPISODE OFFER FORM                                                                                            //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function submitOfferedEpisode(event) {
  // Prevent Default
  event.preventDefault();

  // Get the Form
  const $form = $(event.currentTarget);

  // Display the Spinner
  $form.addClass("sending");

  // Get the Data
  const gift = window.LesJours.episode.gift;
  const data = $.extend(
    {},
    {
      "offered-episode-href": gift.episode,
    },
    $form.serializeJSON()
  );

  // Send the Form Using Ajax
  $.ajax({
    url: $form.attr("action"),
    type: $form.attr("method"),
    data: data,
    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    dataType: "json",
  })
    .done(function (json) {
      // Check the status
      if (json.status) {
        // Show the Result Message
        $form.removeClass().addClass("sent");

        // Clear the Form
        $form.find("#offered-episode-email").val("");
        $form.find("#offered-episode-message").val("");
      } else {
        // Show the Error Result Message
        $form.removeClass().addClass("error");
      }
    })
    .fail(function () {
      // Show the Error Result Message
      $form.removeClass().addClass("error");
    });

  // Send a Analytics Event
  sendMatomoTrackEvent({
    category: "Sharing Buttons",
    action: "Partage",
    label: "Cadeau",
  });
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SEND SPONSORSHIP FORM                                                                                            //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function submitSponsorship(event) {
  // Prevent Default
  event.preventDefault();

  // Get the Form
  const $form = $(event.currentTarget);

  // Display the Spinner
  $form.addClass("sending");

  // Send the Form Using Ajax
  $.ajax({
    url: $form.attr("action"),
    type: $form.attr("method"),
    data: $form.serializeJSON(),
    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    dataType: "json",
  })
    .done(function (json) {
      // Check the status
      if (json.status) {
        // Show the Result Message
        $form.removeClass().addClass("sent");

        // Clear the Form
        $form.find("#sponsorship-email").val("");
        $form.find("#sponsorship-message").val("");
      } else {
        // Show the Error Result Message
        $form.removeClass().addClass("error");
      }
    })
    .fail(function () {
      // Show the Error Result Message
      $form.removeClass().addClass("error");
    });

  // Send a Analytics Event
  sendMatomoTrackEvent({
    category: "Sharing Buttons",
    action: "Partage",
    label: "Parrainage",
  });
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// MODAL MODULE                                                                                                       //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Get the CSS Transition End Event
function getTransitionEndEvent() {
  // Transition End Event
  const transitionEndEventNames = {
    WebkitTransition: "webkitTransitionEnd", // Saf 6, Android Browser
    MozTransition: "transitionend", // Only for FF < 15
    OTransition: "oTransitionEnd", // Only for Opera
    msTransition: "MSTransitionEnd", // Only for IE < 10
    transition: "transitionend", // IE10, Opera, Chrome, FF 15+, Saf 7+
  };
  // Browser Transition Prefix
  const prefix = Modernizr.csstransitions ? Modernizr.prefixed("transition") : false;

  // Check if the Browser Support Animations
  if (prefix && transitionEndEventNames[prefix] !== undefined) {
    // Return the Correct Event
    return transitionEndEventNames[prefix] + ".lesjours.slider";
  } else {
    // Return false
    return false;
  }
}

function transitionEnd(event) {
  // Remove the animation class
  event.data.animatedModal.removeClass(event.data.classToRemove);
}

function Modal() {
  this.transitionEndEvent = getTransitionEndEvent();
  this.$modals = $("#modals");
  this.scroll = 0;
  this.eventsToMobileApp =
    typeof window.LesJours.mobileApplication === "object" && window.LesJours.mobileApplication.build > 6554;
}

Modal.prototype.cancelTimeout = function () {
  if (this.$currentModal !== undefined) {
    // Stop the progress bar
    this.$currentModal.find(".loading-bar").stop(true);
    this.$currentModal.find(".loading-bar").css({ width: "0%" });
  }

  if (this.timeout !== undefined) {
    // Clear the timeout
    clearTimeout(this.timeout);
    this.timeout = undefined;
  }
};

Modal.prototype.addTimeout = function (delay, transition) {
  // Add progress bar
  const self = this;
  this.$currentModal.find(".loading-bar").css({ width: "0%" });
  setTimeout(function () {
    self.$currentModal &&
      self.$currentModal.find(".loading-bar").animate({ width: "100%" }, delay - transition, "linear");
  }, transition);

  // Create timeout
  this.timeout = setTimeout($.proxy(this.showNextModal, this), delay);
};

Modal.prototype.showNextModal = function () {
  if (!this.$currentModal) {
    return;
  }

  // Get the next slide
  let $nextModal = this.$currentModal.next();
  if (!$nextModal.length) {
    $nextModal = this.$currentModal.parent().children();
  }
  $nextModal = $($nextModal.get(0));

  // Show it
  this.handleHash("#" + $nextModal.attr("id"));
};

Modal.prototype.play = function (e) {
  // Prevent Default
  e.preventDefault();

  // Check if there is a Displayed Modal
  if (!this.$currentModal) {
    return;
  }

  // Get the Modal Group
  const $modalGroup = this.$currentModal.parent();
  const duration = $modalGroup.data("time");

  // Update class attribute of the slideshow
  $modalGroup.removeClass("pause").addClass("play");

  // Add a new timeout
  this.addTimeout(duration ? parseInt(duration) : 3000, 0);
};

Modal.prototype.pause = function (e) {
  // Prevent Default
  e.preventDefault();

  // Check if there is a Displayed Modal
  if (!this.$currentModal) {
    return;
  }

  // Update class attribute of the slideshow
  this.$currentModal.parent().removeClass("play").addClass("pause");

  // Remove the timeout
  this.cancelTimeout();
};

Modal.prototype.prev = function () {
  // Check if there is a Displayed Modal
  if (!this.$currentModal) {
    return;
  }

  if (this.$currentModal.find("[data-modal-rel]").length === 0) {
    return;
  }
  const $prevLink = $(
    this.$currentModal.find("[data-modal-rel=prev]").get(0) || this.$currentModal.find("[data-modal-rel=last]").get(0)
  );
  this.handleHash($prevLink.data("href") ? $prevLink.data("href") : $prevLink.attr("href"));
};

Modal.prototype.next = function () {
  // Check if there is a Displayed Modal
  if (!this.$currentModal) {
    return;
  }

  if (this.$currentModal.find("[data-modal-rel]").length === 0) {
    return;
  }
  const $nextLink = $(
    this.$currentModal.find("[data-modal-rel=next]").get(0) || this.$currentModal.find("[data-modal-rel=first]").get(0)
  );
  this.handleHash($nextLink.data("href") ? $nextLink.data("href") : $nextLink.attr("href"));
};

Modal.prototype.open = function ($modal, animate) {
  // Update Modal Display Property
  if ($modal.data("displayTimeout")) {
    this.cancelTimeout($modal.data("displayTimeout"));
  }
  $modal.addClass("block");

  // Add Swipe Events Listeners
  if ($modal.find("[data-modal-rel]").length > 0) {
    $modal
      .find('[role="document"]')
      .hammer()
      .on("swiperight.lesjours.modal", this.prev)
      .on("swipeleft.lesjours.modal", this.next);
  }

  // Use Animation
  if (animate) {
    $modal.addClass("animation-in");
    $modal.one(this.transitionEndEvent, null, { animatedModal: $modal, classToRemove: "animation-in" }, transitionEnd);
  }

  // Show Modal
  $modal.addClass("show-modal");
};

Modal.prototype.close = function ($modal, animate) {
  // Remove Swipe Events Listeners
  if ($modal.find("[data-modal-rel]").length > 0) {
    $modal.find('[role="document"]').hammer().off("swiperight.lesjours.modal").off("swipeleft.lesjours.modal");
  }

  // Use Animation
  if (animate) {
    $modal.addClass("animation-out");
    $modal.one(this.transitionEndEvent, null, { animatedModal: $modal, classToRemove: "animation-out" }, transitionEnd);
  }

  // Hide Modal
  $modal.removeClass("show-modal");

  // Update Modal Display Property
  $modal.data(
    "displayTimeout",
    setTimeout(function () {
      $modal.removeClass("block");
      $modal.removeData("displayTimeout");
    }, 500)
  );
};

Modal.prototype.closeOpenedModal = function () {
  // Get the Previous Modal
  let $modal = $(".show-modal");

  // Close the Display Modal if there is One
  if ($modal.length > 0) {
    this.close($($modal[0]), true);
  }

  // Remove .no-overflow class on the body
  $body.removeClass("no-overflow");

  // Reset the scroll
  $window.scrollTop(scroll || 0);
  this.scroll = 0;

  // Clear the current modal
  this.$currentModal = undefined;

  // Send an event to the mobile application
  if (this.eventsToMobileApp) {
    window.LesJoursWebViewChannels.postMessage(JSON.stringify({ action: "preventPop", value: false }));
  }
};

Modal.prototype.handleHash = function (hash) {
  let $modal;
  let $modalGroup;
  let templateName;
  let nunjucksTpl;

  // Remove previous timeout
  this.cancelTimeout();

  // Check if there is a hash
  if (/^#[a-z0-9.:_-]+$/i.test(hash ?? "")) {
    // Get the modal
    $modal = $(hash + "[role=dialog]");

    // Update the current scroll value
    this.scroll = this.scroll || $window.scrollTop();

    // Check if we have to load the modal into the DOM
    if ($modal.length === 0) {
      // Get the Template Name
      templateName = "modals/" + hash.replace(/^#/, "") + ".html";

      // Try to get the Template
      nunjucksTpl = window.nunjucks.getTemplate("modals/" + hash.replace(/^#/, "") + ".html", true, undefined, true);
      if (nunjucksTpl.path === templateName) {
        // Render the Modal
        this.$modals.append(nunjucksTpl.render());

        // Load Sign-in with if it's the Login Modal
        if (templateName === "modals/login.html") {
          $.loadSignInWith();
        }

        // Get the Added Modal
        $modal = $(hash + "[role=dialog]");
      }
    }

    // Check if there is a modal
    if ($modal.length > 0) {
      // Get the Modal
      $modal = $($modal[0]);

      // Save the current modal
      this.$currentModal = $modal;

      // Listen Transition End Event to set .prevent-transition class on the Body
      if (!$body.hasClass("no-overflow")) {
        // Add .no-overflow class on the body
        $body.addClass("no-overflow");

        // Show the Modal
        this.open($modal, true);
      } else {
        // Close the Previous Modal if there is One
        if ($(".show-modal").length > 0) {
          this.close($($(".show-modal")[0]), false);
        }

        // Show the Next Modal
        this.open($modal, false);
      }

      // Load Every iFrame in the Modal
      $modal.find("iframe[data-src]").each(function (idx, iframe) {
        $(iframe).attr("src", $(iframe).attr("data-src")).removeAttr("data-src");
      });

      // Start autoplay videos
      $modal.find("video[autoplay]").each(function (idx, video) {
        video.play();
      });

      // Check if the Modal is in a Slideshow
      if ($modal.parent().data("time") !== undefined) {
        // The current modal displayed is a slideshow's element
        // Check if we have to start a timer
        $modalGroup = $modal.parent();
        if ($modalGroup.hasClass("play")) {
          // Add a new timeout
          this.addTimeout(parseInt($modalGroup.data("time")), parseInt($modalGroup.data("transition")));
        }
      }

      // Send an event to the mobile application
      if (this.eventsToMobileApp) {
        window.LesJoursWebViewChannels.postMessage(JSON.stringify({ action: "preventPop", value: true }));
      }
    }
  } else {
    // Close the Displayed Modal if there is One
    this.closeOpenedModal();
  }
};

Modal.prototype.exist = function (hash) {
  let $modal;
  let templateName;
  let nunjucksTpl;

  // Check if there is a hash
  if (/^#[a-z0-9.:_-]+$/i.test(hash ?? "")) {
    // Get the modal
    $modal = $(hash + "[role=dialog]");

    // Check if we have to load the modal into the DOM
    if ($modal.length === 0) {
      // Get the Template Name
      templateName = "modals/" + hash.replace(/^#/, "") + ".html";

      // Try to get the Template
      nunjucksTpl = window.nunjucks.getTemplate("modals/" + hash.replace(/^#/, "") + ".html", false, undefined, true);
      if (nunjucksTpl.path === templateName) {
        // There is a modal
        return true;
      } else {
        // There is no template : it's not a modal
        return false;
      }
    }

    // Check if there is a modal
    if ($modal.length > 0) {
      // There is a modal
      return true;
    }
  } else {
    // There is no hash : it's not a modal
    return false;
  }
};

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// INITIALIZE THE MODAL MODULE                                                                                        //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const modal = new Modal();

// Bind Clicks Events Listener on Anchors
$body.on("click", "a[href^='#']", function (e) {
  // Check if the hash is not empty
  const hash = $(e.currentTarget).attr("href");
  if (hash === "#") {
    // Nothing to do
    return;
  }

  // Check if there is a modal for the hash
  if (modal.exist(hash)) {
    // Prevent Default
    e.preventDefault();

    // Trigger Hash Event
    $window.trigger("ljHashChange", hash);
  }
});

// Bind Hash Change Listener
$window.on("hashchange.lesjours.modal", function () {
  // Handle the Browser Hash Change Event
  modal.handleHash(window.location.hash === "#" ? "" : window.location.hash);
});
$window.on("ljHashChange.lesjours.modal", function (event, newHash) {
  // Handle our Custom Hash Change Event
  modal.handleHash(newHash === "#" ? "" : newHash);
});

// Bind Keydown Events Listener
$window.on("keydown.lesjours.modal", function (e) {
  switch (e.keyCode) {
    case 39:
      return modal.next(); // →
    case 37:
      return modal.prev(); // ←
    case 27:
      return modal.closeOpenedModal(); // echap
  }
});

// Bind Click Listeners on Modals
$("body").on("click.lesjours.modal", ".modal .play-button", $.proxy(modal.play, modal));
$("body").on("click.lesjours.modal", ".modal .pause-button", $.proxy(modal.pause, modal));
$("body").on("click.lesjours.modal", ".modal", function (e) {
  // Close the modal when clicking on its background
  if ($(e.target).hasClass("modal")) {
    // Close the modal
    modal.handleHash("");
  }
});

// Bind Submit Listeners on Modals
$("body").on("submit.lesjours.modals", "#offered-episode form", submitOfferedEpisode);
$("body").on("submit.lesjours.modals", "#sponsorship form", submitSponsorship);

// Listen Component Reload Events
$window.on("ljComponentsReload", function (event, component) {
  if (component === "modal") {
    // Check if a hash is present so we could display the corresponding modal
    if (window.location.hash && window.location.hash !== "#" && !/\s+/.test(window.location.hash)) {
      // Handle the Browser Hash Change Event
      modal.handleHash(window.location.hash === "#" ? "" : window.location.hash);
    }
  }
});

// Check if we are in the Mobile Application
if (!window.LesJours.isMobileApplication) {
  // Check if we are on a WebView on the Homepage
  if ($.isIOSWebView() && (window.location.pathname === "/" || window.location.pathname === "")) {
    // Render Forgot Modals
    modal.$modals.append(window.nunjucks.render("modals/motdepasse.html", {}));
    modal.$modals.append(window.nunjucks.render("modals/motdepasse-envoye.html", {}));
    modal.$modals.append(window.nunjucks.render("modals/motdepasse-erreur.html", {}));

    // Remove the close button on forgot modals
    $("#motdepasse form").css("paddingTop", "1.5rem").find(".close").hide();
    $("#motdepasse-envoye form").css("paddingTop", "1.5rem").find(".close").hide();
    $("#motdepasse-erreur form").css("paddingTop", "1.5rem").find(".close").hide();
  }

  // Check if we must show the login modal with an email
  if (window.LoginModal !== undefined) {
    // Preset the Email
    $("#login-existant.modal input#login-mail").val(window.LoginModal);

    // Display the Login Modal
    modal.handleHash("#login-existant");
    // Check if a hash is present so we could display the corresponding modal
  } else if (window.location.hash && window.location.hash !== "#" && !/\s+/.test(window.location.hash)) {
    // Handle the Browser Hash Change Event
    modal.handleHash(window.location.hash === "#" ? "" : window.location.hash);
  }
}
