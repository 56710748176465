(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["mes-jours/follows.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "mes-jours/follows.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "elements")) > 0) {
output += "<div class=\"container\">\n\t<p class=\"style-meta-normal fs-italic m-0 pt-2\">\n\t\tVous serez prévenu par un email lors de la publication d’un nouvel épisode dans l’une de vos séries suivies.\n\t\tPour ne pas recevoir d’alerte, <a class=\"link\" href=\"/emailing/\">cliquez ici</a>.\n\t</p>\n\t<div class=\"search-form relative pt-2\">\n\t\t<div class=\"row m-0\">\n\t\t\t<div class=\"flex-0 search-tip col-center pr-1\">\n\t\t\t\t";
output += runtime.suppressValue((lineno = 10, colno = 11, runtime.callWrap(t_4, "icon", context, ["search"])), env.opts.autoescape);
output += "\n\t\t\t</div>\n\t\t\t<div class=\"flex-1 search-input\">\n\t\t\t\t<input type=\"text\" name=\"query\" placeholder=\"Rechercher une série\" autocomplete=\"off\" aria-label=\"Rechercher une série\">\n\t\t\t</div>\n\t\t</div>\n\t\t<div class=\"search-results bg-lightest\"></div>\n\t</div>\n\t<div class=\"row\">\n";
frame = frame.push();
var t_7 = runtime.contextOrFrameLookup(context, frame, "elements");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("element", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0") > 0) {
output += "\t\t<div class=\"col sm-p-0 md-p-0\">\n\t\t\t<div class=\"stripe grey-stripe\"></div>\n\t\t</div>\n";
;
}
if(runtime.memberLookup((t_8),"model") === "Obsession") {
output += "\t\t<div class=\"col sm-w-6c md-w-12c lg-w-12c pv-5\">\n";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("mes-jours/obsession.html", false, "mes-jours/follows.html", false, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
callback(null,t_9);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
callback(null,t_11);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\t\t</div>\n";
});
}
else {
if(runtime.memberLookup((t_8),"model") === "Programme") {
output += "\t\t<div class=\"col sm-w-6c md-w-12c lg-w-12c pv-5\">\n";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("mes-jours/programme.html", false, "mes-jours/follows.html", false, function(t_14,t_13) {
if(t_14) { cb(t_14); return; }
callback(null,t_13);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
callback(null,t_15);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\t\t</div>\n";
});
}
;
}
;
}
}
frame = frame.pop();
output += "\t</div>\n</div>\n";
;
}
else {
output += "<div class=\"container\">\n\t<div class=\"search-form relative pt-2\">\n\t\t<div class=\"row m-0\">\n\t\t\t<div class=\"flex-0 search-tip col-center pr-1\">\n\t\t\t\t";
output += runtime.suppressValue((lineno = 42, colno = 11, runtime.callWrap(t_4, "icon", context, ["search"])), env.opts.autoescape);
output += "\n\t\t\t</div>\n\t\t\t<div class=\"flex-1 search-input\">\n\t\t\t\t<input type=\"text\" name=\"query\" placeholder=\"Rechercher une série\" autocomplete=\"off\" aria-label=\"Rechercher une série\">\n\t\t\t</div>\n\t\t</div>\n\t\t<div class=\"search-results bg-lightest\"></div>\n\t</div>\n</div>\n<div class=\"container mv-4\">\n\t<div class=\"text-center style-meta-larger bold color-dark p-1\">\n\t\tRetrouvez ici vos séries préférées\n\t</div>\n\t<div class=\"text-center p color-medium-5 p-1\">\n\t\tVous aimez particulièrement une série des <i>Jours</i>&nbsp;?\n\t\t<br />\n\t\tSuivez-la et soyez informé·e de la parution d’un nouvel épisode.\n\t</div>\n</div>\n<div class=\"bg-lighter\">\n\t<div class=\"container\">\n\t\t<img class=\"responsive\" src=\"/img/mes-jours/obsession-suivie.png\" alt=\"Suivre\" />\n\t</div>\n</div>\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("mes-jours/follows.html", ctx, cb); }
})();
