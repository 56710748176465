import $ from "jquery";

// Bind Click Event Listener
$("body").on("click.lesjours.collapse", "[data-toggle=collapse]", function (e) {
  // Check if the target is a link
  if ($(e.target).is("a")) {
    // Don't process this event
    return;
  }

  // Prevent Default Action
  e.preventDefault();

  // Get Elements
  const $button = $(e.currentTarget);
  const $target = $($button.data("target"));
  const $group = $target.parents("[aria-multiselectable=true]");

  // Check if the Button is Collapsed
  if (!$button.hasClass("collapsed")) {
    // Hide the Target
    $target.removeClass("show").attr("aria-expanded", false);

    // Update the Button
    $button.addClass("collapsed").attr("aria-expanded", false);

    // Trigger Event on the Target
    $target.trigger("hidden.lj.collapse", [$button, $target]);
  } else {
    // Hide all Elements of the Group
    $group.find(".collapse[aria-expanded=true]").removeClass("show").attr("aria-expanded", false);
    $group.find("[data-toggle=collapse][aria-expanded=true]").addClass("collapsed").attr("aria-expanded", false);

    // Show the Target
    $target.addClass("show").attr("aria-expanded", true);

    // Update the Button
    $button.removeClass("collapsed").attr("aria-expanded", true);

    // Trigger Event on the Target
    $target.trigger("shown.lj.collapse", [$button, $target]);
  }
});
