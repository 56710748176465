(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["modals/emails-unsubscribe.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "modals/emails-unsubscribe.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<div id=\"emails-unsubscribe\" class=\"modal centered\" role=\"dialog\" tabindex=\"-1\">\n\t<div role=\"document\">\n\t\t<div class=\"row row-center\">\n\t\t\t<div class=\"col m-0 ph-0 pb-2 h5 fw-bold row row-center row-justify color-darkest\">\n\t\t\t\tDésabonnement\n\t\t\t</div>\n\t\t\t<span class=\"a btn close\" data-href=\"#\">\n\t\t\t\t<span class=\"sr\">Fermer cette fenêtre</span>\n\t\t\t\t";
output += runtime.suppressValue((lineno = 9, colno = 11, runtime.callWrap(t_4, "icon", context, ["close"])), env.opts.autoescape);
output += "\n\t\t\t</span>\n\t\t\t<div class=\"col mb-2 fw-semi-bold\">\n\t\t\t\tVous avez bien été désabonné.\n\t\t\t\t<br />\n\t\t\t\tVous ne recevrez plus d’e-mails de la part des <i>Jours</i>.\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("modals/emails-unsubscribe.html", ctx, cb); }
})();
