import $ from "jquery";

// Update the form when selecting a voucher type
$("section#codes-promos form input.radio").on("change.lesjours.administration", function () {
  if ($(this).is(":checked")) {
    // Get the Form
    const $form = $(this).parents("form").eq(0);

    // Disable all .js-voucher-* forms
    $form
      .find("div[class^=js-voucher-]")
      .addClass("hidden")
      .find("input, select")
      .prop("required", false)
      .prop("disabled", true);

    // Enable the selected .js-voucher-* form
    $form
      .find("div.js-voucher-" + $(this).val())
      .removeClass("hidden")
      .find("input, select")
      .prop("disabled", false)
      .filter(function (idx, el) {
        const $el = $(el);
        if (!$el.attr("name")) {
          return true;
        }
        return !$(el)
          .attr("name")
          .match(
            /voucher-(price|duration|period)-(jouriste-2020|jouriste-desargente-2020|jouriste-biannuel-2020|jouriste-cash-2020|jouriste-lapresselibre)/
          );
      })
      .prop("required", true);
  }
});

// Update the form when selecting a notification target
$("section#notifications form input.radio").on("change.lesjours.administration", function () {
  if ($(this).is(":checked")) {
    // Get the Form
    const $el = $(this);
    const $form = $el.parents("form").eq(0);

    // Check the selected target
    if ($el.val() === "user") {
      $form
        .find("input[name=notification-email]")
        .prop("required", true)
        .prop("disabled", false)
        .parent()
        .removeClass("hidden");
    } else {
      $form
        .find("input[name=notification-email]")
        .prop("required", false)
        .prop("disabled", true)
        .parent()
        .addClass("hidden");
    }
  }
});

// Update the form when selecting a notification target
$("section#pending").on("change.lesjours.administration", "#filter-invoices", function () {
  // Get the Form
  const $el = $(this);
  const $table = $("section#pending").find($el.data("target")).eq(0);

  // Check the selected target
  switch ($el.val()) {
    case "closed-invoice":
      $table.find(".pending-invoice").addClass("hidden");
      $table.find(".closed-invoice").removeClass("hidden");
      break;
    case "pending-invoice":
      $table.find(".closed-invoice").addClass("hidden");
      $table.find(".pending-invoice").removeClass("hidden");
      break;
    default:
      $table.find(".closed-invoice, .pending-invoice").removeClass("hidden");
  }
});

// Listen Click Events on XHR Submit Buttons
$(".xhr-submit-btn").on("click", function (event) {
  // Get the jQuery Element
  const $button = $(event.currentTarget);
  const $section = $($button.parents("section").get(0));
  const $form = $section.find("form");
  const $results = $section.find("div.js-xhr-response");

  // Prevent Default
  event.preventDefault();
  event.stopPropagation();

  // Send the Form Using Ajax
  $.ajax({
    url: $form.attr("action"),
    type: "POST",
    data: $form.serializeJSON(),
  }).then(function (html) {
    // Update the Result Content
    $results.html(html);
  });
});

// Listen Clicks Events on Partnership Edit Buttons
$("#partners").on("click.lesjours.administration", "a[data-edit-partnership]", function (event) {
  // Get jQuery Elements
  const $button = $(event.currentTarget);
  const $table = $button.parents("table").eq(0);
  const $form = $("#partners").find("form").eq(0);
  const partnership = $table.data("partnership");

  // Prevent Default
  event.preventDefault();

  // Fill the Partnership Form
  $form.prepend($("<input>", { type: "hidden", name: "edit-partnership", value: partnership.id }));
  $form.find("[name=partner-type][value=" + partnership.type + "]").prop("checked", true);
  $form.find("[name=partner-key]").val(partnership.apikey);
  $form.find("[name=partner-secret]").val(partnership.apisecret);
  $form.find("[name=partner-offer]").val(partnership.offer);
  $form.find("[name=partner-quantity]").val(partnership.duration.split(" ")[0]);
  $form.find("[name=partner-duration]").val(partnership.duration.split(" ")[1].replace(/s$/, ""));
  $form.find("[name=partner-meta]").val(partnership.messages.meta);
  $form.find("[name=partner-name]").val(partnership.messages.partnername);
});

// Listen clics on some forecast form buttons
$("#forecast .js-update-plans-buttons button").on("click.lesjours.administration", function (event) {
  // Get jQuery Elements
  const $button = $(event.currentTarget);
  const $form = $button.parents("form").eq(0);
  const $inputs = $form.find("input[name='plan[]']");
  const plans = $button.data("plans").split(",");

  // Prevent Default
  event.preventDefault();

  // Uncheck all
  $inputs.each(function (idx, el) {
    $(el).prop("checked", false);
  });

  // Check selected ones
  plans.forEach(function (duration) {
    $inputs.filter("[data-plan='" + duration + "']").each(function (idx, el) {
      $(el).prop("checked", true);
    });
  });
});
$("#forecast .js-update-dates-buttons button").on("click.lesjours.administration", function (event) {
  // Get jQuery Elements
  const $button = $(event.currentTarget);
  const $form = $button.parents("form").eq(0);

  // Prevent Default
  event.preventDefault();

  // Update inputs
  $form.find("input[name='from']").val($button.data("from"));
  $form.find("input[name='to']").val($button.data("to"));
});
