(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["episode/classic/slideshow-image.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "episode/classic/slideshow-image.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<div id=\"slideshow-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"href"), env.opts.autoescape);
output += "\" class=\"modal centered\" role=\"dialog\" tabindex=\"-1\">\n\t<div class=\"w-100 h-100 ph-0 pt-0 fullscreen\" role=\"document\">\n\t\t<div class=\"row h-100 m-0 row-vertical\">\n\t\t\t<div class=\"col p-0 w-100\"><span class=\"loading-bar\" style=\"width:0px;\"></span></div>\n\t\t\t<div class=\"col mv-2 obsession-name\">\n\t\t\t\t<span class=\"link on-hover lj-color-text text-upper\" data-href=\"/obsessions/";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"href"), env.opts.autoescape);
output += "/\">\n\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"name"), env.opts.autoescape);
output += "\n\t\t\t\t</span>\n\t\t\t</div>\n\t\t\t<span class=\"a btn close\" data-href=\"#\">\n\t\t\t\t<span class=\"sr\">Fermer cette fenêtre</span>\n\t\t\t\t";
output += runtime.suppressValue((lineno = 12, colno = 11, runtime.callWrap(t_4, "icon", context, ["close"])), env.opts.autoescape);
output += "\n\t\t\t</span>\n\t\t\t<div class=\"col flex-1 relative overflow-auto\">\n\t\t\t\t<div class=\"overlay container fluid\">\n\t\t\t\t\t<div class=\"row h-100\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"files")) {
output += "\t\t\t\t\t\t<div class=\"col sm-mb-2 md-mb-2 lg-min-h-100 lg-h-100 lg-min-w-25 lg-max-w-75 lg-w-auto\">\n\t\t\t\t\t\t\t<div class=\"lg-h-100 slideshow pause\" data-duration=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"duration"), env.opts.autoescape);
output += "\" data-autoplay=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"autoplay"), env.opts.autoescape);
output += "\" data-animation=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"animation"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t<div class=\"slideshow-container\">\n";
frame = frame.push();
var t_7 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"files");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("file", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\t\t\t\t\t\t\t\t\t<div class=\"slideshow-slide";
if((!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"autoplay")) && (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0") == 0)) {
output += " sl-current";
;
}
output += "\" data-timing=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"timings")),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0")), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t\t<img class=\"responsive lazyload\" data-src=\"";
output += runtime.suppressValue(t_8, env.opts.autoescape);
output += "\" data-sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, ["100vw","100vw","75vw"]), env.opts.autoescape);
output += "\" data-srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, t_8), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"alt"))), env.opts.autoescape);
output += "\" />\n\t\t\t\t\t\t\t\t\t</div>\n";
;
}
}
frame = frame.pop();
output += "\t\t\t\t\t\t\t\t</div>\n";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"autoplay")) {
output += "\t\t\t\t\t\t\t\t<div class=\"slideshow-buttons\">\n\t\t\t\t\t\t\t\t\t<button class=\"btn-svg btn-medium btn-hover-lesjours\" data-action=\"pause\"><i>";
output += runtime.suppressValue((lineno = 29, colno = 93, runtime.callWrap(t_4, "icon", context, ["pause","Pause","initial"])), env.opts.autoescape);
output += "</i></button>\n\t\t\t\t\t\t\t\t\t<button class=\"btn-svg btn-medium btn-hover-lesjours\" data-action=\"play\"><i>";
output += runtime.suppressValue((lineno = 30, colno = 92, runtime.callWrap(t_4, "icon", context, ["play","Lire","initial"])), env.opts.autoescape);
output += "</i></button>\n\t\t\t\t\t\t\t\t</div>\n";
;
}
output += "\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n";
;
}
else {
output += "\t\t\t\t\t\t<img class=\"col min-h-100 h-100 w-auto max-w-75 sm-hidden md-hidden fit-contain lazyload\" data-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"img"), env.opts.autoescape);
output += "\" data-sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, ["100vw","100vw","75vw"]), env.opts.autoescape);
output += "\" data-srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"img")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"alt"))), env.opts.autoescape);
output += "\" />\n\t\t\t\t\t\t<div class=\"col mb-2 lg-hidden\">\n\t\t\t\t\t\t\t<img class=\"responsive lazyload\" data-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"img"), env.opts.autoescape);
output += "\" data-sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, ["100vw","100vw","75vw"]), env.opts.autoescape);
output += "\" data-srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"img")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"alt"))), env.opts.autoescape);
output += "\" />\n\t\t\t\t\t\t</div>\n";
;
}
output += "\t\t\t\t\t\t<div class=\"col mb-1 flex-1 flex-basis-0 min-w-0 underlined-links col-bottom\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"title")) {
output += "<span class=\"block mb-2 style-meta-normal light fs-italic\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"title"), env.opts.autoescape);
output += "</span>";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"credit")) {
output += "<span class=\"block mb-2 credits\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"credit"), env.opts.autoescape);
output += "</span>";
;
}
output += "\t\t\t\t\t\t\t<div class=\"row m-0 row-center lg-pull-right\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "images")),"length") > 1) {
output += "\t\t\t\t\t\t\t\t\t<meta class=\"hidden\" data-modal-rel=\"first\" href=\"#slideshow-";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "images")),0)),"href"), env.opts.autoescape);
output += "\" />\n\t\t\t\t\t\t\t\t\t<meta class=\"hidden\" data-modal-rel=\"last\" href=\"#slideshow-";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "images")),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "images")),"length") - 1)),"href"), env.opts.autoescape);
output += "\" />\n";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"first")) {
output += "\t\t\t\t\t\t\t\t\t<button class=\"btn-svg btn-medium btn-hover-lesjours\" data-href=\"#slideshow-";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "images")),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0") - 1)),"href"), env.opts.autoescape);
output += "\" data-modal-rel=\"prev\">\n\t\t\t\t\t\t\t\t\t\t<i>";
output += runtime.suppressValue((lineno = 50, colno = 20, runtime.callWrap(t_4, "icon", context, ["previous","Précédent","initial"])), env.opts.autoescape);
output += "</i>\n\t\t\t\t\t\t\t\t\t</button>\n";
;
}
output += "\t\t\t\t\t\t\t\t\t<span class=\"flex-1 mh-1 style-meta-medium text-center color-lesjours\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "/";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "images")),"length"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t\t\t\t<button class=\"btn-svg btn-medium btn-hover-lesjours mr-1 pause-button\">\n\t\t\t\t\t\t\t\t\t\t<i>";
output += runtime.suppressValue((lineno = 55, colno = 20, runtime.callWrap(t_4, "icon", context, ["pause","Pause","initial"])), env.opts.autoescape);
output += "</i>\n\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t<button class=\"btn-svg btn-medium btn-hover-lesjours mr-1 play-button\">\n\t\t\t\t\t\t\t\t\t\t<i>";
output += runtime.suppressValue((lineno = 58, colno = 20, runtime.callWrap(t_4, "icon", context, ["play","Lire","initial"])), env.opts.autoescape);
output += "</i>\n\t\t\t\t\t\t\t\t\t</button>\n";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"last")) {
output += "\t\t\t\t\t\t\t\t\t<button class=\"btn-svg btn-medium btn-hover-lesjours\" data-href=\"#slideshow-";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "images")),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0") + 1)),"href"), env.opts.autoescape);
output += "\" data-modal-rel=\"next\">\n\t\t\t\t\t\t\t\t\t\t<i>";
output += runtime.suppressValue((lineno = 62, colno = 20, runtime.callWrap(t_4, "icon", context, ["next","Suivant","initial"])), env.opts.autoescape);
output += "</i>\n\t\t\t\t\t\t\t\t\t</button>\n";
;
}
;
}
output += "\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("episode/classic/slideshow-image.html", ctx, cb); }
})();
