/* globals Modernizr:true */
import throttle from "lodash/throttle";
import $ from "jquery";
import lazySizes from "lazysizes";

// Constants
const $window = $(window);

// Variables
let $currentModal;

// Get the CSS Animation End Event
function getAnimationEndEvent() {
  // Animation End Event
  const animationEndEventNames = {
    WebkitAnimation: "webkitAnimationEnd", // Saf 6, Android Browser
    MozAnimation: "animationend", // Only for FF < 15
    OAnimation: "oAnimationEnd", // Only for Opera
    msAnimation: "MSAnimationEnd", // Only for IE < 10
    animation: "animationend", // IE10, Opera, Chrome, FF 15+, Saf 7+
  };
  // Browser Animation Prefix
  const prefix = Modernizr.cssanimations ? Modernizr.prefixed("animation") : false;

  // Check if the Browser Support Animations
  if (prefix && animationEndEventNames[prefix] !== undefined) {
    // Return the Correct Event
    return animationEndEventNames[prefix] + ".lesjours.slideshow";
  } else {
    // Return false
    return false;
  }
}

// Variables
const animEndEvent = getAnimationEndEvent();
const fakeEndEvent = "fakeAnimationEnd.lesjours.slideshow";

// Show the Next Slide
function showNextSlide($slideshow, $slides, $current, $next) {
  // Get the Animation Time
  const animTime = parseInt($slideshow.data("duration"), 10);

  // Remove all sl-* classes for other slides
  $slides
    .filter((index, slide) => slide !== $current.get(0) && slide !== $next.get(0))
    .removeClass("sl-move-in sl-move-out sl-current");

  // Check if it's a cut
  if (animTime === 0) {
    // Display the Next Slide
    $next.removeClass("sl-move-in sl-move-out").addClass("sl-current");
  } else {
    // Animate the Slideshow
    // Move out the current slide
    $current.removeClass("sl-move-in sl-current").addClass("sl-move-out");
    // Move in the next slide
    $next.removeClass("sl-move-out").addClass("sl-move-in sl-current");
  }

  // Trigger the Event Mannualy if the Browser does not support animationend events
  if (animEndEvent === false) {
    setTimeout(() => $next.trigger(fakeEndEvent), animTime);
  }
}

// Animation Function : Translate
function next($slideshow) {
  // Get slideshow elements
  const $slides = $slideshow.find(".slideshow-slide");
  const $current = $slides.filter(".sl-current");
  const $next = $current.next().length ? $current.next() : $($slides.get(0));

  // Show the Next Slide
  showNextSlide($slideshow, $slides, $current, $next);
}

function prev($slideshow) {
  // Get slideshow elements
  const $slides = $slideshow.find(".slideshow-slide");
  const $current = $slides.filter(".sl-current");
  const $next = $current.prev().length ? $current.prev() : $($slides.get($slides.length - 1));

  // Show the Next Slide
  showNextSlide($slideshow, $slides, $current, $next);
}

function animationEnd(event) {
  // Get slideshow elements
  const $current = $(event.currentTarget);
  const $slideshow = $current.parents(".slideshow");

  // Check if the event is triggered on the current slide
  if (!$current.hasClass("sl-current")) {
    // Stop processing the event
    return;
  }

  // Check if we are playing the current slideshow
  if ($slideshow.hasClass("play")) {
    // Wait the required amount of time & Display the next slide
    $slideshow.data(
      "timer",
      setTimeout(
        function () {
          // Display the Next Slide
          next($slideshow);
        },
        parseInt($current.data("timing"), 10)
      )
    );
  }
}

function pause($slideshow) {
  // Get Slides
  const $slides = $slideshow.find(".slideshow-slide");

  // Update the Slideshow
  $slideshow.removeClass("play").addClass("pause");

  // Clear the Animation Duration
  $slideshow.find(".slideshow-slide").css("animation-duration", "");

  // Clear Animation End Events Listeners
  $slides.off(animEndEvent !== false ? animEndEvent : fakeEndEvent);

  // Clear Timeouts
  clearTimeout($slideshow.data("timer"));
}

function play($slideshow) {
  // Get Slides
  const $slides = $slideshow.find(".slideshow-slide");
  const $current = $slides.filter(".sl-current");

  // Update the Slideshow
  $slideshow.removeClass("pause").addClass("play");

  // Define the Animation Duration
  $slides.css("animation-duration", parseInt($slideshow.data("duration"), 10) + "ms");

  // Listen Animation End Events
  $slides.on(animEndEvent !== false ? animEndEvent : fakeEndEvent, animationEnd);

  // Wait the required amount of time & Display the next slide
  $slideshow.data(
    "timer",
    setTimeout(
      function () {
        // Display the Next Slide
        next($slideshow);
      },
      parseInt($current.data("timing"), 10)
    )
  );
}

function stopPropagation(e) {
  // Prevent Default
  e.preventDefault();
  e.stopPropagation();
}

function processClickEvent(e) {
  // Prevent Default
  stopPropagation(e);

  // Get Clicked Button & Slideshow
  const $button = $(e.currentTarget);
  const $slideshow = $($button.parents(".slideshow").get(0));

  // Trigger the Correct Function
  switch ($button.data("action")) {
    case "play":
      return play($slideshow);
    case "pause":
      return pause($slideshow);
    case "prev":
      return prev($slideshow);
    case "next":
      return next($slideshow);
  }
}

function getRealWidth($element) {
  return (
    parseInt($element.css("width").replace("px", ""), 10) -
    parseInt($element.css("paddingLeft").replace("px", ""), 10) -
    parseInt($element.css("paddingRight").replace("px", ""), 10)
  );
}

function updateSizes($slideshow, windowWidth, largeDevice) {
  // Get slideshow elements
  const $slides = $slideshow.find(".slideshow-slide");
  let maxHeight;
  let maxWidth;

  // Check if all Images are Loaded
  if ($slideshow.find(".lazyload, .lazyloading").length > 0) {
    // Ask Images to be Loaded
    $slideshow.find(".lazyload").each(function (idx, el) {
      lazySizes.loader.unveil(el);
    });

    // Update Sizes after short Time
    return setTimeout(function () {
      updateSizes($slideshow, windowWidth, largeDevice);
    }, 100);
  }
  $slideshow.data("prevWinWidth", windowWidth);

  // Check if it's a vertical slideshow (.lg-h-100 slideshow on large device)
  if ($slideshow.hasClass("lg-h-100") && largeDevice) {
    // Get the max width & the max height
    if ($slideshow.parent().css("maxWidth").match(/%$/)) {
      maxWidth = Math.floor(
        (parseInt($slideshow.parent().css("maxWidth").replace("%", ""), 10) *
          getRealWidth($slideshow.parent().parent())) /
          100
      );
    } else {
      maxWidth = parseInt($slideshow.parent().css("maxWidth").replace("px", ""), 10);
    }
    maxHeight = $slideshow.parent().height();

    // Keep the min width beetween images & container
    maxWidth = Math.min(
      maxWidth,
      Math.max.apply(
        Math,
        $slides
          .find("img")
          .map(function () {
            return Math.floor((this.naturalWidth * maxHeight) / this.naturalHeight);
          })
          .get()
      )
    );

    // Define slideshow width
    $slideshow.css({ width: maxWidth + "px" });

    // Define image width
    $slides.css({ width: maxWidth + "px" });
  } else {
    // Reset slideshow width
    $slideshow.css({ width: "" });

    // Define image width
    $slides.css({ width: $slideshow.css("width") });

    // Get the max height
    maxHeight = Math.max.apply(
      null,
      $slides
        .find("img")
        .map(function () {
          return $(this).height();
        })
        .get()
    );

    // Check if the height has changed
    if (maxHeight !== $slideshow.height()) {
      // Update the Slideshow Height
      $slideshow.height(maxHeight);

      // Ask Minis Re-Layout
      $window.trigger("ljComponentsRelayout", "mini");
    }
  }
}

function layout(forced) {
  // Variables
  const windowWidth = $window.width();
  const largeDevice = windowWidth >= 840;

  // Update slides sizes
  $(".slideshow").each(function (index, element) {
    // Get slideshow elements
    const $slideshow = $(element);

    // Check if the Slideshow is in a Displayed Modal
    if (
      $slideshow.parents(".modal").length > 0 &&
      (!$currentModal || $currentModal.attr("id") !== $slideshow.parents(".modal").attr("id"))
    ) {
      return;
    }

    // Check if it's a Responsive Slideshow & if we have to Update the Element
    if (
      $slideshow.data("responsive") === false &&
      $slideshow.data("prevWinWidth") === windowWidth &&
      forced === false
    ) {
      return;
    }

    // Update Slideshow Sizes
    updateSizes($slideshow, windowWidth, largeDevice);
  });
}

function handleHash(hash) {
  // Update the Current Modal
  $currentModal = /^#[a-z0-9.:_-]+$/i.test(hash ?? "") ? $(hash + "[role=dialog]") : undefined;

  // Update Layout
  layout(false);

  // Stop hidden Slideshows
  $(".slideshow.play").each(function (index, element) {
    // Get slideshow elements
    const $slideshow = $(element);

    // Check if the Slideshow is in a Displayed Modal
    if (
      $slideshow.parents(".modal").length > 0 &&
      (!$currentModal || $currentModal.attr("id") !== $slideshow.parents(".modal").attr("id"))
    ) {
      // The Slideshow is not in a Displayed Modal
      // Pause the Slideshow
      pause($slideshow);
    }
  });

  // Start displayed Slideshows
  $(".slideshow.pause[data-autoplay='true']").each(function (index, element) {
    // Get slideshow elements
    const $slideshow = $(element);

    // Check if the Slideshow is in a Displayed Modal
    if (
      $slideshow.parents(".modal").length === 0 ||
      ($currentModal && $currentModal.attr("id") === $slideshow.parents(".modal").attr("id"))
    ) {
      // The Slideshow is in a Displayed Modal
      // Start the Slideshow
      play($slideshow);
    }
  });
}

// Listen Component Reload Events
$window.on("ljComponentsReload", function (event, component) {
  if (component === "slideshow") {
    // Check if a hash is present so we could check if there is slideshow to display
    if (window.location.hash && window.location.hash !== "#" && !/\s+/.test(window.location.hash)) {
      // Call the Hashchange Function
      handleHash(window.location.hash === "#" ? "" : window.location.hash);
    }
  }
});

// Bind Page Resize & Lazyloaded Events on Images Listeners
const throttledLayout = throttle(() => layout(false), 1000, { leading: true, trailing: true });
$window.on("resize", throttledLayout);
$window.on("lazyloaded.lesjours.slideshow", function (e) {
  // Check if it's a slideshow image
  const $img = $(e.target);
  if ($img.is("img") && $img.parent().is(".slideshow-slide")) {
    throttledLayout();
  }
});
$.triggerOnLoad("lesjours.lesjours.slideshow", () => {
  // Start playing slideshows
  $(".slideshow[data-autoplay='true']").each(function (idx, el) {
    // Get the element
    const $el = $(el);

    // Listen images loading to trigger relayout
    $el.find("img").one("load.lesjours.slideshow", throttledLayout());

    // Test if the element is visible
    if ($el.is(":visible")) {
      // Start the slideshow
      play($el);
    }
  });
});

// Bind Hash Change Listener
$window.on("hashchange.lesjours.mesjours", function () {
  // Handle the Browser Hash Change Event
  handleHash(window.location.hash === "#" ? "" : window.location.hash);
});
$window.on("ljHashChange.lesjours.mesjours", function (event, newHash) {
  // Handle our Custom Hash Change Event
  handleHash(newHash === "#" ? "" : newHash);
});

// Bind Clicks Events Listeners
$("body").on("click.lesjours.slideshow", "[data-action]", processClickEvent);
$("body").on("mouseup.lesjours.slideshow", "[data-action]", stopPropagation);

// Bind Events to Start & Stop Slideshows
$window.on("lesjours.slideshow.play.lesjours.slideshow", function (e) {
  // Variables
  const $target = $(e.target);

  // Check if the Target is a figure.audio
  if (!$target.is("figure.audio")) {
    return;
  }

  // Get the Slideshow
  const $slideshow = $target.find(".slideshow");

  // Start the Slideshow if we Need
  if (!$slideshow.hasClass("play")) {
    play($slideshow);
  }
});
$window.on("lesjours.slideshow.pause.lesjours.slideshow", function (e) {
  // Variables
  const $target = $(e.target);

  // Check if the Target is a figure.audio
  if (!$target.is("figure.audio")) {
    return;
  }

  // Get the Slideshow
  const $slideshow = $target.find(".slideshow");

  // Pause the Slideshow if we Need
  if (!$slideshow.hasClass("pause")) {
    pause($slideshow);
  }
});
