(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["series/recent-series-slider.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"no-overflow relative print-hidden pv-3 bg-lightest\">\n";
var t_1;
t_1 = {"series": runtime.contextOrFrameLookup(context, frame, "series"),"cardType": "large","cardSizes": ["380px","380px","380px"]};
frame.set("slider", t_1, true);
if(frame.topLevel) {
context.setVariable("slider", t_1);
}
if(frame.topLevel) {
context.addExport("slider", t_1);
}
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("slider/series.html", false, "series/recent-series-slider.html", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
callback(null,t_2);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
callback(null,t_4);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("series/recent-series-slider.html", ctx, cb); }
})();
