import { getDefaultKeyValueStore, KeyValueStorageTypes } from "@lesjoursfr/browser-tools";
import Cookies from "js-cookie";

let kStore = getDefaultKeyValueStore();

// Infer the mtm_source/utm_source from the Referrer
function getSourceFromReferrer() {
  // Variables
  const referrer = document.referrer !== "" ? new URL(document.referrer).hostname : "";
  let source = null;

  // Check if the referrer is our website
  if (referrer === "lesjours.fr") {
    return null;
  }

  // Check the source
  if (referrer.indexOf("google.") !== -1 || referrer.indexOf("googleapis.com") !== -1) {
    source = "Google";
  } else if (referrer.indexOf("facebook.com") !== -1) {
    source = "Facebook";
  } else if (referrer.indexOf("instagram.com") !== -1) {
    source = "Instagram";
  } else if (referrer.indexOf("twitter.com") !== -1 || referrer === "t.co") {
    source = "Twitter";
  } else if (referrer.indexOf("bsky.app") !== -1) {
    source = "Bluesky";
  } else if (referrer.indexOf("de.axelspringer.yana") !== -1) {
    source = "upday news";
  }

  // Check if the current URL is the Homepage
  if (document.URL === "https://lesjours.fr/") {
    // If there is no source, or if the source is Google
    // we change the source to Notoriety
    if (document.referrer === "" || referrer.indexOf("google") !== -1) {
      source = "Notoriete";
    }
  }

  // On retourne la source
  return source;
}

// Get a parameter from the URL
function getParameterByName(name) {
  // Variables
  const url = window.location.href;

  // Escape the parameter name
  name = name.replace(/[[\]]/g, "\\$&");

  // Extract the parameter from the URL
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results || !results[2]) {
    return null;
  }

  // Return the parameter value
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

// Get the Current Obsession for the URL
function getCurrentObsession() {
  // Variables
  const url = window.location.href;

  // Check if we are in an obsession
  const urlObsIndex = url.indexOf("/obsessions/");
  if (urlObsIndex === -1) {
    return null;
  }

  // Return the obsession
  return url.substr(urlObsIndex + 12, url.indexOf("/", urlObsIndex + 12) - (urlObsIndex + 12));
}

// Get the Page Views Count
export function getPageViews() {
  // Return the Page Views Count
  const value = kStore.getItem("nopv");
  return value === undefined ? 0 : parseInt(value, 10);
}

// Update the Page Views Count
export function updatePageViews() {
  // Check if we can do the migration to the new Storage
  if (kStore.type === KeyValueStorageTypes.localStorage && Cookies.get("nopv") !== undefined) {
    kStore.setItem("nopv", Cookies.get("nopv"));
    Cookies.remove("nopv");
  }

  // Get the Page Views count
  let numberOfPageViews = getPageViews();

  // Update the counter
  numberOfPageViews++;
  kStore.setItem("nopv", numberOfPageViews);

  // Return the counter
  return numberOfPageViews;
}

// Get the MTM/UTM Sources List
export function getUtmSourcesList() {
  // Return the MTM/UTM Sources List
  const value = kStore.getItem("listUtmSources");
  return value !== undefined ? value : "";
}

// Update the MTM/UTM Sources List
export function updateUtmSourcesList() {
  // Check if we can do the migration to the new Storage
  if (kStore.type === KeyValueStorageTypes.localStorage && Cookies.get("listUtmSources") !== undefined) {
    kStore.setItem("listUtmSources", Cookies.get("listUtmSources"));
    Cookies.remove("listUtmSources");
  }

  // Get the mtm_source/utm_source from parameters
  let utmSource = getParameterByName("mtm_source") ?? getParameterByName("utm_source");
  if (utmSource === null) {
    // There is no mtm_source/utm_source parameter, retrieve it from the Referrer
    utmSource = getSourceFromReferrer();
  }

  // Get the MTM/UTM sources list
  let utmSourcesList = getUtmSourcesList();

  // Check if the current source is already in the list
  if (utmSource !== null && utmSourcesList.indexOf(utmSource) === -1) {
    // Append the source to the list
    if (utmSourcesList.length > 0) {
      utmSourcesList += ",";
    }
    utmSourcesList += utmSource;

    // Update the cookie
    kStore.setItem("listUtmSources", utmSourcesList);
  }
}

// Get the Obsession Page Views Count
export function getObsessionPageViews() {
  // Return the obsession list
  const value = kStore.getItem("obsessionsPV");
  return value !== undefined ? JSON.parse(value) : {};
}

// Update the Obsession Page Views Count
export function updateObsessionPageViews() {
  // Check if we can do the migration to the new Storage
  if (kStore.type === KeyValueStorageTypes.localStorage && Cookies.get("obsessionsPV") !== undefined) {
    kStore.setItem("obsessionsPV", Cookies.get("obsessionsPV"));
    Cookies.remove("obsessionsPV");
  }

  // Get the obsesion path
  const obsessionPath = getCurrentObsession();
  if (obsessionPath !== null && obsessionPath.length > 0) {
    // Get the obsession list
    let obsessionsList = getObsessionPageViews();

    // Update the counter
    if (!obsessionsList[obsessionPath]) {
      obsessionsList[obsessionPath] = 1;
    } else {
      obsessionsList[obsessionPath] += 1;
    }

    // Update the cookie
    kStore.setItem("obsessionsPV", JSON.stringify(obsessionsList));
  }
}
