(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["mes-jours/resume.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "elements")) > 0) {
output += "<div class=\"container mv-4\">\n\t<div class=\"row\">\n";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "elements");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("element", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\t\t<div class=\"col sm-w-6c md-w-12c lg-w-12c p-2\">\n";
if(runtime.memberLookup((t_4),"model") === "Episode") {
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("mes-jours/episode.html", false, "mes-jours/resume.html", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
callback(null,t_5);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
callback(null,t_7);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
});
}
else {
if(runtime.memberLookup((t_4),"model") === "Podcast") {
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("mes-jours/podcast.html", false, "mes-jours/resume.html", false, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
callback(null,t_9);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
callback(null,t_11);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
});
}
;
}
output += "\t\t</div>\n";
;
}
}
frame = frame.pop();
output += "\t</div>\n</div>\n";
;
}
else {
output += "<div class=\"container mv-4\">\n\t<div class=\"text-center style-meta-larger bold color-dark p-1\">\n\t\tRetrouvez ici vos lectures en cours\n\t</div>\n\t<div class=\"text-center p color-medium-5 p-1\">\n\t\tNous affichons ici les épisodes que vous avez commencé à lire ou écouter.\n\t\t<br />\n\t\t<span class=\"sm-hidden\">Découvrez nos dernières publications&nbsp;:</span>\n\t</div>\n</div>\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("mes-jours/resume.html", ctx, cb); }
})();
