(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["episode/classic/duration.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<span class=\"flex mt-1\">\n\t<span class=\"mr-2\">Temps de lecture</span>\n\t<span>\n\t\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "duration"), env.opts.autoescape);
output += "&nbsp;minute";
if(runtime.contextOrFrameLookup(context, frame, "duration") > 1) {
output += "s";
;
}
output += "\t</span>\n</span>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("episode/classic/duration.html", ctx, cb); }
})();
