import { GDPRConsent } from "gdpr-consent";
import $ from "jquery";
import { showStripePaymentForm } from "../components/stripe";

// Get jQuery Elements
const $planChoice = $("#mon-abonnement");
const $planChoiceBtns = $planChoice.find(".radio-field label");
const $paymentChoice = $("#field-moyen-de-paiement");
const $paymentChoiceBtns = $paymentChoice.find(".radio-field label");
const $summary = $(".summary");

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// EDIT PLAN PAGE JS                                                                                                  //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Switch Bank/Card payment to add required & error to the field
function checkField(paymentType) {
  if (paymentType === "bank-radio") {
    $(".field-address .input").each(function (idx, input) {
      const $input = $(input);
      $input.attr("required", "required");
      $input.parents(".field").addClass("field-required");

      if ($input.hasClass("error") && $("#info-bank-radio").hasClass("hidden")) {
        $("#info-bank-radio").removeClass("hidden");
      }
    });
  } else {
    $(".field-address .input").each(function (idx, input) {
      const $input = $(input);
      $input.removeAttr("required");
      $input.parents(".field").removeClass("field-required");
      $input.removeClass("error");
    });
  }
}

// Display or hide the edit payment section
$planChoice.find("#button-edit-payment").on("click", function (e) {
  e.preventDefault();

  if ($("#field-moyen-de-paiement").hasClass("hidden")) {
    $("#field-moyen-de-paiement").removeClass("hidden");
    $("#button-edit-payment").text("Annuler la modification");
    $("#field-moyen-de-paiement input[type='radio']").prop("disabled", false);
  } else {
    $("#field-moyen-de-paiement").addClass("hidden");
    $("#field-moyen-de-paiement input").prop("checked", false);
    $("#button-edit-payment").text("Modifier mon moyen de paiement");
    $("#field-moyen-de-paiement input[type='radio']").prop("disabled", true);
    checkField($(this).data("paymentstate"));
  }
});

// Display the card alert for immediate payment
$paymentChoiceBtns.on("click", function (e) {
  // Get clicked element
  const $button = $(e.currentTarget).find("input[name=payment]");
  const payment = $button.attr("value");

  // Update the .summary
  $summary.removeClass("hidden").find("[data-payment]").addClass("hidden");
  $summary.find(`[data-payment="${payment}"]`).removeClass("hidden");

  // Update required input
  checkField($button.attr("value"));
});

// Set the price datas according to the plan choice
$planChoiceBtns.on("click", function (e) {
  const planData = $(e.target).data();

  $paymentChoice.find(".data-cardpayment").text(planData.cardpayment);
  $paymentChoice.find(".data-bankpayment").text(planData.bankpayment);
  $paymentChoice.find(".data-duration").text(planData.duration);
  $paymentChoice.find(".data-durationadj").text(planData.durationadj);
  $paymentChoice.find(".data-discount").text(planData.discount);
});

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// STRIPE                                                                                                             //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
if (window.stripePayment) {
  showStripePaymentForm(window.stripePayment);
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ACCOUNT PAGE                                                                                                       //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$("#google-subscription-linking-btn").on("click", function () {
  // Check if the service is allowed
  if (!GDPRConsent.isActivated("subscribewithgoogle")) {
    // Allow the service with the GDPR consent
    $.tiggerSubscriptionLinkingAfterLoad();
    GDPRConsent.activate("subscribewithgoogle");
  } else {
    // Link the Subscription
    $.linkSubscriptionWithGoogle();
  }
});
