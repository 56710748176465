import throttle from "lodash/throttle";
import $ from "jquery";
import * as Playlists from "../libs/playlists";

// Variables
const $window = $(window);
const $cover = $("#obsession .obsession-header .cover");
const $coverOverlay = $("#obsession .obsession-header .overlay.bg-black");
const $coverImage = $cover.find(".responsive-cover");
const $coverSnake = $cover.find(".snake-2");
const $boardSlider = $("#obsession .slider[role=tablist]");
const $series = $("section#series");
const $slideshow = $("section#slideshow .slider");
const $changeOrderBtn = $("#episodes-order");

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// BOARD TABS SLIDER                                                                                                  //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Initialize the Slider
$window.trigger("ljComponentsInit", ["slider", $boardSlider, $boardSlider.innerWidth()]);

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IMAGES SLIDER                                                                                                      //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Initialize the Slider
$window.trigger("ljComponentsInit", ["slider", $slideshow, $slideshow.innerWidth()]);

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SERIES SLIDER
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function initializeSeriesSlider() {
  const $slider = $("section#series .slider");
  const cntWidth = $slider.innerWidth();

  // Initialize the Slider
  $window.trigger("ljComponentsInit", ["slider", $slider, cntWidth]);
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// LOAD REQUIRED JSONS                                                                                                //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$.when(
  // Load the Series JSON File
  window.LesJours.series !== undefined
    ? $.Deferred().resolve(window.LesJours.series).promise()
    : $.loadJsonData("/series.json")
).then(function (series) {
  // Load Series Covers
  $series.append(window.nunjucks.render("series/recent-series-slider.html", { series: series }));
  initializeSeriesSlider();
});

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// BOARD ELEMENTS LAZYLOADING                                                                                         //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const lazyLoadedTabs = ["tab-images", "tab-data", "tab-lieux", "tab-bande-originale", "tab-documents", "tab-playlist"];

let obsessionJsonData = window.LesJours.obsession;
async function lazyloadTabContent($tab) {
  try {
    // Load the Obsession JSON
    if (obsessionJsonData === undefined) {
      obsessionJsonData = await $.loadJsonData("./obsession.json");
    }

    // Render the tab content
    switch ($tab.attr("id")) {
      case "tab-images":
        $("#images").append(window.nunjucks.render("obsession/images-tab.html", { obsession: obsessionJsonData }));
        $("#modals").append(window.nunjucks.render("obsession/images-modals.html", { obsession: obsessionJsonData }));
        break;
      case "tab-data":
        $("#data").append(window.nunjucks.render("obsession/data-tab.html", { obsession: obsessionJsonData }));
        $("#modals").append(window.nunjucks.render("obsession/data-modals.html", { obsession: obsessionJsonData }));
        break;
      case "tab-lieux":
        $("#lieux").append(window.nunjucks.render("obsession/places-tab.html", { obsession: obsessionJsonData }));
        $("#modals").append(window.nunjucks.render("obsession/places-modals.html", { obsession: obsessionJsonData }));
        break;
      case "tab-bande-originale":
        $("#bande-originale").append(
          window.nunjucks.render("obsession/soundtracks-tab.html", { obsession: obsessionJsonData })
        );
        break;
      case "tab-documents":
        $("#documents").append(
          window.nunjucks.render("obsession/documents-tab.html", { obsession: obsessionJsonData })
        );
        break;
      case "tab-playlist":
        $("#playlist").append(window.nunjucks.render("obsession/playlists-tab.html", { obsession: obsessionJsonData }));

        // Open the loaded Playlists
        $("#playlist .playlist").each((idx, playlist) => {
          const $playlist = $(playlist);
          const $holder = $playlist.find(".playlist-holder");

          // Open the Playlist
          Playlists.open($playlist, $holder);
        });
        break;
      default:
        throw new Error("Unknown tab : " + $tab.attr("id"));
    }
  } catch (err) {
    // Log the error
    console.log(err);
  }
}

async function lazyloadTabsContent(event) {
  // Get the selected element
  const $el = $(event.currentTarget);

  // Check the ID of the tab
  if (lazyLoadedTabs.indexOf($el.attr("id")) !== -1) {
    // Check if we already have loaded the tab
    if ($el.data("ljTabLoaded") !== true) {
      // Load the tab
      $el.data("ljTabLoaded", true);
      await lazyloadTabContent($el);
    }
  }
}

$boardSlider.find("[role=tab]").on("click.lesjours.lazyloadTabsContent", lazyloadTabsContent);
$.triggerOnLoad("lesjours.lazyloadTabsContent", () => {
  if (window.location.hash === "") {
    // Nothing to do
    return;
  }

  // Check if there is a tab for this hash
  const $tab = $boardSlider.find('[role=tab][data-href="' + window.location.hash + '"]');
  if ($tab.length > 0) {
    // Load the corresponding tab
    lazyloadTabsContent({ currentTarget: $tab.get(0) });
  }
});

$slideshow.one("click.lesjours.lazyloadTabsContent", ".slideshow-image .a", async (event) => {
  // Get the images tab
  const $tab = $boardSlider.find('[role=tab][data-href="#images"');

  // Check if we already have loaded the tab
  if ($tab.data("ljTabLoaded") === true) {
    return;
  }

  // Load the images tab
  await lazyloadTabsContent({ currentTarget: $tab });

  // Show the modal
  $window.trigger("ljHashChange", $(event.currentTarget).data("href"));
});

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FADE OUT COVER EFFECT                                                                                              //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function fadeOutEffect() {
  // Get Important Values
  const currentScroll = $window.scrollTop();
  const coverHeight = $cover.height();

  // Update the Opacity and the Transorm of the Cover
  if (currentScroll >= 0 && currentScroll <= coverHeight) {
    $coverOverlay.css("opacity", Math.round(100 * (currentScroll / coverHeight)) / 100);
    $coverImage.css("transform", "translate3d(0, -" + Math.round(currentScroll / 2) + "px, 0)");
    $coverSnake.css("opacity", 1 - Math.round(100 * (currentScroll / coverHeight)) / 100);
  }
}

$window.on("scroll.lesjours.fadeOutEffect", throttle(fadeOutEffect, 10, { leading: true, trailing: true }));
$.triggerOnLoad("lesjours.fadeouteffect", fadeOutEffect);

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SEE MORE EVENT HANDLER                                                                                             //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function showMoreElements() {
  // Get Important Values
  const currentScroll = $window.scrollTop();
  const $section = $('.obsession-content .tab-container section[aria-hidden="false"]').eq(0);
  const viewport = $window.height();
  const offset = $section.offset().top;
  const size = $section.height();

  // Check if we have reach the end of the current tab
  if (currentScroll + viewport <= offset + size) {
    // Nothing to do
    return;
  }

  // Check if there is elements to show
  const $hidden = $section.find(".hidden");
  if ($hidden.length > 0) {
    // Display 12 more elements
    $hidden.slice(0, 12).removeClass("hidden");
  }
}

// Listen Scroll Events to show More Elements
$window.on("scroll.lesjours.showMoreElements", throttle(showMoreElements, 1000, { leading: true, trailing: true }));

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// CHANGE EPISODES ORDER                                                                                              //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function changeOrder(event) {
  // Get Important Values
  const $changed = $(event.currentTarget);
  const $target = $($changed.data("target"));

  // Reverse Order
  $target.append($target.children().get().reverse());

  // Take care about large obsessions
  if ($target.children().length > 12) {
    // Reset hiddens classes
    $target.children().each(function (idx, el) {
      if (idx >= 12) {
        $(el).addClass("hidden");
      } else {
        $(el).removeClass("hidden");
      }
    });
  }
}

$changeOrderBtn.on("change.lesjours.changeOrder", changeOrder);
