(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["_macro.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["authors"], 
["class"], 
function (l_authors, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("authors", l_authors);
frame.set("class", Object.prototype.hasOwnProperty.call(kwargs, "class") ? kwargs["class"] : "");var t_2 = "";frame = frame.push();
var t_5 = l_authors;
if(t_5) {t_5 = runtime.fromIterator(t_5);
var t_4 = t_5.length;
for(var t_3=0; t_3 < t_5.length; t_3++) {
var t_6 = t_5[t_3];
frame.set("author", t_6);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
if(runtime.contextOrFrameLookup(context, frame, "class")) {
t_2 += "<span class=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "class"), env.opts.autoescape);
t_2 += "\">";
;
}
t_2 += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((t_6),"people")),"name")?runtime.memberLookup((runtime.memberLookup((t_6),"people")),"name"):runtime.memberLookup((t_6),"people")), env.opts.autoescape);
if(runtime.contextOrFrameLookup(context, frame, "class")) {
t_2 += "</span>";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"length") > 1 && !runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"last")) {
t_2 += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"revindex0") == 1?" et ":", "), env.opts.autoescape);
;
}
;
}
}
frame = frame.pop();
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("authors");
context.setVariable("authors", macro_t_1);
var macro_t_7 = runtime.makeMacro(
["name", "title", "class"], 
[], 
function (l_name, l_title, l_class, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("name", l_name);
frame.set("title", l_title);
frame.set("class", l_class);
var t_8 = "";t_8 += "\t<svg width=\"32\" height=\"32\" class=\"ui-";
t_8 += runtime.suppressValue(l_name, env.opts.autoescape);
if(l_class) {
t_8 += " ";
t_8 += runtime.suppressValue(l_class, env.opts.autoescape);
;
}
t_8 += "\" ";
if(l_title) {
t_8 += "role=\"img\" title=\"";
t_8 += runtime.suppressValue(l_title, env.opts.autoescape);
t_8 += "\"";
;
}
else {
t_8 += "role=\"presentation\"";
;
}
t_8 += ">\n";
if(runtime.contextOrFrameLookup(context, frame, "packageVersion")) {
t_8 += "\t\t<use class=\"lazyload\" data-src=\"/img/ui.";
t_8 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "packageVersion"), env.opts.autoescape);
t_8 += ".svg#";
t_8 += runtime.suppressValue(l_name, env.opts.autoescape);
t_8 += "\"></use>\n";
;
}
else {
t_8 += "\t\t<use class=\"lazyload\" data-src=\"/img/ui.svg#";
t_8 += runtime.suppressValue(l_name, env.opts.autoescape);
t_8 += "\"></use>\n";
;
}
t_8 += "\t</svg>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_8);
});
context.addExport("icon");
context.setVariable("icon", macro_t_7);
var macro_t_9 = runtime.makeMacro(
["name", "title", "class"], 
[], 
function (l_name, l_title, l_class, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("name", l_name);
frame.set("title", l_title);
frame.set("class", l_class);
var t_10 = "";t_10 += "\t<svg width=\"24\" height=\"18\" class=\"flag-";
t_10 += runtime.suppressValue(l_name, env.opts.autoescape);
if(l_class) {
t_10 += " ";
t_10 += runtime.suppressValue(l_class, env.opts.autoescape);
;
}
t_10 += "\" ";
if(l_title) {
t_10 += "role=\"img\" title=\"";
t_10 += runtime.suppressValue(l_title, env.opts.autoescape);
t_10 += "\"";
;
}
else {
t_10 += "role=\"presentation\"";
;
}
t_10 += ">\n";
if(runtime.contextOrFrameLookup(context, frame, "packageVersion")) {
t_10 += "\t\t<use class=\"lazyload\" data-src=\"/img/flag.";
t_10 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "packageVersion"), env.opts.autoescape);
t_10 += ".svg#";
t_10 += runtime.suppressValue(l_name, env.opts.autoescape);
t_10 += "\"></use>\n";
;
}
else {
t_10 += "\t\t<use class=\"lazyload\" data-src=\"/img/flag.svg#";
t_10 += runtime.suppressValue(l_name, env.opts.autoescape);
t_10 += "\"></use>\n";
;
}
t_10 += "\t</svg>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_10);
});
context.addExport("flag");
context.setVariable("flag", macro_t_9);
var macro_t_11 = runtime.makeMacro(
["array", "loop", "playerButtons"], 
[], 
function (l_array, l_loop, l_playerButtons, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("array", l_array);
frame.set("loop", l_loop);
frame.set("playerButtons", l_playerButtons);
var t_12 = "";if(runtime.memberLookup((l_array),"length") > 1) {
t_12 += "\t\t<meta class=\"hidden\" data-modal-rel=\"first\" href=\"#";
t_12 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_array),0)),"href"), env.opts.autoescape);
t_12 += "\" />\n\t\t<meta class=\"hidden\" data-modal-rel=\"last\" href=\"#";
t_12 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_array),runtime.memberLookup((l_array),"length") - 1)),"href"), env.opts.autoescape);
t_12 += "\" />\n";
if(!runtime.memberLookup((l_loop),"first")) {
t_12 += "\t\t<button class=\"btn-svg btn-medium btn-hover-lesjours\" data-href=\"#";
t_12 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_array),runtime.memberLookup((l_loop),"index0") - 1)),"href"), env.opts.autoescape);
t_12 += "\" data-modal-rel=\"prev\">\n\t\t\t<i>";
t_12 += runtime.suppressValue((lineno = 27, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["previous","Précédent","initial"])), env.opts.autoescape);
t_12 += "</i>\n\t\t</button>\n";
;
}
t_12 += "\t\t<span class=\"flex-1 mh-1 style-meta-medium text-center color-lesjours\">";
t_12 += runtime.suppressValue(runtime.memberLookup((l_loop),"index"), env.opts.autoescape);
t_12 += "/";
t_12 += runtime.suppressValue(runtime.memberLookup((l_array),"length"), env.opts.autoescape);
t_12 += "</span>\n";
if(l_playerButtons) {
t_12 += "\t\t<button class=\"btn-svg btn-medium btn-hover-lesjours mr-1 pause-button\">\n\t\t\t<i>";
t_12 += runtime.suppressValue((lineno = 33, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["pause","Pause","initial"])), env.opts.autoescape);
t_12 += "</i>\n\t\t</button>\n\t\t<button class=\"btn-svg btn-medium btn-hover-lesjours mr-1 play-button\">\n\t\t\t<i>";
t_12 += runtime.suppressValue((lineno = 36, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["play","Lire","initial"])), env.opts.autoescape);
t_12 += "</i>\n\t\t</button>\n";
;
}
if(!runtime.memberLookup((l_loop),"last")) {
t_12 += "\t\t<button class=\"btn-svg btn-medium btn-hover-lesjours\" data-href=\"#";
t_12 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_array),runtime.memberLookup((l_loop),"index0") + 1)),"href"), env.opts.autoescape);
t_12 += "\" data-modal-rel=\"next\">\n\t\t\t<i>";
t_12 += runtime.suppressValue((lineno = 41, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["next","Suivant","initial"])), env.opts.autoescape);
t_12 += "</i>\n\t\t</button>\n";
;
}
;
}
;
frame = callerFrame;
return new runtime.SafeString(t_12);
});
context.addExport("pagination");
context.setVariable("pagination", macro_t_11);
var macro_t_13 = runtime.makeMacro(
["noteOrMini"], 
[], 
function (l_noteOrMini, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("noteOrMini", l_noteOrMini);
var t_14 = "";t_14 += runtime.suppressValue(env.getFilter("hrefForNoteOrMini").call(context, l_noteOrMini), env.opts.autoescape);
;
frame = callerFrame;
return new runtime.SafeString(t_14);
});
context.addExport("href");
context.setVariable("href", macro_t_13);
var macro_t_15 = runtime.makeMacro(
["type", "serieModel", "serieHref", "options"], 
[], 
function (l_type, l_serieModel, l_serieHref, l_options, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("type", l_type);
frame.set("serieModel", l_serieModel);
frame.set("serieHref", l_serieHref);
frame.set("options", l_options);
var t_16 = "";t_16 += "<div ";
if(runtime.memberLookup((l_options),"classes")) {
t_16 += "class=\"";
t_16 += runtime.suppressValue(runtime.memberLookup((l_options),"classes"), env.opts.autoescape);
t_16 += "\" ";
;
}
t_16 += "data-mesjours-type=\"";
t_16 += runtime.suppressValue(l_type, env.opts.autoescape);
t_16 += "\" data-model=\"";
t_16 += runtime.suppressValue(l_serieModel, env.opts.autoescape);
t_16 += "\" data-";
t_16 += runtime.suppressValue(env.getFilter("lower").call(context, l_serieModel), env.opts.autoescape);
t_16 += "=\"";
t_16 += runtime.suppressValue(l_serieHref, env.opts.autoescape);
t_16 += "\"></div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_16);
});
context.addExport("mesJoursSerie");
context.setVariable("mesJoursSerie", macro_t_15);
var macro_t_17 = runtime.makeMacro(
["type", "serieModel", "serieHref", "articleModel", "articleHref", "articleCategory", "options"], 
[], 
function (l_type, l_serieModel, l_serieHref, l_articleModel, l_articleHref, l_articleCategory, l_options, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("type", l_type);
frame.set("serieModel", l_serieModel);
frame.set("serieHref", l_serieHref);
frame.set("articleModel", l_articleModel);
frame.set("articleHref", l_articleHref);
frame.set("articleCategory", l_articleCategory);
frame.set("options", l_options);
var t_18 = "";t_18 += "<div class=\"mesjours-actions";
if(runtime.memberLookup((l_options),"classes")) {
t_18 += " ";
t_18 += runtime.suppressValue(runtime.memberLookup((l_options),"classes"), env.opts.autoescape);
;
}
t_18 += "\" data-mesjours-type=\"";
t_18 += runtime.suppressValue(l_type, env.opts.autoescape);
t_18 += "\" data-model=\"";
t_18 += runtime.suppressValue(l_articleModel, env.opts.autoescape);
t_18 += "\" data-";
t_18 += runtime.suppressValue(env.getFilter("lower").call(context, l_articleModel), env.opts.autoescape);
t_18 += "=\"";
t_18 += runtime.suppressValue(l_articleHref, env.opts.autoescape);
t_18 += "\"";
if(l_articleCategory) {
t_18 += " data-category=\"";
t_18 += runtime.suppressValue(l_articleCategory, env.opts.autoescape);
t_18 += "\"";
;
}
t_18 += " data-";
t_18 += runtime.suppressValue(env.getFilter("lower").call(context, l_serieModel), env.opts.autoescape);
t_18 += "=\"";
t_18 += runtime.suppressValue(l_serieHref, env.opts.autoescape);
t_18 += "\"></div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_18);
});
context.addExport("mesJoursArticle");
context.setVariable("mesJoursArticle", macro_t_17);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("_macro.html", ctx, cb); }
})();
