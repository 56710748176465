(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["menu/search-results.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<table class=\"mv-2\">\n";
var t_1;
t_1 = [{"key": "tags","label": "Mot-clé","field": "word","action": "Navigate Tag"},{"key": "obsessions","label": "Obsession","field": "title","extra": "subtitle","action": "Navigate Obsession"},{"key": "episodes","label": "Episode","field": "title","action": "Navigate Episode"},{"key": "authors","label": "Auteur","field": "name","action": "Navigate Author"},{"key": "metapersons","label": "Personnage","field": "name","action": "Navigate Meta-Person"},{"key": "pages","label": "Page","field": "title","action": "Navigate Page"}];
frame.set("groups", t_1, true);
if(frame.topLevel) {
context.setVariable("groups", t_1);
}
if(frame.topLevel) {
context.addExport("groups", t_1);
}
output += "\t<tbody>\n";
frame = frame.push();
var t_4 = runtime.contextOrFrameLookup(context, frame, "groups");
if(t_4) {t_4 = runtime.fromIterator(t_4);
var t_3 = t_4.length;
for(var t_2=0; t_2 < t_4.length; t_2++) {
var t_5 = t_4[t_2];
frame.set("group", t_5);
frame.set("loop.index", t_2 + 1);
frame.set("loop.index0", t_2);
frame.set("loop.revindex", t_3 - t_2);
frame.set("loop.revindex0", t_3 - t_2 - 1);
frame.set("loop.first", t_2 === 0);
frame.set("loop.last", t_2 === t_3 - 1);
frame.set("loop.length", t_3);
frame = frame.push();
var t_8 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "results")),runtime.memberLookup((t_5),"key"));
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("result", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
output += "\t\t<tr>\n\t\t\t<td>\n\t\t\t\t<span class=\"search-type\">\n\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_5),"label"), env.opts.autoescape);
output += "\n\t\t\t\t</span>\n\t\t\t</td>\n\t\t\t<td>\n\t\t\t\t<a class=\"search-result\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_9),"link"), env.opts.autoescape);
output += "\" data-mtm-type=\"click\" data-mtm-category=\"Search suggestion\" data-mtm-action=\"";
output += runtime.suppressValue(runtime.memberLookup((t_5),"action"), env.opts.autoescape);
output += "\" data-mtm-label=\"";
output += runtime.suppressValue((runtime.memberLookup((t_9),"parent")?(runtime.memberLookup((t_9),"parent") + "/" + runtime.memberLookup((t_9),"href")):runtime.memberLookup((t_9),"href")), env.opts.autoescape);
output += "\">\n\t\t\t\t\t";
output += runtime.suppressValue((runtime.memberLookup((t_9),"highlight")?runtime.memberLookup((runtime.memberLookup((t_9),"highlight")),runtime.memberLookup((t_5),"field")):runtime.memberLookup((t_9),runtime.memberLookup((t_5),"field"))), env.opts.autoescape);
output += "\n\t\t\t\t</a>\n";
if(runtime.memberLookup((t_5),"extra")) {
output += "\t\t\t\t<span class=\"search-extra color-medium-5\">\n\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_9),runtime.memberLookup((t_5),"extra")), env.opts.autoescape);
output += "\n\t\t\t\t</span>\n";
;
}
output += "\t\t\t</td>\n\t\t</tr>\n";
;
}
}
frame = frame.pop();
;
}
}
frame = frame.pop();
output += "\t</tbody>\n</table>\n<div class=\"stripe grey-stripe\"></div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("menu/search-results.html", ctx, cb); }
})();
