(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["articles/podcast.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n";
env.getTemplate("_macro.html", false, "articles/podcast.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "mesJoursArticle")) {
var t_4 = t_1.mesJoursArticle;
} else {
cb(new Error("cannot import 'mesJoursArticle'")); return;
}
context.setVariable("mesJoursArticle", t_4);
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") === "inline")) {
output += "<div class=\"article row row-center no-wrap article-podcast article-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type"), env.opts.autoescape);
output += " lj-color-";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"color")),"id"), env.opts.autoescape);
output += "\">\n\t<div class=\"col w-auto flex\">\n\t\t<div class=\"media pr-2 lg-pr-1\">\n\t\t\t<div class=\"player-media js-audio-player\" data-mesjours-record=\"true\" data-programme=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"href"), env.opts.autoescape);
output += "\" data-podcast=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"href"), env.opts.autoescape);
output += "\" data-podcast-duration=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"duration"), env.opts.autoescape);
output += "\" data-mtm-type=\"click\" data-mtm-category=\"Article Podcast\" data-mtm-action=\"Play Podcast\" data-mtm-label=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"href") + "/" + env.getFilter("hrefWithCategory").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")), env.opts.autoescape);
output += "\">\n\t\t\t\t<audio class=\"podcast-audio-player\" aria-hidden=\"true\" preload=\"none\">\n";
frame = frame.push();
var t_7 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"files");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("source", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\t\t\t\t\t<source type=\"";
output += runtime.suppressValue(env.getFilter("mimeType").call(context, t_8), env.opts.autoescape);
output += "\" src=\"";
output += runtime.suppressValue(t_8, env.opts.autoescape);
output += "\" />\n";
;
}
}
frame = frame.pop();
output += "\t\t\t\t</audio>\n\t\t\t\t<button class=\"btn-svg btn-large btn-player-lj-color btn-player-hover-scale js-play-btn\">\n\t\t\t\t\t";
output += runtime.suppressValue((lineno = 29, colno = 12, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["media-play"])), env.opts.autoescape);
output += "\n\t\t\t\t\t";
output += runtime.suppressValue((lineno = 30, colno = 12, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["media-pause"])), env.opts.autoescape);
output += "\n\t\t\t\t</button>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class=\"col w-auto flex-1 lg-pl-1\">\n\t\t<div class=\"row row-space w-100 mh-0\">\n\t\t\t<div class=\"col sm-w-100 md-w-100 lg-w-auto flex-1 ph-0\">\n\t\t\t\t<div class=\"";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") !== "inline") {
output += "sm-mt-2";
;
}
output += "\">\n\t\t\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"href"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t<span class=\"inline title link on-hover color-dark\">\n\t\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("removeBR").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"title")), env.opts.autoescape);
output += "\n\t\t\t\t\t\t</span>\n\t\t\t\t\t</a>\n\t\t\t\t</div>\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"showDate")) {
output += "\t\t\t\t<div class=\"infos sm-mt-1 mt-2\">\n\t\t\t\t\t<span class=\"color-medium-8\">\n\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("humanDate").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"date")), env.opts.autoescape);
output += "\n\t\t\t\t\t</span>\n\t\t\t\t</div>\n";
;
}
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"hideNumber")) {
output += "\t\t\t\t<div class=\"infos sm-mt-1 mt-2\">\n\t\t\t\t\t<span class=\"color-medium-8\">\n\t\t\t\t\t\tPodcast n°&#x202f;";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"number"), env.opts.autoescape);
output += "<span>&nbsp;·&nbsp;</span>";
output += runtime.suppressValue(env.getFilter("humanDuration").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"duration")), env.opts.autoescape);
output += "\n\t\t\t\t\t</span>\n\t\t\t\t</div>\n";
;
}
output += "\t\t\t</div>\n\t\t\t<div class=\"col sm-mt-1 md-mt-2 mt-1 w-auto flex-0 infos ph-0\">\n\t\t\t\t";
output += runtime.suppressValue((lineno = 61, colno = 22, runtime.callWrap(t_4, "mesJoursArticle", context, ["card","Programme",runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"href"),"Podcast",runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"href"),env.getFilter("getPodcastCategory").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),{"classes": "w-auto"}])), env.opts.autoescape);
output += "\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>\n";
;
}
else {
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") === "slider")) {
output += "<div class=\"article row article-podcast article-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type"), env.opts.autoescape);
output += " lj-color-";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"color")),"id"), env.opts.autoescape);
output += "\">\n\t<div class=\"col w-100 article-image\">\n\t\t<div class=\"relative w-100 h-100\" data-href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"href"), env.opts.autoescape);
output += "\">\n";
var t_9;
t_9 = env.getFilter("episodeOrSerieImage").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme"),true);
frame.set("podcastImage", t_9, true);
if(frame.topLevel) {
context.setVariable("podcastImage", t_9);
}
if(frame.topLevel) {
context.addExport("podcastImage", t_9);
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"lazyload")) {
output += "\t\t\t<img class=\"responsive h-100 lazyload\" src=\"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7\" data-src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "podcastImage"), env.opts.autoescape);
output += "\" data-sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"sizes")), env.opts.autoescape);
output += "\" data-srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.contextOrFrameLookup(context, frame, "podcastImage")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"title"))), env.opts.autoescape);
output += "\" />\n";
;
}
else {
output += "\t\t\t<img class=\"responsive h-100\" src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "podcastImage"), env.opts.autoescape);
output += "\" sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"sizes")), env.opts.autoescape);
output += "\" srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.contextOrFrameLookup(context, frame, "podcastImage")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"title"))), env.opts.autoescape);
output += "\" />\n";
;
}
output += "\t\t\t<div class=\"progress-bar js-progress-bar w-100\" data-duration=\"";
output += runtime.suppressValue(env.getFilter("getDuration").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")), env.opts.autoescape);
output += "\" data-programme=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"href"), env.opts.autoescape);
output += "\" data-podcast=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"href"), env.opts.autoescape);
output += "\"></div>\n\t\t</div>\n\t</div>\n\t<div class=\"col w-100 article-content row m-0 row-vertical\">\n";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"hideProgramme")) {
output += "\t\t<div class=\"row mh-0 ";
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") !== "list")) {
output += "mt-2";
;
}
else {
output += "sm-mt-2";
;
}
output += " row-center no-wrap obsession-name\">\n\t\t\t<a class=\"link on-hover lj-color-text text-upper\" href=\"/podcasts/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"href"), env.opts.autoescape);
output += "/\">\n\t\t\t\t";
output += runtime.suppressValue(env.getFilter("removeBR").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"title")), env.opts.autoescape);
output += "\n\t\t\t</a>\n\t\t</div>\n";
;
}
output += "\t\t<div class=\"";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"hideProgramme")) {
output += "mt-2";
;
}
else {
output += "mt-1";
;
}
output += "\">\n";
var t_10;
t_10 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"titleTag")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"titleTag"):"span");
frame.set("titleTag", t_10, true);
if(frame.topLevel) {
context.setVariable("titleTag", t_10);
}
if(frame.topLevel) {
context.addExport("titleTag", t_10);
}
output += "\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"href"), env.opts.autoescape);
output += "\">\n\t\t\t\t<";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "titleTag"), env.opts.autoescape);
output += " class=\"inline title link on-hover color-dark\">\n\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("removeBR").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"title")), env.opts.autoescape);
output += "\n\t\t\t\t</";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "titleTag"), env.opts.autoescape);
output += ">\n\t\t\t</a>\n\t\t</div>\n\t\t<div class=\"mt-1\">\n\t\t\t<div class=\"media pull-left mr-1\">\n\t\t\t\t<div class=\"player-media js-audio-player\" data-mesjours-record=\"true\" data-programme=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"href"), env.opts.autoescape);
output += "\" data-podcast=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"href"), env.opts.autoescape);
output += "\" data-podcast-duration=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"duration"), env.opts.autoescape);
output += "\" data-mtm-type=\"click\" data-mtm-category=\"Article Podcast\" data-mtm-action=\"Play Podcast\" data-mtm-label=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"href") + "/" + env.getFilter("hrefWithCategory").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")), env.opts.autoescape);
output += "\">\n\t\t\t\t\t<audio class=\"podcast-audio-player\" aria-hidden=\"true\" preload=\"none\">\n";
frame = frame.push();
var t_13 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"files");
if(t_13) {t_13 = runtime.fromIterator(t_13);
var t_12 = t_13.length;
for(var t_11=0; t_11 < t_13.length; t_11++) {
var t_14 = t_13[t_11];
frame.set("source", t_14);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
output += "\t\t\t\t\t\t<source type=\"";
output += runtime.suppressValue(env.getFilter("mimeType").call(context, t_14), env.opts.autoescape);
output += "\" src=\"";
output += runtime.suppressValue(t_14, env.opts.autoescape);
output += "\" />\n";
;
}
}
frame = frame.pop();
output += "\t\t\t\t\t</audio>\n\t\t\t\t\t<button class=\"btn-svg btn-large btn-player-lj-color btn-player-hover-scale js-play-btn\">\n\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 104, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["media-play"])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 105, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["media-pause"])), env.opts.autoescape);
output += "\n\t\t\t\t\t</button>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class=\"subtitle color-medium-8\">\n\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"subtitle"), env.opts.autoescape);
output += "\n\t\t\t</div>\n\t\t</div>\n\t\t<div class=\"row row-space mt-1 infos\">\n";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"hideNumber")) {
output += "\t\t\t<span class=\"col w-auto color-dark w-100\">Podcast n°&#x202f;";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"number"), env.opts.autoescape);
output += "<span>&nbsp;·&nbsp;</span>";
output += runtime.suppressValue(env.getFilter("humanDuration").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"duration")), env.opts.autoescape);
output += "</span>\n";
;
}
output += "\t\t\t";
output += runtime.suppressValue((lineno = 117, colno = 21, runtime.callWrap(t_4, "mesJoursArticle", context, ["card","Programme",runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"href"),"Podcast",runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"href"),env.getFilter("getPodcastCategory").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),{"classes": "col mt-1"}])), env.opts.autoescape);
output += "\n\t\t</div>\n\t</div>\n</div>\n";
;
}
else {
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") === "small")) {
output += "<div class=\"article article-podcast article-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type"), env.opts.autoescape);
output += " lj-color-";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"color")),"id"), env.opts.autoescape);
output += " player-media sm-pv-3 pv-4 js-audio-player\" data-type=\"bar\" data-mesjours-record=\"true\" data-programme=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"href"), env.opts.autoescape);
output += "\" data-podcast=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"href"), env.opts.autoescape);
output += "\" data-podcast-duration=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"duration"), env.opts.autoescape);
output += "\">\n\t<div class=\"player-wrapper bg-lighter\">\n\t\t<div class=\"ph-2 pv-1\">\n\t\t\t<div class=\"mb-2\">\n\t\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"href"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t<span class=\"inline title link on-hover color-dark\">\n\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("removeBR").call(context, env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"titleSeo"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"title"))), env.opts.autoescape);
output += "\n\t\t\t\t\t</span>\n\t\t\t\t</a>\n\t\t\t</div>\n\t\t\t<div class=\"flex row-center no-wrap\">\n\t\t\t\t<div class=\"w-auto media\">\n\t\t\t\t\t<div data-mtm-type=\"click\" data-mtm-category=\"Article Podcast\" data-mtm-action=\"Play Podcast\" data-mtm-label=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"href") + "/" + env.getFilter("hrefWithCategory").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t<audio class=\"podcast-audio-player\" aria-hidden=\"true\" preload=\"none\">\n";
frame = frame.push();
var t_17 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"files");
if(t_17) {t_17 = runtime.fromIterator(t_17);
var t_16 = t_17.length;
for(var t_15=0; t_15 < t_17.length; t_15++) {
var t_18 = t_17[t_15];
frame.set("source", t_18);
frame.set("loop.index", t_15 + 1);
frame.set("loop.index0", t_15);
frame.set("loop.revindex", t_16 - t_15);
frame.set("loop.revindex0", t_16 - t_15 - 1);
frame.set("loop.first", t_15 === 0);
frame.set("loop.last", t_15 === t_16 - 1);
frame.set("loop.length", t_16);
output += "\t\t\t\t\t\t\t<source type=\"";
output += runtime.suppressValue(env.getFilter("mimeType").call(context, t_18), env.opts.autoescape);
output += "\" src=\"";
output += runtime.suppressValue(t_18, env.opts.autoescape);
output += "\" />\n";
;
}
}
frame = frame.pop();
output += "\t\t\t\t\t\t</audio>\n\t\t\t\t\t\t<button class=\"btn-svg btn-large btn-player-lj-color btn-player-hover-scale js-play-btn\">\n\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 141, colno = 14, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["media-play"])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 142, colno = 14, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["media-pause"])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t</button>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"w-auto flex-1 col-center ml-1\">\n\t\t\t\t\t<div class=\"player-progress-bar relative bg-light w-100 js-jump-btn\">\n\t\t\t\t\t\t<div class=\"lj-color-bg progress-player js-player-progress\"></div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class=\"flex player-time row-justify row-center fw-bold ph-2 pv-1\">\n\t\t\t<div class=\"current-time js-player-position\">0:00</div>\n\t\t\t<div class=\"playback-rate js-playback-rate ";
output += runtime.suppressValue(env.getFilter("getTextColor").call(context, runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"color")),"main"),"class"), env.opts.autoescape);
output += "\">\n\t\t\t\t<span class=\"btn-svg btn-player-lj-color js-rate-1\">";
output += runtime.suppressValue((lineno = 156, colno = 63, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["speed-1"])), env.opts.autoescape);
output += "</span>\n\t\t\t\t<span class=\"btn-svg btn-player-lj-color js-rate-1p5 hidden\">";
output += runtime.suppressValue((lineno = 157, colno = 72, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["speed-1.5"])), env.opts.autoescape);
output += "</span>\n\t\t\t</div>\n\t\t\t<div class=\"mh-auto ";
output += runtime.suppressValue(env.getFilter("getTextColor").call(context, runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"color")),"main"),"class"), env.opts.autoescape);
output += " h-100\">\n\t\t\t\t<span class=\"btn-svg btn-player-lj-color js-quickjump-btn\" data-seconds=\"-10\">";
output += runtime.suppressValue((lineno = 160, colno = 89, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["arrow-10s"])), env.opts.autoescape);
output += "</span>\n\t\t\t\t<span class=\"btn-svg btn-player-lj-color js-quickjump-btn\" data-seconds=\"30\">";
output += runtime.suppressValue((lineno = 161, colno = 88, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["arrow-30s"])), env.opts.autoescape);
output += "</span>\n\t\t\t</div>\n\t\t\t<div class=\"total-time\">";
output += runtime.suppressValue(env.getFilter("humanDuration").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"duration"),":"), env.opts.autoescape);
output += "</div>\n\t\t</div>\n\t</div>\n\t<div class=\"mt-2\">\n\t\t<div class=\"subtitle color-medium-8\">\n\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"subtitle"), env.opts.autoescape);
output += "\n\t\t</div>\n\t</div>\n\t<div class=\"mt-1\">\n\t\t<div class=\"row no-wrap\">\n\t\t\t<div class=\"col w-auto flex-0 infos color-medium-8\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"showDate")) {
output += "\t\t\t\t<span class=\"mr-3\">\n\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("humanDate").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"date")), env.opts.autoescape);
output += "\n\t\t\t\t</span>\n";
;
}
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"hideNumber")) {
output += "\t\t\t\t<span>\n\t\t\t\t\tPodcast n°&#x202f;";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"number"), env.opts.autoescape);
output += "<span>&nbsp;·&nbsp;</span>";
output += runtime.suppressValue(env.getFilter("humanDuration").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"duration")), env.opts.autoescape);
output += "\n\t\t\t\t</span>\n";
;
}
output += "\t\t\t</div>\n\n\t\t</div>\n\t</div>\n\t";
output += runtime.suppressValue((lineno = 188, colno = 19, runtime.callWrap(t_4, "mesJoursArticle", context, ["card","Programme",runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"href"),"Podcast",runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"href"),env.getFilter("getPodcastCategory").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),{"classes": "mt-1 infos"}])), env.opts.autoescape);
output += "\n</div>\n";
;
}
else {
output += "<div class=\"article row article-podcast article-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type"), env.opts.autoescape);
output += " lj-color-";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"color")),"id"), env.opts.autoescape);
output += "\">\n\t<div class=\"col ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") !== "list") {
output += "w-100";
;
}
else {
output += "sm-w-100 md-w-50";
;
}
output += " article-image\">\n\t\t<div class=\"w-100 h-100 relative\" data-href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"href"), env.opts.autoescape);
output += "\">\n";
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") === "large") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"image") && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"image")),"files")) {
output += "\t\t\t<div class=\"slideshow pause h-100\" data-duration=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"image")),"duration"), env.opts.autoescape);
output += "\" data-autoplay=\"true\" data-animation=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"image")),"animation"), env.opts.autoescape);
output += "\">\n\t\t\t\t<div class=\"slideshow-container h-100\">\n";
frame = frame.push();
var t_21 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"image")),"files");
if(t_21) {t_21 = runtime.fromIterator(t_21);
var t_20 = t_21.length;
for(var t_19=0; t_19 < t_21.length; t_19++) {
var t_22 = t_21[t_19];
frame.set("file", t_22);
frame.set("loop.index", t_19 + 1);
frame.set("loop.index0", t_19);
frame.set("loop.revindex", t_20 - t_19);
frame.set("loop.revindex0", t_20 - t_19 - 1);
frame.set("loop.first", t_19 === 0);
frame.set("loop.last", t_19 === t_20 - 1);
frame.set("loop.length", t_20);
output += "\t\t\t\t\t<div class=\"slideshow-slide";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0") === 0) {
output += " sl-current";
;
}
output += "\" data-timing=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"image")),"timings")),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0")), env.opts.autoescape);
output += "\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"lazyload")) {
output += "\t\t\t\t\t\t<img class=\"responsive h-100 lazyload\" src=\"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7\" data-src=\"";
output += runtime.suppressValue(t_22, env.opts.autoescape);
output += "\" data-sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"sizes")), env.opts.autoescape);
output += "\" data-srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, t_22), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"title"))), env.opts.autoescape);
output += "\" />\n";
;
}
else {
output += "\t\t\t\t\t\t<img class=\"responsive h-100\" src=\"";
output += runtime.suppressValue(t_22, env.opts.autoescape);
output += "\" sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"sizes")), env.opts.autoescape);
output += "\" srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, t_22), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"title"))), env.opts.autoescape);
output += "\" />\n";
;
}
output += "\t\t\t\t\t</div>\n";
;
}
}
frame = frame.pop();
output += "\t\t\t\t</div>\n\t\t\t</div>\n";
;
}
else {
var t_23;
t_23 = env.getFilter("episodeOrSerieImage").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme"),true);
frame.set("podcastImage", t_23, true);
if(frame.topLevel) {
context.setVariable("podcastImage", t_23);
}
if(frame.topLevel) {
context.addExport("podcastImage", t_23);
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"lazyload")) {
output += "\t\t\t<img class=\"responsive h-100 lazyload\" src=\"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7\" data-src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "podcastImage"), env.opts.autoescape);
output += "\" data-sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"sizes")), env.opts.autoescape);
output += "\" data-srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.contextOrFrameLookup(context, frame, "podcastImage")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"title"))), env.opts.autoescape);
output += "\" />\n";
;
}
else {
output += "\t\t\t<img class=\"responsive h-100\" src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "podcastImage"), env.opts.autoescape);
output += "\" sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"sizes")), env.opts.autoescape);
output += "\" srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.contextOrFrameLookup(context, frame, "podcastImage")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"title"))), env.opts.autoescape);
output += "\" />\n";
;
}
;
}
output += "\t\t\t<div class=\"progress-bar js-progress-bar w-100\" data-duration=\"";
output += runtime.suppressValue(env.getFilter("getDuration").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")), env.opts.autoescape);
output += "\" data-programme=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"href"), env.opts.autoescape);
output += "\" data-podcast=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"href"), env.opts.autoescape);
output += "\"></div>\n\t\t</div>\n\t</div>\n\t<div class=\"col ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") !== "list") {
output += "w-100";
;
}
else {
output += "sm-w-100 md-w-50";
;
}
output += " article-content row m-0 row-vertical\">\n\t\t<div class=\"row mh-0 ";
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") !== "list")) {
output += "mt-2";
;
}
else {
output += "sm-mt-2";
;
}
output += " row-center no-wrap obsession-name\">\n\t\t\t<a class=\"link on-hover lj-color-text text-upper\" href=\"/podcasts/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"href"), env.opts.autoescape);
output += "/\">\n\t\t\t\t";
output += runtime.suppressValue(env.getFilter("removeBR").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"title")), env.opts.autoescape);
output += "\n\t\t\t</a>\n\t\t</div>\n\t\t<div class=\"mt-1\">\n";
var t_24;
t_24 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"titleTag")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"titleTag"):"span");
frame.set("titleTag", t_24, true);
if(frame.topLevel) {
context.setVariable("titleTag", t_24);
}
if(frame.topLevel) {
context.addExport("titleTag", t_24);
}
output += "\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"href"), env.opts.autoescape);
output += "\">\n\t\t\t\t<";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "titleTag"), env.opts.autoescape);
output += " class=\"inline title link on-hover color-dark\">\n\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("removeBR").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"title")), env.opts.autoescape);
output += "\n\t\t\t\t</";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "titleTag"), env.opts.autoescape);
output += ">\n\t\t\t</a>\n\t\t</div>\n\t\t<div class=\"mt-1\">\n\t\t\t<div class=\"media pull-left mr-1\">\n\t\t\t\t<div class=\"player-media js-audio-player\" data-mesjours-record=\"true\" data-programme=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"href"), env.opts.autoescape);
output += "\" data-podcast=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"href"), env.opts.autoescape);
output += "\" data-podcast-duration=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"duration"), env.opts.autoescape);
output += "\" data-mtm-type=\"click\" data-mtm-category=\"Article Podcast\" data-mtm-action=\"Play Podcast\" data-mtm-label=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"href") + "/" + env.getFilter("hrefWithCategory").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")), env.opts.autoescape);
output += "\">\n\t\t\t\t\t<audio class=\"podcast-audio-player\" aria-hidden=\"true\" preload=\"none\">\n";
frame = frame.push();
var t_27 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"files");
if(t_27) {t_27 = runtime.fromIterator(t_27);
var t_26 = t_27.length;
for(var t_25=0; t_25 < t_27.length; t_25++) {
var t_28 = t_27[t_25];
frame.set("source", t_28);
frame.set("loop.index", t_25 + 1);
frame.set("loop.index0", t_25);
frame.set("loop.revindex", t_26 - t_25);
frame.set("loop.revindex0", t_26 - t_25 - 1);
frame.set("loop.first", t_25 === 0);
frame.set("loop.last", t_25 === t_26 - 1);
frame.set("loop.length", t_26);
output += "\t\t\t\t\t\t<source type=\"";
output += runtime.suppressValue(env.getFilter("mimeType").call(context, t_28), env.opts.autoescape);
output += "\" src=\"";
output += runtime.suppressValue(t_28, env.opts.autoescape);
output += "\" />\n";
;
}
}
frame = frame.pop();
output += "\t\t\t\t\t</audio>\n\t\t\t\t\t<button class=\"btn-svg btn-large btn-player-lj-color btn-player-hover-scale js-play-btn\">\n\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 242, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["media-play"])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 243, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["media-pause"])), env.opts.autoescape);
output += "\n\t\t\t\t\t</button>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class=\"subtitle color-medium-8\">\n\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"subtitle"), env.opts.autoescape);
output += "\n\t\t\t</div>\n\t\t</div>\n\t\t<div class=\"row row-space mt-1 infos\">\n\t\t\t<div class=\"col w-auto w-100\">\n";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"hideNumber")) {
output += "\t\t\t\t\t<span>Podcast n°&#x202f;";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"number"), env.opts.autoescape);
output += "<span>&nbsp;·&nbsp;</span>";
output += runtime.suppressValue(env.getFilter("humanDuration").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"duration")), env.opts.autoescape);
output += "</span>\n";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"showDate")) {
output += "\t\t\t\t\t<span>";
output += runtime.suppressValue(env.getFilter("humanDate").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"date")), env.opts.autoescape);
output += "</span>\n";
;
}
output += "\t\t\t</div>\n\t\t\t";
output += runtime.suppressValue((lineno = 260, colno = 21, runtime.callWrap(t_4, "mesJoursArticle", context, ["card","Programme",runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"programme")),"href"),"Podcast",runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),"href"),env.getFilter("getPodcastCategory").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"podcast")),{"classes": "col mt-1"}])), env.opts.autoescape);
output += "\n\t\t</div>\n\t</div>\n</div>\n";
;
}
;
}
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("articles/podcast.html", ctx, cb); }
})();
