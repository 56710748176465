import $ from "jquery";
import * as LesJoursFeatures from "./lesjours-features";
const nunjucksSlim = require("../../../node_modules/nunjucks/browser/nunjucks-slim.js");

// Configure Nunjucks Environment
window.nunjucksPrecompiled = window.nunjucksPrecompiled || {};
const env = nunjucksSlim.configure({
  autoescape: false,
  trimBlocks: true, // automatically remove trailing newlines from a block/tag
  lstripBlocks: true, // automatically remove leading whitespace from a block/tag
});

// Add Globals
env.addGlobal("baseUrl", window.LesJours.baseUrl);
env.addGlobal("packageVersion", window.LesJours.version);

// Add Filters
env.addFilter(
  "isFeatureActivated",
  function (feature) {
    return LesJoursFeatures.isActivated(feature);
  },
  false
);
env.addFilter("startsWith", function (input, pattern) {
  return new RegExp("^" + pattern).test(input || "");
});
env.addFilter("test", function (pattern, input) {
  return pattern.test(input || "");
});
env.addFilter("in", function (input, array) {
  return $.inArray(input, array || []) > -1;
});
env.addFilter("split", function (input, separator) {
  return (input || "").split(separator);
});
env.addFilter("slice", function (array, begin, end) {
  // Check if there is an array
  if (array === undefined || array.slice === undefined) {
    return array;
  }

  return array.slice(begin, end);
});
env.addFilter("shuffle", function (array) {
  // Initialize variables
  let m = array.length;
  let t;
  let i;

  // While there remain elements to shuffle
  while (m) {
    // Pick a remaining element
    i = Math.floor(Math.random() * m--);

    // And swap it with the current element
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  // Return the shuffled array
  return array;
});
env.addFilter(
  "unescape",
  (function () {
    const unescapeMap = {
      "&amp;": "&",
      "&lt;": "<",
      "&gt;": ">",
      "&quot;": '"',
      "&#x27;": "'",
      "&#x60;": "`",
      "&nbsp;": String.fromCodePoint(160),
    };
    const escaper = function (match) {
      return unescapeMap[match];
    };
    const source = "(?:&amp;|&lt;|&gt;|&quot;|&#x27;|&#x60;|&nbsp;)";
    const testRegexp = RegExp(source);
    const replaceRegexp = RegExp(source, "g");

    return function (string) {
      string = string === null ? "" : "" + string;
      return testRegexp.test(string) ? string.replace(replaceRegexp, escaper) : string;
    };
  })()
);
env.addFilter("removeBR", function (input) {
  return (input || "").replace(/<\/?br\s?\/?>/g, " ");
});
env.addFilter(
  "removeTags",
  (function () {
    const testRegexp = /(?:<\/?[a-z]+\b[^>]*>)/;
    const replaceRegexp = /(?:<\/?[a-z]+\b[^>]*>)/gi;

    return function (string) {
      string = string === null ? "" : "" + string;
      return testRegexp.test(string) ? string.replace(replaceRegexp, "").replace(/\s+/gi, " ") : string;
    };
  })()
);
env.addFilter(
  "removeLinks",
  (function () {
    const testRegexp = /(?:<\/?a\b[^>]*>)/;
    const replaceRegexp = /(?:<\/?a\b[^>]*>)/gi;

    return function (string) {
      string = string === null ? "" : "" + string;
      return testRegexp.test(string) ? string.replace(replaceRegexp, "").replace(/\s+/gi, " ") : string;
    };
  })()
);
env.addFilter(
  "mimeType",
  (function () {
    // Create the MIME Types Map
    const mimeTypes = {
      // Images files
      jpeg: "image/jpeg",
      jpg: "image/jpeg",
      png: "image/png",
      gif: "image/gif",
      // Audio files
      m4a: "audio/mp4",
      ogg: "audio/ogg",
      opus: "audio/ogg",
      weba: "audio/webm",
      // Video files
      webm: "video/webm",
      mp4: "video/mp4",
    };

    return function (file) {
      // Return the MIME Type
      return mimeTypes[file.substring(file.lastIndexOf(".") + 1)];
    };
  })()
);
env.addFilter("escapedDump", function (obj) {
  // This only works for a simple object without quotes in its values
  return JSON.stringify(obj || {}).replace(/"/g, "'");
});
env.addFilter(
  "toHref",
  (function () {
    const testRegexp = /[^a-z]/;
    const replaceRegexp = /[^a-z]/gi;

    return function (string) {
      string = string === null ? "" : ("" + string).toLowerCase();
      return testRegexp.test(string) ? string.replace(replaceRegexp, "") : string;
    };
  })()
);
env.addFilter("humanDate", function (input) {
  const date = new Date(input);
  let month = date.getMonth();
  let day = date.getDate();

  switch (month) {
    case 0:
      month = "janvier";
      break;
    case 1:
      month = "février";
      break;
    case 2:
      month = "mars";
      break;
    case 3:
      month = "avril";
      break;
    case 4:
      month = "mai";
      break;
    case 5:
      month = "juin";
      break;
    case 6:
      month = "juillet";
      break;
    case 7:
      month = "août";
      break;
    case 8:
      month = "septembre";
      break;
    case 9:
      month = "octobre";
      break;
    case 10:
      month = "novembre";
      break;
    case 11:
      month = "décembre";
      break;
  }

  if (day === 1) {
    day = day + "er";
  }

  return day + " " + month + " " + date.getFullYear();
});
env.addFilter("humanDuration", function (input, separator = "mn") {
  const minutes = Math.floor(input / 60);
  let seconds = Math.round(input % 60);

  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return `${minutes}${separator}${seconds}`;
});
env.addFilter("humanNumber", function (input) {
  return input.toString().replace(/^(\d{1,3})(\d{3})$/, "$1&nbsp;$2");
});
env.addFilter("groupAuthors", function (authors) {
  // Output Authors Groups Objects
  const groupedAuthors = {};
  const output = [];

  // Check if we have authors
  if (authors === undefined) {
    return [];
  }

  // Iterate through Authors
  authors.forEach(function (author) {
    // Check if we already added the kind of author
    if (groupedAuthors[author.title] !== undefined) {
      // Append the Author to the Group
      groupedAuthors[author.title].people.push(author.people);
    } else {
      // Create a new Group of Authors
      groupedAuthors[author.title] = {
        title: author.title,
        main: false,
        people: [author.people],
      };
    }
  });

  // Convert the Object to an Array
  $.each(groupedAuthors, function (idx, el) {
    // Add Object Value to the Array
    output.push(el);
  });

  // Return Grouped Authors
  return output;
});
env.addFilter("authorsKind", function (group) {
  // Get the Title
  switch (group.title) {
    case "text":
      return "Texte";
    case "photo":
      return "Photo" + (group.people.length > 1 ? "s" : "");
    case "drawing":
      return "Illustration" + (group.people.length > 1 ? "s" : "");
    case "itw":
      return "Recueilli par";
    default:
      return group.title;
  }
});
env.addFilter("getEpisodeForImage", function (input, value) {
  if (!input) {
    return input;
  }

  return input.episodes.find((episode) => episode.number === value.inEpisode);
});
env.addFilter("getParagraphs", function (episode) {
  // Check if there is an episode
  if (!episode) {
    return episode;
  }

  // Return the number of paragraphs
  return episode.paragraphs || 0;
});
env.addFilter("getDuration", function (podcast) {
  // Check if there is a podcast
  if (!podcast) {
    return podcast;
  }

  // Return the duration
  return podcast.duration || 0;
});
env.addFilter(
  "hexToRGBA",
  (function () {
    const shorthandHexRegex = /^#?([a-f0-9])([a-f0-9])([a-f0-9])$/i;
    const hexRegex = /^#?([a-f0-9]{2})([a-f0-9]{2})([a-f0-9]{2})$/i;
    const expandHex = function (m, r, g, b) {
      return r + r + g + g + b + b;
    };

    return function (input, alpha) {
      if (!input) {
        return input;
      }

      // If alpha is undefined we use 1
      if (!alpha) {
        alpha = 1;
      }

      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      input = input.replace(shorthandHexRegex, expandHex);

      // Convert the input hex value to rgba
      const result = hexRegex.exec(input);
      return result
        ? "rgba(" +
            parseInt(result[1], 16) +
            "," +
            parseInt(result[2], 16) +
            "," +
            parseInt(result[3], 16) +
            "," +
            alpha +
            ")"
        : input;
    };
  })()
);
env.addFilter("src", function (article, serie) {
  if (!article) {
    return article;
  }

  if (article.image) {
    return article.image.files ? article.image.files[0] : article.image.img;
  } else {
    return serie.img;
  }
});
env.addFilter(
  "srcset",
  (function () {
    const resRegex = /^\/?ressources/;

    return function (input, opts) {
      if (!input) {
        return input;
      }

      // Defaults parameters
      opts = opts || {};

      // Create filepath without ressources folder
      const file = input.replace(resRegex, "");

      // Check if there is options
      if (opts.grayscale) {
        // Return the grayscale version
        return `/ressources/res280/grayscale${file} 280w, /ressources/res480/grayscale${file} 480w, /ressources/res840/grayscale${file} 840w, /ressources/res1500/grayscale${file} 1500w, /ressources/grayscale${file} 1920w`;
      } else if (opts.square) {
        // Return the square version
        return `/ressources/res280/square${file} 280w, /ressources/res480/square${file} 480w, /ressources/res840/square${file} 840w, /ressources/res1500/square${file} 1500w`;
      } else if (opts.portrait) {
        // Return the portrait version
        return `/ressources/res280/portrait${file} 280w, /ressources/res380/portrait${file} 380w, /ressources/res560/portrait${file} 560w, /ressources/res760/portrait${file} 760w`;
      } else {
        // Return the classic version
        return `/ressources/res280${file} 280w, /ressources/res480${file} 480w, /ressources/res840${file} 840w, /ressources/res1500${file} 1500w, /ressources${file} 1920w`;
      }
    };
  })()
);
env.addFilter("sizes", function (input) {
  if (!input) {
    return input;
  }

  // Create the sizes
  let sizes = "(max-width: 588px) " + input[0] + ", ";
  sizes += "(max-width: 960px) " + input[1] + ", ";
  sizes += input[2];

  // Return the sizes
  return sizes;
});
env.addFilter(
  "res",
  (function () {
    const resRegex = /^\/?ressources/;
    const sizes = {
      120: "/ressources/res120",
      280: "/ressources/res280",
      380: "/ressources/res380",
      480: "/ressources/res480",
      560: "/ressources/res560",
      760: "/ressources/res760",
      840: "/ressources/res840",
      1500: "/ressources/res1500",
      1920: "/ressources",
    };

    return function (input, size, opts) {
      if (!input) {
        return input;
      }

      // Defaults parameters
      if ((opts === null || undefined === opts) && typeof size === "object") {
        opts = size;
        size = 1920;
      } else {
        size = size || 1920;
        opts = opts || {};
      }

      // Check if there is options
      if (opts.grayscale) {
        // Return the grayscale version
        return input.replace(resRegex, sizes[size] + "/grayscale");
      } else if (opts.square) {
        // Return the square version
        return input.replace(resRegex, sizes[size] + "/square");
      } else if (opts.portrait) {
        // Return the portrait version
        return input.replace(resRegex, sizes[size] + "/portrait");
      } else {
        // Return the classic version
        return input.replace(resRegex, sizes[size]);
      }
    };
  })()
);
env.addFilter("episodeOrSerieImage", function (episode, serie, onlyTheFirstOne = false) {
  // Check if the episode has an image
  if (episode.image) {
    // Check if the image has several files
    if (episode.image.files) {
      return onlyTheFirstOne ? episode.image.files[0] : episode.image.files;
    }

    // Return the episode's image
    return episode.image.img;
  }

  // Return the serie's image
  return serie.img;
});

env.addFilter("getPodcastCategory", function (input) {
  if (!input) {
    return input;
  }

  if (input.categories !== undefined && input.categories.length > 0) {
    return input.categories[0];
  }

  return null;
});

env.addFilter("hrefWithCategory", function (input) {
  if (!input) {
    return input;
  }

  if (input.categories !== undefined && input.categories.length > 0) {
    return input.categories[0] + "/" + input.href;
  }

  return input.href;
});

env.addFilter("hrefForNoteOrMini", function (noteOrMini) {
  // Check if it's a note or a mini
  let isMini =
    noteOrMini.doc !== undefined ||
    noteOrMini.img !== undefined ||
    noteOrMini.alt !== undefined ||
    noteOrMini.subtitle !== undefined ||
    noteOrMini.model !== undefined;

  // Check if it's a note
  if (isMini === false) {
    return "#note-" + noteOrMini.number;
  }

  // Check if there is a model whitout href
  if (noteOrMini.model === undefined || (noteOrMini.href !== undefined && noteOrMini.href !== "")) {
    // Return the href
    return noteOrMini.href.startsWith("http") ? noteOrMini.href + '" target="_blank" rel="noopener' : noteOrMini.href;
  }

  // Check if there is an Author
  if (noteOrMini.doc.authorPage !== undefined) {
    // Return a link to the Author page
    return "/auteurs/" + noteOrMini.doc.authorPage.href + '/" data-modal="#' + noteOrMini.doc.href;
  }

  // Check if there is a Meta-Person
  if (noteOrMini.doc.metaperson !== undefined) {
    // Return a link to the Meta-Person page
    return "/personnages/" + noteOrMini.doc.metaperson.href + '/" data-modal="#' + noteOrMini.doc.href;
  }

  // Check if the mini has files
  if (
    noteOrMini.doc.img !== undefined ||
    noteOrMini.doc.files === undefined ||
    /(webm|m4a)$/g.test(noteOrMini.doc.files[0])
  ) {
    // Return the document's href
    return "#" + noteOrMini.doc.href;
  } else {
    // Return the first document file
    return noteOrMini.doc.files[0];
  }
});

export default env;
