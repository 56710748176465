(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["media/place.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n<div class=\"media flex\" data-href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"href"), env.opts.autoescape);
output += "\">\n\t<div class=\"media-image\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"lazyload")) {
output += "\t\t<img class=\"responsive-media lazyload\" src=\"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7\" data-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"image"), env.opts.autoescape);
output += "\" data-sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"sizes")), env.opts.autoescape);
output += "\" data-srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"image")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"title"))), env.opts.autoescape);
output += "\" />\n";
;
}
else {
output += "\t\t<img class=\"responsive-media\" src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"image"), env.opts.autoescape);
output += "\" sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"sizes")), env.opts.autoescape);
output += "\" srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"image")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"title"))), env.opts.autoescape);
output += "\" />\n";
;
}
output += "\t</div>\n\t<div class=\"overlay gradient-to-top p-2\">\n\t\t<div class=\"container h-100 row row-vertical row-end\">\n\t\t\t<div class=\"col p-0 text-center\">\n\t\t\t\t<span class=\"title color-white link on-hover\">\n\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"title"), env.opts.autoescape);
output += "\n\t\t\t\t</span>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("media/place.html", ctx, cb); }
})();
