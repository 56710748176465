(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["slider/series.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "slider/series.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
var t_5;
t_5 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"cardType")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"cardType"):"large");
frame.set("cardType", t_5, true);
if(frame.topLevel) {
context.setVariable("cardType", t_5);
}
if(frame.topLevel) {
context.addExport("cardType", t_5);
}
var t_6;
t_6 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"cardSizes")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"cardSizes"):["380px","380px","380px"]);
frame.set("cardSizes", t_6, true);
if(frame.topLevel) {
context.setVariable("cardSizes", t_6);
}
if(frame.topLevel) {
context.addExport("cardSizes", t_6);
}
output += "<div class=\"slider relative sm-ph-0 ph-1\" data-slider-infinite=\"true\">\n\t<div class=\"ph-2 pb-1\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"titleLink")) {
output += "\t\t<a class=\"slider-title color-lesjours\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"titleLink"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"title")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"title"):runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"titleLink")), env.opts.autoescape);
output += "</a>\n";
;
}
else {
output += "\t\t<span class=\"slider-title color-lesjours\">";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"title")) {
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"title"), env.opts.autoescape);
;
}
else {
output += "Les séries des <i>Jours</i>";
;
}
output += "</span>\n";
;
}
output += "\t</div>\n\t<div class=\"slider-container\">\n";
frame = frame.push();
var t_9 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"series");
if(t_9) {t_9 = runtime.fromIterator(t_9);
var t_8 = t_9.length;
for(var t_7=0; t_7 < t_9.length; t_7++) {
var t_10 = t_9[t_7];
frame.set("serie", t_10);
frame.set("loop.index", t_7 + 1);
frame.set("loop.index0", t_7);
frame.set("loop.revindex", t_8 - t_7);
frame.set("loop.revindex0", t_8 - t_7 - 1);
frame.set("loop.first", t_7 === 0);
frame.set("loop.last", t_7 === t_8 - 1);
frame.set("loop.length", t_8);
output += "\t\t<div class=\"p-2 ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"first")) {
output += "active";
;
}
output += "\">\n";
if(runtime.memberLookup((t_10),"model") === "Obsession") {
var t_11;
t_11 = (runtime.memberLookup((t_10),"document")?runtime.memberLookup((t_10),"document"):runtime.memberLookup((t_10),"obsession"));
frame.set("obsession", t_11, true);
if(frame.topLevel) {
context.setVariable("obsession", t_11);
}
if(frame.topLevel) {
context.addExport("obsession", t_11);
}
var t_12;
t_12 = {"href": "/obsessions/" + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"href") + "/","title": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"title"),"subtitle": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"subtitle"),"obsession": runtime.contextOrFrameLookup(context, frame, "obsession"),"image": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"img"),"type": runtime.contextOrFrameLookup(context, frame, "cardType"),"snake": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"snake"),"lazyload": true,"sizes": runtime.contextOrFrameLookup(context, frame, "cardSizes")};
frame.set("card", t_12, true);
if(frame.topLevel) {
context.setVariable("card", t_12);
}
if(frame.topLevel) {
context.addExport("card", t_12);
}
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("cards/obsession.html", false, "slider/series.html", false, function(t_14,t_13) {
if(t_14) { cb(t_14); return; }
callback(null,t_13);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
callback(null,t_15);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
});
}
else {
if(runtime.memberLookup((t_10),"model") === "Programme") {
var t_17;
t_17 = (runtime.memberLookup((t_10),"document")?runtime.memberLookup((t_10),"document"):runtime.memberLookup((t_10),"programme"));
frame.set("programme", t_17, true);
if(frame.topLevel) {
context.setVariable("programme", t_17);
}
if(frame.topLevel) {
context.addExport("programme", t_17);
}
var t_18;
t_18 = {"href": "/podcasts/" + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "programme")),"href") + "/","title": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "programme")),"title"),"subtitle": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "programme")),"subtitle"),"programme": runtime.contextOrFrameLookup(context, frame, "programme"),"image": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "programme")),"img"),"type": runtime.contextOrFrameLookup(context, frame, "cardType"),"snake": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "programme")),"snake"),"lazyload": true,"sizes": runtime.contextOrFrameLookup(context, frame, "cardSizes")};
frame.set("card", t_18, true);
if(frame.topLevel) {
context.setVariable("card", t_18);
}
if(frame.topLevel) {
context.addExport("card", t_18);
}
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("cards/programme.html", false, "slider/series.html", false, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
callback(null,t_19);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_22,t_21) {
if(t_22) { cb(t_22); return; }
callback(null,t_21);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
});
}
;
}
output += "\t\t</div>\n";
;
}
}
frame = frame.pop();
output += "\t</div>\n\t<div class=\"slider-controls\">\n\t\t<button class=\"btn-svg btn-large btn-hover-lesjours\" data-direction=\"backward\">";
output += runtime.suppressValue((lineno = 51, colno = 88, runtime.callWrap(t_4, "icon", context, ["previous","Précédant"])), env.opts.autoescape);
output += "</button>\n\t\t<button class=\"btn-svg btn-large btn-hover-lesjours\" data-direction=\"forward\">";
output += runtime.suppressValue((lineno = 52, colno = 87, runtime.callWrap(t_4, "icon", context, ["next","Suivant"])), env.opts.autoescape);
output += "</button>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("slider/series.html", ctx, cb); }
})();
