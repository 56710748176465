(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["mes-jours/actions/serie.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "mes-jours/actions/serie.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
if(runtime.contextOrFrameLookup(context, frame, "type") === "card") {
if(runtime.contextOrFrameLookup(context, frame, "status") === "login") {
output += "\t<a class=\"color-white follow\" href=\"#login\" data-mtm-type=\"click\" data-mtm-category=\"Mes Jours\" data-mtm-action=\"Login\">\n\t\t";
output += runtime.suppressValue((lineno = 4, colno = 9, runtime.callWrap(t_4, "icon", context, ["follow","Suivre"])), env.opts.autoescape);
output += "\n\t</a>\n";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "status") === "follow") {
output += "\t<span class=\"color-white follow\" data-mtm-type=\"click\" data-mtm-category=\"Mes Jours\" data-mtm-action=\"Subscribe ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmActionNoun"), env.opts.autoescape);
output += "\" data-mtm-label=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmLabel"), env.opts.autoescape);
output += "\">\n\t\t";
output += runtime.suppressValue((lineno = 8, colno = 9, runtime.callWrap(t_4, "icon", context, ["follow","Suivre"])), env.opts.autoescape);
output += "\n\t</span>\n";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "status") === "unfollow") {
output += "\t<span class=\"color-white unfollow\" data-mtm-type=\"click\" data-mtm-category=\"Mes Jours\" data-mtm-action=\"Unsubscribe ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmActionNoun"), env.opts.autoescape);
output += "\" data-mtm-label=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmLabel"), env.opts.autoescape);
output += "\">\n\t\t";
output += runtime.suppressValue((lineno = 12, colno = 9, runtime.callWrap(t_4, "icon", context, ["unfollow","Ne plus suivre"])), env.opts.autoescape);
output += "\n\t</span>\n";
;
}
;
}
;
}
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "type") === "cover") {
if(runtime.contextOrFrameLookup(context, frame, "status") === "login") {
output += "\t<a class=\"btn-cover follow\" href=\"#login\" data-mtm-type=\"click\" data-mtm-category=\"Mes Jours\" data-mtm-action=\"Login\">\n\t\tSuivre";
output += runtime.suppressValue((lineno = 18, colno = 15, runtime.callWrap(t_4, "icon", context, ["button-plus","Suivre","pl-1"])), env.opts.autoescape);
output += "\n\t</a>\n";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "status") === "follow") {
output += "\t<span class=\"btn-cover follow\" data-mtm-type=\"click\" data-mtm-category=\"Mes Jours\" data-mtm-action=\"Subscribe ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmActionNoun"), env.opts.autoescape);
output += "\" data-mtm-label=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmLabel"), env.opts.autoescape);
output += "\">\n\t\tSuivre";
output += runtime.suppressValue((lineno = 22, colno = 15, runtime.callWrap(t_4, "icon", context, ["button-plus","Suivre","pl-1"])), env.opts.autoescape);
output += "\n\t</span>\n";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "status") === "unfollow") {
output += "\t<span class=\"btn-cover unfollow\" data-mtm-type=\"click\" data-mtm-category=\"Mes Jours\" data-mtm-action=\"Unsubscribe ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmActionNoun"), env.opts.autoescape);
output += "\" data-mtm-label=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmLabel"), env.opts.autoescape);
output += "\">\n\t\tSuivie";
output += runtime.suppressValue((lineno = 26, colno = 15, runtime.callWrap(t_4, "icon", context, ["button-minus","Ne plus suivre","pl-1"])), env.opts.autoescape);
output += "\n\t</span>\n";
;
}
;
}
;
}
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "type") === "mesjours") {
if(runtime.contextOrFrameLookup(context, frame, "status") === "follow") {
output += "\t<span class=\"btn-square btn-lesjours follow\" data-mtm-type=\"click\" data-mtm-category=\"Mes Jours\" data-mtm-action=\"Subscribe ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmActionNoun"), env.opts.autoescape);
output += "\" data-mtm-label=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmLabel"), env.opts.autoescape);
output += "\">\n\t\tSuivre";
output += runtime.suppressValue((lineno = 32, colno = 15, runtime.callWrap(t_4, "icon", context, ["button-plus","Suivre","pl-1"])), env.opts.autoescape);
output += "\n\t</span>\n";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "status") === "unfollow") {
output += "\t<span class=\"flex h-100 row-center color-medium-5 unfollow mh-4\" data-mtm-type=\"click\" data-mtm-category=\"Mes Jours\" data-mtm-action=\"Unsubscribe ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmActionNoun"), env.opts.autoescape);
output += "\" data-mtm-label=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmLabel"), env.opts.autoescape);
output += "\">\n\t\tNe plus suivre";
output += runtime.suppressValue((lineno = 36, colno = 23, runtime.callWrap(t_4, "icon", context, ["minus","Ne plus suivre","pl-1"])), env.opts.autoescape);
output += "\n\t</span>\n";
;
}
;
}
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "type") === "mini") {
if(runtime.contextOrFrameLookup(context, frame, "status") === "login") {
output += "\t<a class=\"follow lj-color-text\" href=\"#login\" data-mtm-type=\"click\" data-mtm-category=\"Mes Jours\" data-mtm-action=\"Login\">\n\t\t";
output += runtime.suppressValue((lineno = 42, colno = 9, runtime.callWrap(t_4, "icon", context, ["button-plus","Suivre"])), env.opts.autoescape);
output += "Suivre\n\t</a>\n";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "status") === "follow") {
output += "\t<div class=\"follow lj-color-text\" data-mtm-type=\"click\" data-mtm-category=\"Mes Jours\" data-mtm-action=\"Subscribe ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmActionNoun"), env.opts.autoescape);
output += "\" data-mtm-label=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmLabel"), env.opts.autoescape);
output += "\">\n\t\t";
output += runtime.suppressValue((lineno = 46, colno = 9, runtime.callWrap(t_4, "icon", context, ["button-plus","Suivre"])), env.opts.autoescape);
output += "Suivre\n\t</div>\n";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "status") === "unfollow") {
output += "\t<div class=\"unfollow lj-color-text\" data-mtm-type=\"click\" data-mtm-category=\"Mes Jours\" data-mtm-action=\"Unsubscribe ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmActionNoun"), env.opts.autoescape);
output += "\" data-mtm-label=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmLabel"), env.opts.autoescape);
output += "\">\n\t\t";
output += runtime.suppressValue((lineno = 50, colno = 9, runtime.callWrap(t_4, "icon", context, ["button-minus","Ne plus suivre"])), env.opts.autoescape);
output += "Ne plus suivre\n\t</div>\n";
;
}
;
}
;
}
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "type") === "filled") {
if(runtime.contextOrFrameLookup(context, frame, "status") === "login") {
output += "\t<a class=\"btn-square btn-filled btn-lesjours follow\" href=\"#login\" data-mtm-type=\"click\" data-mtm-category=\"Mes Jours\" data-mtm-action=\"Login\">\n\t\tSuivre";
output += runtime.suppressValue((lineno = 56, colno = 15, runtime.callWrap(t_4, "icon", context, ["button-plus","Suivre","ml-auto"])), env.opts.autoescape);
output += "\n\t</a>\n";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "status") === "follow") {
output += "\t<span class=\"btn-square btn-filled btn-lesjours follow\" data-mtm-type=\"click\" data-mtm-category=\"Mes Jours\" data-mtm-action=\"Subscribe ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmActionNoun"), env.opts.autoescape);
output += "\" data-mtm-label=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmLabel"), env.opts.autoescape);
output += "\">\n\t\tSuivre";
output += runtime.suppressValue((lineno = 60, colno = 15, runtime.callWrap(t_4, "icon", context, ["button-plus","Suivre","ml-auto"])), env.opts.autoescape);
output += "\n\t</span>\n";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "status") === "unfollow") {
output += "\t<span class=\"btn-square btn-filled btn-lesjours unfollow\" data-mtm-type=\"click\" data-mtm-category=\"Mes Jours\" data-mtm-action=\"Unsubscribe ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmActionNoun"), env.opts.autoescape);
output += "\" data-mtm-label=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmLabel"), env.opts.autoescape);
output += "\">\n\t\tSuivie";
output += runtime.suppressValue((lineno = 64, colno = 15, runtime.callWrap(t_4, "icon", context, ["button-minus","Ne plus suivre","ml-auto"])), env.opts.autoescape);
output += "\n\t</span>\n";
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("mes-jours/actions/serie.html", ctx, cb); }
})();
