(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["menu/account-menu.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "menu/account-menu.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "account")),"user")) {
var t_5;
t_5 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "account")),"user");
frame.set("user", t_5, true);
if(frame.topLevel) {
context.setVariable("user", t_5);
}
if(frame.topLevel) {
context.addExport("user", t_5);
}
output += "<div id=\"account-menu\">\n\t<div id=\"account-menu-content\">\n\t\t<div class=\"row m-0 bg-lightest\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user")),"notifications") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user")),"notifications")),"length") > 0) {
output += "\t\t\t<div class=\"notification-messages w-100\">\n\t\t\t\t<div class=\"col sm-w-100 pv-1 bg-warning\">\n\t\t\t\t\t<div class=\"row m-0 row-center\">\n\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 10, colno = 13, runtime.callWrap(t_4, "icon", context, ["notification_16x16","Notifications","svg-small pr-1"])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t<span class=\"style-meta-normal bold color-black text-upper\">Notifications</span>\n";
frame = frame.push();
var t_8 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user")),"notifications");
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("notification", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
output += "\t\t\t\t\t\t<span class=\"col mt-1 notification-message\">\n\t\t\t\t\t\t\t<span class=\"row m-0 row-center row-space no-wrap style-meta color-black fs-italic\">\n\t\t\t\t\t\t\t\t<span class=\"flex-1 text-overflow text-no-wrap\">\n\t\t\t\t\t\t\t\t\tLe ";
output += runtime.suppressValue(runtime.memberLookup((t_9),"date"), env.opts.autoescape);
output += " - ";
output += runtime.suppressValue(runtime.memberLookup((t_9),"title"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t\t\t<a class=\"flex-0 style-meta notification-hide pointer\" data-notification-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_9),"id"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t<i>";
output += runtime.suppressValue((lineno = 19, colno = 19, runtime.callWrap(t_4, "icon", context, ["close"])), env.opts.autoescape);
output += "</i>\n\t\t\t\t\t\t\t\t</a>\n\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t\t<a class=\"pr-0 mb-1 style-meta-normal color-black\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_9),"href"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_9),"message"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t</a>\n\t\t\t\t\t\t</span>\n";
;
}
}
frame = frame.pop();
output += "\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class=\"stripe grey-stripe mb-1\"></div>\n";
;
}
output += "\t\t\t<div class=\"col mt-1 p-0 sm-w-100 menu-links style-meta-normal\">\n\t\t\t\t<span class=\"block menu-header text-upper fw-bold ph-2\">\n\t\t\t\t\tMes lectures\n\t\t\t\t</span>\n\t\t\t\t<a class=\"block menu-link pl-4 pr-2\" href=\"/mon-compte/lectures-en-cours\">\n\t\t\t\t\tLectures en cours\n\t\t\t\t</a>\n\t\t\t\t<a class=\"block menu-link pl-4 pr-2\" href=\"/mon-compte/series-suivies\">\n\t\t\t\t\tSéries suivies\n\t\t\t\t</a>\n\t\t\t</div>\n\t\t\t<div class=\"stripe grey-stripe mv-1\"></div>\n\t\t\t<div class=\"col p-0 sm-w-100 menu-links style-meta-normal bold text-upper\">\n\t\t\t\t<a class=\"block menu-link ph-2\" href=\"/mon-compte/appareils\">\n\t\t\t\t\tMes appareils\n\t\t\t\t</a>\n\t\t\t</div>\n\t\t\t<div class=\"stripe grey-stripe mv-1\"></div>\n\t\t\t<div class=\"col p-0 sm-w-100 menu-links style-meta-normal bold text-upper\">\n\t\t\t\t<a class=\"block menu-link ph-2\" href=\"/mon-compte/\">\n\t\t\t\t\tMon compte\n\t\t\t\t</a>\n\t\t\t</div>\n\t\t\t<div class=\"stripe grey-stripe mv-1\"></div>\n\t\t\t<div class=\"col p-0 sm-w-100 menu-links style-meta-normal\">\n\t\t\t\t<span class=\"block menu-header text-upper fw-bold ph-2\">\n\t\t\t\t\tMon abonnement\n\t\t\t\t</span>\n\t\t\t\t<a class=\"block menu-link pl-4 pr-2\" href=\"/mon-compte/abonnement\">\n\t\t\t\t\tFormule d’abonnement\n\t\t\t\t</a>\n\t\t\t\t<a class=\"block menu-link pl-4 pr-2\" href=\"/mon-compte/modifier-mon-moyen-de-paiement\">\n\t\t\t\t\tMoyen de paiement\n\t\t\t\t</a>\n\t\t\t\t<a class=\"block menu-link pl-4 pr-2\" href=\"/mon-compte/factures\">\n\t\t\t\t\tFactures\n\t\t\t\t</a>\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user")),"childaccount")) {
output += "\t\t\t\t<a class=\"block menu-link pl-4 pr-2\" href=\"/mon-compte/deuxieme-compte\">\n\t\t\t\t\tDeuxième compte\n\t\t\t\t</a>\n";
;
}
output += "\t\t\t</div>\n\t\t\t<div class=\"stripe grey-stripe mv-1\"></div>\n\t\t\t<div class=\"col p-0 sm-w-100 menu-links style-meta-normal bold text-upper\">\n\t\t\t\t<a class=\"block menu-link ph-2\" href=\"/emailing/\">\n\t\t\t\t\tMes newsletters\n\t\t\t\t</a>\n\t\t\t</div>\n\t\t\t<div class=\"stripe grey-stripe mv-1\"></div>\n\t\t\t<div class=\"col p-0 sm-w-100 menu-links style-meta-normal bold text-upper\">\n\t\t\t\t<a class=\"block menu-link ph-2\" href=\"/mon-compte/avantages\">\n\t\t\t\t\tMes avantages abonné·e\n\t\t\t\t</a>\n\t\t\t</div>\n\t\t\t<div class=\"stripe grey-stripe mv-1\"></div>\n\t\t\t<div class=\"col p-0 sm-w-100 menu-links style-meta-normal\">\n\t\t\t\t<span class=\"block menu-header text-upper fw-bold ph-2\">\n\t\t\t\t\tMes coordonnées\n\t\t\t\t</span>\n\t\t\t\t<a class=\"block menu-link pl-4 pr-2\" href=\"/mon-compte/identifiants\">\n\t\t\t\t\tIdentifiants de connexion\n\t\t\t\t</a>\n\t\t\t\t<a class=\"block menu-link pl-4 pr-2\" href=\"/mon-compte/coordonnees\">\n\t\t\t\t\tCoordonnées\n\t\t\t\t</a>\n\t\t\t</div>\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user")),"sevendays")) {
output += "\t\t\t<div class=\"stripe grey-stripe mv-1\"></div>\n\t\t\t<div class=\"col p-0 sm-w-100 menu-links style-meta-normal bold text-upper\">\n\t\t\t\t<a class=\"block menu-link ph-2\" href=\"/mon-compte/offrir-sept-jours\">\n\t\t\t\t\tOffrir 7&nbsp;jours\n\t\t\t\t</a>\n\t\t\t</div>\n";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user")),"sponsorship")) {
output += "\t\t\t<div class=\"stripe grey-stripe mv-1\"></div>\n\t\t\t<div class=\"col p-0 sm-w-100 menu-links style-meta-normal bold text-upper\">\n\t\t\t\t<a class=\"block menu-link ph-2\" href=\"/mon-compte/parrainage\">\n\t\t\t\t\tParrainer vos proches\n\t\t\t\t</a>\n\t\t\t</div>\n";
;
}
output += "\t\t\t<div class=\"stripe grey-stripe mv-1\"></div>\n\t\t\t<div class=\"col mb-1 p-0 sm-w-100 menu-links style-meta-normal bold text-upper\">\n\t\t\t\t<a class=\"block menu-link ph-2 color-lesjours\" href=\"/session?close\">\n\t\t\t\t\tDéconnexion\n\t\t\t\t</a>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("menu/account-menu.html", ctx, cb); }
})();
