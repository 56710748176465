import $ from "jquery";

let windowLoaded = false;

$(window).on("load", function () {
  windowLoaded = true;
});

$.triggerOnLoad = function (namespace, handler) {
  if (windowLoaded) {
    handler();
  } else {
    $(window).on("load." + namespace, handler);
  }
};
