(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["actions/reload.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "actions/reload.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<button id=\"top-reload-btn\" class=\"btn-rounded-obsession btn-collapse btn-shadow lj-color-";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "color"), env.opts.autoescape);
output += " mt-2\">\n\t";
output += runtime.suppressValue((lineno = 2, colno = 8, runtime.callWrap(t_4, "icon", context, ["arrow-reload"])), env.opts.autoescape);
output += "\n\t<span>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "text"), env.opts.autoescape);
output += "</span>\n</button>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("actions/reload.html", ctx, cb); }
})();
