import $ from "jquery";

// Protected Images
const protectedImages = [
  /ep7-juge-simon-img-header/,
  /ep7-juge-simon-img-juge-simon-1/,
  /ep7-juge-simon-img-juge-simon-2/,
  /ep7-juge-simon-img-juge-simon-3/,
  /ep7-juge-simon-img-juge-simon-4/,
];

// Listen Context Menu Events on Images
$(document.body).on("contextmenu.lesjours.rightclick", "img", function (e) {
  // Get the Current Image src
  const imageSrc = $(e.currentTarget).attr("src");

  // Check if it's a Protected Image
  $.each(protectedImages, function (idx, protectedImage) {
    // Check if the Image SRC Match the Protected Image
    if (imageSrc.match(protectedImage)) {
      // Prevent Default Action
      e.preventDefault();
      e.stopPropagation();

      // Stop the $.each iteration
      return false;
    }
  });
});
