(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["episode/episodes.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "episode/episodes.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<section id=\"episodes\" class=\"container mb-8 relative bg-lightest print-hidden\">\n\t<div class=\"row\">\n";
if(runtime.contextOrFrameLookup(context, frame, "current") < env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"episodes"))) {
var t_5;
t_5 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"episodes")),runtime.contextOrFrameLookup(context, frame, "current"));
frame.set("next", t_5, true);
if(frame.topLevel) {
context.setVariable("next", t_5);
}
if(frame.topLevel) {
context.addExport("next", t_5);
}
;
}
if(1 < runtime.contextOrFrameLookup(context, frame, "current")) {
var t_6;
t_6 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"episodes")),runtime.contextOrFrameLookup(context, frame, "current") - 2);
frame.set("prev", t_6, true);
if(frame.topLevel) {
context.setVariable("prev", t_6);
}
if(frame.topLevel) {
context.addExport("prev", t_6);
}
;
}
output += "\t\t<div class=\"col pb-2 lg-w-7c lg-ml-1c\">\n\t\t\t<div class=\"sr\">D’autres épisodes</div>\n\t\t\t<ul class=\"row\">\n";
if(runtime.contextOrFrameLookup(context, frame, "prev")) {
output += "\t\t\t\t<li class=\"col sm-w-100 md-w-50\">\n\t\t\t\t\t<a class=\"row row-center w-100 no-wrap mr-auto ml-0\" href=\"/obsessions/";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"href"), env.opts.autoescape);
output += "/";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prev")),"href"), env.opts.autoescape);
output += "/\">\n\t\t\t\t\t\t<i class=\"col w-auto p-0\">\n\t\t\t\t\t\t\t<span class=\"btn-svg btn-medium btn-hover-lesjours\">";
output += runtime.suppressValue((lineno = 16, colno = 66, runtime.callWrap(t_4, "icon", context, ["previous"])), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t</i>\n\t\t\t\t\t\t<div class=\"col flex-1 relative\">\n\t\t\t\t\t\t\t<span class=\"style-meta\">\n\t\t\t\t\t\t\t\tÉpisode n°&#x202f;";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prev")),"number"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</a>\n\t\t\t\t</li>\n";
;
}
if(runtime.contextOrFrameLookup(context, frame, "next")) {
output += "\t\t\t\t<li class=\"col sm-w-100 md-w-50";
if(!runtime.contextOrFrameLookup(context, frame, "prev")) {
output += " md-ml-4c lg-ml-6c";
;
}
output += "\">\n\t\t\t\t\t<a class=\"row row-center w-100 no-wrap ml-auto mr-0\" href=\"/obsessions/";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"href"), env.opts.autoescape);
output += "/";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "next")),"href"), env.opts.autoescape);
output += "/\">\n\t\t\t\t\t\t<div class=\"col flex-1 text-right relative\">\n\t\t\t\t\t\t\t<span class=\"style-meta\">\n\t\t\t\t\t\t\t\tÉpisode n°&#x202f;";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "next")),"number"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<i class=\"col w-auto p-0\">\n\t\t\t\t\t\t\t<span class=\"btn-svg btn-medium btn-hover-lesjours\">";
output += runtime.suppressValue((lineno = 35, colno = 66, runtime.callWrap(t_4, "icon", context, ["next"])), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t</i>\n\t\t\t\t\t</a>\n\t\t\t\t</li>\n";
;
}
output += "\t\t\t</ul>\n\t\t</div>\n\t\t<div class=\"col pb-2 lg-w-10c lg-ml-1c\">\n\t\t\t<a class=\"flex row-center style-meta-larger bold color-dark\" href=\"#episodes-list\">\n\t\t\t\t<span>\n\t\t\t\t\t<span class=\"link mr-1\">Tous les épisodes de «&nbsp;";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"name"), env.opts.autoescape);
output += "&nbsp;»</span>\n\t\t\t\t</span>\n\t\t\t\t<span class=\"btn-svg btn-medium btn-hover-lesjours btn-no-shadow\">\n\t\t\t\t\t<i>";
output += runtime.suppressValue((lineno = 48, colno = 15, runtime.callWrap(t_4, "icon", context, ["episodes","Voir tous les épisodes"])), env.opts.autoescape);
output += "</i>\n\t\t\t\t</span>\n\t\t\t</a>\n\t\t</div>\n";
frame = frame.push();
var t_9 = env.getFilter("slice").call(context, env.getFilter("reverse").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"episodes")),0,6);
if(t_9) {t_9 = runtime.fromIterator(t_9);
var t_8 = t_9.length;
for(var t_7=0; t_7 < t_9.length; t_7++) {
var t_10 = t_9[t_7];
frame.set("episode", t_10);
frame.set("loop.index", t_7 + 1);
frame.set("loop.index0", t_7);
frame.set("loop.revindex", t_8 - t_7);
frame.set("loop.revindex0", t_8 - t_7 - 1);
frame.set("loop.first", t_7 === 0);
frame.set("loop.last", t_7 === t_8 - 1);
frame.set("loop.length", t_8);
output += "\t\t<div class=\"col pb-2 lg-w-10c lg-ml-1c\">\n";
var t_11;
t_11 = {"href": "/obsessions/" + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"href") + "/" + runtime.memberLookup((t_10),"href") + "/","title": runtime.memberLookup((t_10),"title"),"subtitle": runtime.memberLookup((t_10),"short"),"obsession": runtime.contextOrFrameLookup(context, frame, "obsession"),"episode": t_10,"current": runtime.memberLookup((t_10),"number") == runtime.contextOrFrameLookup(context, frame, "current"),"type": "small","lazyload": true,"sizes": ["180px","180px","180px"]};
frame.set("article", t_11, true);
if(frame.topLevel) {
context.setVariable("article", t_11);
}
if(frame.topLevel) {
context.addExport("article", t_11);
}
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("articles/episode.html", false, "episode/episodes.html", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
callback(null,t_12);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_15,t_14) {
if(t_15) { cb(t_15); return; }
callback(null,t_14);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\t\t</div>\n";
});
}
}
frame = frame.pop();
output += "\t\t<div class=\"col lg-w-10c lg-ml-1c text-right\">\n\t\t\t<a class=\"style-meta-normal bold text-upper color-lesjours link on-hover\" href=\"/obsessions/";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"href"), env.opts.autoescape);
output += "/#episodes\">\n\t\t\t\t<span>Voir les ";
output += runtime.suppressValue(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"episodes")), env.opts.autoescape);
output += " épisodes de la série</span>\n\t\t\t\t";
output += runtime.suppressValue((lineno = 73, colno = 11, runtime.callWrap(t_4, "icon", context, ["button-arrow-right","Voir l’obsession","pl-1"])), env.opts.autoescape);
output += "\n\t\t\t</a>\n\t\t</div>\n\t</div>\n</section>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("episode/episodes.html", ctx, cb); }
})();
