(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["media/soundtrack.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n";
env.getTemplate("_macro.html", false, "media/soundtrack.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<div class=\"media row no-wrap\">\n\t<div class=\"col w-auto\">\n\t\t<div class=\"player-media js-audio-player\">\n\t\t\t<audio aria-hidden=\"true\">\n";
frame = frame.push();
var t_7 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"files");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("source", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\t\t\t\t<source type=\"";
output += runtime.suppressValue(env.getFilter("mimeType").call(context, t_8), env.opts.autoescape);
output += "\" src=\"";
output += runtime.suppressValue(t_8, env.opts.autoescape);
output += "\" />\n";
;
}
}
frame = frame.pop();
output += "\t\t\t</audio>\n\t\t\t<button class=\"btn-svg btn-large btn-player-lj-color btn-player-hover-scale js-play-btn\">\n\t\t\t\t";
output += runtime.suppressValue((lineno = 22, colno = 11, runtime.callWrap(t_4, "icon", context, ["media-play"])), env.opts.autoescape);
output += "\n\t\t\t\t";
output += runtime.suppressValue((lineno = 23, colno = 11, runtime.callWrap(t_4, "icon", context, ["media-pause"])), env.opts.autoescape);
output += "\n\t\t\t</button>\n\t\t</div>\n\t</div>\n\t<div class=\"col w-auto flex-1 pl-0\">\n\t\t<div class=\"row h-100 row-vertical\">\n\t\t\t<div class=\"col\">\n\t\t\t\t<span class=\"title color-dark link on-hover\" data-href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"files")),0), env.opts.autoescape);
output += "\" target=\"_blank\">\n\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"title"), env.opts.autoescape);
output += "\n\t\t\t\t</span>\n\t\t\t</div>\n\t\t\t<div class=\"col mt-1\">\n\t\t\t\t<span class=\"description color-medium-8\">\n\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"desc"), env.opts.autoescape);
output += "\n\t\t\t\t</span>\n\t\t\t</div>\n\t\t\t<div class=\"col mt-1\">\n\t\t\t\t<span class=\"meta color-medium-7\">\n\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"meta"), env.opts.autoescape);
output += "\n\t\t\t\t</span>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("media/soundtrack.html", ctx, cb); }
})();
