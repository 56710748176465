(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["mes-jours/programme.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n";
env.getTemplate("_macro.html", false, "mes-jours/programme.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<div class=\"row\">\n\t<div class=\"col sm-w-6c md-w-12c lg-w-4c sm-mb-2 md-mb-2\">\n\t\t<a class=\"card flex card-programme card-large lj-color-";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"programme")),"color")),"id"), env.opts.autoescape);
output += "\" href=\"/podcasts/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"programme")),"href"), env.opts.autoescape);
output += "/\">\n\t\t\t<div class=\"card-image lj-color-";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"programme")),"color")),"id"), env.opts.autoescape);
output += "\">\n";
var t_5;
t_5 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"programme")),"img");
frame.set("image", t_5, true);
if(frame.topLevel) {
context.setVariable("image", t_5);
}
if(frame.topLevel) {
context.addExport("image", t_5);
}
output += "\t\t\t\t<img class=\"responsive-card\" src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "image"), env.opts.autoescape);
output += "\" sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, ["100vw","100vw","33vw"]), env.opts.autoescape);
output += "\" srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.contextOrFrameLookup(context, frame, "image")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"programme")),"title"))), env.opts.autoescape);
output += "\" />\n\t\t\t</div>\n\t\t\t<div class=\"overlay p-3 ";
output += runtime.suppressValue(((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"programme")),"snake") === "top")?"gradient-to-bottom":"gradient-to-top"), env.opts.autoescape);
output += "\">\n\t\t\t\t<div class=\"container h-100 row row-vertical ";
output += runtime.suppressValue(((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"programme")),"snake") === "top")?"row-start":"row-end"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t<div class=\"col p-0 w-auto\">\n\t\t\t\t\t\t<div class=\"snake-4\">\n\t\t\t\t\t\t\t<div class=\"row m-0 row-vertical row-justify snake-container no-overflow\">\n\t\t\t\t\t\t\t\t<div class=\"sm-w-6c\">\n\t\t\t\t\t\t\t\t\t<span class=\"title text-upper\">\n\t\t\t\t\t\t\t\t\t\t<span class=\"lj-color-text\">";
output += runtime.suppressValue(env.getFilter("removeBR").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"programme")),"title")), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div class=\"subtitle color-white mt-1 sm-w-6c\">\n\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("removeBR").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"programme")),"subtitle")), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</a>\n\t</div>\n\t<div class=\"col sm-w-6c md-w-12c lg-w-8c\">\n\t\t<div class=\"row m-0 row-vertical h-100\">\n";
frame = frame.push();
var t_8 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"programme")),"podcasts");
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("podcast", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
output += "\t\t\t<div class=\"article row md-no-wrap article-podcast article-small lj-color-";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"programme")),"color")),"id"), env.opts.autoescape);
output += runtime.suppressValue(((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0") > 0)?" mt-4":""), env.opts.autoescape);
output += "\">\n\t\t\t\t<div class=\"col sm-w-100 md-w-auto\">\n\t\t\t\t\t<a class=\"block article-image\" href=\"/podcasts/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"programme")),"href"), env.opts.autoescape);
output += "/";
output += runtime.suppressValue(env.getFilter("hrefWithCategory").call(context, t_9), env.opts.autoescape);
output += "/\">\n";
var t_10;
t_10 = env.getFilter("src").call(context, t_9,runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"programme"));
frame.set("image", t_10, true);
if(frame.topLevel) {
context.setVariable("image", t_10);
}
if(frame.topLevel) {
context.addExport("image", t_10);
}
output += "\t\t\t\t\t\t<img class=\"responsive\" src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "image"), env.opts.autoescape);
output += "\" sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, ["131px","131px","131px"]), env.opts.autoescape);
output += "\" srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.contextOrFrameLookup(context, frame, "image")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((t_9),"title"))), env.opts.autoescape);
output += "\" />\n\t\t\t\t\t</a>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"col sm-w-100 md-w-auto flex-1 ml-0 p-0\">\n\t\t\t\t\t<div class=\"row sm-mh-0 sm-mt-1 h-100 row-vertical\">\n\t\t\t\t\t\t<div class=\"col flex row-center obsession-name\">\n\t\t\t\t\t\t\t<a class=\"link on-hover lj-color-text text-upper\" href=\"/podcasts/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"programme")),"href"), env.opts.autoescape);
output += "/\">\n\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("removeBR").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"programme")),"title")), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t</a>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class=\"col mt-1\">\n\t\t\t\t\t\t\t<a href=\"/podcasts/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"programme")),"href"), env.opts.autoescape);
output += "/";
output += runtime.suppressValue(env.getFilter("hrefWithCategory").call(context, t_9), env.opts.autoescape);
output += "/\">\n\t\t\t\t\t\t\t\t<span class=\"inline title link on-hover color-dark\">\n\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("removeBR").call(context, runtime.memberLookup((t_9),"title")), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t\t</a>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"col w-auto flex-0 text-no-wrap infos color-medium-8\">\n\t\t\t\t\tPodcast n°&#x202f;";
output += runtime.suppressValue(runtime.memberLookup((t_9),"number"), env.opts.autoescape);
output += "\n\t\t\t\t</div>\n\t\t\t</div>\n";
;
}
}
frame = frame.pop();
output += "\t\t\t<div class=\"col lg-mt-auto\">\n\t\t\t\t<div class=\"row\">\n\t\t\t\t\t<div class=\"mt-2 mr-2\" data-mesjours-type=\"mesjours\" data-model=\"Programme\" data-programme=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"programme")),"href"), env.opts.autoescape);
output += "\">\n";
var t_11;
t_11 = "unfollow";
frame.set("status", t_11, true);
if(frame.topLevel) {
context.setVariable("status", t_11);
}
if(frame.topLevel) {
context.addExport("status", t_11);
}
var t_12;
t_12 = "square";
frame.set("type", t_12, true);
if(frame.topLevel) {
context.setVariable("type", t_12);
}
if(frame.topLevel) {
context.addExport("type", t_12);
}
var t_13;
t_13 = "Programme";
frame.set("mtmActionNoun", t_13, true);
if(frame.topLevel) {
context.setVariable("mtmActionNoun", t_13);
}
if(frame.topLevel) {
context.addExport("mtmActionNoun", t_13);
}
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("mes-jours/actions/serie.html", false, "mes-jours/programme.html", false, function(t_15,t_14) {
if(t_15) { cb(t_15); return; }
callback(null,t_14);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
callback(null,t_16);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\t\t\t\t\t</div>\n\t\t\t\t\t<span class=\"btn-square btn-lesjours mt-2\" data-href=\"/podcasts/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"programme")),"href"), env.opts.autoescape);
output += "/\">\n\t\t\t\t\t\tVoir tous les podcasts\n\t\t\t\t\t</span>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("mes-jours/programme.html", ctx, cb); }
})();
