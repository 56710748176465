(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["modals/sponsorship.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "modals/sponsorship.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<div id=\"sponsorship\" class=\"modal centered\" role=\"dialog\" tabindex=\"-1\">\n\t<form action=\"/session?sponsorship=true\" method=\"post\" role=\"document\">\n\t\t<fieldset class=\"row row-center\">\n\t\t\t<div class=\"col m-0 ph-0 pb-2 h5 fw-bold row row-center row-justify color-darkest\">\n\t\t\t\t<small class=\"pr-1\">";
output += runtime.suppressValue((lineno = 5, colno = 31, runtime.callWrap(t_4, "icon", context, ["gift-red"])), env.opts.autoescape);
output += "</small><span>Je parraine un ami</span>\n\t\t\t</div>\n\t\t\t<span class=\"a btn close\" data-href=\"#\">\n\t\t\t\t<span class=\"sr\">Fermer cette fenêtre</span>\n\t\t\t\t";
output += runtime.suppressValue((lineno = 9, colno = 11, runtime.callWrap(t_4, "icon", context, ["close"])), env.opts.autoescape);
output += "\n\t\t\t</span>\n\t\t\t<div class=\"col mb-2\">\n\t\t\t\t<div class=\"field m-0\">\n\t\t\t\t\t<label for=\"sponsorship-email\" class=\"lh-inherit\">Entrez l’adresse e-mail de la personne que vous souhaitez parrainer :</label>\n\t\t\t\t\t<input id=\"sponsorship-email\" class=\"input\" name=\"sponsorship-email\" type=\"email\" placeholder=\"Taper l’adresse e-mail\" autocomplete=\"email\" required />\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class=\"col mb-2\">\n\t\t\t\t<div class=\"field m-0\">\n\t\t\t\t\t<label for=\"sponsorship-message\" class=\"lh-inherit\">Ecrivez un message au destinataire :</label>\n\t\t\t\t\t<textarea id=\"sponsorship-message\" class=\"input\" name=\"sponsorship-message\" type=\"text\" placeholder=\"Taper votre message\" rows=\"6\"></textarea>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class=\"col text-center\">\n\t\t\t\t<button class=\"btn-primary btn-lesjours\" type=\"submit\">Envoyer</button>\n\t\t\t</div>\n\t\t\t<div class=\"col m-0 p-0 row row-center row-justify\">\n\t\t\t\t<div class=\"spinner sending-form\">\n\t\t\t\t\t<div class=\"bounce1\"></div>\n\t\t\t\t\t<div class=\"bounce2\"></div>\n\t\t\t\t\t<div class=\"bounce3\"></div>\n\t\t\t\t</div>\n\t\t\t\t<span class=\"color-lesjours sent-message\">\n\t\t\t\t\tVotre email a bien été envoyé.\n\t\t\t\t</span>\n\t\t\t\t<span class=\"color-lesjours error-message\">\n\t\t\t\t\tOups. Nous n’avons pas pu envoyer votre email.\n\t\t\t\t\t<br />\n\t\t\t\t\tMerci de réessayer ultérieurement.\n\t\t\t\t</span>\n\t\t\t</div>\n\t\t</fieldset>\n\t</form>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("modals/sponsorship.html", ctx, cb); }
})();
