import $ from "jquery";

// TempusDominus Holder
let tempusDominus;

// Initialize the TempusDominus plugin
async function initialize() {
  // Import the TempusDominus library
  tempusDominus = await import(/* webpackChunkName: "tempusdominus" */ "./jquery.tempusdominus");

  // Initialize Datepicker
  $(".datepicker").each(function (idx, el) {
    // Show the Datepicker
    tempusDominus.createDatepicker($(el), null);
  });

  // Return the TempusDominus library
  return tempusDominus;
}

// Export a load function
export function load() {
  if (tempusDominus === undefined) {
    tempusDominus = initialize();
  }
}

// Wait for jQuery to be loaded
$(function () {
  // Check if there is Datepicker
  if ($(".datepicker").length > 0) {
    // Load the TempusDominus library
    load();
  }
});
