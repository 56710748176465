import $ from "jquery";
import { createPopper } from "@popperjs/core";

const destroyAfterDelay = 2000;
const namespace = "lesjours.tooltip";

// Tooltip Class
function Tooltip(button, tooltip, options) {
  this.button = button;
  this.tooltip = tooltip;
  this.options = options;
  this.evtName = Date.now();
  this.timeoutEventRunning = false;

  // Attach the Tooltip to the JQuery Element
  $(tooltip).data(namespace, this);
}

Tooltip.prototype._createPopper = function () {
  if (this.popper !== undefined) {
    return;
  }
  $(this.tooltip).removeClass("hidden");
  this.popper = createPopper(this.button, this.tooltip, this.options);
};

Tooltip.prototype._destroyPopper = function () {
  if (this.popper === undefined) {
    return;
  }
  this.popper.destroy();
  $(this.tooltip).addClass("hidden");
  this.popper = undefined;
};

Tooltip.prototype._destroyPopperLater = function () {
  if (this.popper === undefined || this.timeoutEventRunning) {
    return;
  }

  // Delay the tooltip destruction
  this.timeoutEventRunning = true;
  const that = this;
  setTimeout(function () {
    that._destroyPopper();
    that.timeoutEventRunning = false;
  }, destroyAfterDelay);
};

Tooltip.prototype.showOnHover = function () {
  $(this.button).on("mouseenter.lesjours.tooltip." + this.evtName, this._createPopper.bind(this));
  $(this.button).on("mouseleave.lesjours.tooltip." + this.evtName, this._destroyPopper.bind(this));
};

Tooltip.prototype.showOnClick = function () {
  $(this.button).on("click.lesjours.tooltip." + this.evtName, this._createPopper.bind(this));
  $(this.button).on("click.lesjours.tooltip." + this.evtName, this._destroyPopperLater.bind(this));
};

Tooltip.prototype.show = function () {
  this._createPopper();
  $("body").on("click.lesjours.tooltip." + this.evtName, this.destroy.bind(this));
};

Tooltip.prototype.destroy = function () {
  $("body").off("click.lesjours.tooltip." + this.evtName);
  this._destroyPopper();
};

Tooltip.prototype.showBriefly = function () {
  this._createPopper();
  this._destroyPopperLater();
};

// Function to create a Tooltip
function createTooltip(button, tooltip, options) {
  // Show the Tooltip
  if (options.showBriefly === true) {
    new Tooltip(button, tooltip, options).showBriefly();
  } else {
    new Tooltip(button, tooltip, options).show();
  }
}

// Add the Tooltip creation function to jQuery
$.createTooltip = createTooltip;

// Check if the element has a Tooltip
// If the element is an array of node, it will check on the first element
$.fn.hasTooltip = function () {
  return $(this).first().data(namespace) !== undefined;
};

// Function to get the Tooltip
// If the element is an array of node, it will get the data of the first element
$.fn.getTooltip = function () {
  return $(this).first().data(namespace);
};

// Wait for jQuery to be loaded
$(function () {
  // Launch already displayed Tooltips
  $(".tooltip[data-tooltip-type]").each(function (idx, el) {
    const $tooltip = $(el);
    const $parent = $tooltip.parent();

    switch ($tooltip.data("tooltipType")) {
      // Show the Tooltip on hover events
      case "hover":
        new Tooltip($parent.get(0), $tooltip.get(0), {
          placement: $tooltip.data("tooltipPlacement") || "left",
        }).showOnHover();
        break;
      // Show the Tooltip on click events
      case "click":
        new Tooltip($parent.get(0), $tooltip.get(0), {
          placement: $tooltip.data("tooltipPlacement") || "left",
        }).showOnClick();
        break;
      default:
      // Do nothing
    }
  });
});
