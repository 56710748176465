(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["media/image.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n";
env.getTemplate("_macro.html", false, "media/image.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<div class=\"media flex\" data-href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"href"), env.opts.autoescape);
output += "\">\n\t<div class=\"container w-100 row row-vertical\">\n\t\t<div class=\"col ph-0 media-image\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"lazyload")) {
output += "\t\t\t<img class=\"responsive-media lazyload\" src=\"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7\" data-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"image"), env.opts.autoescape);
output += "\" data-sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"sizes")), env.opts.autoescape);
output += "\" data-srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"image")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"alt"))), env.opts.autoescape);
output += "\" />\n";
;
}
else {
output += "\t\t\t<img class=\"responsive-media\" src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"image"), env.opts.autoescape);
output += "\" sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"sizes")), env.opts.autoescape);
output += "\" srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"image")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "media")),"alt"))), env.opts.autoescape);
output += "\" />\n";
;
}
output += "\t\t\t<span class=\"overlay m-2 text-right\">\n\t\t\t\t";
output += runtime.suppressValue((lineno = 20, colno = 11, runtime.callWrap(t_4, "icon", context, ["slideshow","Lire"])), env.opts.autoescape);
output += "\n\t\t\t</span>\n\t\t</div>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("media/image.html", ctx, cb); }
})();
