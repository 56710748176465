(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["slider/episodes.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"articles-slider slider relative sm-ph-0 ph-1\">\n\t<div class=\"ph-2 pb-1\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"titleLink")) {
output += "\t\t<a class=\"slider-title color-lesjours\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"titleLink"), env.opts.autoescape);
output += "\">";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"title")) {
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"title"), env.opts.autoescape);
;
}
else {
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"titleLink"), env.opts.autoescape);
;
}
output += "</a>\n";
;
}
else {
output += "\t\t<span class=\"slider-title color-lesjours\">";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"title")) {
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"title"), env.opts.autoescape);
;
}
else {
output += "Les articles des Jours";
;
}
output += "</span>\n";
;
}
output += "\t</div>\n\t<div class=\"slider-container\">\n";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slider")),"episodes");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("article", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\t\t\t<div class=\"p-2 ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"first")) {
output += "active";
;
}
output += "\">\n\t\t\t\t";
output += "\n";
if(runtime.memberLookup((t_4),"model") === "Episode") {
t_4 = {"href": "/obsessions/" + runtime.memberLookup((runtime.memberLookup((t_4),"obsession")),"href") + "/" + runtime.memberLookup((runtime.memberLookup((t_4),"episode")),"href") + "/","title": env.getFilter("default").call(context, runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_4),"episode")),"home")),"title"),runtime.memberLookup((runtime.memberLookup((t_4),"episode")),"title")),"subtitle": runtime.memberLookup((runtime.memberLookup((t_4),"episode")),"subtitle"),"obsession": runtime.memberLookup((t_4),"obsession"),"episode": runtime.memberLookup((t_4),"episode"),"type": "slider","lazyload": true,"sizes": ["100vw","280px","280px"],"hideNumber": false};
frame.set("article", t_4, true);
if(frame.topLevel) {
context.setVariable("article", t_4);
}
if(frame.topLevel) {
context.addExport("article", t_4);
}
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("articles/episode.html", false, "slider/episodes.html", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
callback(null,t_5);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
callback(null,t_7);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
});
}
else {
if(runtime.memberLookup((t_4),"model") === "Podcast") {
t_4 = {"href": "/podcasts/" + runtime.memberLookup((runtime.memberLookup((t_4),"programme")),"href") + "/" + env.getFilter("hrefWithCategory").call(context, runtime.memberLookup((t_4),"podcast")) + "/","title": runtime.memberLookup((runtime.memberLookup((t_4),"podcast")),"title"),"subtitle": runtime.memberLookup((runtime.memberLookup((t_4),"podcast")),"subtitle"),"programme": runtime.memberLookup((t_4),"programme"),"podcast": runtime.memberLookup((t_4),"podcast"),"type": "slider","lazyload": true,"sizes": ["100vw","280px","280px"],"hideNumber": false};
frame.set("article", t_4, true);
if(frame.topLevel) {
context.setVariable("article", t_4);
}
if(frame.topLevel) {
context.addExport("article", t_4);
}
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("articles/podcast.html", false, "slider/episodes.html", false, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
callback(null,t_9);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
callback(null,t_11);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
});
}
;
}
output += "\t\t\t</div>\n";
;
}
}
frame = frame.pop();
if(runtime.contextOrFrameLookup(context, frame, "mesjours")) {
output += "\t\t\t<div class=\"slider-more flex row-center text-center p-8 mr-2\">\n\t\t\t\t<a class=\"flex row-center text-center bg-lesjours\" href=\"/mon-compte/lectures-en-cours/\">\n\t\t\t\t\t<i>Voir toutes mes lectures en cours</i>\n\t\t\t\t</a>\n\t\t\t</div>\n";
;
}
output += "\t</div>\n\t<div class=\"slider-controls\">\n\t\t<button class=\"btn-svg btn-large btn-hover-lesjours\" data-direction=\"backward\">";
output += runtime.suppressValue((lineno = 54, colno = 88, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["previous","Précédant"])), env.opts.autoescape);
output += "</button>\n\t\t<button class=\"btn-svg btn-large btn-hover-lesjours\" data-direction=\"forward\">";
output += runtime.suppressValue((lineno = 55, colno = 87, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["next","Suivant"])), env.opts.autoescape);
output += "</button>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("slider/episodes.html", ctx, cb); }
})();
