(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["modals/login.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "modals/login.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<div id=\"login\" class=\"modal centered\" role=\"dialog\" tabindex=\"-1\">\n\t<div role=\"document\">\n\t\t<legend class=\"col mb-2 style-meta-larger\">Se connecter</legend>\n\t\t<span class=\"a btn close\" data-href=\"#\">\n\t\t\t<span class=\"sr\">Fermer cette fenêtre</span>\n\t\t\t";
output += runtime.suppressValue((lineno = 6, colno = 10, runtime.callWrap(t_4, "icon", context, ["close"])), env.opts.autoescape);
output += "\n\t\t</span>\n\t\t<div class=\"row row-justify\">\n\t\t\t<div class=\"col w-auto mb-1\">\n\t\t\t\t<div id=\"googleid-signin\" data-type=\"standard\" data-shape=\"rectangular\" data-theme=\"outline\" data-text=\"signin_with\" data-size=\"large\" data-locale=\"fr\" data-logo_alignment=\"left\" data-width=\"280\">\n\t\t\t\t\t<div class=\"btn-sso lj-siwg-button\">\n\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 12, colno = 13, runtime.callWrap(t_4, "icon", context, ["logo-google","Se connecter avec Google"])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t<span>Se connecter avec Google</span>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class=\"col w-auto\">\n\t\t\t\t<div id=\"appleid-signin\" data-color=\"black\" data-border=\"true\" data-type=\"sign-in\" data-width=\"280\" data-height=\"40\">\n\t\t\t\t\t<div class=\"btn-sso lj-siwa-button\">\n\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 20, colno = 13, runtime.callWrap(t_4, "icon", context, ["logo-apple","Se connecter avec Apple"])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t<span>Se connecter avec Apple</span>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class=\"stripe main-stripe with-or mv-2\"></div>\n\t\t<form class=\"row row-center\" action=\"/connexion\" method=\"post\">\n\t\t\t<div class=\"col mb-2 fw-bold color-lesjours hidden\"></div>\n\t\t\t<div class=\"col\">\n\t\t\t\t<div class=\"field m-0\">\n\t\t\t\t\t<label for=\"login-mail\">Adresse e-mail</label>\n\t\t\t\t\t<input id=\"login-mail\" class=\"input\" name=\"login-mail\" type=\"email\" placeholder=\"mon-email@exemple.com\" autocomplete=\"email\" required />\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class=\"col\">\n\t\t\t\t<div class=\"field m-0\">\n\t\t\t\t\t<label for=\"login-password\">Mot de passe</label>\n\t\t\t\t\t<input id=\"login-password\" class=\"input\" name=\"login-password\" type=\"password\" placeholder=\"××××××××\" autocomplete=\"off\" required />\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class=\"col mt-1\">\n\t\t\t\t<span class=\"link style-meta-normal\" data-href=\"#motdepasse\">J’ai oublié mon mot de passe</span>\n\t\t\t</div>\n\t\t\t<div class=\"col mv-2 text-center\">\n\t\t\t\t<button class=\"btn-primary btn-lesjours\" type=\"submit\">Valider</button>\n\t\t\t</div>\n\t\t\t<div class=\"col style-meta text-upper color-main\">\n\t\t\t\tPas encore jouriste&nbsp;? <span class=\"a color-lesjours\" data-href=\"/abonnement/jouriste\">Abonnez-vous maintenant</span>.\n\t\t\t</div>\n\t\t</form>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("modals/login.html", ctx, cb); }
})();
