import $ from "jquery";

// Find autohide elements on the page
$("[data-autohide]").each(function (idx, el) {
  // Hide the HTML element after the specified milliseconds count
  setTimeout(function () {
    // Hide the HTML element
    $(el).hide();
  }, $(el).data("autohide"));
});
