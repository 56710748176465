import throttle from "lodash/throttle";
import $ from "jquery";

// Constants
const $window = $(window);
const $header = $("#header .header");
const $images = $("figure.image .image-parallax, figure.image .image-window");

function doParallaxEffect($image) {
  // Get Important Values
  const $imgTag = $image.find("img");
  let currentScroll = $window.scrollTop();
  let windowHeight = $window.height();
  const imageOffset = $image.offset().top;
  const imageHeight = $image.height();

  // Fix Values
  currentScroll = currentScroll + $header.height();
  windowHeight = windowHeight - $header.height();

  // Update the Transorm of the Image
  if (currentScroll + windowHeight < imageOffset + imageHeight) {
    $imgTag.css("transform", "translate3d(0, 0, 0)");
  } else if (imageOffset < currentScroll) {
    $imgTag.css("transform", "translate3d(0, -" + ($imgTag.height() - imageHeight) + "px, 0)");
  } else {
    $imgTag.css(
      "transform",
      "translate3d(0, -" +
        Math.round(
          (1 - (imageOffset - currentScroll) / (windowHeight - imageHeight)) * ($imgTag.height() - imageHeight)
        ) +
        "px, 0)"
    );
  }
}

function doWindowEffect($image) {
  // Get Important Values
  const $imgTag = $image.find("img");
  let currentScroll = $window.scrollTop();
  let windowHeight = $window.height();
  const imageOffset = $image.offset().top;
  const imageHeight = $image.height();

  // Fix Values
  currentScroll = currentScroll + $header.height();
  windowHeight = windowHeight - $header.height();

  // Update the Transorm of the Image
  if (currentScroll + windowHeight < imageOffset) {
    $imgTag.css("transform", "translate3d(0, -" + ($imgTag.height() - imageHeight) + "px, 0)");
  } else if (imageOffset + imageHeight < currentScroll) {
    $imgTag.css("transform", "translate3d(0, 0, 0)");
  } else {
    $imgTag.css(
      "transform",
      "translate3d(0, " +
        -Math.round(((imageOffset - currentScroll) / (windowHeight - imageHeight)) * ($imgTag.height() - imageHeight)) +
        "px, 0)"
    );
  }
}

function scrollEffect() {
  // Iterate through Images
  $images.each(function (idx, image) {
    // Get Important Values
    const $image = $(image);

    // Check the Image Class
    if ($image.hasClass("image-parallax")) {
      // Update the Transorm of the Image
      doParallaxEffect($image);
    } else {
      // Update the Transorm of the Image
      doWindowEffect($image);
    }
  });
}

// Listen Scroll Events if there is Scroll Images
if ($images.length > 0) {
  $window.on("scroll", throttle(scrollEffect, 10, { leading: true, trailing: true }));
  $.triggerOnLoad("lesjours.fadeouteffect", scrollEffect);
}
