import $ from "jquery";
import lazySizes from "lazysizes";

// Constants
const $body = $(document.body);
const models = {
  episode: "Episode",
  podcast: "Podcast",
  obsession: "Obsession",
  programme: "Programme",
};

// Resume Elements Holder
let owner, prefix, pouchDB;

// PouchDB core Functions
function logPouchDBError(error) {
  console.log(error);
}
function logPouchDBResponse(res) {
  console.log(res);
}

// Helpers
function getPodcastHrefWithCategory(data) {
  return data.category !== undefined ? data.category + "/" + data.podcast : data.podcast;
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DATABASE UPDATE FUNCTIONS                                                                                          //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class PouchDBDocument {
  constructor(model, hasProgressBar) {
    this.model = model;
    this.hasProgressBar = hasProgressBar;
  }

  static create(model, serie, article) {
    // There is two way to create a PouchDBDocument :
    // - you can pass every argument of the function
    // - or you can just pass an Object in the first argument
    if (typeof model === "string") {
      switch (model) {
        case models.episode:
          return new PouchDBEpisode(serie, article);
        case models.podcast:
          return new PouchDBPodcast(serie, article);
        case models.obsession:
          return new PouchDBObsession(serie);
        case models.programme:
          return new PouchDBProgramme(serie);
        default:
          throw new Error(
            `Unknow model : "${model}". Use one of the followings : ${Object.values(models).join(", ")}.`
          );
      }
    } else {
      switch (model.model) {
        case models.episode:
          return new PouchDBEpisode(model.obsession, model.episode);
        case models.podcast:
          return new PouchDBPodcast(model.programme, model.podcast);
        case models.obsession:
          return new PouchDBObsession(model.obsession);
        case models.programme:
          return new PouchDBProgramme(model.programme);
        default:
          throw new Error(
            `Unknow model : "${model.model}". Use one of the followings : ${Object.values(models).join(", ")}.`
          );
      }
    }
  }

  getDocumentId() {
    throw new Error(`Unimplemented method !`);
  }

  getDocumentHref() {
    throw new Error(`Unimplemented method !`);
  }

  async getDocument() {
    try {
      return await pouchDB.database().get(this.getDocumentId());
    } catch (err) {
      if (err.status === 404) {
        return null;
      }
      throw err;
    }
  }

  upsertDocument(diffFunction) {
    return pouchDB.database().upsert(this.getDocumentId(), diffFunction.bind(this));
  }

  async removeDocument() {
    // Get the document
    const doc = await this.getDocument();
    if (doc === null) {
      return { ok: true, message: "Already deleted !" };
    }

    // Remove the Document
    return pouchDB.database().remove(doc);
  }

  createDocument() {
    throw new Error(`Unimplemented method !`);
  }

  async showLoginUI() {
    // Update the UI
    for (const el of $(
      `[data-mesjours-type][data-model="${this.model}"][data-${this.model.toLowerCase()}="${this.getDocumentHref()}"]`
    )) {
      const $el = $(el);
      const type = $el.attr("data-mesjours-type");
      const data = $el.data();

      // Render the HTML Code of the Button
      const html = this.renderLoginBtn(type, data);
      if (html === "") {
        continue;
      }

      // Append the Button to the DOM
      $el.html(html);

      // Check if we have to load a svg
      if ($el.find(".lazyload").length > 0) {
        lazySizes.loader.unveil($el.find(".lazyload").get(0));
      }
    }
  }

  async refreshUI() {
    // Get the Document
    const doc = await this.getDocument();

    // Update the UI
    for (const el of $(
      `[data-mesjours-type][data-model="${this.model}"][data-${this.model.toLowerCase()}="${this.getDocumentHref()}"]`
    )) {
      const $el = $(el);
      const type = $el.attr("data-mesjours-type");
      const data = $el.data();

      // Render the HTML Code of the Button
      const html = this.renderActionBtn(type, data, doc);
      if (html === "") {
        continue;
      }

      // Append the Button to the DOM
      $el.html(html);

      // Check if we have to load a svg
      if ($el.find(".lazyload").length > 0) {
        lazySizes.loader.unveil($el.find(".lazyload").get(0));
      }
    }

    // Check if there is a progress bar
    if (this.hasProgressBar !== true) {
      return;
    }

    // Update the UI
    for (const el of $(`.js-progress-bar[data-${this.model.toLowerCase()}="${this.getDocumentHref()}"]`)) {
      const $el = $(el);
      const data = $el.data();

      // Check if we have a document
      if (doc !== null) {
        // Render the HTML Code of the Progress Bar & append it to the DOM
        $el.html(this.renderProgressBar(data, doc));

        // Change to display block
        $el.css("display", "block");
      } else if ($el.css("display") === "block") {
        // Remove the Progress Bar
        $el.html("");

        // Reset to original display
        $el.css("display", "");
      }
    }
  }

  renderLoginBtn() {
    throw new Error(`Unimplemented method !`);
  }

  renderActionBtn() {
    throw new Error(`Unimplemented method !`);
  }

  renderProgressBar() {
    throw new Error(`Unimplemented method !`);
  }
}

class PouchDBObsession extends PouchDBDocument {
  constructor(obsession) {
    super(models.obsession, false);
    this.obsession = obsession;
  }

  getDocumentId() {
    return `${prefix}-o-${this.obsession}`;
  }

  getDocumentHref() {
    return this.obsession;
  }

  createDocument() {
    return {
      type: "obsession",
      owner: owner,
      obsession: this.obsession,
    };
  }

  renderLoginBtn(type, data) {
    return window.nunjucks.render(`mes-jours/actions/serie.html`, {
      type: type,
      status: "login",
      mtmActionNoun: "Obsession",
      mtmLabel: data.obsession,
    });
  }

  renderActionBtn(type, data, doc) {
    return window.nunjucks.render(`mes-jours/actions/serie.html`, {
      type: type,
      status: doc ? "unfollow" : "follow",
      mtmActionNoun: "Obsession",
      mtmLabel: data.obsession,
    });
  }
}

class PouchDBProgramme extends PouchDBDocument {
  constructor(programme) {
    super(models.programme, false);
    this.programme = programme;
  }

  getDocumentId() {
    return `${prefix}-r-${this.programme}`;
  }

  getDocumentHref() {
    return this.programme;
  }

  createDocument() {
    return {
      type: "programme",
      owner: owner,
      programme: this.programme,
    };
  }

  renderLoginBtn(type, data) {
    return window.nunjucks.render(`mes-jours/actions/serie.html`, {
      type: type,
      status: "login",
      mtmActionNoun: "Programme",
      mtmLabel: data.programme,
    });
  }

  renderActionBtn(type, data, doc) {
    return window.nunjucks.render(`mes-jours/actions/serie.html`, {
      type: type,
      status: doc ? "unfollow" : "follow",
      mtmActionNoun: "Programme",
      mtmLabel: data.programme,
    });
  }
}

class PouchDBEpisode extends PouchDBDocument {
  constructor(obsession, episode) {
    super(models.episode, true);
    this.obsession = obsession;
    this.episode = episode;
  }

  getDocumentId() {
    return `${prefix}-e-${this.episode}`;
  }

  getDocumentHref() {
    return this.episode;
  }

  createDocument(progress) {
    return {
      type: "episode",
      owner: owner,
      obsession: this.obsession,
      episode: this.episode,
      date: Math.floor(Date.now() / 1000),
      progress: progress,
    };
  }

  renderActionBtn(type, data, doc) {
    return window.nunjucks.render("mes-jours/actions/episode.html", {
      type: type,
      status: doc ? (doc.progress === true ? "read" : "reading") : "",
      mtmLabel: data.obsession + "/" + data.episode,
      href: "/obsessions/" + data.obsession + "/" + data.episode + "/",
      progress: doc ? doc.progress : undefined,
    });
  }

  renderProgressBar(data, doc) {
    return window.nunjucks.render("mes-jours/actions/progress-bar.html", {
      progress: doc.progress === true ? 100 : Math.trunc((doc.progress / data.paragraphs) * 100),
    });
  }
}

class PouchDBPodcast extends PouchDBDocument {
  constructor(programme, podcast) {
    super(models.podcast, true);
    this.programme = programme;
    this.podcast = podcast;
  }

  getDocumentId() {
    return `${prefix}-p-${this.podcast}`;
  }

  getDocumentHref() {
    return this.podcast;
  }

  createDocument(progress) {
    return {
      type: "podcast",
      owner: owner,
      programme: this.programme,
      podcast: this.podcast,
      date: Math.floor(Date.now() / 1000),
      progress: progress,
    };
  }

  renderActionBtn(type, data, doc) {
    return window.nunjucks.render("mes-jours/actions/podcast.html", {
      type: type,
      status: doc ? (doc.progress === true ? "listened" : "listening") : "",
      mtmLabel: data.programme + "/" + getPodcastHrefWithCategory(data),
      href: "/podcasts/" + data.programme + "/" + getPodcastHrefWithCategory(data) + "/",
      progress: doc ? doc.progress : undefined,
    });
  }

  renderProgressBar(data, doc) {
    return window.nunjucks.render("mes-jours/actions/progress-bar.html", {
      progress: doc.progress === true ? 100 : Math.trunc((doc.progress / data.duration) * 100),
    });
  }
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// MES JOURS COMPONENT FUNCTIONS                                                                                      //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Show Follow Status on Series
function showFollowStatusForSerie(model, serie) {
  return PouchDBDocument.create(model, serie).refreshUI();
}
function showFollowStatus() {
  // Get Obsessions
  const obsessions = $.uniqueSort(
    $("[data-mesjours-type][data-model=Obsession]").map((idx, el) => $(el).data("obsession"))
  );

  // Get Programmes
  const programmes = $.uniqueSort(
    $("[data-mesjours-type][data-model=Programme]").map((idx, el) => $(el).data("programme"))
  );

  // Iterate through Obsessions
  $.each(obsessions, function (idx, obsession) {
    // Show Follow Status for the Obsession
    showFollowStatusForSerie(models.obsession, obsession);
  });

  // Iterate through Programmes
  $.each(programmes, function (idx, programme) {
    // Show Follow Status for the Obsession
    showFollowStatusForSerie(models.programme, programme);
  });
}

// Show Login Status on Series
function showLoginStatus() {
  // Get Obsessions
  const obsessions = $.uniqueSort(
    $("[data-mesjours-type][data-model=Obsession]").map((idx, el) => $(el).data("obsession"))
  );

  // Get Programmes
  const programmes = $.uniqueSort(
    $("[data-mesjours-type][data-model=Programme]").map((idx, el) => $(el).data("programme"))
  );

  // Iterate through Obsessions
  $.each(obsessions, function (idx, obsession) {
    // Show Login Status for the Obsession
    PouchDBDocument.create(models.obsession, obsession).showLoginUI();
  });

  // Iterate through Programmes
  $.each(programmes, function (idx, programme) {
    // Show Login Status for the Obsession
    PouchDBDocument.create(models.programme, programme).showLoginUI();
  });
}

// Show Progress Status on Articles
function showProgressStatusForArticle(model, article) {
  return PouchDBDocument.create(model, null, article).refreshUI();
}
function showProgressStatus() {
  // Get Episodes
  const episodes = $.uniqueSort($("[data-mesjours-type][data-model=Episode]").map((idx, el) => $(el).data("episode")));

  // Get Podcasts
  const podcasts = $.uniqueSort($("[data-mesjours-type][data-model=Podcast]").map((idx, el) => $(el).data("podcast")));

  // Iterate through Episodes
  $.each(episodes, function (idx, episode) {
    // Show Readings Status for the Episode
    showProgressStatusForArticle(models.episode, episode);
  });

  // Iterate through Podcasts
  $.each(podcasts, function (idx, podcast) {
    // Show Listenings Status for the Podcast
    showProgressStatusForArticle(models.podcast, podcast);
  });
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DATABASE UPDATE FUNCTIONS                                                                                          //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Follow/Unfollow a Serie
async function followSerie(model, serie) {
  try {
    // Upsert the Document
    const doc = PouchDBDocument.create(model, serie);
    const res = await doc.upsertDocument(function (doc) {
      if (!doc.type) {
        // Create a new document
        return this.createDocument();
      }

      // Don't update the document
      return false;
    });

    // Log the response
    logPouchDBResponse(res);

    // Update the UI
    doc.refreshUI();
  } catch (err) {
    // Log the error
    logPouchDBError(err);
  }
}
async function unfollowSerie(model, serie) {
  try {
    const doc = PouchDBDocument.create(model, serie);
    const res = await doc.removeDocument();

    // Log the response
    logPouchDBResponse(res);

    // Update the UI
    doc.refreshUI();
  } catch (err) {
    // Log the error
    logPouchDBError(err);
  }
}

// Episode/Podcast reading/listening progress
async function updateArticleProgress(model, serie, article, progress) {
  try {
    // Upsert the Document
    const res = await PouchDBDocument.create(model, serie, article).upsertDocument(function (doc) {
      if (!doc.type) {
        // Create a new document
        const created = this.createDocument();
        if (created.type === "episode") {
          // Send the episode the devices
          $.ajax("/ws-mes-jours/notifyDevicesAboutReading", {
            method: "POST",
            dataType: "json",
            data: { episode: created.episode },
          });
        }
        return created;
      }

      if (doc.progress === true) {
        // Don't update the document
        return false;
      }

      // Update the previous document
      if (doc.bookmarked) {
        delete doc.bookmarked; // We need to keep that to update correctly old documents
      }
      if (!doc.progress) {
        doc.progress = 0;
      }
      doc.date = Math.floor(Date.now() / 1000);
      if (progress === true) {
        doc.progress = true;
      } else {
        doc.progress = Math.max(doc.progress, progress);
      }
      return doc;
    });

    // Log the Response
    logPouchDBResponse(res);
  } catch (err) {
    // Log the error
    logPouchDBError(err);
  }
}
function flagArticleAsTerminated(model, serie, article) {
  return updateArticleProgress(model, serie, article, true);
}
async function removeArticleProgress(model, serie, article) {
  try {
    const doc = PouchDBDocument.create(model, serie, article);
    const res = await doc.removeDocument();

    // Log the response
    logPouchDBResponse(res);

    // Update the UI
    doc.refreshUI();
  } catch (err) {
    // Log the error
    logPouchDBError(err);
  }
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SEND TO DEVICE TOOLTIP FUNCTIONS                                                                                   //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function showDevicesTooltip($button, devices) {
  // Remove previous Tooltips
  $button.find(".tooltip").remove();

  // Add the Tooltip content
  $button.append(
    window.nunjucks.render("mes-jours/actions/devices.html", {
      devices: devices,
      ...$button.data(),
    })
  );

  // Show a Tooltip
  $.createTooltip($button.get(0), $button.find(".tooltip").get(0), { placement: "left" });
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// MES JOURS EVENTS HANDLER FUNCTIONS                                                                                 //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Handle Click on Follows Buttons
function handleFollowClickForSerie(event) {
  // Get Resume Information
  const $btn = $(event.currentTarget);
  const $follow = $btn.parents("[data-mesjours-type]");

  // Prevent Default
  event.preventDefault();

  // Check if PouchDB is loaded
  if (pouchDB === undefined) {
    return;
  }

  // Check the Operation
  if ($btn.hasClass("follow")) {
    // Follow the Obsession
    followSerie($follow.data());
  } else {
    // Unfollow the Obsession
    unfollowSerie($follow.data());
  }
}

// Handle Click on Send to Devices Buttons
function handleSendToDevicesClick(event) {
  // Get Resume Information
  const $btn = $(event.currentTarget);

  // Prevent Default
  event.preventDefault();

  // Check if PouchDB is loaded
  if (pouchDB === undefined) {
    return;
  }

  // Don't process the event if the clicked element is inside a Tooltip
  if ($(event.target).parents(".tooltip").length > 0) {
    return;
  }

  // Get availables Devices
  $.ajax("/ws-mes-jours/listDevices", { method: "GET", dataType: "json" }).then(function (response) {
    // Show the Tooltip
    showDevicesTooltip($btn, response.devices);
  });
}

// Handle Click on Send to Device Buttons
function handleSendToDeviceClick(event) {
  // Get Resume Information
  const $btn = $(event.currentTarget);
  const $actions = $btn.parents("[data-mesjours-type][data-model=Episode],[data-mesjours-type][data-model=Podcast]");

  // Prevent Default
  event.preventDefault();

  // Get the device & the episode or the podcast
  const data = { device: $btn.data("device") };
  if ($actions.attr("data-model") === "Episode") {
    data.episode = $actions.data("episode");
  } else {
    data.podcast = $actions.data("podcast");
  }

  // Send the episode/podcast to the device
  $.ajax("/ws-mes-jours/sendToDevice", { method: "POST", dataType: "json", data: data });
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// INITIALIZE MES JOURS COMPONENT                                                                                     //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Initialize Resume Information
async function initializePouchDB(couchbaseConfig) {
  // Import the PouchDB library
  pouchDB = await import(/* webpackChunkName: "pouchdb" */ "./pouchdb.mesjours");

  // Create Databases
  owner = couchbaseConfig.configuration.owner;
  prefix = couchbaseConfig.configuration.prefix;
  pouchDB.initialize(couchbaseConfig, onDocumentChange);
}

// Process document change events
const onDocumentChange = function (change) {
  // Log the change
  logPouchDBResponse(change);

  // Analyze the Document ID
  const matchedId = /([0-9]+)-(o|e|p|r)-([a-z0-9-]+)/i.exec(change.id);
  if (matchedId !== null) {
    switch (matchedId[2]) {
      case "o":
        showFollowStatusForSerie(models.obsession, matchedId[3]);
        break;
      case "r":
        showFollowStatusForSerie(models.programme, matchedId[3]);
        break;
      case "e":
        showProgressStatusForArticle(models.episode, matchedId[3]);
        break;
      case "p":
        showProgressStatusForArticle(models.podcast, matchedId[3]);
        break;
    }
  }
};

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// EXPORT MES JOURS COMPONENT                                                                                         //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Initialize the module
async function configureMesJours(account) {
  // Check if we are on a CMS Page
  if (window.location.hostname.match(/(dev\.|local\.)?cms.lesjours.fr/) !== null) {
    // We have to be on the Website
    return;
  }

  // Check if it's a User
  if (account === undefined || account.user === undefined) {
    // The user must be logged in
    // Show the Login Status
    showLoginStatus();
    return;
  }

  // Check if we have a configuration for MesJours
  if (account.mesjours === undefined) {
    // We must have a configuration
    return;
  }

  // Initialize PouchDB
  await initializePouchDB(account.mesjours);

  // Show the Progress/Follow Status
  showProgressStatus();
  showFollowStatus();

  // Bind Events Listeners
  $body.on(
    "click.lesjours.mesjours",
    "[data-mesjours-type][data-model=Obsession] .follow, [data-mesjours-type][data-model=Obsession] .unfollow, [data-mesjours-type][data-model=Programme] .follow, [data-mesjours-type][data-model=Programme] .unfollow",
    handleFollowClickForSerie
  );
  $body.on(
    "click.lesjours.mesjours",
    "[data-mesjours-type][data-model=Episode] .send-to-mobile, [data-mesjours-type][data-model=Podcast] .send-to-mobile",
    handleSendToDevicesClick
  );
  $body.on(
    "click.lesjours.mesjours",
    "[data-mesjours-type][data-model=Episode] .device-item, [data-mesjours-type][data-model=Podcast] .device-item",
    handleSendToDeviceClick
  );

  // Send an event
  $(window).trigger("ljMesJoursLoaded");
}

// Check if the module is loaded
function isMesJoursLoaded() {
  // Check if the Module is Loaded
  // If there is no Synchronization Handler the Module is not loaded yet
  return pouchDB !== undefined;
}

// Refresh the module
function refreshMesJours() {
  // Check if the Component is Initialized
  if (pouchDB === undefined) {
    return;
  }

  // Show the Progress Status on Episodes & Podcasts Tiles
  showProgressStatus();

  // Show the Follow Status on Obsession & Programme Covers
  showFollowStatus();
}

// Get the Episode
function getEpisode(obsession, episode) {
  return PouchDBDocument.create(models.episode, obsession, episode).getDocument();
}

// Get the Podcast
function getPodcast(programme, podcast) {
  return PouchDBDocument.create(models.podcast, programme, podcast).getDocument();
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// HOMEPAGE ELEMENTS                                                                                                  //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
async function getReadingEpisodes(from = 0, limit = 12) {
  const readings = await $.ajax("/ws-mes-jours/readings?" + $.param({ populate: true, from: from, limit: limit }), {
    method: "GET",
    dataType: "json",
  });

  // Return the current reading episodes
  return readings.episodes;
}

async function getFollowedSeries() {
  const followed = await $.ajax("/ws-mes-jours/subscriptions?" + $.param({ populate: true }), {
    method: "GET",
    dataType: "json",
  });

  // Return the current reading episodes
  return followed.series;
}

// Export some functions
export {
  models,
  configureMesJours,
  isMesJoursLoaded,
  refreshMesJours,
  flagArticleAsTerminated,
  updateArticleProgress,
  removeArticleProgress,
  followSerie,
  unfollowSerie,
  getEpisode,
  getPodcast,
  getReadingEpisodes,
  getFollowedSeries,
};
