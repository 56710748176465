(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["mes-jours/actions/podcast.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "mes-jours/actions/podcast.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
if(runtime.contextOrFrameLookup(context, frame, "type") === "header") {
if(runtime.contextOrFrameLookup(context, frame, "status") === "listening") {
output += "\t<span class=\"row m-0 row-center lj-color-text reading pointer\">\n\t\tEn cours<i class=\"ml-1\">";
output += runtime.suppressValue((lineno = 4, colno = 33, runtime.callWrap(t_4, "icon", context, ["reading","En cours"])), env.opts.autoescape);
output += "</i>\n\t</span>\n";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "status") === "listened") {
output += "\t<span class=\"row m-0 row-center lj-color-text read\">\n\t\tEcouté<i class=\"ml-1\">";
output += runtime.suppressValue((lineno = 8, colno = 31, runtime.callWrap(t_4, "icon", context, ["read","Podcast Ecouté"])), env.opts.autoescape);
output += "</i>\n\t</span>\n";
;
}
;
}
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "type") === "mesjours") {
if(runtime.contextOrFrameLookup(context, frame, "status") === "listening") {
output += "\t<span class=\"inline-flex row-center color-medium-5 check\">\n\t\tJe l’ai écouté<i class=\"ml-1\">";
output += runtime.suppressValue((lineno = 14, colno = 39, runtime.callWrap(t_4, "icon", context, ["read","Je l’ai écouté"])), env.opts.autoescape);
output += "</i>\n\t</span>\n\t<span class=\"inline-flex row-center color-medium-5 remove ml-4\">\n\t\tSupprimer<i class=\"ml-1\">";
output += runtime.suppressValue((lineno = 17, colno = 34, runtime.callWrap(t_4, "icon", context, ["remove","Supprimer"])), env.opts.autoescape);
output += "</i>\n\t</span>\n";
;
}
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "type") === "cover") {
output += "\t<span class=\"flex row-center send-to-mobile\" data-mtm-action-noun=\"Podcast\" data-mtm-label=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmLabel"), env.opts.autoescape);
output += "\">\n\t\tTélécharger<i class=\"ml-1\">";
output += runtime.suppressValue((lineno = 22, colno = 36, runtime.callWrap(t_4, "icon", context, ["send-to-mobile","Envoyer sur mon téléphone"])), env.opts.autoescape);
output += "</i>\n\t</span>\n";
if(runtime.contextOrFrameLookup(context, frame, "status") === "listening") {
output += "\t<span class=\"flex ml-1 row-center reading\" data-href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "href"), env.opts.autoescape);
output += "\">\n\t\tEn cours<i class=\"ml-1\">";
output += runtime.suppressValue((lineno = 26, colno = 33, runtime.callWrap(t_4, "icon", context, ["reading","En cours"])), env.opts.autoescape);
output += "</i>\n\t</span>\n";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "status") === "listened") {
output += "\t<span class=\"flex ml-1 row-center read\">\n\t\tEcouté<i class=\"ml-1\">";
output += runtime.suppressValue((lineno = 30, colno = 31, runtime.callWrap(t_4, "icon", context, ["read","Podcast Ecouté"])), env.opts.autoescape);
output += "</i>\n\t</span>\n";
;
}
;
}
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "type") === "card") {
output += "\t<span class=\"flex row-center color-medium-5 send-to-mobile\" data-mtm-action-noun=\"Podcast\" data-mtm-label=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmLabel"), env.opts.autoescape);
output += "\">\n\t\tTélécharger<i class=\"ml-1\">";
output += runtime.suppressValue((lineno = 35, colno = 36, runtime.callWrap(t_4, "icon", context, ["send-to-mobile","Envoyer sur mon téléphone"])), env.opts.autoescape);
output += "</i>\n\t</span>\n";
if(runtime.contextOrFrameLookup(context, frame, "status") === "listening") {
output += "\t<span class=\"flex ml-1 row-center alpha-50 lj-color-text reading\" data-href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "href"), env.opts.autoescape);
output += "\">\n\t\tEn cours<i class=\"ml-1\">";
output += runtime.suppressValue((lineno = 39, colno = 33, runtime.callWrap(t_4, "icon", context, ["reading","En cours"])), env.opts.autoescape);
output += "</i>\n\t</span>\n";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "status") === "listened") {
output += "\t<span class=\"flex ml-1 row-center lj-color-text read\">\n\t\tEcouté<i class=\"ml-1\">";
output += runtime.suppressValue((lineno = 43, colno = 31, runtime.callWrap(t_4, "icon", context, ["read","Podcast Ecouté"])), env.opts.autoescape);
output += "</i>\n\t</span>\n";
;
}
;
}
;
}
;
}
;
}
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("mes-jours/actions/podcast.html", ctx, cb); }
})();
