(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["cards/programme.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n";
env.getTemplate("_macro.html", false, "cards/programme.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "mesJoursSerie")) {
var t_4 = t_1.mesJoursSerie;
} else {
cb(new Error("cannot import 'mesJoursSerie'")); return;
}
context.setVariable("mesJoursSerie", t_4);
output += "<a class=\"card flex card-programme card-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"type"), env.opts.autoescape);
output += " lj-color-";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"programme")),"color")),"id"), env.opts.autoescape);
output += "\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"href"), env.opts.autoescape);
output += "\">\n\t<div class=\"card-image lj-color-";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"programme")),"color")),"id"), env.opts.autoescape);
output += "\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"lazyload")) {
output += "\t\t<img class=\"responsive-card lazyload\" src=\"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7\" data-src=\"";
output += runtime.suppressValue(env.getFilter("res").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"image"),760,{"portrait": true}), env.opts.autoescape);
output += "\" data-sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"sizes")), env.opts.autoescape);
output += "\" data-srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"image"),{"portrait": true}), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"title"))), env.opts.autoescape);
output += "\" />\n";
;
}
else {
output += "\t\t<img class=\"responsive-card\" src=\"";
output += runtime.suppressValue(env.getFilter("res").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"image"),760,{"portrait": true}), env.opts.autoescape);
output += "\" sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"sizes")), env.opts.autoescape);
output += "\" srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"image"),{"portrait": true}), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"title"))), env.opts.autoescape);
output += "\" />\n";
;
}
output += "\t</div>\n\t<div class=\"card-container overlay ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"type") === "large") {
output += "p-3";
;
}
else {
output += "p-2";
;
}
output += " gradient-to-top\">\n\t\t<div class=\"container h-100 row row-vertical row-end\">\n\t\t\t<div class=\"col p-0\">\n\t\t\t\t<div class=\"";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"type") === "large" || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"type") === "master") {
output += "snake-4";
;
}
else {
output += "snake-3";
;
}
output += "\">\n\t\t\t\t\t<div class=\"row m-0 row-vertical row-justify snake-container no-overflow\">\n\t\t\t\t\t\t<div class=\"sm-w-6c\">\n\t\t\t\t\t\t\t<span class=\"title text-upper\">\n\t\t\t\t\t\t\t\t<span class=\"lj-color-text\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"title"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class=\"subtitle color-white mt-1 sm-w-6c\">\n\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"subtitle"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t";
output += runtime.suppressValue((lineno = 37, colno = 20, runtime.callWrap(t_4, "mesJoursSerie", context, ["card","Programme",runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "card")),"programme")),"href"),{"classes": "bottom-right"}])), env.opts.autoescape);
output += "\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</a>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("cards/programme.html", ctx, cb); }
})();
