(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["episode/classic/mini-obsession.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "episode/classic/mini-obsession.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "mesJoursSerie")) {
var t_4 = t_1.mesJoursSerie;
} else {
cb(new Error("cannot import 'mesJoursSerie'")); return;
}
context.setVariable("mesJoursSerie", t_4);
output += "<aside id=\"mini-obsession\" class=\"mini mini-episode mt-0 mb-2 ml-0 md-w-2c lg-w-3c\">\n\t<a href=\"/obsessions/";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"href"), env.opts.autoescape);
output += "\">\n\t\t<div class=\"row m-0 row-center no-wrap obsession-name\">\n\t\t\t<div class=\"link on-hover lj-color-text text-upper\">\n\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"name"), env.opts.autoescape);
output += "\n\t\t\t</div>\n\t\t</div>\n\t\t<div class=\"lj-color-text\">\n\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"desc"), env.opts.autoescape);
output += "\n\t\t</div>\n\t\t";
output += runtime.suppressValue((lineno = 11, colno = 18, runtime.callWrap(t_4, "mesJoursSerie", context, ["mini","Obsession",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"href"),{"classes": "mini-follow"}])), env.opts.autoescape);
output += "\n\t</a>\n</aside>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("episode/classic/mini-obsession.html", ctx, cb); }
})();
