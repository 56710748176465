(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["episode/story/note.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n<aside id=\"note-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "note")),"number"), env.opts.autoescape);
output += "\" class=\"mini mini-story w-auto\">\n\t<div class=\"title\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "note")),"title"), env.opts.autoescape);
output += "</div>\n\t<br />\n\t<div class=\"caption\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "note")),"caption"), env.opts.autoescape);
output += "</div>\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "note")),"href")) {
output += "\t\t<br />\n\t\t<a class=\"more link-unstyled\" href=\"";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "note")),"href"),"#"), env.opts.autoescape);
output += "\" target=\"_blank\">En savoir plus</a>\n";
;
}
output += "</aside>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("episode/story/note.html", ctx, cb); }
})();
