(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["mes-jours/actions/devices.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "mes-jours/actions/devices.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<div class=\"tooltip hidden\">\n\t<div class=\"tooltip-holder\">\n\t\t<div class=\"tooltip-content devices-list\">\n";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "devices")) > 0) {
output += "\t\t\t<div class=\"tooltip-title\">Envoyer à :</div>\n";
frame = frame.push();
var t_7 = runtime.contextOrFrameLookup(context, frame, "devices");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("device", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\t\t\t<div class=\"tooltip-item device-item\" data-device=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"token"), env.opts.autoescape);
output += "\" data-mtm-type=\"click\" data-mtm-category=\"Mes Jours\" data-mtm-action=\"Send ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmActionNoun"), env.opts.autoescape);
output += " to Mobile\" data-mtm-label=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmLabel"), env.opts.autoescape);
output += "\">\n\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_8),"device"), env.opts.autoescape);
output += "\n\t\t\t</div>\n";
;
}
}
frame = frame.pop();
;
}
else {
output += "\t\t\t<div class=\"tooltip-title\">Aucun appareil connecté</div>\n\t\t\t<div>\n\t\t\t\tVous pouvez télécharger notre application sur&nbsp;:\n\t\t\t\t<div class=\"flex row-around mb-1\">\n\t\t\t\t\t<a class=\"inline-block\" href=\"https://play.google.com/store/apps/details?id=fr.lesJours\" style=\"width: 100px;\">\n\t\t\t\t\t\t<img class=\"responsive\" src=\"/img/stores/google-play-badge.png\" title=\"Disponible sur Google Play\" />\n\t\t\t\t\t</a>\n\t\t\t\t\t<a class=\"inline-block\" href=\"https://apps.apple.com/us/app/les-jours/id1233676102\" style=\"width: 100px;\">\n\t\t\t\t\t\t<img class=\"responsive\" src=\"/img/stores/app-store-badge.png\" title=\"Télécharger dans l’App Store\" />\n\t\t\t\t\t</a>\n\t\t\t\t</div>\n\t\t\t</div>\n";
;
}
output += "\t\t</div>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("mes-jours/actions/devices.html", ctx, cb); }
})();
