(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["modals/episodes-list.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "modals/episodes-list.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<div id=\"episodes-list\" class=\"modal centered\" role=\"dialog\" tabindex=\"-1\">\n\t<div role=\"document\">\n\t\t<div class=\"row row-center\">\n\t\t\t<div class=\"col m-0 ph-0 pb-2 h5 fw-bold row row-center row-justify color-darkest\">\n\t\t\t\t<small class=\"pr-1\">";
output += runtime.suppressValue((lineno = 5, colno = 31, runtime.callWrap(t_4, "icon", context, ["episodes"])), env.opts.autoescape);
output += "</small><span>Les épisodes</span>\n\t\t\t</div>\n\t\t\t<span class=\"a btn close\" data-href=\"#\">\n\t\t\t\t<span class=\"sr\">Fermer cette fenêtre</span>\n\t\t\t\t";
output += runtime.suppressValue((lineno = 9, colno = 11, runtime.callWrap(t_4, "icon", context, ["close"])), env.opts.autoescape);
output += "\n\t\t\t</span>\n\t\t\t<div class=\"col\">\n\t\t\t\t<table>\n\t\t\t\t\t<tbody>\n";
frame = frame.push();
var t_7 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"episodes");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("episode", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t<td>\n\t\t\t\t\t\t\t\tÉpisode&nbsp;n°&#x202f;";
output += runtime.suppressValue(runtime.memberLookup((t_8),"number"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t</td>\n\t\t\t\t\t\t\t<td>\n\t\t\t\t\t\t\t\t<a href=\"/obsessions/";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"href"), env.opts.autoescape);
output += "/";
output += runtime.suppressValue(runtime.memberLookup((t_8),"href"), env.opts.autoescape);
output += "/\">\n\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_8),"title"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t</a>\n\t\t\t\t\t\t\t</td>\n\t\t\t\t\t\t</tr>\n";
;
}
}
frame = frame.pop();
output += "\t\t\t\t\t</tbody>\n\t\t\t\t</table>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("modals/episodes-list.html", ctx, cb); }
})();
