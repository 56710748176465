import $ from "jquery";

// Variables
const $window = $(window);

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SERIES SLIDERS
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function initializeSeriesSlider() {
  const $slider = $("section#series .slider");
  const cntWidth = $slider.innerWidth();

  // Initialize the Slider
  $window.trigger("ljComponentsInit", ["slider", $slider, cntWidth]);
}

// Initialize Sliders
initializeSeriesSlider();

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// TIME COUNTER
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function buildTimeDiffString(diff) {
  // Variables
  const seconds = 1000;
  const minutes = 60 * seconds;
  const hours = 60 * minutes;
  const days = 24 * hours;

  // Remaining
  const rDays = Math.floor(diff / days);
  diff = diff - days * rDays;
  const rHours = Math.floor(diff / hours);
  diff = diff - hours * rHours;
  const rMinutes = Math.floor(diff / minutes);
  diff = diff - minutes * rMinutes;
  const rSeconds = Math.floor(diff / seconds);

  // Build the Time Part of the String
  const str =
    (rHours < 10 ? "0" + rHours : rHours) +
    "&nbsp;h&nbsp;" +
    (rMinutes < 10 ? "0" + rMinutes : rMinutes) +
    "&nbsp;m&nbsp;" +
    (rSeconds < 10 ? "0" + rSeconds : rSeconds) +
    "&nbsp;s";

  // Build the Time Difference String
  if (rDays > 0) {
    return "H - " + rDays + " jour" + (rDays === 1 ? "" : "s") + " " + str;
  } else {
    return "H - " + str;
  }
}

// Start the Counter if there is One
const $counter = $(".page-openhouse [data-event-date]");
if ($counter.length > 0) {
  // Update the counter each second
  setInterval(function () {
    // Get Parameters
    const eventDate = $counter.data("eventDate").split("-");
    const event = new Date(
      parseInt(eventDate[0], 10),
      parseInt(eventDate[1], 10) - 1,
      parseInt(eventDate[2], 10)
    ).getTime();
    const now = new Date().getTime();
    const remaining = event - now;

    // Check if it's the moment
    if (remaining <= 0) {
      // Stop the counter
      $counter.html("H - 00h00m00s");
    } else {
      // Update the counter
      $counter.html(buildTimeDiffString(remaining));
    }
  }, 1000);
}
