(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["menu/search-menu.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "menu/search-menu.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<nav id=\"search-menu\" class=\"container fluid w-100 p-0\">\n\t<div id=\"search-menu-content\" class=\"row m-0 bg-lightest suggested-search-group\">\n\t\t<form class=\"search-form col\" method=\"get\" action=\"/recherche/\" autocomplete=\"off\">\n\t\t\t<div class=\"row m-0\">\n\t\t\t\t<div class=\"flex-1 search-input\">\n\t\t\t\t\t<input type=\"text\" name=\"query\" placeholder=\"Entrez votre recherche ici\" autocomplete=\"off\" aria-label=\"Entrez votre recherche ici\" />\n\t\t\t\t\t<input type=\"text\" name=\"query-suggest\" disabled=\"disabled\" aria-label=\"Suggestion\" />\n\t\t\t\t</div>\n\t\t\t\t<div class=\"flex-0 search-tip\">\n\t\t\t\t\tTapez sur la touche<span class=\"inline-block ph-1\">";
output += runtime.suppressValue((lineno = 10, colno = 63, runtime.callWrap(t_4, "icon", context, ["enter"])), env.opts.autoescape);
output += "</span>pour valider\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</form>\n\t\t<div class=\"stripe grey-stripe\"></div>\n\t\t<div class=\"search-results col p-0\"></div>\n\t</div>\n</nav>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("menu/search-menu.html", ctx, cb); }
})();
