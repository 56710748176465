import $ from "jquery";

// Extend jQuery to Provide JSON Serialization of Forms
$.fn.serializeJSON = function () {
  // Get Form Data as an Array
  const object = {};
  const array = this.serializeArray();

  // Iterate over Values
  $.each(array, function (index, element) {
    // Check if we Already have a Value for this Index
    if (object[element.name]) {
      // Convert the Value as Array
      if (!object[element.name].push) {
        object[element.name] = [object[element.name]];
      }

      // Append the Current Value to the Index
      object[element.name].push(element.value || "");
    } else {
      // Save the Value
      object[element.name] = element.value || "";
    }
  });

  // Return the Serialized Form
  return object;
};
