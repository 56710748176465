(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["mes-jours/slider/followed.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "mes-jours/slider/followed.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<div id=\"home-followed-series\" class=\"home-mes-jours-container mv-3\">\n";
if(runtime.contextOrFrameLookup(context, frame, "followed")) {
var t_5;
t_5 = {"title": "Mes séries suivies","titleLink": "/mon-compte/series-suivies","series": runtime.contextOrFrameLookup(context, frame, "series"),"cardType": "small","cardSizes": ["280px","280px","280px"]};
frame.set("slider", t_5, true);
if(frame.topLevel) {
context.setVariable("slider", t_5);
}
if(frame.topLevel) {
context.addExport("slider", t_5);
}
;
}
else {
var t_6;
t_6 = {"series": runtime.contextOrFrameLookup(context, frame, "series"),"cardType": "large","cardSizes": ["380px","380px","380px"]};
frame.set("slider", t_6, true);
if(frame.topLevel) {
context.setVariable("slider", t_6);
}
if(frame.topLevel) {
context.addExport("slider", t_6);
}
;
}
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("slider/series.html", false, "mes-jours/slider/followed.html", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
callback(null,t_7);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
callback(null,t_9);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("mes-jours/slider/followed.html", ctx, cb); }
})();
