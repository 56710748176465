/* globals GaaMetering:true */
import $ from "jquery";
import { trackContentImpression, trackContentInteraction } from "./analytics";

function initGaaMetering(configuration) {
  let { userState, showcaseEntitlement, googleApiClientId } = configuration;

  let registerUserPromise = new Promise(() => {
    // Get the information for the user who has just registered.
    GaaMetering.getGaaUserPromise().then((gaaUser) => {
      // Send the information to the registration endpoint
      $.post("/mon-compte/register", { gsiIdToken: gaaUser.credential, gaaEpisode: window.LesJours.episode.href }).then(
        () => {
          window.location.reload();
        }
      );
    });
  });

  let handleLoginPromise = new Promise(() => {
    GaaMetering.getLoginPromise().then(() => {
      // Open the connexion page
      window.open(
        `/connexion?redirect=${encodeURIComponent(window.location.href.replace(window.location.origin, ""))}`,
        "_self"
      );
    });
  });

  let showPaywall = () => {
    // Navigate to the subscription page
    window.open("/abonnement/jouriste", "_self");
  };

  GaaMetering.init({
    googleApiClientId: googleApiClientId,
    paywallType: "SERVER_SIDE",
    allowedReferrers: ["lesjours.fr"],
    userState: userState,
    handleLoginPromise: handleLoginPromise,
    registerUserPromise: registerUserPromise,
    showPaywall: showPaywall,
    showcaseEntitlement: showcaseEntitlement,
  });

  (self.SWG = self.SWG || []).push(function (subscriptions) {
    subscriptions.getEventManager().then((manager) => {
      manager.registerEventListener((event) => {
        // Add code here to send the event to your analytics
        // Events code can be found here : https://github.com/subscriptions-project/swg-js/blob/main/src/proto/api_messages.ts#L46
        switch (event.eventType) {
          case 23: // IMPRESSION_SHOWCASE_REGWALL
            // Publisher provides an anonymous userState object with no access and the user sees the Google registration prompt.
            trackContentImpression({
              name: "Google - Extended Access",
              piece: "Showcase - Inscription",
              target: "https://accounts.google.com/v3/signin/identifier",
            });
            break;
          case 1060: // ACTION_SHOWCASE_REGWALL_SIWG_CLICK
            // User clicks the Sign in with Google button.
            trackContentInteraction({
              interaction: "click",
              name: "Google - Extended Access",
              piece: "Showcase - Inscription",
              target: "https://accounts.google.com/v3/signin/identifier",
            });
            break;
          case 1036: // ACTION_SHOWCASE_REGWALL_EXISTING_ACCOUNT_CLICK
            // User clicks the Already registered? Sign in. link.
            trackContentInteraction({
              interaction: "click",
              name: "Google - Extended Access",
              piece: "Showcase - Inscription",
              target: "https://lesjours.fr/connexion",
            });
            break;
          case 21: // IMPRESSION_METER_TOAST
            // Extended Access library consumes Google entitlement to unlock content and the user sees the Extended Access prompt.
            trackContentImpression({
              name: "Google - Extended Access",
              piece: "Showcase - Abonnement",
              target: "https://lesjours.fr/abonnement/jouriste",
            });
            break;
          case 1025: // ACTION_METER_TOAST_SUBSCRIBE_CLICK
            // The user clicks on the Subscribe call-to-action in the Extended Access prompt.
            trackContentInteraction({
              interaction: "click",
              name: "Google - Extended Access",
              piece: "Showcase - Abonnement",
              target: "https://lesjours.fr/abonnement/jouriste",
            });
            break;
        }
      });
    });
  });
}

let gaaMeteringConfiguration = null;

function hookGaaMetering(gaaMeteringAtLoading) {
  let hooked = {
    push: (event) => {
      if (typeof event === "object" && event.googleApiClientId !== undefined) {
        gaaMeteringConfiguration = event;
        return;
      }

      if (event === "swg-gaa-loaded") {
        return initGaaMetering(gaaMeteringConfiguration);
      }
    },
  };

  for (const event of gaaMeteringAtLoading) {
    hooked.push(event);
  }

  return hooked;
}

if (typeof window.LesJours.gaaMetering === "object") {
  window.LesJours.gaaMetering = hookGaaMetering(window.LesJours.gaaMetering);
}
