// Hold features
const featuresList = {
  MY_DEVICES: "mydevices",
  SHOW_DURATION: "showduration",
};
let activatedFeatures;

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// INTERNAL FUNCTIONS                                                                                                 //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Test if the module is not initialized
function isNotInitialized() {
  return activatedFeatures === undefined;
}

// Initialize the module
function initialize() {
  // Get the list of activated features
  let features = [];
  if (window.LesJours && window.LesJours.account && window.LesJours.account.features) {
    features = window.LesJours.account.features;
  }

  // Compute activated features
  const activated = {};
  Object.values(featuresList).forEach(function (featureKey) {
    activated[featureKey] = features.indexOf(featureKey) !== -1;
  });
  activatedFeatures = activated;
}

// Test if a feature is enabled
function isFeatureActivated(feature) {
  // Ensure that the module is initialized
  if (isNotInitialized()) {
    initialize();
  }

  // Return the feature status
  return activatedFeatures[feature];
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// EXPORTED FUNCTIONS                                                                                                 //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Features list
export const features = featuresList;

// Function to check if a feature exist
export function isActivated(feature) {
  return isFeatureActivated(feature);
}
