(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["series/recent-series-list.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "series/recent-series-list.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
if(Object.prototype.hasOwnProperty.call(t_1, "mesJoursSerie")) {
var t_5 = t_1.mesJoursSerie;
} else {
cb(new Error("cannot import 'mesJoursSerie'")); return;
}
context.setVariable("mesJoursSerie", t_5);
frame = frame.push();
var t_8 = runtime.contextOrFrameLookup(context, frame, "series");
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("serie", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
var t_10;
t_10 = runtime.memberLookup((t_9),"model");
frame.set("model", t_10, true);
if(frame.topLevel) {
context.setVariable("model", t_10);
}
if(frame.topLevel) {
context.addExport("model", t_10);
}
t_9 = runtime.memberLookup((t_9),"document");
frame.set("serie", t_9, true);
if(frame.topLevel) {
context.setVariable("serie", t_9);
}
if(frame.topLevel) {
context.addExport("serie", t_9);
}
output += "<div class=\"col sm-w-6c p-2\">\n";
if(runtime.contextOrFrameLookup(context, frame, "model") === "Obsession") {
var t_11;
t_11 = "/obsessions/" + runtime.memberLookup((t_9),"href") + "/";
frame.set("href", t_11, true);
if(frame.topLevel) {
context.setVariable("href", t_11);
}
if(frame.topLevel) {
context.addExport("href", t_11);
}
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "model") === "Programme") {
var t_12;
t_12 = "/podcasts/" + runtime.memberLookup((t_9),"href") + "/";
frame.set("href", t_12, true);
if(frame.topLevel) {
context.setVariable("href", t_12);
}
if(frame.topLevel) {
context.addExport("href", t_12);
}
;
}
;
}
output += "\t<div class=\"search row md-no-wrap search-obsession lj-color-";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_9),"color")),"id"), env.opts.autoescape);
output += "\">\n\t\t<div class=\"col sm-w-100 md-w-auto\">\n\t\t\t<a class=\"block search-image\" href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "href"), env.opts.autoescape);
output += "\">\n\t\t\t\t<img class=\"responsive h-100\" src=\"";
output += runtime.suppressValue(runtime.memberLookup((t_9),"img"), env.opts.autoescape);
output += "\" sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, ["100vw","180px","180px"]), env.opts.autoescape);
output += "\" srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.memberLookup((t_9),"img")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeBR").call(context, runtime.memberLookup((t_9),"title"))), env.opts.autoescape);
output += "\" />\n\t\t\t</a>\n\t\t</div>\n\t\t<div class=\"col sm-w-100 md-w-auto flex-1 ml-0 p-0\">\n\t\t\t<div class=\"row sm-m-0 h-100 row-vertical\">\n\t\t\t\t<div class=\"col sm-mt-2 obsession-name\">\n\t\t\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "href"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t<h2 class=\"inline title link on-hover lj-color-text text-upper\">\n\t\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("removeBR").call(context, runtime.memberLookup((t_9),"title")), env.opts.autoescape);
output += "\n\t\t\t\t\t\t</h2>\n\t\t\t\t\t</a>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"col mt-1\">\n\t\t\t\t\t<span class=\"subtitle color-medium-8\">\n\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_9),"subtitle"), env.opts.autoescape);
output += "\n\t\t\t\t\t</span>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"col mt-1 lg-hidden\">\n\t\t\t\t\t";
output += runtime.suppressValue((lineno = 32, colno = 21, runtime.callWrap(t_5, "mesJoursSerie", context, ["filled",runtime.contextOrFrameLookup(context, frame, "model"),runtime.memberLookup((t_9),"href")])), env.opts.autoescape);
output += "\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class=\"col w-auto sm-hidden md-hidden\">\n\t\t\t";
output += runtime.suppressValue((lineno = 37, colno = 19, runtime.callWrap(t_5, "mesJoursSerie", context, ["filled",runtime.contextOrFrameLookup(context, frame, "model"),runtime.memberLookup((t_9),"href")])), env.opts.autoescape);
output += "\n\t\t</div>\n\t</div>\n</div>\n";
;
}
}
frame = frame.pop();
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("series/recent-series-list.html", ctx, cb); }
})();
