import $ from "jquery";

// Listen Change Events on the Page
$(document.body).on("input.lesjours.input", "input, select", function (e) {
  // Check the Changed Input
  const $input = $(e.currentTarget);

  // Test if the Input is Valid
  if ($input.is(":invalid")) {
    // Add the .error CSS Class to the Input
    $input.addClass("error");
  } else {
    // Remove the .error CSS Class from the Input
    $input.removeClass("error");
  }
});
