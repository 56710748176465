import $ from "jquery";
import { trackContentImpressionsFor } from "./analytics";
import nunjucks from "./nunjucks";

// Enum for insertion methods
const insertions = {
  append: 1,
  after: 2,
  prepend: 3,
  replace: 4,
};
// Enum for devices
const devices = {
  smartphone: "sm",
  tablet: "md",
  desktop: "lg",
};
// CTA counter for ctaId
let counter = 0;

function showCTA(pivot, template, param, options) {
  // Create a unique ID for the CTA
  param.ctaId = `cta-${++counter}`;

  if (options.devices !== undefined) {
    // Check the type of options.devices
    if (typeof options.devices === "string") {
      options.devices = [options.devices];
    }
    // Filter the array and add it to the template parameters
    param.hidden = Object.values(devices).filter((device) => !options.devices.includes(device));

    // Check if all devices are targetted to be hidden
    if (param.hidden.length === 3) {
      console.warn(
        "Unknown device targetted. Therefore, the CTA was hidden on all devices and was not rendered. Use at least on of the followings : smartphone, tablet, desktop."
      );
      return null;
    }
  }

  // Check if we have Mailchimp input(s)
  if (param.mceInputs) {
    if (!Array.isArray(param.mceInputs)) {
      param.mceInputs = [param.mceInputs];
    }
    // Check if we have all parameters
    param.mceInputs.forEach((input, key) => {
      if (input.mceName === undefined || input.mceValue === undefined) {
        throw new Error(
          `Error at input ${key}. You must use the following syntax for each input : { mceName: 'xxx', mceValue: 'yyy' }`
        );
      }
    });
  }

  // Check if we have custom colors parameter and if a required color is missing
  if (
    typeof param.colors === "object" &&
    (typeof param.colors.bgColor !== "string" || typeof param.colors.textOnBgColor !== "string")
  ) {
    throw new Error(
      "One or more color parameters are missing, therefore, the template was not rendered. You must set the following « colors » parameters : bgColor, textOnBgColor."
    );
  }

  // Render the CTA template
  const rendered = nunjucks.render(template, param);
  const renderedNode = $(rendered).get(0);

  // Insert the CTA
  const $pivot = $(pivot);
  switch (options.insertion ?? insertions.append) {
    case insertions.after:
      $pivot.after(renderedNode);
      break;
    case insertions.prepend:
      $pivot.prepend(renderedNode);
      break;
    case insertions.append:
      $pivot.append(renderedNode);
      break;
    case insertions.replace:
      $pivot.empty();
      $pivot.append(renderedNode);
      break;
    default:
      throw new Error(
        `Unknown insertion method : ${options.insertion}. Use one of the followings : append, after, prepend, replace.`
      );
  }

  // Trigger Content Tracking
  trackContentImpressionsFor(renderedNode);

  // Check if the pivot is inside an article
  if ($pivot.closest(".episode-container").length > 0) {
    // Ask Minis Re-Layout
    $(window).trigger("ljComponentsRelayout", "mini");
  }

  // Return the rendered Node
  return renderedNode;
}

function show(pivot, template, params, options) {
  if (window.LesJours.debugCTAs) {
    return (Array.isArray(params) ? params : [params]).map((param) =>
      showCTA(pivot, template, param, { ...options, insertion: insertions.append })
    );
  }

  if (Array.isArray(params)) {
    return showCTA(pivot, template, params[Math.floor(Math.random() * params.length)], options);
  }

  return showCTA(pivot, template, params, options);
}

// Export some functions
export { devices, insertions, show };
