(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["mes-jours/episode.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n";
env.getTemplate("_macro.html", false, "mes-jours/episode.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<div class=\"article row md-no-wrap article-episode article-small lj-color-";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"obsession")),"color")),"id"), env.opts.autoescape);
output += "\">\n\t<div class=\"col sm-w-100 md-w-auto\">\n\t\t<a class=\"block article-image\" href=\"/obsessions/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"obsession")),"href"), env.opts.autoescape);
output += "/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"episode")),"href"), env.opts.autoescape);
output += "/";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"progress")?("#scrollto-" + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"progress")):""), env.opts.autoescape);
output += "\">\n";
var t_5;
t_5 = env.getFilter("src").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"episode"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"obsession"));
frame.set("image", t_5, true);
if(frame.topLevel) {
context.setVariable("image", t_5);
}
if(frame.topLevel) {
context.addExport("image", t_5);
}
output += "\t\t\t<img class=\"responsive h-100\" src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "image"), env.opts.autoescape);
output += "\" sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, ["180px","180px","180px"]), env.opts.autoescape);
output += "\" srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.contextOrFrameLookup(context, frame, "image")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"episode")),"title"))), env.opts.autoescape);
output += "\" />\n\t\t</a>\n\t</div>\n\t<div class=\"col sm-w-100 md-w-auto flex-1 ml-0 p-0\">\n\t\t<div class=\"row sm-mh-0 sm-mt-1 h-100 row-vertical\">\n\t\t\t<div class=\"col flex row-center obsession-name\">\n\t\t\t\t<a class=\"link on-hover lj-color-text text-upper\" href=\"/obsessions/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"obsession")),"href"), env.opts.autoescape);
output += "/\">\n\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("removeBR").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"obsession")),"title")), env.opts.autoescape);
output += "\n\t\t\t\t</a>\n\t\t\t</div>\n\t\t\t<div class=\"col mv-1\">\n\t\t\t\t<a href=\"/obsessions/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"obsession")),"href"), env.opts.autoescape);
output += "/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"episode")),"href"), env.opts.autoescape);
output += "/";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"progress")?("#scrollto-" + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"progress")):""), env.opts.autoescape);
output += "\">\n\t\t\t\t\t<span class=\"inline title link on-hover color-dark\">\n\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("removeBR").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"episode")),"title")), env.opts.autoescape);
output += "\n\t\t\t\t\t</span>\n\t\t\t\t</a>\n\t\t\t</div>\n\t\t\t<div class=\"col mt-auto infos mesjours-actions\" data-mesjours-type=\"mesjours\" data-model=\"Episode\" data-obsession=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"obsession")),"href"), env.opts.autoescape);
output += "\" data-episode=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"episode")),"href"), env.opts.autoescape);
output += "\">\n";
var t_6;
t_6 = "reading";
frame.set("status", t_6, true);
if(frame.topLevel) {
context.setVariable("status", t_6);
}
if(frame.topLevel) {
context.addExport("status", t_6);
}
var t_7;
t_7 = "mesjours";
frame.set("type", t_7, true);
if(frame.topLevel) {
context.setVariable("type", t_7);
}
if(frame.topLevel) {
context.addExport("type", t_7);
}
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("mes-jours/actions/episode.html", false, "mes-jours/episode.html", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
callback(null,t_8);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_11,t_10) {
if(t_11) { cb(t_11); return; }
callback(null,t_10);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class=\"col w-auto flex-0 infos text-right\">\n\t\t<span class=\"color-medium-8\">Épisode n°&#x202f;";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"episode")),"number"), env.opts.autoescape);
output += "</span><br /><span class=\"color-medium-5\">";
output += runtime.suppressValue(env.getFilter("humanDate").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "element")),"episode")),"date")), env.opts.autoescape);
output += "</span>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("mes-jours/episode.html", ctx, cb); }
})();
