import $ from "jquery";
import throttle from "lodash/throttle";

// Constants
const $window = $(window);
const kSharingButtonsMinScroll = $window.height(); // The sharing buttons are only shown after a screen scroll

// Sharing buttons
function loadSharingButtons($share, $bulletPoints) {
  // Bind Listener
  $window.on(
    "scroll.lesjours.actions",
    throttle(
      () => {
        // Check if the current scroll is greater than the threshold
        if (kSharingButtonsMinScroll < $window.scrollTop()) {
          // Show the sharing button & hide the bullet points button
          $share.addClass("show");
          $bulletPoints?.removeClass("show");
        } else {
          // Hide the sharing button & show the bullet points button
          $share.removeClass("show");
          $bulletPoints?.addClass("show");
        }
      },
      1000,
      { leading: true, trailing: true }
    )
  );
}

// Bullet points button
function loadBulletPointsButton($bulletPoints) {
  // Bind Listener
  $bulletPoints.on("click.lesjours.actions", () => {
    // Scroll to the bullet points area & open it
    $.scrollToAnchor("#bulletpoints");
    $("#bulletpoints .collapsed").trigger("click");
  });
}

// Listen Component Reload Events
$window.on("ljComponentsReload", function (event, component) {
  if (component !== "actions") {
    return;
  }

  let $share = $("#actions .share");
  if ($share.length === 0) {
    $window.off("scroll.lesjours.actions");
    return;
  }

  let $bulletPoints = $("#bulletpoints-action");
  if ($bulletPoints.length > 0) {
    loadSharingButtons($share, $bulletPoints);
    loadBulletPointsButton($bulletPoints);
  } else {
    loadSharingButtons($share, null);
  }
});
