(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["modals/reset.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "modals/reset.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<div id=\"reset\" class=\"modal centered\" role=\"dialog\" tabindex=\"-1\">\n\t<form action=\"/session?reset\" method=\"post\" role=\"document\">\n\t\t<fieldset class=\"row row-center\">\n\t\t\t<legend class=\"col mb-2 style-meta-larger\">Créer un nouveau mot de passe</legend>\n\t\t\t<span class=\"a btn close\" data-href=\"#\">\n\t\t\t\t<span class=\"sr\">Fermer cette fenêtre</span>\n\t\t\t\t";
output += runtime.suppressValue((lineno = 7, colno = 11, runtime.callWrap(t_4, "icon", context, ["close"])), env.opts.autoescape);
output += "\n\t\t\t</span>\n\t\t\t<div class=\"col mb-2\">Merci de saisir votre nouveau mot de passe.</div>\n\t\t\t<div class=\"col mb-2\">\n\t\t\t\t<div class=\"field m-0\">\n\t\t\t\t\t<label for=\"reset-password\">Nouveau mot de passe</label>\n\t\t\t\t\t<input id=\"reset-password\" class=\"input\" name=\"password\" type=\"password\" placeholder=\"××××××××\" autocomplete=\"off\" required />\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class=\"col text-center\">\n\t\t\t\t<button class=\"btn-primary btn-lesjours\" type=\"submit\">Valider</button>\n\t\t\t</div>\n\t\t</fieldset>\n\t</form>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("modals/reset.html", ctx, cb); }
})();
