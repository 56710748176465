(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["obsession/people-modal.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "obsession/people-modal.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
if(Object.prototype.hasOwnProperty.call(t_1, "pagination")) {
var t_5 = t_1.pagination;
} else {
cb(new Error("cannot import 'pagination'")); return;
}
context.setVariable("pagination", t_5);
output += "<div id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"href"), env.opts.autoescape);
output += "\" class=\"modal animated\" role=\"dialog\" tabindex=\"-1\">\n\t<div class=\"overflow-auto animated-portrait\" role=\"document\">\n\t\t<div class=\"row m-0 sm-p-2 md-p-3 lg-p-4\">\n\t\t\t<span class=\"a btn close\" data-href=\"#\">\n\t\t\t\t<span class=\"sr\">Fermer cette fenêtre</span>\n\t\t\t\t";
output += runtime.suppressValue((lineno = 6, colno = 11, runtime.callWrap(t_4, "icon", context, ["close"])), env.opts.autoescape);
output += "\n\t\t\t</span>\n";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"video") || (runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"video")),"type") != "paysage")) {
output += "\t\t\t<div class=\"col lg-w-5c sm-p-0 md-p-0 lg-pl-0\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"video") && (runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"video")),"type") == "portrait")) {
output += "\t\t\t\t<video class=\"responsive\" ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"video")),"autoplay")) {
output += "autoplay loop muted";
;
}
else {
output += "controls";
;
}
output += " playsinline controlsList=\"nodownload\">\n";
frame = frame.push();
var t_8 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"files");
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("source", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
output += "\t\t\t\t\t\t<source type=\"";
output += runtime.suppressValue(env.getFilter("mimeType").call(context, t_9), env.opts.autoescape);
output += "\" src=\"";
output += runtime.suppressValue(t_9, env.opts.autoescape);
output += "\" />\n";
;
}
}
frame = frame.pop();
output += "\t\t\t\t</video>\n";
;
}
else {
output += "\t\t\t\t<img class=\"responsive lazyload\" data-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"img"), env.opts.autoescape);
output += "\" data-sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, ["100vw","100vw","75vw"]), env.opts.autoescape);
output += "\" data-srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"img")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"alt"))), env.opts.autoescape);
output += "\" />\n";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"credit")) {
output += "\t\t\t\t<span class=\"block pt-1 text-left credits\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"credit"), env.opts.autoescape);
output += "</span>\n";
;
}
output += "\t\t\t</div>\n";
;
}
output += "\t\t\t<div class=\"col ";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"video") || (runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"video")),"type") != "paysage")) {
output += "lg-w-7c ";
;
}
output += "pt-4 sm-ph-0 md-ph-0 lg-pr-0 lg-pt-0\">\n\t\t\t\t<div class=\"obsession-name\">\n\t\t\t\t\t<span class=\"link on-hover lj-color-text text-upper\" data-href=\"/obsessions/";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"href"), env.opts.autoescape);
output += "/\">\n\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"name"), env.opts.autoescape);
output += "\n\t\t\t\t\t</span>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"mt-1\">\n\t\t\t\t\t<span class=\"inline title color-dark\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"title"), env.opts.autoescape);
output += "</span>\n\t\t\t\t</div>\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"subtitle")) {
output += "\t\t\t\t<span class=\"mt-2 subtitle color-medium-8\">\n\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"subtitle"), env.opts.autoescape);
output += "\n\t\t\t\t</span>\n";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"video") && (runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"video")),"type") == "paysage")) {
output += "\t\t\t\t<div class=\"mt-2\">\n\t\t\t\t\t<video class=\"responsive\" ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"video")),"autoplay")) {
output += "autoplay loop muted";
;
}
else {
output += "controls";
;
}
output += " playsinline controlsList=\"nodownload\">\n";
frame = frame.push();
var t_12 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"files");
if(t_12) {t_12 = runtime.fromIterator(t_12);
var t_11 = t_12.length;
for(var t_10=0; t_10 < t_12.length; t_10++) {
var t_13 = t_12[t_10];
frame.set("source", t_13);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
output += "\t\t\t\t\t\t\t<source type=\"";
output += runtime.suppressValue(env.getFilter("mimeType").call(context, t_13), env.opts.autoescape);
output += "\" src=\"";
output += runtime.suppressValue(t_13, env.opts.autoescape);
output += "\" />\n";
;
}
}
frame = frame.pop();
output += "\t\t\t\t\t</video>\n\t\t\t\t</div>\n";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"desc")) {
output += "\t\t\t\t<div class=\"mt-2 desc color-main underlined-links\">\n\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"desc"), env.opts.autoescape);
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"authorPage")) {
output += "\t\t\t\t\t<br />\n\t\t\t\t\t<br />\n\t\t\t\t\t<a class=\"fs-italic\" href=\"/auteurs/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"authorPage")),"href"), env.opts.autoescape);
output += "/\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"authorPage")),"name"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\tRetrouvez tous les épisodes par ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"authorPage")),"name"), env.opts.autoescape);
output += ".\n\t\t\t\t\t</a>";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"metaperson")) {
output += "\t\t\t\t\t<br />\n\t\t\t\t\t<br />\n\t\t\t\t\t<a class=\"fs-italic\" href=\"/personnages/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"metaperson")),"href"), env.opts.autoescape);
output += "/\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"metaperson")),"name"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\tRetrouvez ";
output += runtime.suppressValue(((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"metaperson")),"people") > 1)?"toutes les séries":"tous les épisodes"), env.opts.autoescape);
output += " avec ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"metaperson")),"name"), env.opts.autoescape);
output += ".\n\t\t\t\t\t</a>\n";
;
}
;
}
output += "</div>\n";
;
}
if(runtime.contextOrFrameLookup(context, frame, "loop")) {
output += "\t\t\t\t<div class=\"mt-1 text-right\">\n\t\t\t\t\t";
output += runtime.suppressValue((lineno = 67, colno = 18, runtime.callWrap(t_5, "pagination", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"people"),runtime.contextOrFrameLookup(context, frame, "loop"),false])), env.opts.autoescape);
output += "\n\t\t\t\t</div>\n";
;
}
output += "\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("obsession/people-modal.html", ctx, cb); }
})();
