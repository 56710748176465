import { getDefaultKeyValueStore, KeyValueStorageTypes } from "@lesjoursfr/browser-tools";
import $ from "jquery";
import Cookies from "js-cookie";

let kStore = getDefaultKeyValueStore();

function getPlaylistData($playlist) {
  return JSON.parse($playlist.data("playlists").replace(/'/g, '"'));
}

function showPlaylistInIFrame($playlist, $target, platform, playlists) {
  // Update CSS Classes
  $playlist.removeClass(["playlist-closed", "playlist-selector", "playlist-opened"]).addClass(["playlist-opening"]);

  // Remove listeners
  $target.off("click.lesjours.playlists");

  // Check the Platform
  let iframe;
  switch (platform) {
    case "deezer":
      iframe = $("<iframe>", {
        height: "350px",
        width: "100%",
        src:
          "https://www.deezer.com/plugins/player?format=classic&autoplay=false&playlist=true&width=700&height=350&color=ff0000&layout=dark&size=medium&type=playlist&id=" +
          playlists.deezer +
          "&app_id=1",
        scrolling: "no",
        frameborder: "0",
        allowTransparency: "true",
      });
      break;
    case "spotify":
      iframe = $("<iframe>", {
        height: "350px",
        width: "100%",
        src: "https://open.spotify.com/embed/playlist/" + playlists.spotify,
        frameborder: "0",
        allowtransparency: "true",
        allow: "encrypted-media",
      });
      break;
    case "youtube":
      iframe = $("<iframe>", {
        height: "315px",
        width: "100%",
        src: "https://www.youtube.com/embed/videoseries?list=" + playlists.youtube + "&autoplay=0",
        frameborder: "0",
        allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
        allowfullscreen: true,
      });
      break;
    case "bandcamp":
      iframe = $("<iframe>", {
        height: "315px",
        width: "100%",
        src:
          "https://bandcamp.com/EmbeddedPlayer/album=" +
          playlists.bandcamp +
          "/size=large/bgcol=ffffff/linkcol=0687f5/artwork=small/transparent=true/",
        frameborder: "0",
        allowtransparency: "true",
        allow: "encrypted-media",
        seamless: true,
      });
      break;
    default:
      console.log("Wrong playlist platform : " + platform);
      iframe = $("<div>");
      break;
  }

  // Create the Button
  const button = $("<button>", {
    class: "btn-square btn-lesjours",
    text: "Écouter ailleurs",
  });
  button.one("click.lesjours.playlists", function () {
    // Show the Selector
    showSelector($playlist, $target, true);
  });

  // Add the Player
  $target.html(
    $("<div>", {
      class: "relative",
    })
      .append(button)
      .append(iframe)
  );
}

function openPlaylistUrl($playlist, $target, platform, playlists) {
  // Check the Platform
  let url;
  switch (platform) {
    case "deezer":
      url = `https://www.deezer.com/fr/playlist/${playlists.deezer}`;
      break;
    case "spotify":
      url = `https://open.spotify.com/playlist/${playlists.spotify}`;
      break;
    case "youtube":
      url = `https://www.youtube.com/playlist?list=${playlists.youtube}`;
      break;
    case "bandcamp":
      url = `https://bandcamp.com/EmbeddedPlayer/album=${playlists.bandcamp}/size=large/bgcol=ffffff/linkcol=0687f5/artwork=small/transparent=true/`;
      break;
    default:
      console.log("Wrong playlist platform : " + platform);
      return;
  }

  // Open the URL
  window.open(url);
}

function showPlaylist($playlist, $target, platform) {
  // Get Playlists
  const playlists = getPlaylistData($playlist);

  // Check if the platform is defined for the playlist
  if (playlists[platform] === undefined) {
    // Do nothing
    return;
  }

  // Check if we must show the Playlist in a iFrame or a new Tab
  if (window.LesJours.isMobileApplication === false) {
    showPlaylistInIFrame($playlist, $target, platform, playlists);
  } else {
    openPlaylistUrl($playlist, $target, platform, playlists);
  }
}

function showSelector($playlist, $target, forced) {
  // Variables
  const playlists = getPlaylistData($playlist);
  let prevChoice;
  let selector;

  // Update CSS Classes
  $playlist.removeClass(["playlist-closed", "playlist-opened"]).addClass(["playlist-opening", "playlist-selector"]);

  // Check if we are inside a Browser or a WebView
  if (window.LesJours.isMobileApplication === false) {
    // Check if we can do the migration to the new Storage
    if (kStore.type === KeyValueStorageTypes.localStorage && Cookies.get("playlistsChoice") !== undefined) {
      kStore.setItem("playlistsChoice", Cookies.get("playlistsChoice"));
      Cookies.remove("playlistsChoice");
    }

    // Check if we already know the user choice
    prevChoice = kStore.getItem("playlistsChoice");
    if (!forced && prevChoice && playlists[prevChoice] !== undefined) {
      // Show the Playlist
      return showPlaylist($playlist, $target, prevChoice);
    }
  }

  // Show the Selector
  selector = '<div class="row">';
  selector += '<div class="col">';
  selector += '<p class="text-center">Sur quelle plateforme voulez-vous écouter votre playlist ?</p>';
  selector += "</div>";
  selector += '<div class="col col-center text-center mb-2 sm-w-2c">';
  if (playlists.deezer !== undefined) {
    selector += '<span class="btn-svg btn-large btn-hover-lesjours btn-no-shadow" data-playlists-choice="deezer">';
  } else {
    selector += '<span class="btn-svg btn-large btn-disabled btn-no-shadow">';
  }
  selector += '<svg class="ui-playlist-deezer" role="img" title="Deezer" viewBox="0 0 90 90">';
  selector += '<circle cx="45" cy="45" r="45" fill="#fff"></circle>';
  selector +=
    '<path fill="#c83e2c" d="M75 35.946H62.027v7.57H75zm0-10.544H62.027v7.57H75zm0 21.082H62.027v7.57H75zM27.973 57.027H15v7.57h12.973zm15.674 0H30.674v7.57h12.973zm15.679 0H46.353v7.57h12.973zm15.674 0H62.027v7.57H75zM59.326 46.484H46.353v7.57h12.973zm-15.68 0H30.675v7.57h12.973zm0-10.538H30.675v7.57h12.973z"></path>';
  selector += "</svg>";
  selector += "</span>";
  selector += "</div>";
  selector += '<div class="col col-center text-center mb-2 sm-w-2c">';
  if (playlists.spotify !== undefined) {
    selector += '<span class="btn-svg btn-large btn-hover-lesjours btn-no-shadow" data-playlists-choice="spotify">';
  } else {
    selector += '<span class="btn-svg btn-large btn-disabled btn-no-shadow">';
  }
  selector += '<svg class="ui-playlist-spotify" role="img" title="Spotify" viewBox="0 0 90 90">';
  selector += '<circle cx="45" cy="45" r="45" fill="#fff"></circle>';
  selector +=
    '<path fill="#c83e2c" d="M62.746 41.596c-9.671-5.743-25.62-6.272-34.852-3.469a2.807 2.807 0 01-3.5-1.87 2.808 2.808 0 011.871-3.5C36.861 29.54 54.477 30.16 65.61 36.77a2.806 2.806 0 01.98 3.844 2.805 2.805 0 01-3.844.982zM62.43 50.1a2.34 2.34 0 01-3.22.77c-8.06-4.955-20.354-6.391-29.892-3.496a2.343 2.343 0 01-2.919-1.557 2.343 2.343 0 011.56-2.918c10.896-3.306 24.442-1.704 33.7 3.986a2.34 2.34 0 01.77 3.215zm-3.672 8.17a1.868 1.868 0 01-2.57.621C49.142 54.586 40.274 53.615 29.832 56a1.87 1.87 0 01-2.239-1.406A1.866 1.866 0 0129 52.356c11.428-2.613 21.23-1.49 29.138 3.343a1.87 1.87 0 01.62 2.572zM45 15c-16.568 0-30 13.43-30 30 0 16.569 13.432 30 30 30 16.569 0 30-13.431 30-30 0-16.57-13.431-30-30-30"></path>';
  selector += "</svg>";
  selector += "</span>";
  selector += "</div>";
  selector += '<div class="col col-center text-center mb-2 sm-w-2c">';
  if (playlists.youtube !== undefined) {
    selector += '<span class="btn-svg btn-large btn-hover-lesjours btn-no-shadow" data-playlists-choice="youtube">';
  } else {
    selector += '<span class="btn-svg btn-large btn-disabled btn-no-shadow">';
  }
  selector += '<svg class="ui-playlist-youtube" role="img" title="Youtube" viewBox="0 0 90 90">';
  selector += '<circle cx="45" cy="45" r="45" fill="#fff"></circle>';
  selector +=
    '<path fill="#c83e2c" d="M38.864 53.92V36.08L54.545 45zm34.882-23.455a7.535 7.535 0 00-5.305-5.339C63.762 23.864 45 23.864 45 23.864s-18.762 0-23.441 1.262a7.54 7.54 0 00-5.306 5.339C15 35.174 15 45 15 45s0 9.826 1.253 14.535a7.544 7.544 0 005.306 5.34C26.238 66.136 45 66.136 45 66.136s18.762 0 23.441-1.26a7.54 7.54 0 005.305-5.34C75 54.825 75 45 75 45s0-9.826-1.254-14.535"></path>';
  selector += "</svg>";
  selector += "</span>";
  selector += "</div>";
  selector += '<div class="col col-center text-center mb-2 sm-w-2c">';
  if (playlists.bandcamp !== undefined) {
    selector += '<span class="btn-svg btn-large btn-hover-lesjours btn-no-shadow" data-playlists-choice="bandcamp">';
  } else {
    selector += '<span class="btn-svg btn-large btn-disabled btn-no-shadow">';
  }
  selector += '<svg class="ui-playlist-bandcamp" role="img" title="Bandcamp" viewBox="0 0 90 90">';
  selector += '<circle cx="45" cy="45" r="45" fill="#fff"></circle>';
  selector +=
    '<path fill="#c83e2c" d="M35.381 56.756H7.5l13.02-24.034h27.881l-13.02 24.034m43.402-11.552c-.294-2.125-1.793-3.273-3.848-3.273-1.925 0-4.63 1.046-4.63 6.342 0 2.901 1.238 5.972 4.467 5.972 2.152 0 3.651-1.486 4.01-3.982H82.5c-.685 4.52-3.391 7.015-7.728 7.015-5.283 0-8.185-3.878-8.185-9.005 0-5.262 2.772-9.377 8.315-9.377 3.913 0 7.24 2.024 7.598 6.308h-3.717M56.692 41.93c-3.129 0-4.728 2.464-4.728 6.174 0 3.507 1.729 6.14 4.728 6.14 3.392 0 4.664-3.104 4.664-6.14-.003-3.17-1.601-6.174-4.665-6.174m-8.313-9.208h3.717v8.904h.065c1.011-1.686 3.13-2.732 5.022-2.732 5.314 0 7.892 4.185 7.892 9.279 0 4.687-2.283 9.105-7.273 9.105-2.283 0-4.728-.571-5.837-2.866H51.9v2.396h-3.523V32.722"></path>';
  selector += "</svg>";
  selector += "</span>";
  selector += "</div>";
  selector += '<div class="w-100 mb-2 fs-italic text-center">';
  selector += "Certains titres peuvent être indisponibles sur certaines plateformes.";
  selector += "<br />";
  selector += "En sélectionnant une plateforme vous autorisez le dépôt de cookies par celle-ci.";
  selector += "</div>";
  selector += "</div>";
  $target.html(selector);

  // Listen the User Choice
  $target.on("click.lesjours.playlists", "[data-playlists-choice]", null, function (event) {
    // Get the User Choice
    const playlistsChoice = $(event.currentTarget).data("playlistsChoice");

    // Check if we are inside a Browser or a WebView
    if (window.LesJours.isMobileApplication === false) {
      // Update the cookie
      kStore.setItem("playlistsChoice", playlistsChoice);
    }

    // Show the Playlist
    showPlaylist($playlist, $target, playlistsChoice);

    // Trigger an event
    $(window).trigger("playlistsChoice", playlistsChoice);
  });
}

function closePlaylist($playlist, $target) {
  // Update CSS Classes
  $playlist.removeClass(["playlist-opening", "playlist-selector", "playlist-opened"]).addClass(["playlist-closed"]);

  // Remove listeners
  $target.off("click.lesjours.playlists");

  // Close the Playlist
  $target.html("");
}

function open($playlist, $target) {
  // Show the Selector
  showSelector($playlist, $target, false);
}

function close($playlist, $target) {
  // Close the Playlist
  closePlaylist($playlist, $target);
}

// Autoload some playlists
$("html")
  .find(".playlist.playlist-closed")
  .filter("[data-playlists-autoload=true]")
  .each(function (idx, el) {
    // Show the Selector
    const $el = $(el);
    showSelector($el, $el.find(".playlist-holder"), false);
  });

// Listen selection events
$(window).on("playlistsChoice", function (event, playlistsChoice) {
  // Load playlists on the selector
  $("html")
    .find(".playlist.playlist-selector")
    .each(function (idx, el) {
      // Show the Playlist
      const $el = $(el);
      showPlaylist($el, $el.find(".playlist-holder"), playlistsChoice);
    });
});

// Export some functions
export { close, open };
