(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["mes-jours/actions/progress-bar.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"h-100 lj-color-bg\" style=\"width: ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "progress"), env.opts.autoescape);
output += "%;\"></div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("mes-jours/actions/progress-bar.html", ctx, cb); }
})();
