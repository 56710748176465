/* globals Modernizr:true, _mtm:true */
import { showGdprBanner } from "./libs/consent";
import Cookies from "js-cookie";
import $ from "jquery";
import throttle from "lodash/throttle";
import "hammerjs";
import "@popperjs/core";
import lazySizes from "lazysizes";
import "./libs/lazysizes.svg4everybody";
import nunjucks from "./libs/nunjucks";
import "./libs/datepicker";
import * as Playlists from "./libs/playlists";
import * as MesJours from "./libs/mesjours";
import * as LesJoursCTAs from "./libs/lesjours-ctas";
import { configureAnalytics } from "./libs/analytics";
import { configureTagManager } from "./libs/tagmanager";
import "./libs/swg";
import "./libs/swg.gaa";
import { requirePageJs } from "./pages/pages.website";
import MastodonShareButtons from "@lesjoursfr/mastodon-share-button";
import mastodonShareButtonsFr from "@lesjoursfr/mastodon-share-button/src/lang/fr";
import { updateObsessionPageViews, updateUtmSourcesList, updatePageViews } from "./libs/utm-pageviews.js";

// LazySizes configuration
lazySizes.cfg.loadMode = 1; // load also very near view elements
lazySizes.cfg.loadHidden = false; // don't load visibility: hidden; elements
lazySizes.cfg.ricTimeout = 300; // timeout option used for the requestIdleCallback

// Attach jQuery, lodash, Cookies, nunjucks, Playlists, MesJours & LesJoursCTAs to the window
window.$ = $;
window.jQuery = $;
window._ = { throttle: throttle };
window.Cookies = Cookies;
window.nunjucks = nunjucks;
window.Playlists = Playlists;
window.MesJours = MesJours;
window.LesJoursCTAs = LesJoursCTAs;

// Require libraries
require("./vendors/modernizr.js");
require("./libs/jquery.triggerOnLoad.js");
require("./libs/jquery.hammer.js");
require("./libs/jquery.popper.js");
require("./libs/jquery.scroll-to-anchor.js");
require("./libs/jquery.serializejson.js");
require("./libs/jquery.isonscreen.js");
require("./libs/jquery.reduce.js");
require("./libs/jquery.dataloader.js");
require("./libs/jquery.ios-webview.js");
require("./libs/playlists.js");
require("./libs/iframeResizer.js");
require("./libs/lesjours-features.js");
function importAll(r) {
  r.keys().forEach(r);
}
importAll(require.context("./tpl-compiled/website/", true, /\.js$/));
require("./components/actions.js");
require("./components/autodisplay.js");
require("./components/autohide.js");
require("./components/cmslink.js");
require("./components/collapse.js");
require("./components/comparison.js");
require("./components/copy.js");
require("./components/donorbox.js");
require("./components/image.js");
require("./components/input.js");
require("./components/link.js");
require("./components/menu.js");
require("./components/mini.js");
require("./components/modal.js");
require("./components/onboarding.js");
require("./components/player.js");
require("./components/push.js");
require("./components/rightclick.js");
require("./components/sign-in-with.js");
require("./components/slider.js");
require("./components/slideshow.js");
require("./components/tabs.js");
require("./components/theme.js");

async function getAccountData() {
  // Check if we have to fetch the account information from the website
  if (window.LesJours !== undefined && window.LesJours.account !== undefined) {
    // Return the preloaded data
    return window.LesJours.account;
  }

  // Fetch the data from the server
  window.LesJours.account = await $.ajax({ method: "GET", url: "/account.json", cache: false, dataType: "json" });
  return window.LesJours.account;
}

$(function () {
  "use strict";

  // Show the GDPR Banner
  showGdprBanner();

  // Add a js class to the body
  $(document.body).addClass("js");

  // Update the number of page views
  let numberOfPageViews = updatePageViews();

  // Get the user category
  let visitorCategory;
  if (numberOfPageViews < 10) {
    visitorCategory = "decouverte";
  } else if (numberOfPageViews < 30) {
    visitorCategory = "regulier";
  } else {
    visitorCategory = "habitue";
  }

  // Send the values to the datalayer
  _mtm.push({ numberOfPageViews: numberOfPageViews, visitorCategory: visitorCategory });

  // Disable scrollRestoration feature of History in Webkit Browsers
  if (history !== undefined && "scrollRestoration" in history) {
    // Back off, browser, I got this...
    history.scrollRestoration = "manual";
  }

  // Get the Account Data
  getAccountData().then((account) => {
    // Initialize Analytics, TagManager & MesJours
    configureAnalytics(account);
    configureTagManager(account);
    MesJours.configureMesJours(account);

    // Send an event with the Account data
    $(window).trigger("ljUserAccount", account);
  });

  // Refresh Cookies
  updateUtmSourcesList();
  updateObsessionPageViews();

  // Load the Page code
  requirePageJs((window.LesJours || {}).pageType);

  // Check if we need to load the colors.css file
  Modernizr.on("customproperties", function (isBrowserSupportCssVariables) {
    if (!isBrowserSupportCssVariables) {
      $("<link/>", {
        rel: "stylesheet",
        href: "/css/colors." + (window.LesJours || {}).version + ".min.css",
      }).appendTo(document.head);
    }
  });

  // Load Mastodon share buttons
  window.MastodonShareButtons = new MastodonShareButtons({
    lang: mastodonShareButtonsFr,
    saveInstance: true,
  });
});
