/* eslint camelcase: "off" */
/* globals _paq:true */
import $ from "jquery";

const paramsToRemove = [
  "sponsorship",
  "hash",
  "mail",
  "key",
  "sponsorship-email",
  "inspect",
  "offer-code",
  "offer-user",
  "delete-mail",
  "delete-code",
  "IDENTIFIER",
  "OPERATIONTYPE",
  "TRANSACTIONID",
  "CLIENTIDENT",
  "CLIENTEMAIL",
  "ORDERID",
  "VERSION",
  "LANGUAGE",
  "CURRENCY",
  "EXTRADATA",
  "CARDCODE",
  "CARDCOUNTRY",
  "EXECCODE",
  "MESSAGE",
  "DESCRIPTOR",
  "ALIAS",
  "3DSECURE",
  "AMOUNT",
  "HASH",
];

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// UTILITIES                                                                                                          //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Clean Get Parameters in URL
function cleanGetParameters(search) {
  let params = search.replace("?", "&");
  let i;

  // Iterate through Parameters
  for (i = 0; i < paramsToRemove.length; i++) {
    // Replace the Parameter
    params = params.replace(new RegExp("&" + paramsToRemove[i] + "=[^&]*(&|$)", "gi"), "&");
  }

  // Check if the First Parameter is &
  if (params[0] === "&") {
    // Remove It
    params = params.slice(1);
  }

  // Return the Cleaned Search String
  return params.length > 0 ? "?" + params : "";
}

// Create a Function with a Timeout
function createFunctionWithTimeout(callback, opt_timeout) {
  let called = false;
  function fn() {
    if (!called) {
      called = true;
      callback();
    }
  }
  setTimeout(fn, opt_timeout || 1000);
  return fn;
}

// Get the window location
function getLocation() {
  const locationAlias = window.location;

  if (!locationAlias.origin) {
    locationAlias.origin =
      locationAlias.protocol + "//" + locationAlias.hostname + (locationAlias.port ? ":" + locationAlias.port : "");
  }

  return locationAlias;
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// AUTOMATIC EVENTS                                                                                                   //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
let sendEventsToMatomo = false;

// Process MouseUp events for Matomo Events Tracking
function processMouseUpEvent(event) {
  // Get the Event Data
  const $evtElement = $(event.currentTarget);
  const evtData = $evtElement.data();
  let blankClick = false;

  // Test if it's a Mouse Click Event (Left & Middle)
  if (event.which !== 1 && event.which !== 2) {
    return null;
  }

  // Don't Process "_blank" Click on Form Buttons
  if (
    event.which === 2 &&
    $evtElement.get(0).tagName.toLowerCase() === "button" &&
    $evtElement.attr("type") === "submit"
  ) {
    return null;
  }

  // Check if it's a "_blank" Click
  if (event.which === 2 || event.ctrlKey === true || event.metaKey === true) {
    blankClick = true;
  }

  // Test if There is all Required Fields
  if (evtData.mtmCategory === undefined || evtData.mtmAction === undefined) {
    return null;
  }

  // Check if we already have process this Event
  if (event.ljMTMAlreadyProcessed) {
    return null;
  }
  event.ljMTMAlreadyProcessed = true;

  // Create the Matomo Event
  const mtmEvent = {};
  mtmEvent.category = evtData.mtmCategory;
  mtmEvent.action = evtData.mtmAction;
  if (evtData.mtmLabel !== undefined) {
    mtmEvent.label = evtData.mtmLabel;
  }
  if (evtData.mtmValue !== undefined) {
    mtmEvent.value = evtData.mtmValue;
  }

  // Check if it's an Inbound Link
  if (
    !blankClick &&
    $evtElement.get(0).tagName.toLowerCase() === "a" &&
    $evtElement.attr("target") !== "_blank" &&
    !$evtElement.attr("href").match(/^#/)
  ) {
    // Check if we must follow the link after
    if (evtData.mtmNofollow === undefined) {
      // Delay the Click to Allow Event Sending
      event.preventDefault();
      mtmEvent.callback = createFunctionWithTimeout(function () {
        window.location.href = $evtElement.attr("href");
      }, 1000);
    }
  } else if (
    $evtElement.get(0).tagName.toLowerCase() === "button" &&
    $evtElement.parents("form").eq(0).attr("target") !== "_blank" &&
    $evtElement.attr("type") === "submit"
  ) {
    // Delay the Click to Allow Event Sending
    event.preventDefault();
    mtmEvent.callback = createFunctionWithTimeout(function () {
      $evtElement.parents("form").eq(0).trigger("submit");
    }, 1000);
  }

  // Return the Matomo Event
  return mtmEvent;
}

export function sendMatomoTrackEvent(mtmEvent) {
  // Test if Matomo is Loaded & if we can send Events for the User
  if (typeof _paq === "undefined" || sendEventsToMatomo === false) {
    // We can't send the Event so log it
    console.log(mtmEvent);
    return;
  }

  // Send Matomo Event : ['trackEvent', category, action, name, value, customData, callback]
  _paq.push(["trackEvent", mtmEvent.category, mtmEvent.action, mtmEvent.label, mtmEvent.value, mtmEvent.callback]);
}

// Activate the Automatic Event Tracking
function activateEventTracking() {
  // Listen Clicks Events on Matomo Events Elements
  $(document.body).on("mouseup.lesjours.analytics", '[data-mtm-type="click"]', (event) => {
    // Process the MouseUp event
    let mtmEvent = processMouseUpEvent(event);
    if (mtmEvent !== null) {
      // Call the Callback with the Matomo Event
      sendMatomoTrackEvent(mtmEvent);
    }
  });
}
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// CONVERSIONS                                                                                                        //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function sendConversion($mtmConversion) {
  // Get the window location
  const currentLocation = getLocation();

  // Get Conversion data
  const conversionData = $mtmConversion.data("mtmConversion");

  // Check if we have a Category
  if (conversionData.category === undefined) {
    // We need a Category, there is nothing we can do without it.
    return;
  }

  // Check if we have to remove sensitive parameters from the URL
  if (currentLocation.search && currentLocation.search !== "?") {
    // Clean the Page URL
    _paq.push([
      "setCustomUrl",
      currentLocation.origin + currentLocation.pathname + cleanGetParameters(currentLocation.search),
    ]);
  }

  // Send a PageView Event
  _paq.push(["trackPageView"]);

  // Send Matomo Event : ['trackEvent', category, action, name, value, customData, callback]
  _paq.push(["trackEvent", conversionData.category, conversionData.action, conversionData.label]);
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// INTERNAL SEARCH TRACKING                                                                                           //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function sendInternalSearchTracking($mtmSearchMeta) {
  // Get Site Search data
  const mtmSearchMetaData = $mtmSearchMeta.data("mtmSearch");

  // Check if we have a Keyword
  if (mtmSearchMetaData.keyword === undefined) {
    // We need a Keyword, there is nothing we can do without it.
    return;
  }

  // Send Matomo Site Search : ['trackSiteSearch', keyword, category, numberOfResults]
  _paq.push(["trackSiteSearch", mtmSearchMetaData.keyword, mtmSearchMetaData.category, mtmSearchMetaData.results]);
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// CONTENT TRACKING                                                                                                   //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function trackContentImpressionsFor(node) {
  // Test if Matomo is Loaded to Avoid Errors
  if (typeof _paq === "undefined") {
    // The Matomo library is missing so we can't track Content Impressions.
    return;
  }

  // Activate the Content Tracking for the node
  _paq.push(["trackContentImpressionsWithinNode", node]);

  // Check if the node has a form
  const $form = $(node).find("form");
  if ($form.length > 0) {
    $form.on("submit.lesjours.analytics", function () {
      _paq.push(["trackContentInteractionNode", this, "submittedForm"]);
    });
  }
}

export function trackContentImpression(mtmContent) {
  // Test if Matomo is Loaded to Avoid Errors
  if (typeof _paq === "undefined") {
    // The Matomo library is missing so we can't track Content Impressions.
    return;
  }

  // Track the content impression
  _paq.push(["trackContentImpression", mtmContent.name, mtmContent.piece, mtmContent.target]);
}

export function trackContentInteraction(mtmContent) {
  // Test if Matomo is Loaded to Avoid Errors
  if (typeof _paq === "undefined") {
    // The Matomo library is missing so we can't track Content Impressions.
    return;
  }

  // Track the content interaction
  _paq.push(["trackContentInteraction", mtmContent.interaction, mtmContent.name, mtmContent.piece, mtmContent.target]);
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// CONFIGURATION                                                                                                      //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function configureAnalytics(account) {
  // Test if Matomo is Loaded to Avoid Errors
  if (typeof _paq === "undefined") {
    // The Matomo library is missing so we can't configure Matomo.
    return;
  }

  // Check if there is a Conversion Tag
  const $mtmConversion = $("meta[data-mtm-conversion]");
  if ($mtmConversion.length > 0) {
    // Send the Conversion Event
    sendConversion($mtmConversion.first());

    // Activate Matomo Content Tracking
    _paq.push(["trackVisibleContentImpressions"]);

    // Don't configure Matomo
    return;
  }

  // Check if we have to configure Matomo
  if (account.gaconfig === undefined) {
    return; // We don't have a GA Configuration to configure Matomo
  } else if (account.user !== undefined && account.user.employee === true) {
    activateEventTracking(); // Activate the Automatic Event Tracking
    return; // Matomo is disabled for employee account
  }

  // Get the window location
  const currentLocation = getLocation();

  // Setup Matomo Dimensions
  if (account.gaconfig.dims.lj_user_type !== undefined) {
    _paq.push(["setCustomDimension", 1, account.gaconfig.dims.lj_user_type]);
  }
  if (account.gaconfig.dims.lj_user_plan !== undefined) {
    _paq.push(["setCustomDimension", 2, account.gaconfig.dims.lj_user_plan]);
  }
  if (account.gaconfig.dims.lj_key_account !== undefined) {
    _paq.push(["setCustomDimension", 3, account.gaconfig.dims.lj_key_account]);
  }

  // Check if we have to remove sensitive parameters from the URL
  if (currentLocation.search && currentLocation.search !== "?") {
    // Clean the Page URL
    _paq.push([
      "setCustomUrl",
      currentLocation.origin + currentLocation.pathname + cleanGetParameters(currentLocation.search),
    ]);
  }

  /*
   * In your website, in standard pages, you would typically have a call to record Page views via matomoTracker.trackPageView().
   * On your search result page, you would call instead piwikTracker.trackSiteSearch(keyword, category, searchCount) function
   * to record the internal search request.
   * Note: the 'keyword' parameter is required, but category and searchCount are optional.
   * https://developer.matomo.org/guides/tracking-javascript-guide#internal-search-tracking
   */
  const $mtmSearchMeta = $("meta[data-mtm-search]");
  if ($mtmSearchMeta.length > 0) {
    // Send an SearchTracking Event
    sendInternalSearchTracking($mtmSearchMeta.first());
  } else {
    // Send a PageView Event
    _paq.push(["trackPageView"]);
  }

  // Activate Matomo Content Tracking
  _paq.push(["trackVisibleContentImpressions"]);

  // Activate the Automatic Event Tracking
  sendEventsToMatomo = true;
  activateEventTracking();
}
