(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["ctas/home-subscription.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n<div id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ctaId"), env.opts.autoescape);
output += "\" class=\"cta bg-lightest";
if(runtime.contextOrFrameLookup(context, frame, "hidden")) {
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "hidden");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("device", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += " ";
output += runtime.suppressValue(t_4, env.opts.autoescape);
output += "-hidden";
;
}
}
frame = frame.pop();
;
}
output += "\"";
if(runtime.contextOrFrameLookup(context, frame, "mtmContentPiece")) {
output += " data-track-content data-content-name=\"CTA - Home abonnement\" data-content-piece=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mtmContentPiece"), env.opts.autoescape);
output += "\"";
;
}
output += ">\n";
if(runtime.contextOrFrameLookup(context, frame, "colors")) {
output += "\t<style>\n\t\t#";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ctaId"), env.opts.autoescape);
output += " .cta-custom-bg {\n\t\t\tbackground-color: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"bgColor"), env.opts.autoescape);
output += ";\n\t\t\tcolor: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"textOnBgColor"), env.opts.autoescape);
output += ";\n\t\t}\n\n\t\t#";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ctaId"), env.opts.autoescape);
output += " .cta-custom-light-bg {\n\t\t\tbackground-color: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"lightBgColor"), env.opts.autoescape);
output += ";\n\t\t\tcolor: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"bgColor"), env.opts.autoescape);
output += ";\n\t\t}\n\n\t\t#";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ctaId"), env.opts.autoescape);
output += " .cta-custom-text {\n\t\t\tcolor: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"bgColor"), env.opts.autoescape);
output += ";\n\t\t}\n\n\t\t#";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ctaId"), env.opts.autoescape);
output += " .cta-pastille {\n\t\t\tbackground-color: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"textOnBgColor"), env.opts.autoescape);
output += " !important;\n\t\t\tcolor: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"bgColor"), env.opts.autoescape);
output += " !important;\n\t\t}\n\t</style>\n";
;
}
output += "\t<div class=\"pv-2 ph-1 ";
if(runtime.contextOrFrameLookup(context, frame, "colors") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "colors")),"lightBgColor")) {
output += "cta-custom-light-bg";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "colors")) {
output += "cta-custom-text";
;
}
else {
output += "bg-lesjours-light color-lesjours";
;
}
;
}
output += "\">\n\t\t<a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
output += "\" title=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.contextOrFrameLookup(context, frame, "title"))), env.opts.autoescape);
output += "\" class=\"block cta-link h4 mb-0 text-center\"";
if(runtime.contextOrFrameLookup(context, frame, "mtmContentPiece")) {
output += " data-content-target=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
output += "\"";
;
}
output += ">\n\t\t\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
output += "\n\t\t</a>\n\t</div>\n\t<div class=\"pv-2 ph-1 ";
if(runtime.contextOrFrameLookup(context, frame, "colors")) {
output += "cta-custom-bg";
;
}
else {
output += "bg-lesjours color-white";
;
}
output += "\">\n\t\t<div class=\"homepage-container\">\n\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
output += "\" title=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.contextOrFrameLookup(context, frame, "title"))), env.opts.autoescape);
output += "\" class=\"block cta-link cta-promo h5 mb-0 flex row-justify row-center\"";
if(runtime.contextOrFrameLookup(context, frame, "mtmContentPiece")) {
output += " data-content-target=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
output += "\"";
;
}
output += ">\n\t\t\t\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "promo"), env.opts.autoescape);
output += "\n\t\t\t</a>\n\t\t</div>\n\t</div>\n\t<div class=\"cta-note p-1 text-center\">\n\t\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "note"), env.opts.autoescape);
output += "\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("ctas/home-subscription.html", ctx, cb); }
})();
