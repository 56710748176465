/* globals Modernizr:true */
import Cookies from "js-cookie";
import $ from "jquery";

// Constants
const $html = $("html");
const $body = $(document.body);
const $window = $(window);
const CookiesKey = "theme";
const Themes = Object.freeze({
  light: "light",
  dark: "dark",
});

// Variables
let initialized = false;

// Get the current Theme
function getCurrentTheme() {
  return Cookies.get(CookiesKey) === Themes.dark ? Themes.dark : Themes.light;
}

// Get the opposite theme
function oppositeTheme(theme) {
  return theme === Themes.dark ? Themes.light : Themes.dark;
}

// Reset the UI to Show/Hide the Notification Button
function resetUI() {
  // Get the Current Theme
  const theme = getCurrentTheme();

  // Get Theme Buttons
  const $themeBtns = $(".js-toggle-theme");

  // Hide all Buttons
  $themeBtns.children().addClass("hidden");

  // Show the Correct Button
  $themeBtns.find(`[data-toggle-theme="${oppositeTheme(theme)}"]`).removeClass("hidden");
}

// Theme Buttons Click Listener
function toogleTheme(e) {
  // Prevent Default
  e.preventDefault();

  // Get the new theme & the old one
  const theme = $(e.currentTarget).data("toggleTheme");

  // Update the theme
  Cookies.set(CookiesKey, theme, { expires: 13 * 31 });

  // Activate CSS Animation
  $html.addClass(`color-theme-in-transition lj-${theme}-theme`).removeClass(`lj-${oppositeTheme(theme)}-theme`);
  setTimeout(function () {
    // Desactivate CSS Animation
    $html.removeClass("color-theme-in-transition");
  }, 750);

  // Reset the UI
  resetUI();
}

// Initialize Theme
function initTheme() {
  // Initialize the UI
  resetUI();

  // Listen Clicks on Buttons
  $body.on("click.lesjours.theme", ".js-toggle-theme [data-toggle-theme]", toogleTheme);

  // Update flag
  initialized = true;
}

// Check the browser support CSS Variables
Modernizr.on("customproperties", function (isBrowserSupportCssVariables) {
  if (isBrowserSupportCssVariables) {
    initTheme();
  }
});

// Listen burger menu events
$window.on("ljBurgerEvent", function (event, action) {
  // Check if the burger menu has been opened
  if (action !== "opened") {
    return;
  }

  // Check if the module is initialized
  if (initialized !== true) {
    return;
  }

  // Get the Current Theme
  resetUI();
});
