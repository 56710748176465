(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["articles/episode.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n";
env.getTemplate("_macro.html", false, "articles/episode.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "mesJoursArticle")) {
var t_4 = t_1.mesJoursArticle;
} else {
cb(new Error("cannot import 'mesJoursArticle'")); return;
}
context.setVariable("mesJoursArticle", t_4);
var t_5;
t_5 = (runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"type") === "portrait");
frame.set("hasAnImageInPortrait", t_5, true);
if(frame.topLevel) {
context.setVariable("hasAnImageInPortrait", t_5);
}
if(frame.topLevel) {
context.addExport("hasAnImageInPortrait", t_5);
}
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"isOnHome") === true) && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"home")),"image")) {
var t_6;
t_6 = false;
frame.set("hasAnImageInPortrait", t_6, true);
if(frame.topLevel) {
context.setVariable("hasAnImageInPortrait", t_6);
}
if(frame.topLevel) {
context.addExport("hasAnImageInPortrait", t_6);
}
;
}
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") !== "small") && (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") !== "inline")) {
output += "<div class=\"article row article-episode article-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type"), env.opts.autoescape);
output += " lj-color-";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"obsession")),"color")),"id"), env.opts.autoescape);
output += "\">\n";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"type") === "sans-image") {
output += "\t<div class=\"col ";
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") !== "list")) {
output += "w-100";
;
}
else {
output += "sm-w-100 md-w-50 lg-w-50";
;
}
output += " image-quote\">\n\t\t<div class=\"relative w-100 h-100\" data-href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"href"), env.opts.autoescape);
output += "\">\n\t\t\t<div class=\"overlay lj-color-bg alpha-10\"></div>\n\t\t\t<div class=\"row h-100 m-0 ph-3 row-center row-middle article-quote\">\n\t\t\t\t<span class=\"col p-0 text-center\">";
output += runtime.suppressValue((lineno = 31, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icon"), "icon", context, ["quote"])), env.opts.autoescape);
output += "</span>\n\t\t\t\t<span class=\"col p-0 mt-2 lj-color-text text-center fs-italic quote-text\">«&nbsp;";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"quote")),"text"), env.opts.autoescape);
output += "&nbsp;»</span>\n";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"quote")),"author")) {
output += "\t\t\t\t<span class=\"col p-0 mt-1 color-medium-6 text-center quote-author\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"quote")),"author"), env.opts.autoescape);
output += "</span>\n";
;
}
output += "\t\t\t</div>\n\t\t\t<div class=\"progress-bar js-progress-bar w-100\" data-paragraphs=\"";
output += runtime.suppressValue(env.getFilter("getParagraphs").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")), env.opts.autoescape);
output += "\" data-obsession=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"obsession")),"href"), env.opts.autoescape);
output += "\" data-episode=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"href"), env.opts.autoescape);
output += "\">\n\t\t\t</div>\n\t\t</div>\n";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"visibility") === "visible") {
output += "\t\t<div class=\"free-label color-white bg-lesjours p-1";
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") === "slider") || (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") === "master")) {
output += " small";
;
}
output += "\">\n\t\t\taccès libre\n\t\t</div>\n";
;
}
output += "\t</div>\n";
;
}
else {
output += "\t<div class=\"col ";
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") !== "list")) {
output += "w-100";
;
}
else {
output += "sm-w-100 md-w-50 lg-w-50";
;
}
output += " article-image";
if(runtime.contextOrFrameLookup(context, frame, "hasAnImageInPortrait")) {
output += " image-portrait";
;
}
output += "\">\n\t\t<div class=\"w-100 h-100 relative\" data-href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"href"), env.opts.autoescape);
output += "\">\n";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"video")),"files") && (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") === "large")) {
output += "\t\t\t<figure class=\"block h-100 no-overflow\">\n\t\t\t\t<video class=\"h-100\" alt=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"video")),"title"), env.opts.autoescape);
output += "\" autoplay loop muted playsinline>\n";
frame = frame.push();
var t_9 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"video")),"files");
if(t_9) {t_9 = runtime.fromIterator(t_9);
var t_8 = t_9.length;
for(var t_7=0; t_7 < t_9.length; t_7++) {
var t_10 = t_9[t_7];
frame.set("source", t_10);
frame.set("loop.index", t_7 + 1);
frame.set("loop.index0", t_7);
frame.set("loop.revindex", t_8 - t_7);
frame.set("loop.revindex0", t_8 - t_7 - 1);
frame.set("loop.first", t_7 === 0);
frame.set("loop.last", t_7 === t_8 - 1);
frame.set("loop.length", t_8);
output += "\t\t\t\t\t<source type=\"";
output += runtime.suppressValue(env.getFilter("mimeType").call(context, t_10), env.opts.autoescape);
output += "\" src=\"";
output += runtime.suppressValue(t_10, env.opts.autoescape);
output += "\" />\n";
;
}
}
frame = frame.pop();
output += "\t\t\t\t</video>\n\t\t\t</figure>\n";
;
}
else {
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"image")),"files") && (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") === "large")) {
output += "\t\t\t<div class=\"slideshow pause h-100\" data-duration=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"image")),"duration"), env.opts.autoescape);
output += "\" data-autoplay=\"true\" data-animation=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"image")),"animation"), env.opts.autoescape);
output += "\">\n\t\t\t\t<div class=\"slideshow-container h-100\">\n";
frame = frame.push();
var t_13 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"image")),"files");
if(t_13) {t_13 = runtime.fromIterator(t_13);
var t_12 = t_13.length;
for(var t_11=0; t_11 < t_13.length; t_11++) {
var t_14 = t_13[t_11];
frame.set("file", t_14);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
output += "\t\t\t\t\t<div class=\"slideshow-slide";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0") === 0) {
output += " sl-current";
;
}
output += "\" data-timing=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"image")),"timings")),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0")), env.opts.autoescape);
output += "\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"lazyload")) {
output += "\t\t\t\t\t\t<img class=\"responsive h-100 lazyload\" src=\"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7\" data-src=\"";
output += runtime.suppressValue(t_14, env.opts.autoescape);
output += "\" data-sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"sizes")), env.opts.autoescape);
output += "\" data-srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, t_14), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"title"))), env.opts.autoescape);
output += "\" />\n";
;
}
else {
output += "\t\t\t\t\t\t<img class=\"responsive h-100\" src=\"";
output += runtime.suppressValue(t_14, env.opts.autoescape);
output += "\" sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"sizes")), env.opts.autoescape);
output += "\" srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, t_14), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"title"))), env.opts.autoescape);
output += "\" />\n";
;
}
output += "\t\t\t\t\t</div>\n";
;
}
}
frame = frame.pop();
output += "\t\t\t\t</div>\n\t\t\t</div>\n";
;
}
else {
var t_15;
t_15 = (runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"image")),"files")?runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"image")),"files")),0):runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"image")),"img"));
frame.set("articleImage", t_15, true);
if(frame.topLevel) {
context.setVariable("articleImage", t_15);
}
if(frame.topLevel) {
context.addExport("articleImage", t_15);
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"isOnHome") === true) {
var t_16;
t_16 = env.getFilter("default").call(context, runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"home")),"image"),runtime.contextOrFrameLookup(context, frame, "articleImage"));
frame.set("articleImage", t_16, true);
if(frame.topLevel) {
context.setVariable("articleImage", t_16);
}
if(frame.topLevel) {
context.addExport("articleImage", t_16);
}
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"lazyload")) {
output += "\t\t\t<img class=\"responsive h-100 lazyload\" src=\"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7\" data-src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "articleImage"), env.opts.autoescape);
output += "\" data-sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"sizes")), env.opts.autoescape);
output += "\" data-srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.contextOrFrameLookup(context, frame, "articleImage")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"title"))), env.opts.autoescape);
output += "\" />\n";
;
}
else {
output += "\t\t\t<img class=\"responsive h-100\" src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "articleImage"), env.opts.autoescape);
output += "\" sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"sizes")), env.opts.autoescape);
output += "\" srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.contextOrFrameLookup(context, frame, "articleImage")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"title"))), env.opts.autoescape);
output += "\" />\n";
;
}
;
}
;
}
output += "\t\t\t<div class=\"progress-bar js-progress-bar w-100\" data-paragraphs=\"";
output += runtime.suppressValue(env.getFilter("getParagraphs").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")), env.opts.autoescape);
output += "\" data-obsession=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"obsession")),"href"), env.opts.autoescape);
output += "\" data-episode=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"href"), env.opts.autoescape);
output += "\">\n\t\t\t</div>\n\t\t</div>\n";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"visibility") === "visible") {
output += "\t\t<div class=\"free-label color-white bg-lesjours p-1";
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") === "slider") || (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") === "master")) {
output += " small";
;
}
output += "\">\n\t\t\taccès libre\n\t\t</div>\n";
;
}
output += "\t</div>\n";
;
}
output += "\t<div class=\"col ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") === "list") {
output += "sm-w-100 md-w-50";
;
}
else {
output += "w-100";
;
}
output += " article-content row m-0 row-vertical\">\n";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"hideObsession")) {
output += "\t\t<div class=\"row mh-0 ";
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") !== "list")) {
output += "mt-2";
;
}
else {
output += "sm-mt-2";
;
}
output += " row-center no-wrap obsession-name\">\n\t\t\t<a class=\"link on-hover lj-color-text text-upper\" href=\"/obsessions/";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"obsession")),"href"), env.opts.autoescape);
output += "/\">\n\t\t\t\t";
output += runtime.suppressValue(env.getFilter("removeBR").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"obsession")),"title")), env.opts.autoescape);
output += "\n\t\t\t</a>\n\t\t</div>\n";
;
}
output += "\t\t<div class=\"";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"hideObsession")) {
output += "mt-2";
;
}
else {
output += "mt-1";
;
}
output += "\">\n";
var t_17;
t_17 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"titleTag")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"titleTag"):"span");
frame.set("titleTag", t_17, true);
if(frame.topLevel) {
context.setVariable("titleTag", t_17);
}
if(frame.topLevel) {
context.addExport("titleTag", t_17);
}
output += "\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"href"), env.opts.autoescape);
output += "\">\n\t\t\t\t<";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "titleTag"), env.opts.autoescape);
output += " class=\"inline title link on-hover color-dark\">\n\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("removeBR").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"title")), env.opts.autoescape);
output += "\n\t\t\t\t</";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "titleTag"), env.opts.autoescape);
output += ">\n\t\t\t</a>\n\t\t</div>\n\t\t<div class=\"mt-1 subtitle color-medium-8\">\n\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"subtitle"), env.opts.autoescape);
output += "\n\t\t</div>\n\t\t<div class=\"row row-space row-vertical infos\">\n\t\t\t<div class=\"col flex-1 ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") === "slider") {
output += "mt-1";
;
}
else {
output += "sm-mt-1 mt-2";
;
}
output += "\">\n";
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"showDate"))) {
output += "\t\t\t\t<span class=\"w-auto color-dark\">";
output += runtime.suppressValue(env.getFilter("humanDate").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"date")), env.opts.autoescape);
output += "</span>\n";
;
}
if((!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"hideNumber"))) {
output += "\t\t\t\t<span class=\"";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") === "slider") {
output += "w-100";
;
}
else {
output += "w-auto";
;
}
output += " color-dark\">Épisode n°&#x202f;";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"number"), env.opts.autoescape);
output += "</span>\n";
;
}
output += "\t\t\t</div>\n\t\t\t";
output += runtime.suppressValue((lineno = 120, colno = 21, runtime.callWrap(t_4, "mesJoursArticle", context, ["card","Obsession",runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"obsession")),"href"),"Episode",runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"href"),null,{"classes": (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") === "slider"?"col w-auto mt-1":"col w-auto sm-mt-1 mt-2")}])), env.opts.autoescape);
output += "\n\t\t</div>\n\t</div>\n</div>\n";
;
}
else {
output += "<div class=\"article row ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") === "small") {
output += "md-no-wrap";
;
}
else {
output += "no-wrap";
;
}
output += " article-episode article-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type"), env.opts.autoescape);
output += " lj-color-";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"obsession")),"color")),"id"), env.opts.autoescape);
output += "\">\n\t<div class=\"col ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") !== "inline") {
output += "sm-w-100 md-w-auto";
;
}
else {
output += "w-auto";
;
}
output += "\">\n\t\t<a class=\"block article-image";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"current")) {
output += " relative";
;
}
output += "\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"href"), env.opts.autoescape);
output += "\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"current")) {
output += "\t\t\t<div class=\"article-current lj-color-bg\"></div>\n";
;
}
var t_18;
t_18 = env.getFilter("src").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"obsession"));
frame.set("image", t_18, true);
if(frame.topLevel) {
context.setVariable("image", t_18);
}
if(frame.topLevel) {
context.addExport("image", t_18);
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"lazyload")) {
output += "\t\t\t<img class=\"responsive h-100 lazyload\" src=\"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7\" data-src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "image"), env.opts.autoescape);
output += "\" data-sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"sizes")), env.opts.autoescape);
output += "\" data-srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.contextOrFrameLookup(context, frame, "image")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"title"))), env.opts.autoescape);
output += "\" />\n";
;
}
else {
output += "\t\t\t<img class=\"responsive h-100\" src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "image"), env.opts.autoescape);
output += "\" sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"sizes")), env.opts.autoescape);
output += "\" srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.contextOrFrameLookup(context, frame, "image")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"title"))), env.opts.autoescape);
output += "\" />\n";
;
}
output += "\t\t</a>\n\t</div>\n\t<div class=\"col w-auto flex-1\">\n\t\t<div class=\"row row-space w-100\">\n\t\t\t<div class=\"col sm-w-100 md-w-100 lg-w-auto flex-1 lg-ph-0\">\n\t\t\t\t<div class=\"";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") !== "inline") {
output += "sm-mt-2";
;
}
output += "\">\n";
var t_19;
t_19 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"titleTag")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"titleTag"):"span");
frame.set("titleTag", t_19, true);
if(frame.topLevel) {
context.setVariable("titleTag", t_19);
}
if(frame.topLevel) {
context.addExport("titleTag", t_19);
}
output += "\t\t\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"href"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t<";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "titleTag"), env.opts.autoescape);
output += " class=\"inline title link on-hover color-dark\">\n\t\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("removeBR").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"title")), env.opts.autoescape);
output += "\n\t\t\t\t\t\t</";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "titleTag"), env.opts.autoescape);
output += ">\n\t\t\t\t\t</a>\n\t\t\t\t</div>\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"showDate")) {
output += "\t\t\t\t<div class=\"infos sm-mt-1 mt-2\">\n\t\t\t\t\t<span class=\"color-medium-8\">\n\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("humanDate").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"date")), env.opts.autoescape);
output += "\n\t\t\t\t\t</span>\n\t\t\t\t</div>\n";
;
}
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"hideNumber")) {
output += "\t\t\t\t<div class=\"infos sm-mt-1 mt-2\">\n\t\t\t\t\t<span class=\"";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"type") === "inline") {
output += "lj-color-text";
;
}
else {
output += "color-medium-8";
;
}
output += "\">\n\t\t\t\t\t\tÉpisode n°&#x202f;";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"number"), env.opts.autoescape);
output += "\n\t\t\t\t\t</span>\n\t\t\t\t</div>\n";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"current")) {
output += "\t\t\t\t<div class=\"infos sm-mt-1 mt-2\">\n\t\t\t\t\t<span class=\"lj-color-text\">\n\t\t\t\t\t\tVous êtes sur cet épisode\n\t\t\t\t\t</span>\n\t\t\t\t</div>\n";
;
}
output += "\t\t\t</div>\n\t\t\t<div class=\"col sm-mt-1 md-mt-2 mt-1 w-auto flex-0 infos lg-ph-0\">\n\t\t\t\t";
output += runtime.suppressValue((lineno = 173, colno = 22, runtime.callWrap(t_4, "mesJoursArticle", context, ["card","Obsession",runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"obsession")),"href"),"Episode",runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "article")),"episode")),"href"),null,{"classes": "w-auto"}])), env.opts.autoescape);
output += "\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("articles/episode.html", ctx, cb); }
})();
