(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["menu/account-icon.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div id=\"account\" class=\"col p-0 col-center mr-2 w-auto mv-auto\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "account")),"user")) {
var t_1;
t_1 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "account")),"user");
frame.set("user", t_1, true);
if(frame.topLevel) {
context.setVariable("user", t_1);
}
if(frame.topLevel) {
context.addExport("user", t_1);
}
output += "    <div class=\"row m-0 pr-2 no-wrap\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user")),"notifications") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user")),"notifications")),"length") > 0) {
output += "        <div class=\"notification-badge\">\n            <span class=\"block color-lightest ff-proxima fw-regular\">\n                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "user")),"notifications")),"length"), env.opts.autoescape);
output += "\n            </span>\n        </div>\n";
;
}
output += "        <a class=\"col p-0 print-hidden caret\" href=\"/mon-compte/\">\n            <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 45 45\">\n                <circle cx=\"22.509\" cy=\"22.524\" r=\"21.427\" fill=\"none\" stroke=\"#c83e2c\" stroke-miterlimit=\"10\" stroke-width=\"1.714\"/>\n                <path fill=\"none\" stroke=\"#c83e2c\" stroke-miterlimit=\"10\" stroke-width=\"1.714\" d=\"M19.885 27.577c-4.141.818-7.64 3.336-9.683 6.766m24.732.112c-2.244-3.56-6.167-6.196-10.024-6.938v-2.564\"/>\n                <path fill=\"none\" stroke=\"#c83e2c\" stroke-miterlimit=\"10\" stroke-width=\"1.714\" d=\"M27.91 20.03c0 2.797-2.084 5.28-4.865 5.28h-.214c-2.78 0-5.207-2.483-5.207-5.28v-4.095c0-2.796 2.426-4.768 5.207-4.768h.214c2.781 0 4.865 1.972 4.865 4.769z\"/>\n                <path fill=\"#c83e2c\" d=\"M22.188 17.881v3.143c0 .967 1.5.967 1.5 0V17.88c0-.967-1.5-.967-1.5 0z\"/>\n            </svg>\n        </a>\n    </div>\n";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "account")),"ipaccount")) {
output += "    <div class=\"row m-0 no-wrap\">\n        <div class=\"col p-0 w-auto h-100 ml-1 ip-account\">\n            <span class=\"btn-primary p-0 \">\n                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "account")),"ipaccount"), env.opts.autoescape);
output += "\n            </span>\n        </div>\n    </div>\n";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "account")),"ssolibrary")) {
output += "    <div class=\"row m-0 no-wrap\">\n        <div class=\"col p-0 w-auto h-100 ml-1 ip-account\">\n            <span class=\"btn-primary p-0 \">\n                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "account")),"ssolibrary"), env.opts.autoescape);
output += "\n            </span>\n        </div>\n    </div>\n";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "account")),"openhouse")) {
output += "    <div class=\"row m-0 no-wrap ip-account\">\n        <a class=\"col w-auto btn-primary mr-1 sm-hidden md-hidden subscribe-btn\" href=\"/abonnement/jouriste\" data-track-content data-content-name=\"CTA - Header abonnement\" data-content-piece=\"S’abonner\">\n            S’abonner\n        </a>\n        <span class=\"col w-auto btn-primary p-0\">\n            <span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "account")),"openhouse")),"campaign"), env.opts.autoescape);
output += "</span>\n            <span class=\"ml-1g style-meta fs-italic sm-hidden md-hidden\"></span>\n        </span>\n    </div>\n";
;
}
else {
output += "    <div class=\"row m-0 no-wrap\">\n        <a class=\"col w-auto btn-primary sm-hidden md-hidden subscribe-btn\" href=\"/abonnement/jouriste\" data-track-content data-content-name=\"CTA - Header abonnement\" data-content-piece=\"S’abonner\">\n            S’abonner\n        </a>\n        <a class=\"col w-auto btn-primary sm-hidden md-hidden login-btn\" href=\"#login\">\n            Se connecter\n        </a>\n        <a class=\"col p-0 lg-hidden login-btn\" href=\"#login\">\n            <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 45 45\">\n                <path fill=\"none\" stroke=\"#c83e2c\" stroke-miterlimit=\"10\" stroke-width=\"1.293\" d=\"M16.223 11.405a16.17 16.17 0 0 1 11.762-5.074A16.17 16.17 0 0 1 44.154 22.5a16.17 16.17 0 0 1-16.169 16.169 16.17 16.17 0 0 1-11.762-5.074\"/>\n                <path fill=\"none\" stroke=\"#c83e2c\" stroke-miterlimit=\"10\" stroke-width=\"1.293\" d=\"M26.005 26.313c-3.125.617-5.765 2.517-7.307 5.106m18.663.084c-1.693-2.686-4.654-4.675-7.564-5.235v-1.935\"/>\n                <path fill=\"none\" stroke=\"#c83e2c\" stroke-miterlimit=\"10\" stroke-width=\"1.293\" d=\"M32.06 20.618c0 2.11-1.572 3.984-3.67 3.984h-.162c-2.098 0-3.93-1.873-3.93-3.984v-3.09c0-2.11 1.831-3.598 3.93-3.598h.161c2.099 0 3.672 1.488 3.672 3.599z\"/>\n                <path fill=\"#c83e2c\" d=\"M27.743 18.996v2.372c0 .73 1.132.73 1.132 0v-2.372c0-.73-1.132-.73-1.132 0M8.505 12.113a.856.856 0 0 0-.605 1.465l8.071 8.047H1.057a.855.855 0 0 0-.857.857c0 .475.383.858.857.858h14.914l-8.072 8.072a.856.856 0 1 0 1.211 1.213l9.537-9.512a.92.92 0 0 0 .25-.631.92.92 0 0 0-.25-.631L9.11 12.365a.85.85 0 0 0-.605-.252\"/>\n            </svg>\n        </a>\n    </div>\n";
;
}
;
}
;
}
;
}
output += "</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("menu/account-icon.html", ctx, cb); }
})();
