(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["obsession/soundtracks-tab.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"row\">\n";
frame = frame.push();
var t_3 = env.getFilter("selectattr").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"soundtrack"),"files");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("item", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\t<div class=\"col sm-w-6c lg-w-10c p-2\">\n";
var t_5;
t_5 = {"href": "#" + runtime.memberLookup((t_4),"href"),"title": runtime.memberLookup((t_4),"title"),"desc": runtime.memberLookup((t_4),"desc"),"meta": runtime.memberLookup((t_4),"meta"),"files": runtime.memberLookup((t_4),"files"),"lazyload": true,"sizes": ["100vw","50vw","33vw"]};
frame.set("media", t_5, true);
if(frame.topLevel) {
context.setVariable("media", t_5);
}
if(frame.topLevel) {
context.addExport("media", t_5);
}
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("media/soundtrack.html", false, "obsession/soundtracks-tab.html", false, function(t_7,t_6) {
if(t_7) { cb(t_7); return; }
callback(null,t_6);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
callback(null,t_8);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\t</div>\n";
});
}
}
frame = frame.pop();
output += "</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("obsession/soundtracks-tab.html", ctx, cb); }
})();
