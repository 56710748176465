import $ from "jquery";

// Constants
const $window = $(window);

$("body").on("mouseup.lesjours.link", "[data-href]", function (event) {
  // Get the Element
  const $element = $(event.currentTarget);
  const href = $element.data("href");

  // Test if it's a Mouse Click Event (Left & Middle)
  if (event.which !== 1 && event.which !== 2) {
    return;
  }

  // Check if we already have process this Event
  if (event.ljHrefAlreadyProcessed) {
    return;
  }
  event.ljHrefAlreadyProcessed = true;

  // Check if it's a "_blank" Click
  if (event.which === 2 || event.ctrlKey === true || event.metaKey === true) {
    // Open the Link in a New Tab
    window.open(href, "_blank");
    // Check if it's a Hash
  } else if (href.match(/^#/)) {
    // Trigger Hash Event
    $window.trigger("ljHashChange", href);
    // It's a Link
  } else {
    // Open the Link in the Current Tab
    window.open(href, "_self");
  }
});
