/* eslint camelcase: "off" */
/* global Modernizr:true, _mtm:true */
import throttle from "lodash/throttle";
import $ from "jquery";

// Constants
const transitionEndEvent = (function () {
  // Transition End Event
  const transitionEndEventNames = {
    WebkitTransition: "webkitTransitionEnd", // Saf 6, Android Browser
    MozTransition: "transitionend", // Only for FF < 15
    OTransition: "oTransitionEnd", // Only for Opera
    msTransition: "MSTransitionEnd", // Only for IE < 10
    transition: "transitionend", // IE10, Opera, Chrome, FF 15+, Saf 7+
  };
  // Browser Transition Prefix
  const prefix = Modernizr.csstransitions ? Modernizr.prefixed("transition") : false;

  // Check if the Browser Support Animations
  if (prefix && transitionEndEventNames[prefix] !== undefined) {
    // Return the Correct Event
    return transitionEndEventNames[prefix] + ".lesjours.menus";
  } else {
    // Return false
    return false;
  }
})();
const $html = $("html");
const $window = $(window);
const $body = $(document.body);
const $header = $("#header");
const $burger = $header.find("#burger");
const $search = $header.find("#search");
const timeout = {};

// Variables
let payload;
let $account = $header.find("#account .caret");
let headerIsTransparent = false;
let headerIsScrolled = false;
let loaded = false;
let scroll = 0;
let headerBreakpoint;

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// UTILITIES                                                                                                          //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ensure that all Menus are loaded
function ensureLoadedMenus() {
  if (loaded) {
    // Nothing to do
    return;
  }

  // Check if we have data
  if (payload) {
    // Load the Menus
    loaded = true;
    $header.find("#search-menu").replaceWith(window.nunjucks.render("menu/search-menu.html", {}));
    $header.find("#account-menu").replaceWith(window.nunjucks.render("menu/account-menu.html", { account: payload }));
    $header.find("#menu").replaceWith(window.nunjucks.render("menu/burger.html", { account: payload }));

    // Send an Event to Matomo Tag Manager
    _mtm.push({ event: "menusLoaded" });
  }
}

// Create a Function to clear timeout
function cancelTimeoutIfExist(key) {
  if (timeout[key]) {
    clearTimeout(timeout[key]);
    delete timeout[key];
  }
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// HEADER CHANGES ON SCROLL
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function calculateHeaderBreakpoint() {
  if (
    window.matchMedia("(min-width: 1200px)").matches &&
    $("#master").hasClass("master-sticky") &&
    $("#master .master-right-desktop").is(":visible")
  ) {
    headerBreakpoint = ($("#master").height() / 3) * 2;
  } else {
    headerBreakpoint = ($(window).height() / 3) * 2;
  }
}

function headerTransition() {
  if (window.scrollY > headerBreakpoint) {
    $body.addClass("header-scrolled");
    headerIsScrolled = true;
  } else {
    $body.removeClass("header-scrolled");
    headerIsScrolled = false;
  }
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// OPEN & CLOSE TRANSITIONS                                                                                           //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function transitionEnd(event) {
  // Remove the animation class
  event.data.animatedMenu.removeClass(event.data.classToRemove);
}

function close(key, $menu) {
  // Clear the timeout
  cancelTimeoutIfExist(key);

  // Use Animation
  $menu.addClass("animation-out");
  $menu.one(transitionEndEvent, null, { animatedMenu: $menu, classToRemove: "animation-out" }, transitionEnd);

  // Hide Menu
  $menu.removeClass("show");

  // If it's a transparent header, add back the transparent colors
  if (headerIsTransparent && !headerIsScrolled) {
    timeout.header = setTimeout(function () {
      $body.removeClass("header-opened-menu");
      delete timeout.header;
    }, 400);
  }

  // Update Menu Display Property
  timeout[key] = setTimeout(function () {
    $menu.removeClass("block");
    delete timeout[key];
  }, 500);

  // Remove .no-overflow class on the body
  $body.removeClass("no-overflow");

  // Reset the scroll
  $window.scrollTop(scroll || 0);
  scroll = 0;
}

function open(key, $menu) {
  // Clear the timeout
  cancelTimeoutIfExist(key);

  // If there is a "close header" Timeout
  // (a previous menu is closing)
  cancelTimeoutIfExist("header");

  // Update the current scroll value
  scroll = scroll || $window.scrollTop();

  // Add .no-overflow class on the body
  $body.addClass("no-overflow");
  $menu.addClass("block");

  // Use Animation
  $menu.addClass("animation-in");
  $menu.one(transitionEndEvent, null, { animatedMenu: $menu, classToRemove: "animation-in" }, transitionEnd);

  // If it's a transparent header
  if (headerIsTransparent && !headerIsScrolled) {
    // Remove the transparent colors
    $body.addClass("header-opened-menu");
    // Then, how the Menu
    timeout[key] = setTimeout(function () {
      $menu.addClass("show");
      delete timeout[key];
    }, 200);
  } else {
    // Show the Menu instantly (ease-in delay)
    timeout[key] = setTimeout(function () {
      $menu.addClass("show");
      delete timeout[key];
    }, 0);
  }
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// OPEN & CLOSE FUNCTIONS                                                                                             //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Function to Close the Burger Menu
function closeBurger() {
  // Close the Menu
  close("burger", $header.find("#menu"));

  // Run the burger closing animation
  $burger.removeClass("open");

  // Bonus: Revert the "initial" class so the transition on the burger will work again
  timeout.initial = setTimeout(function () {
    $burger.addClass("initial");
    delete timeout.initial;
  }, 300);

  // Trigger Event
  $window.trigger("ljBurgerEvent", "closed");
}

// Function to Open the Burger Menu
function openBurger() {
  // Show the Menu
  open("burger", $header.find("#menu"));

  // The "initial" class is used to disable the animation (when the page load)
  $burger.removeClass("initial").addClass("open");

  // Remove any Previous Content
  $header.find("#menu .suggested-search-group .search-input input").val("");
  $header.find("#menu .suggested-search-group .search-input input[name=query-suggest]").data("suggest", undefined);
  $header.find("#menu .suggested-search-group .search-results").html("");

  // Trigger Event
  $window.trigger("ljBurgerEvent", "opened");
}

// Function to Close the Search Menu
function closeSearch() {
  // Clear the timeout
  cancelTimeoutIfExist("search");
  cancelTimeoutIfExist("searchFocus");

  // Close the Menu
  // Change the cross button to the search button
  $search.removeClass("open").addClass("initial");

  // Launch the css animation for the search bar
  $header.find("#search-menu-content").removeClass("animation");

  // Then, change the visibility for the search container
  timeout.search = setTimeout(function () {
    $header.find("#search-menu").removeClass("show");
    delete timeout.search;
  }, 300);

  // If it's a transparent header, add back the transparent colors
  if (headerIsTransparent && !headerIsScrolled) {
    timeout.header = setTimeout(function () {
      $body.removeClass("header-opened-menu");
      delete timeout.header;
    }, 250);
  }

  // Trigger Event
  $window.trigger("ljSearchEvent", "closed");
}

// Function to Open the Search Menu
function openSearch() {
  // Clear the timeout
  cancelTimeoutIfExist("search");
  cancelTimeoutIfExist("searchFocus");

  // If there is a "close header" Timeout
  // (a previous menu is closing)
  cancelTimeoutIfExist("header");

  // Remove any Previous Content
  $header.find("#search-menu .search-input input").val("");
  $header.find("#search-menu .search-input input[name=query-suggest]").data("suggest", undefined);
  $header.find("#search-menu .search-results").html("");

  // Show the Search Menu
  // If it's a transparent header
  if (headerIsTransparent && !headerIsScrolled) {
    // Remove the transparent colors
    $body.addClass("header-opened-menu");
    // Then, show the Search Menu
    timeout.search = setTimeout(function () {
      $header.find("#search-menu").addClass("show");
      $search.removeClass("initial").addClass("open");
      // Launch the css animation for the search bar
      $header.find("#search-menu-content").addClass("animation");
      delete timeout.search;
    }, 200);
  } else {
    // Show the Search Menu instantly
    $header.find("#search-menu").addClass("show");
    $search.removeClass("initial").addClass("open");
    // Launch the css animation for the search bar
    $header.find("#search-menu-content").addClass("animation");
  }

  // Trigger Event
  $window.trigger("ljSearchEvent", "opened");

  // Wait until the Animation is Terminated to Move the Cursor in the Input
  timeout.searchFocus = setTimeout(function () {
    // Set the Focus on the Input Field
    $header.find("#search-menu .search-input input[name=query]").focus();
    delete timeout.searchFocus;
  }, 500);
}

// Function to Close the Account Menu
function closeAccount() {
  // Update the caret
  $("#account .caret").removeClass("open");

  // Close the Menu
  close("account", $header.find("#account-menu"));

  // Trigger Event
  $window.trigger("ljAccountEvent", "closed");
}

// Function to Open the Account Menu
function openAccount() {
  // Update the caret
  $("#account .caret").addClass("open");

  // Show the Menu
  open("account", $header.find("#account-menu"));

  // Trigger Event
  $window.trigger("ljAccountEvent", "opened");
}

// Function to Close All Menus
function hideAllElements(event) {
  // Check if we have click on a menu-content element
  if ($(event.target).parents("#account-menu-content, #menu-content").length > 0) {
    // Don't Hide Menus
    return;
  }

  // We have to Hide Menus
  if ($burger.hasClass("open")) {
    closeBurger();
  }
  if ($search.hasClass("open")) {
    closeSearch();
  }
  if ($account.hasClass("open")) {
    closeAccount();
  }
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// BURGER MENU                                                                                                        //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Hide/Show the Burger
function toggleBurger(event) {
  // Prevent Default
  event.preventDefault();
  event.stopPropagation();

  // Hide other Menus
  if ($account.hasClass("open")) {
    closeAccount();
  }
  if ($search.hasClass("open")) {
    closeSearch();
  }

  // Clear Timeout
  cancelTimeoutIfExist("initial");

  // Check if the Menu is Open
  if ($burger.hasClass("open")) {
    // Close the Menu
    closeBurger();
  } else {
    // Load the Menus if they are not loaded yet
    ensureLoadedMenus();

    // Open the Menu
    openBurger();
  }
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SEARCH MENU                                                                                                        //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Hide/Show the Search
function toggleSearch(event) {
  // Prevent Default
  event.preventDefault();
  event.stopPropagation();

  // Hide other Menus
  if ($account.hasClass("open")) {
    closeAccount();
  }
  if ($burger.hasClass("open")) {
    closeBurger();
  }

  // Clear Timeout
  cancelTimeoutIfExist("initial");

  // Check if the Menu is Open
  if ($search.hasClass("open")) {
    // Close the Menu
    closeSearch();
  } else {
    // Load the Menus if they are not loaded yet
    ensureLoadedMenus();

    // Open the Menu
    openSearch();
  }
}

// Send the Search Request
function doSearchRequest($query, $suggest, $results) {
  $.getJSON("/recherche/", {
    suggest: true,
    query: $query.val(),
  }).done(function (json) {
    // Check if there is a Suggestion in Results
    if (json.suggest.length > 0) {
      // Save the Suggestion
      $suggest.data("suggest", json.suggest[0]);

      // Show the Suggestion
      $suggest.val($query.val() + json.suggest[0].slice($query.val().length));
    }

    // Check if there is Search Results
    if (
      json.obsessions.length > 0 ||
      json.episodes.length > 0 ||
      json.authors.length > 0 ||
      json.metapersons.length > 0 ||
      json.tags.length > 0 ||
      json.searches.length > 0
    ) {
      // Render Search Results
      $results.html(window.nunjucks.render("menu/search-results.html", { results: json }));
    } else {
      // Clean Search Results
      $results.empty();
    }
  });
}

// Handle Keydown/Keyup Events in the Input Search
function keySearchHandler(event) {
  // Variables
  const $element = $(event.currentTarget);
  const $group = $element.parents(".suggested-search-group");
  const $inputs = $group.find(".search-input");
  const $results = $group.find(".search-results");
  const $query = $inputs.find("input[name=query]");
  const $suggest = $inputs.find("input[name=query-suggest]");

  // Check the Key
  switch (event.which) {
    // Tab Key
    case 9:
      // Prevent Default to Avoid Focus Change
      event.preventDefault();

      // Update the Query if there is a Suggestion
      if ($suggest.data("suggest") !== "") {
        $query.val($suggest.data("suggest"));
      }
      return;
    // Enter Key
    case 13:
      // Prevent Default
      event.preventDefault();

      // Send the Form
      $group.find(".search-form").get(0).submit();
      return;
  }

  // Check the Event Type
  switch (event.type) {
    case "keydown":
      // Remove the Displayed Suggestion
      $suggest.data("suggest", "");
      $suggest.val("");
      return;
    case "keyup":
      // Check if there is Something to Search
      if ($query.val().length === 0) {
        return;
      }

      // Send the Search Request
      doSearchRequest($query, $suggest, $results);
  }
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ACCOUNT MENU                                                                                                       //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Hide/Show the Account Menu
function toggleAccount(event) {
  event.preventDefault();
  event.stopPropagation();

  // Hide other Menus
  if ($burger.hasClass("open")) {
    closeBurger();
  }
  if ($search.hasClass("open")) {
    closeSearch();
  }

  // Check if the Menu is Open
  if ($("#account .caret").hasClass("open")) {
    // Close the Menu
    closeAccount();
  } else {
    // Load the Menus if they are not loaded yet
    ensureLoadedMenus();

    // Open the Menu
    openAccount();
  }
}

// Hide a Notification
function hideNotification(event) {
  event.preventDefault();
  event.stopPropagation();
  if ($burger.hasClass("open")) {
    closeBurger();
  }

  // Variables
  const $hideButton = $(event.currentTarget);
  const $notification = $hideButton.parents(".notification-message");
  const $notifications = $notification.parents(".notification-messages");
  const $notificationBadge = $header.find("#account .notification-badge");

  // Drop Notification
  $.get("/session", { "hide-notification": $hideButton.data("notification-id") }).done(function () {
    // Remove the Notification
    $notification.remove();

    // Test if there is remaining notifications
    if ($notifications.find(".notification-message").length > 0) {
      // Update the Counter
      $notificationBadge.find("span").html($notifications.find(".notification-message").length);
    } else {
      // Hide the notification element
      $notifications.replaceWith($("<div>", { class: "grey-stripe mv-1" }));

      // Remove the notification badge
      $notificationBadge.remove();
    }
  });
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// INITIALIZE BURGER & ACCOUNT MENUS                                                                                  //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function initializeBurgerAndAccountMenus(account) {
  // Save the account data
  payload = account;

  // Check if the page has a transparent header
  // If true, change the boolean to return to its intial state when scrolling up
  if ($body.hasClass("header-transparent")) {
    headerIsTransparent = true;
  }

  // Load the Icon if it's missing
  if ($header.find("#account").is(":empty")) {
    $header.find("#account").replaceWith(window.nunjucks.render("menu/account-icon.html", { account: account }));
    $account = $header.find("#account .caret");
  }

  // Start an Interval if it's an Open House User
  if (account.openhouse && account.openhouse.end) {
    setInterval(function () {
      // Update the Remaining Time
      const end = account.openhouse.end;
      const now = Date.now() / 1000;
      const remaining = Math.floor((end - now) / 60);

      // Check the remaining time
      if (remaining === 1) {
        $("#account .ip-account .style-meta").html("1 minute restante");
      } else if (remaining > 1) {
        $("#account .ip-account .style-meta").html(remaining + " minutes restantes");
      } else {
        $("#account .ip-account .style-meta").html("Terminée");
      }
    }, 5000);
  }
  // Change the header while scrolling
  $window.on("scroll.lesjours.header", throttle(headerTransition, 250, { leading: true, trailing: true }));

  // Bind Menus Events
  $html.on("click.lesjours.burger", hideAllElements);
  $header.on("click.lesjours.burger", "#burger", toggleBurger);
  $header.on("click.lesjours.burger", "#search", toggleSearch);
  $header.on("click.lesjours.burger", "#account .caret", toggleAccount);
  $header.on("click.lesjours.burger", "#account-menu .notification-hide", hideNotification);

  // Bind Search Events
  $header.on("keydown.lesjours.burger", ".search-form .search-input input[name=query]", keySearchHandler);
  $header.on(
    "keyup.lesjours.burger",
    ".search-form .search-input input[name=query]",
    throttle(keySearchHandler, 250, { leading: true, trailing: true })
  );
  $header.on("click.lesjours.burger", ".search-form", (e) => {
    e.preventDefault();
    e.stopPropagation();
  });
}
// Calculate header's breakpoint
calculateHeaderBreakpoint();
// Listen events
$window.on("ljUserAccount", (event, account) => {
  // Initialize the Menus
  initializeBurgerAndAccountMenus(account);
});
