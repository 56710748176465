(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["episode/classic/languages.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "episode/classic/languages.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "flag")) {
var t_4 = t_1.flag;
} else {
cb(new Error("cannot import 'flag'")); return;
}
context.setVariable("flag", t_4);
output += "<div class=\"mt-2 translations\">\n\t<span class=\"style-meta\">Lisez cet épisode dans d’autres langues :</span>\n\t<ul class=\"list list-inline style-meta\">\n\t\t<li class=\"hidden\" data-translation=\"fr\">";
output += runtime.suppressValue((lineno = 4, colno = 50, runtime.callWrap(t_4, "flag", context, ["fr","français"])), env.opts.autoescape);
output += " ";
output += runtime.suppressValue("français", env.opts.autoescape);
output += "</li>\n";
frame = frame.push();
var t_7 = runtime.contextOrFrameLookup(context, frame, "translations");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("translation", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\t\t<li data-translation=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"code"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 6, colno = 55, runtime.callWrap(t_4, "flag", context, [runtime.memberLookup((t_8),"code"),runtime.memberLookup((t_8),"name")])), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.memberLookup((t_8),"name"), env.opts.autoescape);
output += "</li>\n";
;
}
}
frame = frame.pop();
output += "\t</ul>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("episode/classic/languages.html", ctx, cb); }
})();
