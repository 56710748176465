import $ from "jquery";
import { loadStripe } from "@stripe/stripe-js/pure";

const kStripeLightTheme = {
  theme: "stripe",

  variables: {
    colorPrimary: "#c83e2c",
    colorBackground: "#fff",
    colorText: "#414141",
  },
};

const kStripeDarkTheme = {
  theme: "stripe",

  variables: {
    colorPrimary: "#c83e2c",
    colorBackground: "#0d1e37",
    colorText: "#a4c0ea",
  },
};

function showMessage(messageText) {
  const $messageContainer = $("#stripe-payment-message");

  $messageContainer.removeClass("hidden");
  $messageContainer.text(messageText);

  setTimeout(function () {
    $messageContainer.addClass("hidden");
    $messageContainer.text("");
  }, 20000);
}

function setLoading(isLoading) {
  if (isLoading) {
    // Hide any error message
    const $messageContainer = $("#stripe-payment-message");
    $messageContainer.addClass("hidden");
    $messageContainer.text("");

    // Show the spinner
    $("#stripe-submit").disabled = true;
    $("#stripe-spinner").removeClass("hidden");
    $("#stripe-button-text").addClass("hidden");
  } else {
    // Hide the spinner
    $("#stripe-submit").disabled = false;
    $("#stripe-spinner").addClass("hidden");
    $("#stripe-button-text").removeClass("hidden");
  }
}

function isBlockedPaymentError(error) {
  return (
    error.type === "invalid_request_error" &&
    error.message.startsWith("The payment is blocked due to a high likelihood of chargeback.")
  );
}

export async function showStripePaymentForm({ publishableKey, paymentClientSecret, returnUrl, isSetupOnly }) {
  // Load the Stripe JS SDK
  const stripe = await loadStripe(publishableKey);

  // Initialize Stripe Elements
  const elements = stripe.elements({
    clientSecret: paymentClientSecret,
    appearance: $("html").hasClass("lj-dark-theme") ? kStripeDarkTheme : kStripeLightTheme,
  });
  const paymentElement = elements.create("payment", {
    layout: "tabs",
    paymentMethodOrder: ["card", "sepa_debit", "bancontact"],
  });
  paymentElement.mount("#stripe-payment-element");

  // Listen submit events
  $("#stripe-payment-form").on("submit.lesjours.stripe", async function (e) {
    // Prevent default
    e.preventDefault();

    // Show the spinner
    setLoading(true);

    // Check if it's a payment or a setup
    let error;
    if (isSetupOnly === true) {
      // Setup the payment
      error = (
        await stripe.confirmSetup({
          elements: elements,
          confirmParams: {
            return_url: returnUrl,
          },
        })
      ).error;
    } else {
      // Process the payment
      error = (
        await stripe.confirmPayment({
          elements: elements,
          confirmParams: {
            return_url: returnUrl,
          },
        })
      ).error;
    }

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      showMessage(error.message);
    } else if (isBlockedPaymentError(error)) {
      showMessage(
        "Ce moyen de paiement a été bloqué par Stripe à cause d’un risque élévé de refus. Si vous souhaitez tout de même vous abonner avec celui-ci merci de nous écrire à contact@lesjours.fr."
      );
    } else {
      showMessage("Une erreur inconnue est survenue.");
    }

    // Hide the spinner
    setLoading(false);
  });
}
