import $ from "jquery";

// Listen click on the resiliation reason's button
$("#unsubscribe-disclaimer button[data-choice]").on("click.lesjours.resiliation", function (e) {
  // Prevent defdault
  e.preventDefault();

  // Get the Element
  const $btn = $(e.currentTarget);

  // Hide all reason sections & the disclaimer section
  $("[id^=unsubscribe-choice-]").addClass("hidden");
  $("#unsubscribe-disclaimer").addClass("hidden");

  // Show the correct section
  $("#unsubscribe-choice-" + $btn.data("choice")).removeClass("hidden");

  // Scroll to the top
  $("html, body").animate({ scrollTop: 0 }, 250, "swing");
});

// Listen click on form submit buttons
$("[id^=unsubscribe-choice-] a[data-choice]").on("click.lesjours.resiliation", function (e) {
  // Prevent defdault
  e.preventDefault();

  // Get the Element & the Form
  const $btn = $(e.currentTarget);
  const $form = $btn.parents("form").eq(0);

  // Set the Choice
  $form.find("input[name=unsubscribe-action]").val($btn.data("choice"));

  // Submit the Form
  $form.trigger("submit");
});
