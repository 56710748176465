/* ! lazySizes plugin based on : svg4everybody v2.1.9 | github.com/jonathantneal/svg4everybody */
import lazySizes from "lazysizes";

// create xhr requests object
const requests = {};

function embed(parent, svg, target) {
  // create a document fragment to hold the contents of the target
  const fragment = document.createDocumentFragment();

  // cache the closest matching viewBox
  const viewBox = !svg.hasAttribute("viewBox") && target.getAttribute("viewBox");

  // conditionally set the viewBox on the svg
  if (viewBox) {
    svg.setAttribute("viewBox", viewBox);
  }

  // clone the target
  const clone = target.cloneNode(true);

  // copy the contents of the clone into the fragment
  while (clone.childNodes.length) {
    fragment.appendChild(clone.firstChild);
  }

  // append the fragment into the svg
  parent.appendChild(fragment);
}

function loadreadystatechange(xhr) {
  // listen to changes in the request
  xhr.onreadystatechange = function () {
    // if the request is ready
    if (xhr.readyState === 4) {
      // get the cached html document
      let cachedDocument = xhr._cachedDocument;

      // ensure the cached html document based on the xhr response
      if (!cachedDocument) {
        cachedDocument = xhr._cachedDocument = document.implementation.createHTMLDocument("");
        cachedDocument.body.innerHTML = xhr.responseText;
        xhr._cachedTarget = {};
      }

      // clear the xhr embeds list and embed each item
      xhr._embeds.splice(0).forEach(function (item) {
        // get the cached target
        let target = xhr._cachedTarget[item.id];

        // ensure the cached target
        if (!target) {
          target = xhr._cachedTarget[item.id] = cachedDocument.getElementById(item.id);
        }

        // embed the target into the svg
        embed(item.parent, item.svg, target);
      });
    }
  };

  // test the ready state change immediately
  xhr.onreadystatechange();
}

function getSVGAncestor(node) {
  let svg = node;
  while (svg.nodeName.toLowerCase() !== "svg") {
    svg = svg.parentNode;
    if (!svg) {
      break;
    }
  }
  return svg;
}

function svg4everybody(use) {
  // get the current <svg>
  const parent = use.parentNode;
  const svg = getSVGAncestor(parent);
  const src = use.getAttribute("xlink:href") || use.getAttribute("href") || use.getAttribute("data-src");

  if (svg && src) {
    // remove the <use> element
    parent.removeChild(use);

    // parse the src and get the url and id
    const srcSplit = src.split("#");
    const url = srcSplit.shift();
    const id = srcSplit.join("#");

    // if the link is external
    if (url.length) {
      // get the cached xhr request
      let xhr = requests[url];

      // ensure the xhr request exists
      if (!xhr) {
        xhr = requests[url] = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.send();
        xhr._embeds = [];
      }

      // add the svg and id as an item to the xhr embeds list
      xhr._embeds.push({
        parent: parent,
        svg: svg,
        id: id,
      });

      // prepare the xhr ready state change event
      loadreadystatechange(xhr);
    } else {
      // embed the local id into the svg
      embed(parent, svg, document.getElementById(id));
    }
  }
}

const onRead = function (e) {
  if (e.detail.instance !== lazySizes) {
    return;
  }

  // Call the svg4everybody Polyfill on the SVG
  svg4everybody(e.target);
};

window.addEventListener("lazyunveilread", onRead, true);
