import throttle from "lodash/throttle";
import Cookies from "js-cookie";
import $ from "jquery";

// Variables
const $window = $(window);
const $cmsscroll = $("#cmsscroll");
let episode;

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SCROLL POSITION RELOADER                                                                                           //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function reloadScrollPosition() {
  // Reload the Saved Scroll Position
  const savedPosition = Cookies.get("cmsscroll-" + episode);
  if (savedPosition !== undefined) {
    $window.scrollTop(savedPosition);
  }
}

function saveScrollPosition() {
  // Record the Current Scroll Position
  Cookies.set("cmsscroll-" + episode, $window.scrollTop(), { expires: 1, path: "/apercu/episode/" });
}

// Check if we have a CMS Scroll Meta
if ($cmsscroll.length > 0) {
  // Get the Episode
  episode = $cmsscroll.data("episode");

  // Reload Previously Saved Scroll Position
  reloadScrollPosition();

  // Bind Scroll Listener
  $window.on("scroll", throttle(saveScrollPosition, 1000, { leading: true, trailing: true }));
}
