(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["episode/note-modal.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "episode/note-modal.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<div id=\"note-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "note")),"number"), env.opts.autoescape);
output += "-modal\" class=\"modal animated\" role=\"dialog\" tabindex=\"-1\">\n\t<div class=\"overflow-auto animated-note\" role=\"document\">\n\t\t<div class=\"row m-0 row-vertical\">\n\t\t\t<span class=\"a btn close\" data-href=\"#\">\n\t\t\t\t<span class=\"sr\">Fermer cette fenêtre</span>\n\t\t\t\t";
output += runtime.suppressValue((lineno = 6, colno = 11, runtime.callWrap(t_4, "icon", context, ["close"])), env.opts.autoescape);
output += "\n\t\t\t</span>\n\t\t\t<div class=\"col ph-5 sm-ph-2 mb-1 mt-8 flex\">\n\t\t\t\t<span class=\"block\">\n\t\t\t\t\t<span class=\"inline title color-dark\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "note")),"title"), env.opts.autoescape);
output += "</span>\n\t\t\t\t</span>\n\t\t\t</div>\n\t\t\t<div class=\"col ph-5 sm-ph-2 mb-2\">\n\t\t\t\t<div class=\"mt-2 desc color-main underlined-links\">\n\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "note")),"caption"), env.opts.autoescape);
output += "\n\t\t\t\t</div>\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "note")),"href")) {
output += "\t\t\t\t<div class=\"mt-2 desc color-main underlined-links\">\n\t\t\t\t\t<a href=\"";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "note")),"href"),"#"), env.opts.autoescape);
output += "\" target=\"_blank\">En savoir plus</a>\n\t\t\t\t</div>\n";
;
}
output += "\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("episode/note-modal.html", ctx, cb); }
})();
