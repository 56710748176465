import $ from "jquery";

/*
 * JavaScript isOnScreen jQuery Plugin
 * Based on the work of moagrius : https://github.com/moagrius/isOnScreen
 *
 * Simple jQuery plugin to determine if an element is within the viewport.
 * Optional parameters allow the user to specify a minimum percentage of the element's dimensions that must be visible to qualify.
 *
 * Usage :
 * @param {Number} x float values to test against, as a decimal value of how much of the element's width must be visible for the method to return true. defaults to 1 (100%)
 * @param {Number} y float values to test against, as a decimal value of how much of the element's height must be visible for the method to return true. defaults to 1 (100%)
 * @returns {Boolean}
 */

// Create the jQuery Function
$.fn.isOnScreen = function (x, y) {
  // Add Defaults Values
  if (x === null || x === undefined) {
    x = 1;
  }
  if (y === null || y === undefined) {
    y = 1;
  }

  // Check if the Element is Visible
  const height = this.outerHeight();
  const width = this.outerWidth();
  if (!width || !height) {
    return false;
  }
  if (this.is(":hidden")) {
    return false;
  }

  // Viewport Informations
  const win = $(window);
  const viewport = {
    top: win.scrollTop(),
    left: win.scrollLeft(),
  };
  viewport.right = viewport.left + win.width();
  viewport.bottom = viewport.top + win.height();

  // Get Element Bounds
  const bounds = this.offset();
  bounds.right = bounds.left + width;
  bounds.bottom = bounds.top + height;

  // Check if the Element is not Visible :
  // - The left   bound position is greater than the right  position of the viewport
  // - The right  bound position is lower   than the left   position of the viewport
  // - The top    bound position is greater than the bottom position of the viewport
  // - The bottom bound position is lower   than the top    position of the viewport
  const visible = !(
    viewport.right < bounds.left ||
    viewport.left > bounds.right ||
    viewport.bottom < bounds.top ||
    viewport.top > bounds.bottom
  );
  if (!visible) {
    return false;
  }

  // Build Element Deltas
  // Deltas corresponds to the percentage of the left/top/right/bottom side of the element that is in the viewport
  // 1 mean that all the element side is in the viewport
  const deltas = {
    top: Math.min(1, (bounds.bottom - viewport.top) / height),
    bottom: Math.min(1, (viewport.bottom - bounds.top) / height),
    left: Math.min(1, (bounds.right - viewport.left) / width),
    right: Math.min(1, (viewport.right - bounds.left) / width),
  };

  // Check if the Element Width is Greater than the Viewport and if Left & Right Sides are Outside of the Viewport
  if (win.width() < width && deltas.left < 1 && deltas.right < 1) {
    // Check if the Element Height is in the Viewport
    return deltas.top * deltas.bottom >= y;
    // Check if the Element Height is Greater than the Viewport and if Top & Bottom Sides are Outside of the Viewport
  } else if (win.height() < height && deltas.top < 1 && deltas.bottom < 1) {
    // Check if the Element Width is in the Viewport
    return deltas.left * deltas.right >= x;
  } else {
    // Check if Element Width & Height are in the Viewport
    return deltas.left * deltas.right >= x && deltas.top * deltas.bottom >= y;
  }
};
