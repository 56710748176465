/* globals AppleID:true , google:true*/
import { GDPRConsent } from "gdpr-consent";
import $ from "jquery";
import { SignInWithOptions } from "../config";

$(document.body).on("click", ".lj-siwg-button, .lj-siwa-button", function (event) {
  // Prevent default
  event.preventDefault();
  event.stopPropagation();

  // Check if it's Google or Apple
  let isGoogle = $(event.currentTarget).hasClass("lj-siwg-button");
  let gdprServiceName = isGoogle ? "signinwithgoogle" : "signinwithapple";

  // Check if the service is allowed
  if (GDPRConsent.isActivated(gdprServiceName)) {
    // Nothing more to do
    return;
  }

  // Allow the service with the GDPR consent
  GDPRConsent.activate(gdprServiceName);
});

// Get the redirection URL
function redirectURI() {
  return window.LesJours.siwRedirectURI ?? SignInWithOptions.redirectURI;
}
function redirectState() {
  return window.LesJours.siwState ?? btoa(JSON.stringify({ redirect: window.location.href }));
}

// Add the Sign-in with Google/Apple loader
let signInWithGoogleLoaded = false;
$.loadSignInWithGoogle = function () {
  if (signInWithGoogleLoaded === true) {
    return;
  }

  let siwgBtn = document.getElementById("googleid-signin");
  if (siwgBtn === null) {
    return;
  }

  signInWithGoogleLoaded = true;
  google.accounts.id.initialize({
    client_id: SignInWithOptions.googleClientId,
    context: "signin",
    ux_mode: "redirect",
    login_uri: `${redirectURI()}?state=${redirectState()}`,
  });
  google.accounts.id.renderButton(siwgBtn, { ...siwgBtn.dataset });
};

let signInWithAppleLoaded = false;
$.loadSignInWithApple = function () {
  if (signInWithAppleLoaded === true) {
    return;
  }

  let siwaBtn = document.getElementById("appleid-signin");
  if (siwaBtn === null) {
    return;
  }

  signInWithAppleLoaded = true;
  AppleID.auth.init({
    clientId: SignInWithOptions.appleClientId,
    scope: "name email",
    redirectURI: redirectURI(),
    state: redirectState(),
    usePopup: false,
  });
};

$.loadSignInWith = function () {
  if (GDPRConsent.isActivated("signinwithgoogle")) {
    $.loadSignInWithGoogle();
  }
  if (GDPRConsent.isActivated("signinwithapple")) {
    $.loadSignInWithApple();
  }
};
