(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["episode/mini-modal.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"model")) {
output += "\n";
output += "\n";
var t_1;
t_1 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"doc");
frame.set("doc", t_1, true);
if(frame.topLevel) {
context.setVariable("doc", t_1);
}
if(frame.topLevel) {
context.addExport("doc", t_1);
}
output += "\n";
var t_2;
t_2 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"img")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"img"):env.getFilter("res").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"img"),120));
frame.set("img", t_2, true);
if(frame.topLevel) {
context.setVariable("img", t_2);
}
if(frame.topLevel) {
context.addExport("img", t_2);
}
var t_3;
t_3 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"alt")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"alt"):runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"alt"));
frame.set("alt", t_3, true);
if(frame.topLevel) {
context.setVariable("alt", t_3);
}
if(frame.topLevel) {
context.addExport("alt", t_3);
}
output += "\n";
var t_4;
t_4 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"title")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"title"):runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"title"));
frame.set("title", t_4, true);
if(frame.topLevel) {
context.setVariable("title", t_4);
}
if(frame.topLevel) {
context.addExport("title", t_4);
}
output += "\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"files")) {
var t_5;
t_5 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"href")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"href"):runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"files")),0));
frame.set("href", t_5, true);
if(frame.topLevel) {
context.setVariable("href", t_5);
}
if(frame.topLevel) {
context.addExport("href", t_5);
}
var t_6;
t_6 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"subtitle")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"subtitle"):runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"desc"));
frame.set("subtitle", t_6, true);
if(frame.topLevel) {
context.setVariable("subtitle", t_6);
}
if(frame.topLevel) {
context.addExport("subtitle", t_6);
}
var t_7;
t_7 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"caption")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"caption"):runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"meta"));
frame.set("caption", t_7, true);
if(frame.topLevel) {
context.setVariable("caption", t_7);
}
if(frame.topLevel) {
context.addExport("caption", t_7);
}
;
}
else {
var t_8;
t_8 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"href");
frame.set("href", t_8, true);
if(frame.topLevel) {
context.setVariable("href", t_8);
}
if(frame.topLevel) {
context.addExport("href", t_8);
}
var t_9;
t_9 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"subtitle")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"subtitle"):runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"subtitle"));
frame.set("subtitle", t_9, true);
if(frame.topLevel) {
context.setVariable("subtitle", t_9);
}
if(frame.topLevel) {
context.addExport("subtitle", t_9);
}
var t_10;
t_10 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"caption");
frame.set("caption", t_10, true);
if(frame.topLevel) {
context.setVariable("caption", t_10);
}
if(frame.topLevel) {
context.addExport("caption", t_10);
}
;
}
;
}
else {
output += "\n";
var t_11;
t_11 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"img");
frame.set("img", t_11, true);
if(frame.topLevel) {
context.setVariable("img", t_11);
}
if(frame.topLevel) {
context.addExport("img", t_11);
}
var t_12;
t_12 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"alt");
frame.set("alt", t_12, true);
if(frame.topLevel) {
context.setVariable("alt", t_12);
}
if(frame.topLevel) {
context.addExport("alt", t_12);
}
output += "\n";
var t_13;
t_13 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"title");
frame.set("title", t_13, true);
if(frame.topLevel) {
context.setVariable("title", t_13);
}
if(frame.topLevel) {
context.addExport("title", t_13);
}
output += "\n";
var t_14;
t_14 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"href");
frame.set("href", t_14, true);
if(frame.topLevel) {
context.setVariable("href", t_14);
}
if(frame.topLevel) {
context.addExport("href", t_14);
}
var t_15;
t_15 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"subtitle");
frame.set("subtitle", t_15, true);
if(frame.topLevel) {
context.setVariable("subtitle", t_15);
}
if(frame.topLevel) {
context.addExport("subtitle", t_15);
}
var t_16;
t_16 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"caption");
frame.set("caption", t_16, true);
if(frame.topLevel) {
context.setVariable("caption", t_16);
}
if(frame.topLevel) {
context.addExport("caption", t_16);
}
;
}
env.getTemplate("_macro.html", false, "episode/mini-modal.html", false, function(t_18,t_17) {
if(t_18) { cb(t_18); return; }
t_17.getExported(function(t_19,t_17) {
if(t_19) { cb(t_19); return; }
if(Object.prototype.hasOwnProperty.call(t_17, "icon")) {
var t_20 = t_17.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_20);
output += "<div id=\"mini-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"number"), env.opts.autoescape);
output += "-modal\" class=\"modal animated\" role=\"dialog\" tabindex=\"-1\">\n\t<div class=\"overflow-auto animated-note\" role=\"document\">\n\t\t<div class=\"row m-0 row-vertical\">\n\t\t\t<span class=\"a btn close\" data-href=\"#\">\n\t\t\t\t<span class=\"sr\">Fermer cette fenêtre</span>\n\t\t\t\t";
output += runtime.suppressValue((lineno = 36, colno = 11, runtime.callWrap(t_20, "icon", context, ["close"])), env.opts.autoescape);
output += "\n\t\t\t</span>\n\t\t\t<div class=\"col ph-5 sm-ph-2 mb-1 mt-8 flex row-center\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"model") === "MusicSoundtrack") {
output += "\t\t\t\t<div class=\"player-surrounded relative js-audio-player mr-1\" data-type=\"circle\">\n\t\t\t\t\t<svg width=\"32\" height=\"32\" class=\"ui-player-progress initial\" role=\"img\" viewBox=\"0 0 120 120\">\n\t\t\t\t\t\t<circle cx=\"60\" cy=\"60\" r=\"54\" fill=\"none\" stroke=\"#f3df93\" stroke-width=\"10\" class=\"progress-circle js-jump-btn\"/>\n\t\t\t\t\t\t<circle cx=\"60\" cy=\"60\" r=\"54\" fill=\"none\" stroke=\"#e8c131\" stroke-width=\"10\" class=\"progress-buffer js-player-buffer\"/>\n\t\t\t\t\t\t<circle cx=\"60\" cy=\"60\" r=\"54\" fill=\"none\" stroke=\"#9a7d11\" stroke-width=\"10\" class=\"progress-player js-player-progress\"/>\n\t\t\t\t\t\t<circle cx=\"60\" cy=\"60\" r=\"42\" fill=\"#fff\" class=\"progress-button js-play-btn\"/>\n\t\t\t\t\t</svg>\n\t\t\t\t\t<div class=\"overlay container\">\n\t\t\t\t\t\t<audio aria-hidden=\"true\">\n";
frame = frame.push();
var t_23 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"doc")),"files");
if(t_23) {t_23 = runtime.fromIterator(t_23);
var t_22 = t_23.length;
for(var t_21=0; t_21 < t_23.length; t_21++) {
var t_24 = t_23[t_21];
frame.set("source", t_24);
frame.set("loop.index", t_21 + 1);
frame.set("loop.index0", t_21);
frame.set("loop.revindex", t_22 - t_21);
frame.set("loop.revindex0", t_22 - t_21 - 1);
frame.set("loop.first", t_21 === 0);
frame.set("loop.last", t_21 === t_22 - 1);
frame.set("loop.length", t_22);
output += "\t\t\t\t\t\t\t<source type=\"";
output += runtime.suppressValue(env.getFilter("mimeType").call(context, t_24), env.opts.autoescape);
output += "\" src=\"";
output += runtime.suppressValue(t_24, env.opts.autoescape);
output += "\" />\n";
;
}
}
frame = frame.pop();
output += "\t\t\t\t\t\t</audio>\n\t\t\t\t\t\t<button type=\"button\">\n\t\t\t\t\t\t\t<div class=\"container\">\n\t\t\t\t\t\t\t\t<img class=\"responsive lazyload\" src=\"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7\" data-src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "img"), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.contextOrFrameLookup(context, frame, "alt"))), env.opts.autoescape);
output += "\" />\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</button>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n";
;
}
else {
output += "\t\t\t\t<span class=\"image\">\n\t\t\t\t\t<img class=\"responsive lazyload\" src=\"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7\" data-src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "img"), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.contextOrFrameLookup(context, frame, "alt"))), env.opts.autoescape);
output += "\" />\n\t\t\t\t</span>\n";
;
}
output += "\t\t\t\t<span class=\"block\">\n\t\t\t\t\t<span class=\"inline title color-dark\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
output += "</span>\n\t\t\t\t</span>\n\t\t\t</div>\n\t\t\t<div class=\"col ph-5 sm-ph-2 mb-2\">\n";
if(runtime.contextOrFrameLookup(context, frame, "subtitle")) {
output += "\t\t\t\t<div class=\"mt-2 desc color-main underlined-links\">\n\t\t\t\t\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subtitle"), env.opts.autoescape);
output += "\n\t\t\t\t</div>\n";
;
}
if(runtime.contextOrFrameLookup(context, frame, "caption")) {
output += "\t\t\t\t<div class=\"mt-2 desc fs-italic color-main underlined-links\">\n\t\t\t\t\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "caption"), env.opts.autoescape);
output += "\n\t\t\t\t</div>\n";
;
}
if(runtime.contextOrFrameLookup(context, frame, "href") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"model") !== "MusicSoundtrack") {
output += "\t\t\t\t<div class=\"mt-2 desc color-main underlined-links\">\n\t\t\t\t\t<a href=\"";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "href"),"#"), env.opts.autoescape);
output += "\" target=\"_blank\">En savoir plus</a>\n\t\t\t\t</div>\n";
;
}
output += "\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("episode/mini-modal.html", ctx, cb); }
})();
