/* global _mtm:true */
import $ from "jquery";

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// CONFIGURATION                                                                                                      //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function configureTagManager(account) {
  const $window = $(window);
  const $body = $(document.body);

  // Check if we must pass the userId to Matomo Tag Manager
  if ($("meta[data-mtm-conversion]").length === 0 && account.gaconfig !== undefined) {
    // Check if it's a User
    if (account.gaconfig.user !== undefined) {
      // Send the userId to Matomo Tag Manager
      _mtm.push({ event: "userIdLoaded", userId: account.gaconfig.user });
    }
  }

  // Check if it's a User
  if (account.user !== undefined) {
    // Check if the User have a Plan
    if (account.user.plan !== undefined) {
      // Send the User Plan
      $window.trigger("lesjours.plan", account.user.plan);
      _mtm.push({ event: "userPlanLoaded", userPlan: account.user.plan });
    }

    // Check if the User have a Payment
    if (account.user.payment !== undefined) {
      // Send the User Payment
      $window.trigger("lesjours.payment", account.user.payment);
      _mtm.push({ event: "userPaymentLoaded", userPayment: account.user.payment });
    }

    // Check if the User have a Sponsorship Code
    if (account.user.sponsorship !== undefined) {
      // Show Sponsorship Elements
      $body.addClass("user-has-sponsorship");

      // Send an Event with the Sponsorship Code
      $window.trigger("lesjours.sponsorship", account.user.sponsorship);
      _mtm.push({ event: "userSponsorshipLoaded", userSponsorship: account.user.sponsorship });
    }

    // Check if the user account is Expired
    if (account.gaconfig.dims.lj_user_plan === "Expiré") {
      // Show Visitors Elements
      $body.addClass("user-is-visitor");

      // Send an Event with the User Type : expired
      $body.attr("data-user", "expired");
      $window.trigger("lesjours.user", "expired");
      _mtm.push({ event: "userTypeLoaded", userType: "expired" });
    } else {
      // Show Subscriber Elements
      $body.addClass("user-is-subscriber");

      // Send an Event with the User Type : subscriber
      $body.attr("data-user", "subscriber");
      $window.trigger("lesjours.user", "subscriber");
      _mtm.push({ event: "userTypeLoaded", userType: "subscriber" });
    }
    // Check if it's an IP Account
  } else if (account.ipaccount !== undefined) {
    // Show Subscriber Elements
    $body.addClass("user-is-ipaccount");

    // Send an Event with the User Type : ipaccount
    $body.attr("data-user", "ipaccount");
    $window.trigger("lesjours.user", "ipaccount");
    _mtm.push({ event: "userTypeLoaded", userType: "ipaccount" });
    // Check if it's a SSO Account
  } else if (account.ssolibrary !== undefined) {
    // Show Subscriber Elements
    $body.addClass("user-is-ssolibrary");

    // Send an Event with the User Type : ssolibrary
    $body.attr("data-user", "ssolibrary");
    $window.trigger("lesjours.user", "ssolibrary");
    _mtm.push({ event: "userTypeLoaded", userType: "ssolibrary" });
    // Check if we are in the CMS
  } else if (window.location.hostname.match(/(dev\.|local\.)?cms.lesjours.fr/)) {
    // Show Subscriber Elements
    $body.addClass("user-is-subscriber");

    // Send an Event with the User Type : subscriber
    $body.attr("data-user", "subscriber");
    $window.trigger("lesjours.user", "subscriber");
    _mtm.push({ event: "userTypeLoaded", userType: "subscriber" });
    // Check if the User is a Visitor
  } else {
    // Show Visitors Elements
    $body.addClass("user-is-visitor");

    // Send an Event with the User Type : visitor
    $body.attr("data-user", "visitor");
    $window.trigger("lesjours.user", "visitor");
    _mtm.push({ event: "userTypeLoaded", userType: "visitor" });
  }

  // Check if there is Matomo Tag Manager events
  if (Array.isArray(account.gaconfig?.evts)) {
    for (const event of account.gaconfig.evts) {
      _mtm.push(event);
    }
  }
}
