import $ from "jquery";
import { showStripePaymentForm } from "../components/stripe";
import { getObsessionPageViews, getUtmSourcesList } from "../libs/utm-pageviews";

const $form = $("div.subscription form");
const $fieldsets = $form.find("fieldset");
const $mySubscriptionForm = $form.find("#mon-abonnement").parent();
const $emailInput = $fieldsets.find("#subscription-mail");
const $plans = $fieldsets.filter("#mon-abonnement").find(".plan-wrapper");
const $planChoiceSelects = $plans.find(".select-plan");
const $payments = $fieldsets.find(".payments");
const $paymentChoiceBtns = $payments.find("label");
const $cards = $fieldsets.filter("#ma-carte").find(".label-wrapper");
const $cardChoiceBtns = $cards.find("label");
const $deliveries = $fieldsets.filter("#delivery").find(".radio-field");
const $deliveryChoiceBtns = $deliveries.find("label");
const $organizations = $fieldsets.filter("#mon-organisation-type").find(".label-wrapper");
const $orgaChoiceBtns = $organizations.find("label");
const $codeBtn = $form.find("#mon-code-special .btn-square");
const $textareas = $form.find("#email-delivery textarea, #postmail-delivery textarea");
const $summary = $form.find(".summary");
const $addressFields = $form
  .find("#coordonnees")
  .find("input[name=address], input[name=zip], input[name=city], select[name=country]");
const $taxReceiptInput = $form.find("#tax-receipt input");
let defaultPayment = "bank";

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FUNCTIONS                                                                                                          //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function addressFieldsAreRequired(state) {
  if (state === false) {
    $addressFields.each(function (idx, input) {
      const $input = $(input);
      $input.removeAttr("required");
      $input.parents(".field").removeClass("field-required");
      $input.removeClass("error");
    });
  } else {
    $addressFields.each(function (idx, input) {
      const $input = $(input);
      $input.attr("required", "required");
      $input.parents(".field").addClass("field-required");
    });
  }
}

function resetPlanSelection() {
  // Uncheck all Plan Inputs
  $plans.find("input[name=plan]").prop("checked", false);

  // Hide & Uncheck all Payment Inputs
  $payments.addClass("hidden").find("label").find("input[name=payment]").prop("checked", false);

  // Update required input
  addressFieldsAreRequired(false);
}

function selectPlan($plan) {
  // Get the clicked input
  const $planInput = $plan.find("input[name=plan]");
  const selected = $planInput.val();

  // Check the clicked button
  $planInput.prop("checked", true);

  // Show the Payment fieldset
  $payments.parents("fieldset").removeClass("hidden").prev().removeClass("hidden");

  // Show Payment Inputs related to the current Plan
  $payments.filter(`#payments-${selected}`).removeClass("hidden");

  // Select a default payment
  $payments
    .filter(`#payments-${selected}`)
    .find("label[data-payment=" + defaultPayment + "]")
    .trigger("click");
}

function selectPayment($payment) {
  // Uncheck all Payment Inputs
  $payments.find("input[name=payment]").prop("checked", false);

  // Check the clicked button
  $payment.find("input[name=payment]").prop("checked", true);

  // Save the selected payment
  defaultPayment = $payment.data("payment");

  // Update the .summary
  $summary
    .removeClass("hidden")
    .removeClass("bg-goldenrod bg-golden-grass bg-brandy-punch bg-tuscany bg-persian-red bg-mexican-red")
    .addClass("bg-" + $payment.data("bgColor"));
  $summary.find(".summary-label-1").html($payment.data("label-1"));
  $summary.find(".summary-label-2").html($payment.data("label-2"));

  // Update required input
  if ($payment.data("payment") === "bank" || $taxReceiptInput.is(":checked")) {
    // Address Fields are Required
    addressFieldsAreRequired(true);
  } else {
    // Address Fields are Optionnals
    addressFieldsAreRequired(false);
  }
}

function resetCardSelection() {
  // Uncheck all Card Inputs
  $cards.find("input[name=giftcard]").prop("checked", false);
  $cards.find(".btn-checkbox").removeClass("checked");

  // Hide & Uncheck all Delivery Inputs
  $deliveries.find("input[name=delivery]").prop("checked", false);
}

function selectCard($card) {
  // Add opacity to all other elements
  $card.find("input[name=giftcard]").prop("checked", true);
  $card.find(".btn-checkbox").addClass("checked");

  // Update the .summary
  $summary
    .removeClass("hidden")
    .removeClass("bg-goldenrod bg-golden-grass bg-brandy-punch bg-tuscany bg-persian-red bg-mexican-red")
    .addClass("bg-" + $card.data("bgColor"));
  $summary.find(".summary-label-1").html($card.data("label-1"));
  $summary.find(".summary-label-2").html($card.data("label-2"));
}

function selectDelivery($delivery) {
  // Uncheck all Delivery Inputs
  $deliveries.find("input[name=delivery]").prop("checked", false);

  // Get the clicked input
  const $deliveryInput = $delivery.find("input[name=delivery]");
  const selected = $deliveryInput.val();

  // Check the clicked button
  $deliveryInput.prop("checked", true);

  // Hide all delivery fieldset & update
  $fieldsets
    .filter("[id$=-delivery]")
    .addClass("hidden")
    .find("input, select")
    .each(function (idx, input) {
      const $input = $(input);
      $input.attr("disabled", "disabled");
      $input.removeAttr("required");
      $input.parents(".field").removeClass("field-required");
    });

  // Show the correct delivery fieldset
  $fieldsets
    .filter(`#${selected}-delivery`)
    .removeClass("hidden")
    .find("input, select")
    .each(function (idx, input) {
      const $input = $(input);
      $input.removeAttr("disabled");
      $input.attr("required", "required");
      $input.parents(".field").addClass("field-required");
    });
}

function selectOrganization($organization) {
  // Uncheck all Organization Inputs
  $organizations.find("input[name=organization_type]").prop("checked", false);
  $organizations.find(".btn-checkbox").removeClass("checked");

  // Add opacity to all other elements
  $organization.find("input[name=organization_type]").prop("checked", true);
  $organization.find(".btn-checkbox").addClass("checked");
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FORM CHANGE LISTENERS                                                                                              //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Listen Changes on the Form Input
$emailInput.on("change.lesjours.subscription", function () {
  // Input value
  const email = $emailInput.val();

  // Send the Email to the Server
  $.post(
    "/session?event-email=true",
    { "typed-email": email },
    function (data) {
      // Check if we know the Email
      if (data && data.emailExists) {
        // Preset the Email
        $("#login-existant.modal input#login-mail").val(email);

        // Display the Login Modal
        window.location.hash = "login-existant";
      }
    },
    "json"
  );
});

// Listen Plan Choice Events
$planChoiceSelects.on("click.lesjours.subscription", function (e) {
  // Prevent Default
  e.preventDefault();

  // Select the Plan
  resetPlanSelection();
  selectPlan($(e.currentTarget));
});

// Listen Payment Choice Events
$paymentChoiceBtns.on("click.lesjours.subscription", function (e) {
  // Prevent Default
  e.preventDefault();

  // Select the Payment
  selectPayment($(e.currentTarget));
});

// Listen Card Choice Events
$cardChoiceBtns.on("click.lesjours.subscription", function (e) {
  // Prevent Default
  e.preventDefault();

  // Select the Card
  resetCardSelection();
  selectCard($(e.currentTarget));
});

$deliveryChoiceBtns.on("click.lesjours.subscription", function (e) {
  // Prevent Default
  e.preventDefault();

  // Select the Organization
  selectDelivery($(e.currentTarget));
});

$orgaChoiceBtns.on("click.lesjours.subscription", function (e) {
  // Prevent Default
  e.preventDefault();

  // Select the Organization
  selectOrganization($(e.currentTarget));
});

$fieldsets
  .filter("#mon-organisation-acces, #mon-organisation-taille")
  .find("label")
  .on("click.lesjours.subscription", function (e) {
    // Prevent Default
    e.preventDefault();

    // Check/Uncheck the input
    const $input = $(e.currentTarget).find("input");
    $input.prop("checked", !$input.prop("checked"));
  });

// Listen Clics on Code Button
$codeBtn.on("click.lesjours.subscription", function (e) {
  // Prevent Default
  e.preventDefault();

  // Check if the Code Input is Valid
  if ($form.find("#mon-code-special input").is(":invalid")) {
    // Do nothing
    return;
  }

  // Refresh the Page with the Code
  const href = "/abonnement/code-special?code=" + encodeURIComponent($form.find("#mon-code-special input").val());
  if (window.location.href === window.location.origin + href) {
    window.location.reload();
  } else {
    window.location.href = href;
  }
});

// Bind keypress listener on Textarea to limit the textarea size
$textareas
  .on("keypress", function (event) {
    // Get the Textarea jQuery Element
    const $textarea = $(event.currentTarget);
    const rows = parseInt($textarea.attr("rows"));
    const maxlength = parseInt($textarea.attr("maxlength"));

    // Always allow delete keys
    if (event.keyCode === 8 || event.keyCode === 46) {
      // Stop limit processing
      return;
    }

    // Always allow event with CTRL Key
    if (event.metaKey || event.ctrlKey) {
      // Stop limit processing
      return;
    }

    // Check if the User has Press the ENTER Key
    if (event.keyCode === 13) {
      // Check if the user has reach the lines limit
      if (rows <= $textarea.val().split(/\r\n|[\r\n]/).length) {
        // Prevent the Default Action
        return event.preventDefault();
      }
    }

    // Check if the user has reach the size limit
    if (maxlength <= $textarea.val().length) {
      // Prevent the Default Action
      return event.preventDefault();
    }
  })
  // Bind keyup listener on Textarea to Update the Length Counter
  .on("keyup", function (event) {
    // Get the Textarea jQuery Element
    const $textarea = $(event.currentTarget);
    const maxlength = parseInt($textarea.attr("maxlength"));

    // Update the Remaining Chars Counter
    $textarea
      .siblings("small")
      .find("span.color-lesjours")
      .html(maxlength - $textarea.val().length + " restants");
  });

// Listen to click event on Tax Receipt checkbox
$taxReceiptInput.on("click.lesjours.subscription", function () {
  if ($(this).is(":checked") || $paymentChoiceBtns.find("input:checked").val() === "bank") {
    // Address Fields are Required
    addressFieldsAreRequired(true);
  } else {
    // Address Fields are Optionnals
    addressFieldsAreRequired(false);
  }
});

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// RELOAD FORMS                                                                                                       //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
resetPlanSelection();
resetCardSelection();
if (window.reloadForm !== undefined) {
  if (typeof window.reloadForm.plan === "string") {
    selectPlan($(`#${window.reloadForm.plan}`));

    if (typeof window.reloadForm.payment === "string") {
      selectPayment($(`#${window.reloadForm.plan}-${window.reloadForm.payment}`));
    }
  } else if (typeof window.reloadForm.giftcard === "string") {
    selectCard($(`#${window.reloadForm.giftcard}`));

    if (typeof window.reloadForm.delivery === "string") {
      selectDelivery($(`#${window.reloadForm.delivery}`));
    }
  }
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SCROLL TO THE PLAN & HIDE OTHER PLANS                                                                              //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Check if we have to scroll to a plan
if (window.scrollToPlan !== undefined && $fieldsets.find("#" + window.scrollToPlan).length > 0) {
  // Wait 100ms before scrolling
  setTimeout(function () {
    // Get Elements
    const $header = $("#header");
    const $selectedPlan = $fieldsets.find("#" + window.scrollToPlan);

    // Check if we have found something
    if ($selectedPlan.length > 0) {
      // Hide all other Plans
      $fieldsets.find(".plan-wrapper").each(function (idx, plan) {
        // Check if it's the selected plan
        if ($selectedPlan.get(0) !== plan) {
          // Hide the plan
          $(plan).addClass("hidden");
        }
      });

      // Move the Scroll to put the Question on the Top of the Window
      $("html, body").animate(
        {
          scrollTop: $selectedPlan.offset().top - $header.height(),
        },
        250,
        "swing"
      );
    }
  }, 100);
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ADD EXTRA FIELDS TO THE SUBSCRIPTION FORM                                                                          //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Create <input> in the #mon-abonnement fieldset for listUtmSources & obsessionsPV cookies
if ($mySubscriptionForm.length > 0) {
  // Get the UTM Source List
  let utmSourcesList = getUtmSourcesList();
  if (typeof utmSourcesList === "string" && utmSourcesList.length > 0) {
    $("<input>", {
      id: "list-utm-sources",
      name: "list-utm-sources",
      type: "hidden",
      value: utmSourcesList,
    }).prependTo($mySubscriptionForm);
  }

  // Get the Obsession Page Views Count
  let obsessionPageViews = getObsessionPageViews();
  if (typeof obsessionPageViews === "object" && Object.keys(obsessionPageViews).length > 0) {
    $("<input>", {
      id: "list-obsessions-pv",
      name: "list-obsessions-pv",
      type: "hidden",
      value: JSON.stringify(obsessionPageViews),
    }).prependTo($mySubscriptionForm);
  }
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// STRIPE                                                                                                             //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
if (window.stripePayment) {
  showStripePaymentForm(window.stripePayment);
}
