(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["modals/offered-episode-expired.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "modals/offered-episode-expired.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
output += "<div id=\"offered-episode-expired\" class=\"modal centered\" role=\"dialog\" tabindex=\"-1\">\n\t<div role=\"document\">\n\t\t<div class=\"row row-center\">\n\t\t\t<div class=\"col m-0 ph-0 pb-2 h5 fw-bold row row-center row-justify color-darkest\">\n\t\t\t\t<small class=\"pr-1\">";
output += runtime.suppressValue((lineno = 5, colno = 31, runtime.callWrap(t_4, "icon", context, ["gift-red"])), env.opts.autoescape);
output += "</small><span>Épisode offert</span>\n\t\t\t</div>\n\t\t\t<span class=\"a btn close\" data-href=\"#\">\n\t\t\t\t<span class=\"sr\">Fermer cette fenêtre</span>\n\t\t\t\t";
output += runtime.suppressValue((lineno = 9, colno = 11, runtime.callWrap(t_4, "icon", context, ["close"])), env.opts.autoescape);
output += "\n\t\t\t</span>\n\t\t\t<div class=\"col\">\n\t\t\t\tBonjour, ce lien a malheureusement expiré.\n\t\t\t\t<br />\n\t\t\t\tNous vous remercions de votre intérêt pour <i>Les&nbsp;Jours</i> et vous invitons à vous abonner pour continuer votre lecture et soutenir un média tenace, indépendant et sans publicité, dont les enquêtes ne sont financés que par ses lectrices et lecteurs. <a class=\"link text-inherit\" href=\"/abonnement/jouriste\">Je continue à lire <i>Les&nbsp;Jours</i></a>.\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("modals/offered-episode-expired.html", ctx, cb); }
})();
