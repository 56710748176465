import $ from "jquery";
import Cookies from "js-cookie";

$(function () {
  // Check if we are in the Mobile Application
  if (window.LesJours.isMobileApplication === true) {
    // We can't have a CMS Cookie
    return;
  }

  // Check if there is a CMS Cookie
  if (Cookies.get("LJcmsLoggedIn") && window.location.pathname.match(/^\/obsessions\/[a-zA-Z0-9-]+/)) {
    // Create the Button
    const isObsession = window.location.pathname.match(/^\/obsessions\/[a-zA-Z0-9-]+\/$/);
    const explodedPath = window.location.pathname.replace(/\/$/, "").split("/");
    const elementHref = explodedPath[explodedPath.length - 1];
    const cmslinkHref = "https://cms.lesjours.fr/" + (isObsession ? "obsessions" : "episodes") + "/href/" + elementHref;
    let cmslinkbutton = '<div class="cmslink">';
    cmslinkbutton += '<a class="cmslinkbutton" href="' + cmslinkHref + '" target="_blank">';
    cmslinkbutton += "Éditer";
    cmslinkbutton += "</a>";
    cmslinkbutton += "</div>";

    // Show a Button
    $(document.body).append(cmslinkbutton);
  }
});
