(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["episode/classic/mini.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"model")) {
var t_1;
t_1 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"doc");
frame.set("doc", t_1, true);
if(frame.topLevel) {
context.setVariable("doc", t_1);
}
if(frame.topLevel) {
context.addExport("doc", t_1);
}
output += "\n";
var t_2;
t_2 = ((!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"files") || !env.getFilter("test").call(context, /(webm|m4a)$/g,runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"files")),0)))?"aside":"figure");
frame.set("tag", t_2, true);
if(frame.topLevel) {
context.setVariable("tag", t_2);
}
if(frame.topLevel) {
context.addExport("tag", t_2);
}
output += "\n";
var t_3;
t_3 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"img")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"img"):env.getFilter("res").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"img"),120));
frame.set("img", t_3, true);
if(frame.topLevel) {
context.setVariable("img", t_3);
}
if(frame.topLevel) {
context.addExport("img", t_3);
}
var t_4;
t_4 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"alt")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"alt"):runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"alt"));
frame.set("alt", t_4, true);
if(frame.topLevel) {
context.setVariable("alt", t_4);
}
if(frame.topLevel) {
context.addExport("alt", t_4);
}
output += "\n";
if(runtime.contextOrFrameLookup(context, frame, "tag") == "aside" && (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"files") && !runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"img"))) {
var t_5;
t_5 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"href")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"href"):runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"files")),0));
frame.set("href", t_5, true);
if(frame.topLevel) {
context.setVariable("href", t_5);
}
if(frame.topLevel) {
context.addExport("href", t_5);
}
;
}
else {
var t_6;
t_6 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"href")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"href"):"#" + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"href"));
frame.set("href", t_6, true);
if(frame.topLevel) {
context.setVariable("href", t_6);
}
if(frame.topLevel) {
context.addExport("href", t_6);
}
;
}
output += "\n";
var t_7;
t_7 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"title")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"title"):runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"title"));
frame.set("title", t_7, true);
if(frame.topLevel) {
context.setVariable("title", t_7);
}
if(frame.topLevel) {
context.addExport("title", t_7);
}
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"files") && !runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"img"))) {
var t_8;
t_8 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"subtitle")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"subtitle"):runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"desc"));
frame.set("subtitle", t_8, true);
if(frame.topLevel) {
context.setVariable("subtitle", t_8);
}
if(frame.topLevel) {
context.addExport("subtitle", t_8);
}
var t_9;
t_9 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"caption")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"caption"):runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"meta"));
frame.set("caption", t_9, true);
if(frame.topLevel) {
context.setVariable("caption", t_9);
}
if(frame.topLevel) {
context.addExport("caption", t_9);
}
;
}
else {
var t_10;
t_10 = (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"subtitle")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"subtitle"):runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"subtitle"));
frame.set("subtitle", t_10, true);
if(frame.topLevel) {
context.setVariable("subtitle", t_10);
}
if(frame.topLevel) {
context.addExport("subtitle", t_10);
}
var t_11;
t_11 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"caption");
frame.set("caption", t_11, true);
if(frame.topLevel) {
context.setVariable("caption", t_11);
}
if(frame.topLevel) {
context.addExport("caption", t_11);
}
;
}
output += "<";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "tag"), env.opts.autoescape);
output += " id=\"mini-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"number"), env.opts.autoescape);
output += "\" class=\"mini mini-episode lg-w-3c\">\n";
if(runtime.contextOrFrameLookup(context, frame, "tag") == "figure") {
output += "\t<div class=\"player-surrounded relative js-audio-player\" data-type=\"circle\">\n\t\t<svg width=\"32\" height=\"32\" class=\"ui-player-progress initial\" role=\"img\" viewBox=\"0 0 120 120\">\n\t\t\t<circle cx=\"60\" cy=\"60\" r=\"54\" fill=\"none\" stroke=\"#f3df93\" stroke-width=\"10\" class=\"progress-circle js-jump-btn\"/>\n\t\t\t<circle cx=\"60\" cy=\"60\" r=\"54\" fill=\"none\" stroke=\"#e8c131\" stroke-width=\"10\" class=\"progress-buffer js-player-buffer\"/>\n\t\t\t<circle cx=\"60\" cy=\"60\" r=\"54\" fill=\"none\" stroke=\"#9a7d11\" stroke-width=\"10\" class=\"progress-player js-player-progress\"/>\n\t\t\t<circle cx=\"60\" cy=\"60\" r=\"42\" fill=\"#fff\" class=\"progress-button js-play-btn\"/>\n\t\t</svg>\n\t\t<div class=\"overlay container\">\n\t\t\t<audio aria-hidden=\"true\">\n";
frame = frame.push();
var t_14 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "doc")),"files");
if(t_14) {t_14 = runtime.fromIterator(t_14);
var t_13 = t_14.length;
for(var t_12=0; t_12 < t_14.length; t_12++) {
var t_15 = t_14[t_12];
frame.set("source", t_15);
frame.set("loop.index", t_12 + 1);
frame.set("loop.index0", t_12);
frame.set("loop.revindex", t_13 - t_12);
frame.set("loop.revindex0", t_13 - t_12 - 1);
frame.set("loop.first", t_12 === 0);
frame.set("loop.last", t_12 === t_13 - 1);
frame.set("loop.length", t_13);
output += "\t\t\t\t<source type=\"";
output += runtime.suppressValue(env.getFilter("mimeType").call(context, t_15), env.opts.autoescape);
output += "\" src=\"";
output += runtime.suppressValue(t_15, env.opts.autoescape);
output += "\" />\n";
;
}
}
frame = frame.pop();
output += "\t\t\t</audio>\n\t\t\t<button type=\"button\">\n\t\t\t\t<div class=\"container\">\n\t\t\t\t\t<img class=\"responsive lazyload\" src=\"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7\" data-src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "img"), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.contextOrFrameLookup(context, frame, "alt"))), env.opts.autoescape);
output += "\" />\n\t\t\t\t</div>\n\t\t\t</button>\n\t\t</div>\n\t</div>\n";
;
}
output += "\t<a class=\"link-unstyled\" href=\"";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "href"),"#"), env.opts.autoescape);
output += "\"";
if(env.getFilter("startsWith").call(context, env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "href"),"#"),"http")) {
output += " target=\"_blank\" rel=\"noopener\"";
;
}
output += ">\n";
if(runtime.contextOrFrameLookup(context, frame, "tag") == "aside") {
output += "\t\t<div class=\"image relative\">\n\t\t\t<svg width=\"32\" height=\"32\" class=\"ui-image-circle initial\" role=\"img\" viewBox=\"0 0 120 120\">\n\t\t\t\t<circle cx=\"60\" cy=\"60\" r=\"54\" fill=\"none\" stroke=\"#c83e2c\" stroke-width=\"10\" class=\"image-circle\"/>\n\t\t\t\t<circle cx=\"60\" cy=\"60\" r=\"42\" fill=\"#fff\" class=\"image-button\"/>\n\t\t\t</svg>\n\t\t\t<div class=\"overlay container\">\n\t\t\t\t<img class=\"responsive lazyload\" src=\"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7\" data-src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "img"), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.contextOrFrameLookup(context, frame, "alt"))), env.opts.autoescape);
output += "\" />\n\t\t\t</div>\n\t\t</div>\n";
;
}
output += "\t\t<div class=\"title\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
output += "</div>\n\t\t<div class=\"subtitle\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subtitle"), env.opts.autoescape);
output += "</div>\n\t</a>\n";
if(runtime.contextOrFrameLookup(context, frame, "caption")) {
if(runtime.contextOrFrameLookup(context, frame, "tag") == "figure") {
output += "\t\t\t<figcaption>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "caption"), env.opts.autoescape);
output += "</figcaption>\n";
;
}
else {
output += "\t\t\t<div class=\"caption\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "caption"), env.opts.autoescape);
output += "</div>\n";
;
}
;
}
output += "</";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "tag"), env.opts.autoescape);
output += ">\n";
;
}
else {
output += "<aside id=\"mini-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"number"), env.opts.autoescape);
output += "\" class=\"mini mini-episode lg-w-3c\">\n\t<a class=\"link-unstyled\" href=\"";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"href"),"#"), env.opts.autoescape);
output += "\"";
if(env.getFilter("startsWith").call(context, env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"href"),"#"),"http")) {
output += " target=\"_blank\" rel=\"noopener\"";
;
}
output += ">\n\t\t<div class=\"image relative\">\n\t\t\t<svg width=\"32\" height=\"32\" class=\"ui-image-circle initial\" role=\"img\" viewBox=\"0 0 120 120\">\n\t\t\t\t<circle cx=\"60\" cy=\"60\" r=\"54\" fill=\"none\" stroke=\"#c83e2c\" stroke-width=\"10\" class=\"image-circle\"/>\n\t\t\t\t<circle cx=\"60\" cy=\"60\" r=\"42\" fill=\"#fff\" class=\"image-button\"/>\n\t\t\t</svg>\n\t\t\t<div class=\"overlay container\">\n\t\t\t\t<img class=\"responsive lazyload\" src=\"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7\" data-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"img"), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"alt"))), env.opts.autoescape);
output += "\" />\n\t\t\t</div>\n\t\t</div>\n\t\t<div class=\"title\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"title"), env.opts.autoescape);
output += "</div>\n\t\t<div class=\"subtitle\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"subtitle"), env.opts.autoescape);
output += "</div>\n\t</a>\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"caption")) {
output += "\t\t<div class=\"caption\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "mini")),"caption"), env.opts.autoescape);
output += "</div>\n";
;
}
output += "</aside>\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("episode/classic/mini.html", ctx, cb); }
})();
