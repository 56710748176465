/* globals Modernizr:true */
import $ from "jquery";
import Hammer from "hammerjs";

// Constants
const $window = $(window);

// Get the CSS Transition End Event
function getTransitionEndEvent() {
  // Transition End Event
  const transitionEndEventNames = {
    WebkitTransition: "webkitTransitionEnd", // Saf 6, Android Browser
    MozTransition: "transitionend", // Only for FF < 15
    OTransition: "oTransitionEnd", // Only for Opera
    msTransition: "MSTransitionEnd", // Only for IE < 10
    transition: "transitionend", // IE10, Opera, Chrome, FF 15+, Saf 7+
  };

  // Browser Transition Prefix
  const prefix = Modernizr.csstransitions ? Modernizr.prefixed("transition") : false;

  // Check if the Browser Support Animations
  if (prefix && transitionEndEventNames[prefix] !== undefined) {
    // Return the Correct Event
    return transitionEndEventNames[prefix] + ".lesjours.slider";
  } else {
    // Return false
    return false;
  }
}

// Variables
const transitionEndEvent = getTransitionEndEvent();

// UTILITIES
// Stop the Propagation
function stopPropagation(e) {
  // Prevent Default
  e.preventDefault();
  e.stopPropagation();
}

// Disable the buttons
function disableButtons($slider, direction) {
  // Check if we already moving the slider
  if ($slider.data("process-event") === true) {
    return;
  }
  $slider.data("process-event", true);

  // Check if we can move the slider
  if (!canMove($slider, direction)) {
    // We have reach a bound
    // Stop Moving the Slider
    return $slider.data("process-event", false);
  }

  // Ensure the Infinity Loop for a Slider
  ensureInfinityLoop($slider, direction, function () {
    // Move the Slider
    moveSlider($slider, direction);
  });
}

// To check if we can move the sliders
function canMove($slider, direction) {
  if (direction > 0 && !$slider.hasClass("right-reached")) {
    return true;
  } else if (direction < 0 && !$slider.hasClass("left-reached")) {
    return true;
  } else {
    return false;
  }
}

// INFINITY LOOP FOR INFINITE SLIDERS :
// Click Event
function ensureInfinityLoop($slider, direction, callback) {
  // Check if it's an infinite slider
  if ($slider.data("slider-infinite") !== true) {
    // Call the callback directly
    return callback();
  }

  // Constants
  const $container = $slider.find(".slider-container");
  const $slides = $container.children();
  const $curSlide = $slides.filter(".active");
  const moveCounter = Math.abs(direction);
  const sliderWidth = $slider.innerWidth();

  // Variables
  let $pivot = $curSlide;
  let wCounter = 0;
  let beforeWidth = 0;

  // Check if we are moving to the right
  if (direction > 0) {
    // The active slide is at the left of the screen
    // We have to use the slide at the right of the screen as pivot
    wCounter = $pivot.outerWidth(true);
    $pivot.nextAll().each(function (idx, el) {
      // Check if the element is outside the visible area
      if (sliderWidth < wCounter) {
        return;
      }

      // Update the width counter & the pivot
      $pivot = $(el);
      wCounter += $pivot.outerWidth(true);
    });
  }

  // Check if we have enough slides before the end
  if (
    (direction > 0 && moveCounter < $pivot.nextAll().length) ||
    (direction < 0 && moveCounter < $pivot.prevAll().length)
  ) {
    // There is enough remaining slides
    // Call the callback directly
    return callback();
  }

  // Check the direction
  if (direction > 0) {
    // Move "moveCounter" slides from the begining to the end of the slider
    $slides.slice(0, moveCounter).appendTo($container);
  } else {
    // Move "moveCounter" slides from the end to the begining of the slider
    $slides.slice(-moveCounter).prependTo($container);
  }

  // Get the Width of Previous Elements
  $curSlide.prevAll().each(function (idx, el) {
    beforeWidth -= $(el).outerWidth(true);
  });

  // Update the Transform Property
  $container.css("left", beforeWidth + "px");

  // Free the stack to let the browser apply changes before calling the callback
  setTimeout(callback, 10);
}

// Pan Event
function ensurePanInfinityLoop($slider, direction, callback) {
  // Check if it's an infinite slider
  if ($slider.data("slider-infinite") !== true) {
    // Call the callback directly
    return callback();
  }

  // Constants
  const $container = $slider.find(".slider-container");
  const $slides = $container.children();
  const $curSlide = $container.find(".active");
  const sliderWidth = $slider.innerWidth();
  const containerWidth = $container.data("width");
  const moveWidth = $curSlide.innerWidth() * 2;
  const moveCounter = 2;

  // Variables
  let $pivot = $curSlide;
  let wCounter = 0;
  let beforeWidth = 0;
  let afterWidth = $slider.data("after-width");
  let sliderPos = $container.offset().left;

  // Check if we are moving to the right
  if (direction > 0) {
    // The active slide is at the left of the screen
    // We have to use the slide at the right of the screen as pivot
    wCounter = $pivot.outerWidth(true);
    $pivot.nextAll().each(function (idx, el) {
      // Check if the element is outside the visible area
      if (sliderWidth < wCounter) {
        return;
      }

      // Update the width counter & the pivot
      $pivot = $(el);
      wCounter += $pivot.outerWidth(true);
    });
  }

  // Check the distance left before the end
  if (direction > 0) {
    $pivot.prevAll().each(function (idx, el) {
      beforeWidth += $(el).innerWidth();
    });
  } else {
    $pivot.nextAll().each(function (idx, el) {
      beforeWidth += $(el).innerWidth();
    });
  }

  // If we have enough distance left
  if (beforeWidth < containerWidth - moveWidth) {
    // Move the slider
    return callback();
  }

  // Check the direction
  if (direction > 0) {
    // Move "moveCounter" slides from the begining to the end of the slider
    $slides.slice(0, moveCounter).appendTo($container);
    // Update the slider position
    sliderPos += moveWidth;
    // Update the after width to cancel the right-reached
    afterWidth += moveWidth;
  } else {
    // Move "moveCounter" slides from the end to the begining of the slider
    $slides.slice(-moveCounter).prependTo($container);
    // Update the slider position
    sliderPos -= moveWidth;
    // Update the after width to cancel the left-reached
    afterWidth -= moveWidth;
  }

  // Update the slider position
  $container.css("left", sliderPos);
  // Update the "after-width" data
  $slider.data("after-width", afterWidth);

  // Move the slider
  setTimeout(callback, 10);
}

// STOP THE SLIDERS :
// Click Event (CSS transition end)
function transitionEnd(event) {
  // Get Slider Elements
  const $current = $(event.currentTarget);
  const $slider = $current.parents(".slider");

  // Check if the event has been processed
  if ($current.data("transition-processed") === true) {
    // Stop processing the event
    return;
  }

  // Check if there is a Timer
  if ($current.data("transition-timer") !== undefined) {
    // Cancel the Timer
    clearTimeout($current.data("transition-timer"));
  }

  // Flag the Transition as Processed
  $current.data("transition-timer", undefined);
  $current.data("transition-processed", true);

  // Remove the .moving class
  $slider.removeClass("moving");

  // Flag the event as processed
  $slider.data("process-event", false);
}

// Pan Event
function stopSlider(event) {
  const $slider = $(event.currentTarget).parent(".slider");

  // Reset delta data
  $slider.data("delta", 0);

  // Stop process event
  $slider.data("process-event", false);

  // Check if we reached too far on the left
  if ($slider.data("afterWidth") > $slider.find(".slider-container").data("width")) {
    $slider.find(".slider-container").css({ left: 0 });
    $slider.data("beforeWidth", 10);
    $slider.data("afterWidth", $slider.find(".slider-container").data("width"));
  }
}

// MOVE THE SLIDERS :
// Click Event
function moveSlider($slider, direction) {
  // Start Moving the Slider
  $slider.addClass("moving");

  // Constants
  const $container = $slider.find(".slider-container");
  const $slides = $container.children();
  const $curSlide = $slides.filter(".active");
  const sliderWidth = $slider.innerWidth();
  const sliderMoreWidth = $slider.find(".slider-more").length > 0 ? $slider.find(".slider-more").outerWidth() : 0;

  // Variables
  let moveCounter = Math.abs(direction);
  let beforeWidth = 0;
  let afterWidth = 0;
  let $nextSlide;

  // Check if there is a next/previous slide
  if (direction > 0) {
    // Get the Number of Slides to Move
    moveCounter = Math.min(moveCounter, $curSlide.nextAll().length);

    // We can move the slider to the next position
    $nextSlide = $curSlide;
    while (moveCounter > 0) {
      $nextSlide = $nextSlide.next();
      moveCounter -= 1;
    }
  } else {
    // Get the Number of Slides to Move
    moveCounter = Math.min(moveCounter, $curSlide.prevAll().length);

    // We can move the slider to the previous position
    $nextSlide = $curSlide;
    while (moveCounter > 0) {
      $nextSlide = $nextSlide.prev();
      moveCounter -= 1;
    }
  }

  // Get the Width of Previous Elements
  $nextSlide.prevAll().each(function (idx, el) {
    beforeWidth -= $(el).outerWidth(true);
  });

  // Update the Active Slide
  $curSlide.removeClass("active");
  $nextSlide.addClass("active");

  // Listen Transition End Event
  $container.one(transitionEndEvent, transitionEnd);

  // Update Left Button Display
  if ($nextSlide.index() === 0) {
    $slider.addClass("left-reached");
  } else {
    $slider.removeClass("left-reached");
  }

  // Update Right Button Display
  // Check if all Remaining Elements fit inside the container
  afterWidth = $nextSlide.outerWidth(true);
  $nextSlide.nextAll().each(function (idx, el) {
    afterWidth += $(el).outerWidth(true);
  });
  if (afterWidth - sliderMoreWidth < sliderWidth) {
    $slider.addClass("right-reached");

    // For a better display we must move the container
    // to put the last slide at the right of the container
    beforeWidth += sliderWidth - afterWidth;
  } else if ($nextSlide.index() === $slides.length - 1) {
    $slider.addClass("right-reached");
  } else {
    $slider.removeClass("right-reached");
  }

  // Updata slider data for before/after width
  $slider.data("before-width", beforeWidth);
  $slider.data("after-width", afterWidth);

  // Animate the Slider
  $container.css("left", $slider.data("before-width") + "px");

  // Add a Timer to trigger the Event Mannualy if the Browser does not fire the Event
  $container.data("transition-processed", false);
  $container.data(
    "transition-timer",
    setTimeout(function () {
      // Fire the Event
      $container.trigger(transitionEndEvent, { currentTarget: $container });
    }, 250)
  );
}

// Pan Event
function panSlider($slider, direction) {
  // Constants
  const $container = $slider.find(".slider-container");
  const $slides = $container.children();
  const sliderWidth = $slider.innerWidth();

  // Variables
  let $curSlide = $slides.filter(".active");
  let moveCounter = 0;

  // Animate the Slider
  $container.css({ left: "-=" + direction });

  // Add beforeWidth to the datas
  $slider.data("before-width", $container.offset().left);
  $slider.data("after-width", $slider.data("after-width") - direction);

  // Count the number of slides before the slider
  moveCounter = Math.trunc(-($slider.data("before-width") / $curSlide.outerWidth(true)));
  $slides.each(function (idx, el) {
    // Change the current slide
    if (idx === moveCounter) {
      $curSlide = $(el);
    }
  });

  // Change the active slide
  $slides.removeClass("active");
  $curSlide.addClass("active");

  // Update Left Button Display
  if ($slider.data("after-width") >= $container.data("width")) {
    $slider.addClass("left-reached");
  } else {
    $slider.removeClass("left-reached");
  }

  // If we reached the right side of the slider
  if ($slider.data("after-width") < sliderWidth) {
    // Add right-reached class
    $slider.addClass("right-reached");
  } else if ($curSlide.index() === $slides.length - 1) {
    $slider.addClass("right-reached");
  } else {
    $slider.removeClass("right-reached");
  }
}

// START THE SLIDERS :
// Click Event
function slide(event) {
  // Stop the Propagation
  stopPropagation(event);

  // Check if it's a Pan/Click event
  if ($(event.currentTarget).attr("data-direction") === "forward") {
    disableButtons($($(event.currentTarget).parents(".slider").get(0)), $window.width() >= 840 ? 2 : 1);
  } else if ($(event.currentTarget).attr("data-direction") === "backward") {
    disableButtons($($(event.currentTarget).parents(".slider").get(0)), -($window.width() >= 840 ? 2 : 1));
  }
}

// Pan Event
function startPanSlider(event) {
  // Avoid the pan-y slide issue
  if (event.gesture.srcEvent.type === "pointercancel") {
    return;
  }

  // Stop the Propagation
  stopPropagation(event);

  const $slider = $(event.currentTarget).parent(".slider");
  const deltaX = event.gesture.deltaX;
  const direction = $slider.data("delta") - deltaX;

  // Stock the current move in the data
  $slider.data("delta", deltaX);

  // Check if we can move the slider
  if (!canMove($slider, direction)) {
    // Stop Moving the Slider
    return;
  }

  // Ensure the Infinity Loop for a Slider
  ensurePanInfinityLoop($slider, direction, function () {
    // Move the Slider
    panSlider($slider, direction);
  });
}

// INITIALIZE THE SLIDERS :
function initializeSlider($slider, sliderWidth) {
  // Constants
  const $container = $slider.find(".slider-container");
  const $slides = $container.children();
  const $curSlide = $slides.filter(".active");
  const $buttons = $slider.find(".slider-controls button");

  // Variables
  let beforeWidth = 0;
  let afterWidth = 0;

  // Slider Datas
  $slider.data("delta", 0);
  $slider.data("before-width", 0);
  $slider.data("after-width", 0);
  $container.data("width", 0);
  $container.data("traveled-distance", 0);

  // Check if the Slider has already been Inizialized
  if ($slider.data("slider-initialized") === true) {
    return;
  }
  $slider.data("slider-initialized", true);

  // Bind Click & Swipe Events
  $buttons.filter("button[data-direction=forward]").on("click.lesjours.slider", slide);
  $buttons.filter("button[data-direction=backward]").on("click.lesjours.slider", slide);
  $container.hammer({ direction: Hammer.DIRECTION_HORIZONTAL }).on("pan.lesjours.slider", startPanSlider);
  $container.hammer({ direction: Hammer.DIRECTION_HORIZONTAL }).on("panend.lesjours.slider", stopSlider);

  // Get the width of previous elements
  $curSlide.prevAll().each(function (idx, el) {
    beforeWidth -= $(el).outerWidth(true);
  });

  // Get the width of next elements
  afterWidth = $curSlide.outerWidth(true);
  $curSlide.nextAll().each(function (idx, el) {
    afterWidth += $(el).outerWidth(true);
  });

  // Update Buttons Display
  if ($curSlide.index() === 0) {
    $slider.addClass("left-reached");
  }
  if (-beforeWidth + afterWidth <= sliderWidth) {
    // Disable the slider
    $slider.addClass("left-reached");
    $slider.addClass("right-reached");

    // Put the first slide at the left of the container
    beforeWidth = 0;
  } else if (afterWidth < sliderWidth) {
    $slider.addClass("right-reached");

    // For a better display we must move the container
    // to put the last slide at the right of the container
    beforeWidth += sliderWidth - afterWidth;
  } else if ($curSlide.index() === $slides.length - 1) {
    $slider.addClass("right-reached");
  }

  // Set slider datas
  $slider.data("after-width", afterWidth);
  $slider.data("before-width", beforeWidth);
  $container.data("width", afterWidth);

  // Animate the Slider
  $container.css("left", $slider.data("before-width") + "px");
}

// Listen Component Init Events
$window.on("ljComponentsInit", function (event, component, $slider, sliderWidth) {
  if (component === "slider") {
    initializeSlider($slider, sliderWidth);
  }
});
