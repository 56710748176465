import $ from "jquery";

// Listen Events on Years Tabs
$("#par-date [role=tablist]:last [role=tab]").on("click.lesjours.obsessions", function () {
  // Scroll to the Top of the Page
  $("html, body").animate(
    {
      scrollTop: 0,
    },
    500,
    "swing"
  );
});
