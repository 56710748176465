(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["obsession/place-modal.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("_macro.html", false, "obsession/place-modal.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "icon")) {
var t_4 = t_1.icon;
} else {
cb(new Error("cannot import 'icon'")); return;
}
context.setVariable("icon", t_4);
if(Object.prototype.hasOwnProperty.call(t_1, "pagination")) {
var t_5 = t_1.pagination;
} else {
cb(new Error("cannot import 'pagination'")); return;
}
context.setVariable("pagination", t_5);
output += "<div id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"href"), env.opts.autoescape);
output += "\" class=\"modal centered\" role=\"dialog\" tabindex=\"-1\">\n\t<div class=\"w-100 h-100 ph-0 pt-0 fullscreen\" role=\"document\">\n\t\t<div class=\"row h-100 m-0 row-vertical\">\n\t\t\t<div class=\"col mv-2 obsession-name\">\n\t\t\t\t<span class=\"link on-hover lj-color-text text-upper\" data-href=\"/obsessions/";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"href"), env.opts.autoescape);
output += "/\">\n\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"name"), env.opts.autoescape);
output += "\n\t\t\t\t</span>\n\t\t\t</div>\n\t\t\t<div class=\"col mb-2 style-meta-large sm-hidden\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"title"), env.opts.autoescape);
output += "</div>\n\t\t\t<span class=\"a btn close\" data-href=\"#\">\n\t\t\t\t<span class=\"sr\">Fermer cette fenêtre</span>\n\t\t\t\t";
output += runtime.suppressValue((lineno = 12, colno = 11, runtime.callWrap(t_4, "icon", context, ["close"])), env.opts.autoescape);
output += "\n\t\t\t</span>\n\t\t\t<div class=\"col flex-1 relative overflow-auto\">\n\t\t\t\t<div class=\"overlay container fluid\">\n\t\t\t\t\t<div class=\"row\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"gmap")) {
output += "\t\t\t\t\t\t<div class=\"col mb-2 lg-w-50\">\n\t\t\t\t\t\t\t<iframe class=\"lazyload\" data-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"gmap"), env.opts.autoescape);
output += "\" width=\"100%\" height=\"500\" frameborder=\"0\" style=\"border: 0;\"></iframe>\n\t\t\t\t\t\t</div>\n";
;
}
else {
output += "\t\t\t\t\t\t<div class=\"col mb-2 lg-h-100 lg-w-auto lg-min-w-33 lg-max-w-60\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"video") && (runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"video")),"type") == "paysage")) {
output += "\t\t\t\t\t\t\t<video class=\"responsive\" ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"video")),"autoplay")) {
output += "autoplay loop muted";
;
}
else {
output += "controls";
;
}
output += " playsinline controlsList=\"nodownload\">\n";
frame = frame.push();
var t_8 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"files");
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("source", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
output += "\t\t\t\t\t\t\t\t\t<source type=\"";
output += runtime.suppressValue(env.getFilter("mimeType").call(context, t_9), env.opts.autoescape);
output += "\" src=\"";
output += runtime.suppressValue(t_9, env.opts.autoescape);
output += "\" />\n";
;
}
}
frame = frame.pop();
output += "\t\t\t\t\t\t\t</video>\n";
;
}
else {
output += "\t\t\t\t\t\t\t<img class=\"responsive lazyload\" data-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"img"), env.opts.autoescape);
output += "\" data-sizes=\"";
output += runtime.suppressValue(env.getFilter("sizes").call(context, ["100vw","100vw","60vw"]), env.opts.autoescape);
output += "\" data-srcset=\"";
output += runtime.suppressValue(env.getFilter("srcset").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"img")), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(env.getFilter("unescape").call(context, env.getFilter("removeTags").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"alt"))), env.opts.autoescape);
output += "\" />\n";
;
}
output += "\t\t\t\t\t\t</div>\n";
;
}
output += "\t\t\t\t\t\t<div class=\"col mb-1 flex-1 flex-basis-0 min-w-0 underlined-links col-bottom\">\n";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"subtitle")) {
output += "\t\t\t\t\t\t\t<span class=\"block mb-0 style-meta-large light\">\n\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"subtitle"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t</span>\n";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"desc")) {
output += "\t\t\t\t\t\t\t<span class=\"block mb-2 fs-italic\">\n\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"desc"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t</span>\n";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"credit")) {
output += "\t\t\t\t\t\t\t<span class=\"block mb-2 credits\">\n\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"credit"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t</span>\n";
;
}
if(runtime.contextOrFrameLookup(context, frame, "loop")) {
output += "\t\t\t\t\t\t\t<div class=\"row m-0 row-center lg-pull-right\">\n\t\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 52, colno = 21, runtime.callWrap(t_5, "pagination", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "obsession")),"places"),runtime.contextOrFrameLookup(context, frame, "loop"),false])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t</div>\n";
;
}
output += "\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("obsession/place-modal.html", ctx, cb); }
})();
