(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["mes-jours/search-results.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<table class=\"mh-3 mv-1\">\n\t<tbody>\n";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "results")),"series");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("result", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\t\t<tr>\n\t\t\t<td>\n\t\t\t\t<span class=\"search-type\">\n\t\t\t\t\t";
output += runtime.suppressValue((runtime.memberLookup((t_4),"model") === "Obsession"?"Obsession":"Podcast"), env.opts.autoescape);
output += "\n\t\t\t\t</span>\n\t\t\t</td>\n\t\t\t<td>\n\t\t\t\t<span class=\"search-result pointer\" data-model=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"model"), env.opts.autoescape);
output += "\" data-";
output += runtime.suppressValue(env.getFilter("lower").call(context, runtime.memberLookup((t_4),"model")), env.opts.autoescape);
output += "=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_4),"document")),"href"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t<span>";
output += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((t_4),"document")),"highlight")?runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((t_4),"document")),"highlight")),"title"):runtime.memberLookup((runtime.memberLookup((t_4),"document")),"title")), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t<span class=\"ml-2 color-medium-5\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_4),"document")),"subtitle"), env.opts.autoescape);
output += "</span>\n\t\t\t\t</span>\n\t\t\t</td>\n\t\t</tr>\n";
;
}
}
frame = frame.pop();
output += "\t</tbody>\n</table>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
return function(ctx, cb) { return nunjucks.render("mes-jours/search-results.html", ctx, cb); }
})();
