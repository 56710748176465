import $ from "jquery";
import { getObsessionPageViews } from "../libs/utm-pageviews";

const $onboarding = $("#onboarding");
const $onboardingSteps = $onboarding.find("#confirmation-newsletter, #confirmation-series, #confirmation-application");
const $series = $onboarding.find("#confirmation-series-list");
const $onboardingStep1Btn = $onboarding.find("#confirmation-newsletter .js-onboarding-send-form");
const $onboardingStep2Btn = $onboarding.find("#confirmation-series .js-onboarding-go-next");

// Check if we have to load the Obsessions List
if ($series.length > 0) {
  // Load the Obsessions List
  $.loadJsonData("/series.json").then(function (series) {
    const obsessionPageViews = getObsessionPageViews();
    if (typeof obsessionPageViews === "object" && Object.keys(obsessionPageViews).length > 0) {
      series = series.sort(function (a, b) {
        const aTime = new Date(a.document.lastPublication).getTime();
        const bTime = new Date(b.document.lastPublication).getTime();
        const aPV = obsessionPageViews[a.document.href] || 0;
        const bPV = obsessionPageViews[b.document.href] || 0;

        if (aPV > bPV) {
          return -1;
        }
        if (aPV < bPV) {
          return 1;
        }
        if (aTime > bTime) {
          return -1;
        }
        if (aTime < bTime) {
          return 1;
        }
        return 0;
      });
    }

    // Take only the 12 first series
    series = series.slice(0, 12);

    // Show the slider
    $series.append(window.nunjucks.render("series/recent-series-list.html", { series: series }));
  });
}

// Send the newsletters form
$onboardingStep1Btn.on("click.lesjours.onboarding", function (e) {
  // Prevent Default
  e.preventDefault();

  // Get the Form
  const $form = $(e.currentTarget).parents("form").eq(0);

  // Send the Form
  $.ajax({
    url: $form.attr("action"),
    method: $form.attr("method").toUpperCase(),
    data: new FormData($form.get(0)),
    processData: false,
    contentType: false,
  }).then(function () {
    // Hide all steps except the second one
    $onboardingSteps.addClass("hidden").filter("#confirmation-series").removeClass("hidden");

    // Scroll to the top
    $("html, body").animate({ scrollTop: 0 }, 500, "swing");
  });
});

// Show the last onboarding step
$onboardingStep2Btn.on("click.lesjours.onboarding", function (e) {
  // Prevent Default
  e.preventDefault();

  // Hide all steps except the last one
  $onboardingSteps.addClass("hidden").filter("#confirmation-application").removeClass("hidden");

  // Scroll to the top
  $("html, body").animate({ scrollTop: 0 }, 500, "swing");
});
