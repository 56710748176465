import $ from "jquery";
import throttle from "lodash/throttle";
import * as MesJours from "../libs/mesjours";

// Variables
const $window = $(window);
const $header = $("#header");
const $actions = $("#actions");
const $modals = $(".podcast-modals-container");
const $series = $("section#series");

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PODCAST SCROLL EVENTS HANDLER FUNCTIONS : HEADER                                                                   //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function podcastScrollListener() {
  // Get Current Configuration
  const $headerCnt = $header.find(".header");
  const curScroll = $window.scrollTop();
  const viewport = $window.height();
  const $articleEl = $("article #podcast-media");
  const offset = $articleEl.eq(0).offset().top;

  // Check if the user has scrolled
  if (curScroll === 0 || curScroll + viewport < offset) {
    // The user hasn't scrolled already : display the regular header
    $headerCnt.removeClass("header-mode-episode");
  } else {
    // The user has already scrolled : display the episode header
    $headerCnt.addClass("header-mode-episode");
  }
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SERIES SLIDER
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function initializeSeriesSlider() {
  const $slider = $("section#series .slider");
  const cntWidth = $slider.innerWidth();

  // Initialize the Slider
  $window.trigger("ljComponentsInit", ["slider", $slider, cntWidth]);
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// LOAD REQUIRED JSONS                                                                                                //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$.when(
  // Load the Series JSON File
  window.LesJours.series !== undefined
    ? $.Deferred().resolve([window.LesJours.series, "success"]).promise()
    : $.loadJsonData("/series.json")
).then(function (series) {
  // Get the Podcast from window.LesJours
  const podcast = window.LesJours.podcast;

  // Load more stuff for the website
  if (!window.LesJours.isMobileApplication) {
    // Add Sharing Buttons to the DOM
    $modals.append(window.nunjucks.render("modals/download.html", podcast.download));
    $actions.append(
      window.nunjucks.render("actions/share.html", {
        share: podcast.share,
        withDownload: true,
        withGift: false,
        mtmCampaign: "podcast-sharing",
        mtmContent: `${podcast.share.programme}+${podcast.share.podcast}`,
      })
    );
    $window.trigger("ljComponentsReload", "actions");

    // Load Series Covers
    $series.append(window.nunjucks.render("series/recent-series-slider.html", { series: series }));
    initializeSeriesSlider();
  }

  // Refresh Mes Jours
  if (!window.LesJours.isMobileApplication) {
    MesJours.refreshMesJours();
  }
});

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// INITIALIZE THE PODCAST PAGE MODULE                                                                                 //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Check if we are in the Mobile Application
if (window.LesJours.isMobileApplication !== true) {
  // Listen Scroll Events for Progress & Scrollup
  $window.on("scroll", throttle(podcastScrollListener, 250, { leading: true, trailing: true }));
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// RELOAD THE PLAYER PROGRESSION                                                                                      //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
if (window.LesJours.isMobileApplication !== true) {
  $window.on("ljMesJoursLoaded", () => {
    $(".js-audio-player").each((idx, el) => {
      $(el).getLesJoursPlayer().showSavedProgress();
    });
  });
}
