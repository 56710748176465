import $ from "jquery";

// Data Holders
const pending = {};
const cache = {};

// Check if we already have Cached Data
function isJsonDataCached(url) {
  // Check if there is an entry in the Data Cache
  return cache[url] !== undefined;
}

// Check if we already sent the Request for the Data
function isRequestSent(url) {
  // Check if there is a Promise in the Pending Object
  return pending[url] !== undefined;
}

// Get JSON Data
function getJsonData(url) {
  // Retrieve the Data
  const promise = $.getJSON(url);

  // Listen the End of the Request
  promise.then(function (json) {
    // Save the Data into the Cache
    cache[url] = json;

    // Remove the Promise from the Pending Object
    delete pending[url];
  });

  // Save the Promise to the Pending Object
  pending[url] = promise;

  // Return the Created Promise
  return promise;
}

// Add jQuery Function to Request Data Loading
// This function always return a Promise Object
$.loadJsonData = function (url) {
  // Check if we have the given URL in the Cache
  if (isJsonDataCached(url)) {
    return $.Deferred().resolve(cache[url]).promise();
    // Check if we have already have made the Request for the given URL
  } else if (isRequestSent(url)) {
    return pending[url];
    // Request the loading of the given URL
  } else {
    return getJsonData(url);
  }
};
