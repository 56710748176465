import $ from "jquery";

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// COPY LINK                                                                                                          //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// A function to copy a data-link on a button
async function copy() {
  const $button = $(this);
  const contentToCopy = $button.data("toCopy");

  try {
    // Call either the Navigator Clipboard API (see https://developer.mozilla.org/en-US/docs/Web/API/Clipboard)
    // Or the polyfill for older browsers using the execCommand('copy') deprecated method (see global.legacy.js)
    await navigator.clipboard.writeText(contentToCopy);

    // Get or create the Tooltip
    const $tooltip = $button.find(".tooltip");
    if ($tooltip.hasTooltip()) {
      // Get and show the toolip
      $tooltip.getTooltip().showBriefly();
      return;
    }

    // Create and append the tooltip
    const $newTooltip = $(`<div class='tooltip hidden' data-tooltip-type='click' data-tooltip-placement='right'>
      <div class='tooltip-holder'>
        <div class='tooltip-content'>
          <div class='tooltip-item'>Copié&nbsp;!</div>
        </div>
      </div>
    </div>`);
    $(this).append($newTooltip);

    // Show the tooltip
    $.createTooltip($button.get(0), $newTooltip.get(0), {
      placement: $newTooltip.data("tooltipPlacement") || "left",
      showBriefly: true,
    });
  } catch (err) {
    console.log(err);
  }
}

// Bind Click Event Listener
$("body").on("click.lesjours.copy", "[data-to-copy]", copy);
