import throttle from "lodash/throttle";
import $ from "jquery";

// Constants
const $window = $(window);

function Mini() {
  this.$anchors = $();
  this.$container = $();
  this.$minis = [];
  this.offset = 68 / 2 + 6 + 8; // The offset to the center of the image
  this.shown = 0;
}

Mini.prototype._topDownLayoutPosition = function (index, $element, containerOffset) {
  // Variables
  const mini = !!$element.data("mini");
  const id = mini ? "#mini-" + $element.data("mini") : "#note-" + $element.data("note");
  const $mini = this.$minis[index] || (this.$minis[index] = $(id));
  // The top offset computation may vary depending on the content (regular "mini" or "note")
  let top = mini
    ? $element.offset().top - containerOffset + $element.height() / 2 - this.offset
    : $element.offset().top - containerOffset;
  // The previous mini if there is one
  const $prev = index > 0 ? this.$minis[index - 1] : null;

  // Skip story minis
  if ($mini.hasClass("mini-story")) {
    return true;
  }

  // Apply fixes to mini's top value :
  // - Force Min Top to 50px (First Article Content Margin-Top)
  if (top < 50) {
    top = 50;
  }
  // - Check if this mini is going to overlap the previous one and fix
  if ($prev !== null && top < ($prev.data("miniTopVal") || $prev.position().top) + $prev.outerHeight(true)) {
    top += ($prev.data("miniTopVal") || $prev.position().top) + $prev.outerHeight(true) - top;
  }

  // Update Mini Top
  $mini.data("miniTopVal", top).css("top", top);

  // Always continue
  return true;
};

Mini.prototype._bottomUpLayoutPosition = function (index, $element, containerHeight) {
  // Variables
  const mini = !!$element.data("mini");
  const id = mini ? "#mini-" + $element.data("mini") : "#note-" + $element.data("note");
  const $mini = this.$minis[index] || (this.$minis[index] = $(id));
  // The current top offset
  let top = $mini.data("miniTopVal") || $mini.position().top;
  // The previous mini if there is one
  const $prev = index > 0 ? this.$minis[index - 1] : null;

  // Skip story minis
  if ($mini.hasClass("mini-story")) {
    return true;
  }

  // Apply fixes to mini's top value :
  // - Check if the mini will be hidden by the end of the article
  if (containerHeight < top + $mini.outerHeight(true)) {
    top -= top + $mini.outerHeight(true) - containerHeight;

    // Update Mini Top
    $mini.data("miniTopVal", top).css("top", top);
  }
  // - Check if this mini is going to overlap the previous one and fix
  if ($prev !== null && top < ($prev.data("miniTopVal") || $prev.position().top) + $prev.outerHeight(true)) {
    // Move up a bit the previous one
    $prev.data("miniTopVal", top - $prev.outerHeight(true)).css("top", top - $prev.outerHeight(true));

    // Continues
    return true;
  } else {
    // Stop the scan
    return false;
  }
};

Mini.prototype.layout = function () {
  // Check if we have anchors
  if (!this.$anchors.length) {
    return;
  }

  // Retrieve the container's top offset relative to the document
  // It is easier and more accurate to use document's offset
  const containerOffset = this.$container.eq(0).offset().top;
  const containerHeight = this.$container.reduce(function (accumulator, el) {
    return accumulator + $(el).height();
  }, 0);
  const anchorsLength = this.$anchors.length;
  let i;

  // Position every #mini-X so they will be vertically centered to the anchor
  // - 1st step : we are going from the top to the bottom
  for (i = 0; i < anchorsLength; i++) {
    if (this._topDownLayoutPosition(i, this.$anchors[i], containerOffset) === false) {
      break;
    }
  }

  // Check if there is a paywal on the episode
  if (this.protected) {
    // Don't do the second step as it may hide the beginning of the first mini
    // if there is too much minis at the beginning of the episode
    return;
  }

  // - 2nd step : we are going from the bottom to the top
  for (i = anchorsLength - 1; i >= 0; i--) {
    if (this._bottomUpLayoutPosition(i, this.$anchors[i], containerHeight) === false) {
      break;
    }
  }
};

Mini.prototype.show = function () {
  // Avoid unnecessary computation if everything is already shown
  if (this.shown === this.$minis.length) {
    return;
  }

  const current = $window.scrollTop();
  const height = $window.height();
  const self = this;

  // Simply check if the anchor entered the viewport and add a "show" class to the associated mini
  this.$anchors.each(function (index, $element) {
    if (self.$minis[index].hasClass("show")) {
      return;
    }

    const offset = $element.offset().top;
    if (
      self.$minis[index].attr("id") === "mini-obsession" ||
      (offset > current && offset < current + height) ||
      self.$minis[index].hasClass("mini-story")
    ) {
      self.$minis[index].addClass("show");
      self.shown++;
    }
  });
};

Mini.prototype.init = function () {
  // Get Mini Elements
  this.$container = $("article .episode-container, article div.story-slides");
  this.$anchors = $("article.episode").find("[data-mini],[data-note]");
  this.$minis = []; // An array of the associated mini
  this.protected = $("article.episode").hasClass("protected");
  this.shown = 0;

  // Check if $container has elements
  // If it's empty, we must exit the configuration to avoid errors
  if (!this.$container.length) {
    return;
  }

  // Extend the anchors' elements once and for all
  this.$anchors = this.$anchors.map(function (index, element) {
    return $(element);
  });

  // Compute Minis' Positions
  this.layout();

  // Override the links if the associated mini contain a player with a button
  // and display a modal for notes on small devices
  const self = this;
  this.$anchors.each(function (index, $element) {
    const $mini = self.$minis[index];
    const $button = $mini.find(".player button");

    function hover() {
      $mini.addClass("active");
    }
    function blur() {
      $mini.removeClass("active");
    }

    $element
      .on("mouseenter.lesjours.mini", hover)
      .on("focus.lesjours.mini", hover)
      .on("mouseleave.lesjours.mini", blur)
      .on("blur.lesjours.mini", blur);

    // Check if there is a Player Button
    if ($button.length) {
      // Listen Clicks on the Player
      $element.on("click.lesjours.mini", function (e) {
        // Prevent default action
        e.preventDefault();

        // Click on the button
        $button.trigger("click");
      });
      // Check if it's a note
    } else if (!$element.data("mini")) {
      // Listen Clicks on the Note
      $element.on("click.lesjours.mini", function (e) {
        // Check if the element has an href attribute
        if ($element.attr("href") === undefined) {
          // Do nothing special
          return;
        }

        // Prevent default action & stop propagation
        e.preventDefault();
        e.stopPropagation();

        // Check if the note is visible
        if (!$($element.attr("href")).is(":visible")) {
          // Trigger Hash Event
          $window.trigger("ljHashChange", $element.attr("href") + "-modal");
        } else {
          // Scroll to the Note Position
          // We must take care about the header height and let a small margin : 20px
          $window.scrollTop($($element.attr("href")).offset().top - $("#header").height() - 20);
        }
      });
    } else {
      // Listen Clicks on the Mini
      $element.on("click.lesjours.mini", function (e) {
        // Check if the element has a modal
        if ($element.data("modal") !== undefined) {
          // This is a People link : Open the modal instead of following the link to the Meta-Person page
          // Prevent default action & stop propagation
          e.preventDefault();
          e.stopPropagation();

          // Open the modal
          $window.trigger("ljHashChange", $element.data("modal"));
        } else if ($("#mini-" + $element.data("mini") + "-modal").length > 0) {
          // Check if the mini is visible
          if (!$("#mini-" + $element.data("mini")).is(":visible")) {
            // The mini is hidden so we must show it to the user
            // Prevent default action & stop propagation
            e.preventDefault();
            e.stopPropagation();

            // Open the modal
            $window.trigger("ljHashChange", "#mini-" + $element.data("mini") + "-modal");
          }
        }
      });
    }
  });

  // Show Minis
  this.show();
};

Mini.prototype.clean = function () {
  // Remove all Events Listeners
  this.$anchors.each(function (index, $element) {
    $element
      .off("mouseenter.lesjours.mini")
      .off("focus.lesjours.mini")
      .off("mouseleave.lesjours.mini")
      .off("blur.lesjours.mini")
      .off("click.lesjours.mini");
  });

  // Reset Mini Elements Holders
  this.$anchors = $();
  this.$container = $();
  this.$minis = [];
  this.protected = undefined;
  this.shown = 0;
};

// Bind Resize & Page Load Listeners
const mini = new Mini();
const showMini = throttle(mini.show.bind(mini), 250, { leading: true, trailing: true });
const relayout = throttle(mini.layout.bind(mini), 1000, { leading: true, trailing: true });
$window.on("resize.lesjours.minis", relayout);
$window.on("scroll.lesjours.minis", showMini);
$("article .episode-container img").on("load", relayout);

// Listen Component Reload Events
$window.on("ljComponentsReload", function (event, component) {
  if (component === "mini") {
    mini.clean();
    mini.init();
  }
});

// Listen Component Relayout Events
$window.on("ljComponentsRelayout", function (event, component) {
  if (component === "mini") {
    mini.layout();
  }
});
