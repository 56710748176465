import $ from "jquery";
import throttle from "lodash/throttle";
import * as LesJoursFeatures from "../libs/lesjours-features";
import * as MesJours from "../libs/mesjours";
import * as Playlists from "../libs/playlists";

// Variables
const $html = $("html");
const $window = $(window);
const $articleHead = $("article header");
const $article = $("article div.episode-container");
const $extra = $("article div.episode-extra-container");
const $actions = $("#actions");
const $modals = $(".episode-modals-container");
const $series = $("section#series");
const $episodes = $("section#episodes");
const $progressBar = $("#header .article-progress-bar .progress");
const $playlistBtns = $("article .playlist .playlist-button");
const $explicitBtn = $("article .explicit-content-cta .show-content");
let previous = 0;
let originalContent;
let datavizScroll;

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SCROLL TO A SPECIFIC PARAGRAPH                                                                                     //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function scrollToParagraph(paragraphIdx) {
  const $paragraphs = $("article [data-mesjours-record=true] [mesjours-record-paragraphs=true] > *");

  // Check if the article is a Live in Progress
  if (window.LesJours.episode.live !== undefined) {
    paragraphIdx = $paragraphs.length - paragraphIdx;
  }

  // Scroll to the paragraph
  $window.scrollTop($($paragraphs[Math.min(paragraphIdx, $paragraphs.length - 1)]).offset().top);
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// CHANGE THE ARTICLE LANGUAGE                                                                                        //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function changeArticleLanguage(event) {
  // Prevent Default
  event.preventDefault();

  // Variables
  const $button = $(event.currentTarget);
  let translation;

  // Check if we are switching back to french
  if ($button.data("translation") === "fr") {
    translation = {
      code: "fr",
      article: originalContent,
    };
  } else {
    // Get the Selected Translation
    $.each(window.LesJours.translations, function (idx, element) {
      if (element.code === $button.data("translation")) {
        translation = element;
      }
    });
    if (translation === undefined) {
      return;
    }

    // Save the Original Content
    if (originalContent === undefined) {
      originalContent = $article.html();
    }
  }

  // Update Buttons
  $articleHead
    .find("[data-translation]")
    .removeClass("hidden")
    .filter("[data-translation=" + translation.code + "]")
    .addClass("hidden");

  // Change the Content
  $article.html(translation.article);

  // Reload Minis
  const episode = window.LesJours.episode;
  const obsession = episode.obsession ?? window.LesJours.obsession;

  // Remove Minis
  $extra.children().remove();

  // Add the Obsession Mini to the Article
  $extra.append(window.nunjucks.render("episode/classic/mini-obsession.html", { obsession: obsession }));

  // Load Notes & Minis
  $.each(episode.minis, function (idx, mini) {
    // Check if the Mini is Displayed in the Article
    if ($article.find("[data-mini=" + mini.number + "]").length > 0) {
      // Add the Mini to the Article
      $extra.append(window.nunjucks.render("episode/classic/mini.html", { mini: mini }));
    }
  });
  $.each(episode.notes, function (idx, note) {
    // Check if the Note is Displayed in the Article
    if ($article.find("[data-note=" + note.number + "]").length > 0) {
      // Add the Note to the Article
      $extra.append(window.nunjucks.render("episode/classic/note.html", { note: note }));
    }
  });

  // Trigger Events to Reload Components
  $window.trigger("ljComponentsReload", "mini");
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// EPISODE SCROLL EVENTS HANDLER FUNCTIONS : PROGRESS BAR & MES JOURS                                                 //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// eslint-disable-next-line no-unused-vars
function processProgressForEpisodes($articleEl, event, keepHeader) {
  // Get Current Configuration
  const curScroll = $window.scrollTop();
  const viewport = $window.height();
  const offset = $articleEl.eq(0).offset().top;
  const size = $articleEl.reduce(function (accumulator, el) {
    return accumulator + $(el).height();
  }, 0);

  // Compute the Scrollbar Width
  if (curScroll === 0 || curScroll + viewport < offset) {
    // The user hasn't started to read the episode
    $progressBar.css("width", "0%");
  } else if (offset + size < curScroll + viewport) {
    // The user has reach the end of the episode
    $progressBar.css("width", "100%");
  } else {
    // The user is reading the episode
    // We must calculate the progress in percent
    // size                             ->  100%
    // (curScroll + viewport) - offset  ->  ? %  (This is the progression we need)
    $progressBar.css("width", Math.round(((curScroll + viewport - offset) * 100) / size) + "%");
  }
}

function saveReadingProgress(obsession, episode, $elements) {
  // Check if MesJours is loaded
  if (!MesJours.isMesJoursLoaded()) {
    // Don't update the Progression
    return;
  }

  // Variables
  const currentScroll = $(document).scrollTop() + $("header#header").height();
  let elementIdx = 0;
  let progress;

  // Detect the Current Position in the Article
  $elements.each(function (idx, element) {
    if ($(element).offset().top < currentScroll) {
      elementIdx = idx;
    }
  });

  // Check if the user has start reading the Article
  if (elementIdx === 0) {
    // Don't Send the Progression
    return;
  }

  // Check if the article is a Live in Progress
  if (window.LesJours.episode.live !== undefined) {
    elementIdx = $elements.length - elementIdx;
  }

  // Check if the current progress is lower than the previous
  // or if the episode is already read
  if (elementIdx <= previous || previous === true) {
    // Don't Send the Progression
    return;
  }

  // Check if the user has terminated to read the episode
  // When the user is on the last element we considere that he has finished the article
  // We compare the length to the elementIdx + 2 because the last element is the slideshow holder
  if ($elements.length <= elementIdx + 2 && window.LesJours.episode.live === undefined) {
    // Flag the Episode has Read
    progress = true;
  } else {
    // Get the current Progression
    progress = elementIdx;
  }

  // Update the Reading Progress
  MesJours.updateArticleProgress(MesJours.models.episode, obsession, episode, progress).finally(function () {
    // Get the Episode
    return MesJours.getEpisode(obsession, episode).then(function (document) {
      // Update the Previous Reading Progress
      previous = document.progress;
    });
  });
}

function articleProgressScrollListener(event, keepHeader) {
  // Check if there is an Article
  const $articleEl = $("article div.episode-container");
  if ($articleEl.length > 0) {
    // Process the Event
    processProgressForEpisodes($articleEl, event, keepHeader);
  }

  // Check if we have to record the reading progress for "Mes Jours"
  const $articleRecording = $("article [data-mesjours-record=true]");
  if ($articleRecording.length > 0) {
    // Update the Reading Progress
    saveReadingProgress(
      $articleRecording.data("obsession"),
      $articleRecording.data("episode"),
      $articleRecording.find("[mesjours-record-paragraphs=true] > *")
    );
  }
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// OPEN/CLOSE PLAYLIST                                                                                                //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function togglePlaylist(event) {
  // Prevent Default
  event.preventDefault();

  // Variables
  const $button = $(event.currentTarget);
  const $playlist = $($button.parents(".playlist").get(0));
  const $holder = $playlist.find(".playlist-holder");

  // Check if we want to display or hide the playlist
  if (!$playlist.hasClass("playlist-closed")) {
    // Close the Playlist
    Playlists.close($playlist, $holder);
  } else {
    // Open the Playlist
    Playlists.open($playlist, $holder);
  }
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SHOW EXPLICIT CONTENT                                                                                              //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function showExplicitContent(event) {
  // Prevent Default
  event.preventDefault();

  // Hide the Explicit Content Warning
  $(event.currentTarget).parents(".explicit-content-cta").remove();

  // Show Hidden Elements in the Article
  $article.find(".hidden").removeClass("hidden");

  // Trigger Events to Reload Components
  $window.trigger("ljComponentsReload", "mini");
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SERIES SLIDER
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function initializeSeriesSlider() {
  const $slider = $("section#series .slider");
  const cntWidth = $slider.innerWidth();

  // Initialize the Slider
  $window.trigger("ljComponentsInit", ["slider", $slider, cntWidth]);
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// STORY
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function showNextSlide($story, $slides, $cur, $next) {
  // Update Story
  $cur.removeClass("active");
  $next.addClass("active");

  // Update prev/next buttons
  const nextIndex = $next.index();
  if (nextIndex === 0) {
    $story.find(".story-prev-next").addClass("hidden");
    $story.find(".story-share").addClass("hidden");
  } else if (nextIndex === $slides.length - 1) {
    $story.find(".story-prev-next").removeClass("hidden");
    $story.find(".story-prev-next [data-direction=prev]").addClass("hidden");
    $story.find(".story-prev-next [data-direction=next]").addClass("hidden");
    $story.find(".story-prev-next [data-direction=episodes]").removeClass("hidden");
    $story.find(".story-share").removeClass("hidden");
  } else {
    $story.find(".story-prev-next").removeClass("hidden");
    $story.find(".story-prev-next [data-direction=prev]").removeClass("hidden");
    $story.find(".story-prev-next [data-direction=next]").removeClass("hidden");
    $story.find(".story-prev-next [data-direction=episodes]").addClass("hidden");
    $story.find(".story-share").addClass("hidden");
  }

  // Check if the story must be in Fullscreen
  if ($story.hasClass("story-fullscreen")) {
    // Lock/Unlock the Scroll
    if ($next.index() === $slides.length - 1) {
      // Remove no-overflow class on the <body>
      $("body").removeClass("no-overflow fixed");
    } else {
      // Add no-overflow class on the <body>
      $("body").addClass("no-overflow fixed");
    }
  }

  // Start/Stop Playing Videos
  if ($cur.find("video").length > 0 && !$cur.find("video").get(0).paused) {
    $cur.find("video").get(0).pause();
  }
  if ($next.find("video").length > 0) {
    $next.find("video").get(0).play();
  }

  // Trigger an event
  $window.trigger("ljStoryShowNextSlide", {
    cur: $cur.index(),
    next: $next.index(),
    size: $slides.length,
  });
}

function exitStory() {
  // Check if there is an episodes cta on the page
  let anchor;
  if ($("#cta-episode").length > 0 && $("#cta-episode").is(":visible")) {
    // Scroll to the Episode CTA
    anchor = "#cta-episode";
  } else {
    // Scroll to the Episodes Slider
    anchor = "#episodes";
  }

  // Scroll to the Hash
  $("html, body").animate(
    {
      scrollTop: $(anchor).offset().top - $("#header").height(),
    },
    500,
    "swing"
  );
}

function prevSlide() {
  // Get jQuery Elements
  const $story = $("article div.story");
  const $slides = $story.find(".story-slide");
  const $cur = $slides.filter(".active");
  const idx = $cur.index();
  let $next;

  // Check if we have something to do
  if (idx > 0) {
    // Move to the previous element
    $next = $cur.prev();
  }
  if (!$next) {
    return;
  }

  // Show the Next Slide
  showNextSlide($story, $slides, $cur, $next);
}

function nextSlide() {
  // Get jQuery Elements
  const $story = $("article div.story");
  const $slides = $story.find(".story-slide");
  const $cur = $slides.filter(".active");
  const idx = $cur.index();
  let $next;

  // Check if we have something to do
  if (idx < $slides.length - 1) {
    // Move to the next element
    $next = $cur.next();
  } else {
    // Stop the Story
    return exitStory();
  }
  if (!$next) {
    return;
  }

  // Show the Next Slide
  showNextSlide($story, $slides, $cur, $next);
}

function scrollToEpisodes() {
  // Check if we are in the Mobile Application
  if (window.LesJours.isMobileApplication === true) {
    // Send a message to the App JS channel
    if (window.LesJoursWebViewChannels !== undefined) {
      // Send a message
      window.LesJoursWebViewChannels.postMessage(
        JSON.stringify({
          action: "showObsession",
          value: window.LesJours.obsession.href,
        })
      );
    }
  } else {
    // Scroll to anchor
    $.scrollToAnchor("#episodes");
  }
}

function initializeStory() {
  // Get the Story Host
  const $story = $("article div.story");

  // Check if the story must be in Fullscreen
  if ($story.hasClass("story-fullscreen")) {
    // Disable Overflow on the Body
    $("body").addClass("no-overflow fixed");
  }

  // Initialize prev/next buttons
  $story.find(".story-buttons [data-direction]").on("click.lesjours.episode", function (e) {
    // Prevent default
    e.preventDefault();

    // Check the direction
    switch ($(e.currentTarget).data("direction")) {
      case "prev":
        return prevSlide();
      case "next":
        return nextSlide();
      case "episodes":
        return scrollToEpisodes();
    }
  });

  // Initialize Click listeners on Slides
  $story.on("click.lesjours.episode", function (e) {
    // Check the Event Target
    if (!$(e.target).is(".story-slide.active")) {
      // Nothing to do
      return;
    }

    // Process the Event
    const windowWidth = $window.width();
    const pageX = e.pageX;

    // Check if we are on the left side or on the right side
    if (pageX < windowWidth * 0.4) {
      return prevSlide();
    }
    if (windowWidth * 0.4 < pageX) {
      return nextSlide();
    }
  });

  // Bind Keydown Events Listener
  $window.keydown(function (e) {
    switch (e.keyCode) {
      case 39:
        return nextSlide(); // →
      case 37:
        return prevSlide(); // ←
    }
  });
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DATAVIZ
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function initializeDataviz() {
  // Get the Dataviz Host
  const $iframe = $("article iframe.infographie");
  const iframe = $iframe.get(0);

  // Hashchange Event Function
  function hashchange() {
    // Check if we Have an Hash
    if (window.location.hash && window.location.hash.match(/^#?infographie-[a-zA-Z0-9_-]+$/)) {
      // We must Pass the Hash to the Dataviz
      iframe.iFrameResizer.sendMessage({
        action: "scrollToAnchor",
        payload: window.location.hash.replace(/^#?infographie-([a-zA-Z0-9_-]+)$/, "$1"),
      });
    }
  }

  // Message Callback Function
  function messageCallback(event) {
    // Check if there is a Message & an Action
    if (!event.message || !event.message.action) {
      // Do nothing
      return;
    }

    // Check the Message Action
    switch (event.message.action) {
      case "contentDisplayed":
        // The Dataviz is Displayed
        // Bind Hash Change Listener
        $window.on("hashchange.lesjours.infographie", hashchange);

        // Call the Hashchange Function
        hashchange();
        break;
      case "requestNightMode":
        // Send the Current Theme
        $("article iframe.infographie")
          .get(0)
          .iFrameResizer.sendMessage({
            action: "currentTheme",
            payload: $html.hasClass("lj-dark-theme") ? "dark" : "light",
          });
        break;
      case "requestSocial":
        // Send Sharing Information to the Dataviz
        $("article iframe.infographie").get(0).iFrameResizer.sendMessage({
          action: "social",
          payload: window.LesJours.episode.share,
        });
        break;
      case "hideHeader":
        // Add the no-header on the <body>
        $("body").addClass("no-header");
        break;
      case "showHeader":
        // Remove the no-header on the <body>
        $("body").removeClass("no-header");
        break;
      case "scrollToAnchor":
        // Remove no-overflow & no-header on the <body>
        $("body").removeClass("no-overflow fixed no-header");

        // Check if the Payload is a valid Anchor
        if (/^[a-zA-Z0-9-]+$/.test(event.message.payload) && $("#" + event.message.payload).length > 0) {
          // Check if the hash is "episodes"
          if (event.message.payload === "episodes") {
            // Check if there is an episodes cta on the page
            if ($("#cta-episode").length > 0 && $("#cta-episode").is(":visible")) {
              // Replace the payload
              event.message.payload = "cta-episode";
            }
          }

          // Scroll to the Hash
          $("html, body").animate(
            {
              scrollTop: $("#" + event.message.payload).offset().top - $("#header").height(),
            },
            500,
            "swing"
          );
        }
        break;
    }
  }

  // Scroll Callback Function
  function scrollCallback(payload) {
    // Check if we have an Y Coordinate
    if (payload.y && payload.y > 0) {
      // Save the new Dataviz Scroll Position
      datavizScroll = payload.y;

      // Remove the Header Height
      datavizScroll -= $("#header").height();
      if (datavizScroll < 0) {
        datavizScroll = 0;
        return;
      }

      // Scroll to the Position after a Short Delay
      setTimeout(function () {
        $window.scrollTop(datavizScroll);
      }, 500);
    }

    // Stop Processing the Scroll Event
    return false;
  }

  // Check if the iFrame must be in Fullscreen
  if ($iframe.data("fullscreen") === true) {
    // Disable Overflow on the Body
    $("body").addClass("no-overflow fixed");
  }

  // Start iFrame Resizer for the Dataviz
  window.iFrameResize(
    {
      autoResize: $iframe.data("autoresize") === true,
      sizeHeight: $iframe.data("autoresize") === true,
      sizeWidth: false,
      scrolling: false,
      onMessage: messageCallback,
      onScroll: scrollCallback,
      onInit: function () {
        // Say hello to the iFrame
        iframe.iFrameResizer.sendMessage({
          action: "hello",
        });
      },
    },
    iframe
  );
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// NOTES, MINIS, SLIDESHOWS, MODALS, SHARE BUTTONS                                                                    //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function showNotesMinisAndModals() {
  // Get the Episode
  const episode = window.LesJours.episode;
  const obsession = episode.obsession ?? window.LesJours.obsession;
  const $episode = $("article div.episode-container, article div.story-slides");

  // Check if there is Translations for the Episode
  if (window.LesJours.translations) {
    // Load Translations Area
    $articleHead
      .find("address")
      .after(window.nunjucks.render("episode/classic/languages.html", { translations: window.LesJours.translations }));

    // Listen Clics on Translations Buttons
    $articleHead.find("[data-translation]").on("click.lesjours.episode", changeArticleLanguage);
  }

  // Check the Episode Type
  switch (episode.type) {
    case "ClassicEpisode":
    case "ASideBSideEpisode":
    case "LiveEpisode":
      // Load Notes & Minis
      $extra.append(window.nunjucks.render("episode/classic/mini-obsession.html", { obsession: obsession }));
      $.each(episode.minis, function (idx, mini) {
        // Check if the Mini is Displayed in the Article
        if ($episode.find("[data-mini=" + mini.number + "]").length > 0) {
          // Add the Mini to the Article
          $extra.append(window.nunjucks.render("episode/classic/mini.html", { mini: mini }));
        }
      });
      $.each(episode.notes, function (idx, note) {
        // Check if the Note is Displayed in the Article
        if ($episode.find("[data-note=" + note.number + "]").length > 0) {
          // Add the Note to the Article
          $extra.append(window.nunjucks.render("episode/classic/note.html", { note: note }));
        }
      });

      // Load Episode Slideshow
      if (episode.type === "ClassicEpisode" || episode.type === "LiveEpisode") {
        $modals.append(
          window.nunjucks.render("episode/classic/slideshow.html", {
            images: episode.slideshow,
            obsession: obsession,
          })
        );
      }

      // Add Sharing Buttons to the DOM
      if (!window.LesJours.isMobileApplication) {
        $modals.append(window.nunjucks.render("modals/download.html", episode.download));
        $actions.append(
          window.nunjucks.render("actions/share.html", {
            share: episode.share,
            withDownload: true,
            withGift: true,
            mtmCampaign: "episode-sharing",
            mtmContent: `${episode.share.obsession}+${episode.share.episode}`,
          })
        );
        $window.trigger("ljComponentsReload", "actions");
      }

      // Check activated features
      if (LesJoursFeatures.isActivated(LesJoursFeatures.features.SHOW_DURATION)) {
        // Compute the estimated reading time
        const duration = Math.round($article.text().split(" ").length / 200);
        if (duration > 0) {
          $articleHead
            .find("address.style-meta")
            .append(window.nunjucks.render("episode/classic/duration.html", { duration: duration }));
        }
      }
      break;
    case "StoryEpisode":
      // Load Notes & Minis
      $.each(episode.minis, function (idx, mini) {
        // Check if the Mini is Displayed in the Article
        const $miniLink = $episode.find("[data-mini=" + mini.number + "]");
        if ($miniLink.length > 0) {
          // Add the Mini to the Article
          $miniLink.parents(".story-slide").append(window.nunjucks.render("episode/story/mini.html", { mini: mini }));
        }
      });
      $.each(episode.notes, function (idx, note) {
        // Check if the Note is Displayed in the Article
        const $noteLink = $episode.find("[data-note=" + note.number + "]");
        if ($noteLink.length > 0) {
          // Add the Note to the Article
          $noteLink.parents(".story-slide").append(window.nunjucks.render("episode/story/note.html", { note: note }));
        }
      });

      // Add Sharing Buttons to the DOM
      if (!window.LesJours.isMobileApplication) {
        $actions.append(
          window.nunjucks.render("actions/share.html", {
            share: episode.share,
            withDownload: false,
            withGift: true,
            mtmCampaign: "episode-sharing",
            mtmContent: `${episode.share.obsession}+${episode.share.episode}`,
          })
        );
        $window.trigger("ljComponentsReload", "actions");
      }
      break;
  }
  if ($.inArray(episode.type, ["ClassicEpisode", "StoryEpisode", "ASideBSideEpisode", "LiveEpisode"]) !== -1) {
    // Load Notes & Minis Modals
    $.each(episode.minis, function (idx, mini) {
      // Check if the Mini is Displayed in the Article
      if ($episode.find("[data-mini=" + mini.number + "]").length > 0) {
        // Add the Document Modal if there is One
        if (mini.model !== undefined) {
          switch (mini.model) {
            case "People":
            case "Place":
            case "Data":
              // Add the People/Place/Data Modal to the Article
              $modals.append(
                window.nunjucks.render("obsession/" + mini.model.toLowerCase() + "-modal.html", {
                  item: mini.doc,
                  obsession: obsession,
                })
              );
              break;
            case "Document":
            case "MusicSoundtrack":
              // Add the Mini Modal to the Article
              $modals.append(window.nunjucks.render("episode/mini-modal.html", { mini: mini }));
              break;
          }
          // Add a Mini Modal like Notes
        } else {
          // Add the Mini Modal to the Article
          $modals.append(window.nunjucks.render("episode/mini-modal.html", { mini: mini }));
        }
      }
    });
    $.each(episode.notes, function (idx, note) {
      // Check if the Note is Displayed in the Article
      if ($episode.find("[data-note=" + note.number + "]").length > 0) {
        // Add the Note Modal to the Article
        $modals.append(window.nunjucks.render("episode/note-modal.html", { note: note }));
      }
    });
  }

  // Trigger Events to Reload Components
  $window.trigger("ljComponentsReload", "mini");
  $window.trigger("ljComponentsReload", "modal");
  $window.trigger("ljComponentsReload", "slideshow");

  // Reload Minis' position after every click on the bulletpoints block
  if (!window.LesJours.isMobileApplication) {
    $episode.find("#bulletpoints [data-toggle]").on("click.lesjours.episode", () => {
      setTimeout(() => {
        $window.trigger("ljComponentsRelayout", "mini");
      }, 100);
    });
  }
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// LOAD REQUIRED JSONS                                                                                                //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$.when(
  // Load the Obsession JSON File
  window.LesJours.obsession !== undefined
    ? $.Deferred().resolve([window.LesJours.obsession, "success"]).promise()
    : $.loadJsonData("../obsession.json"),
  // Load the Series JSON File
  window.LesJours.series !== undefined
    ? $.Deferred().resolve([window.LesJours.series, "success"]).promise()
    : $.loadJsonData("/series.json")
).then(function (obsessionPromise, seriesPromise) {
  // Get Data from Promises & Get the Episode from window.LesJours
  const episode = window.LesJours.episode;
  const obsession = obsessionPromise[0];
  const series = seriesPromise[0];

  // Load Episodes List Modal
  $modals.append(window.nunjucks.render("modals/episodes-list.html", { obsession: obsession }));

  // Load more stuff for the website
  if (!window.LesJours.isMobileApplication) {
    // Load Episodes Covers
    $episodes.replaceWith(
      window.nunjucks.render("episode/episodes.html", { current: episode.number, obsession: obsession })
    );

    // Load Series Covers
    $series.append(window.nunjucks.render("series/recent-series-slider.html", { series: series }));
    initializeSeriesSlider();
  }

  // Refresh Mes Jours
  if (!window.LesJours.isMobileApplication) {
    MesJours.refreshMesJours();
  }
});

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// INITIALIZE THE EPISODE PAGE MODULE                                                                                 //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Bind Playlist Buttons Listeners
$playlistBtns.on("click.lesjours", togglePlaylist);

// Bind Explicit Content Button Listener
$explicitBtn.on("click.lesjours", showExplicitContent);

// Bind Resume Episode Button Listener
$articleHead
  .find("[data-mesjours-type][data-model=Episode]")
  .on("click.lesjours.scrollto", ".reading[data-scroll-to]", function (e) {
    // Get the Element
    const $target = $(e.currentTarget);

    // Scroll to the correct paragraph
    scrollToParagraph(parseInt($target.data("scrollTo"), 10));
  });

// Check if we are in the Mobile Application
if (window.LesJours.isMobileApplication !== true) {
  // Listen Scroll Events for Progress & Scrollup
  $window.on("scroll", throttle(articleProgressScrollListener, 250, { leading: true, trailing: true }));
}

// Check if we have a Story
if ($("article div.story").length > 0) {
  // Initialize the Story
  initializeStory();
}

// Check if we have a Dataviz
if ($("article iframe.infographie").length > 0) {
  // Initialize the Dataviz
  initializeDataviz();
}

// Check if there is a live
if (window.LesJours.episode.live !== undefined) {
  // Listen Click Events on Summary links
  $article.find(".summary .summary-links").on("click", function () {
    // Get the anchor
    const anchor = $(this).data("anchor");
    if (anchor !== undefined) {
      // Scroll to anchor
      $.scrollToAnchor(anchor);
    }
  });

  // Check if we are in the Mobile Application
  if (window.LesJours.isMobileApplication !== true) {
    // Check if a new post has been published every "X" milliseconds
    const checkLiveUpdate = setInterval(function () {
      // AJAX request to get some episode datas
      $.ajax({
        url: `${window.LesJours.episode.live.url}?action=get-last-update`,
        type: "GET",
      }).then(function (response) {
        // Check if the live has ended
        if (response.inProgress === false) {
          // Clear existing buttons
          $("header #top-reload-btn").remove();

          // Create the page reload button
          const reloadBtn = window.nunjucks.render("actions/reload.html", {
            color: window.LesJours.episode.live.btnColor,
            text: "Le live est terminé, recharger la page.",
          });

          // Insert the page reload button
          $("header .grey-stripe").after(reloadBtn);

          // Listen to click events on the button
          $("header #top-reload-btn").on("click", function () {
            // Add the last post href to the location hash
            window.location.hash = response.lastPostHref;
            // Reload the page
            window.location.reload(true);
          });

          // Clear the interval
          clearInterval(checkLiveUpdate);
          return;
        }

        // If a new post has been published since the user loaded the page
        if (response.livePosts !== window.LesJours.episode.live.livePosts) {
          // Check if the reload button already exists
          if ($("header #top-reload-btn").length === 0) {
            // Create the page reload button
            const reloadBtn = window.nunjucks.render("actions/reload.html", {
              color: window.LesJours.episode.live.btnColor,
              text: "Nouvelle(s) mise(s) à jour",
            });

            // Insert the page reload button
            $("header .grey-stripe").after(reloadBtn);
          }

          // Listen to click events on the button
          $("header #top-reload-btn").on("click", function () {
            // Add the last post href to the location hash
            window.location.hash = response.lastPostHref;
            // Reload the page
            window.location.reload(true);
          });
        }
      });
    }, window.LesJours.episode.live.interval);
  }
}

// Show all asides (notes, minis, sharing buttons, slideshows, modals, ...)
showNotesMinisAndModals();

// Initialize the Scroll Progress
if (window.location.hash.match(/^#?scrollto-[0-9]+$/)) {
  // Reload the Progression after a Short Delay
  setTimeout(function () {
    // Scroll to the correct paragraph
    scrollToParagraph(parseInt(window.location.hash.split("-")[1], 10));
  }, 500);
} else {
  // Initialize the Scroll Progress to 0
  articleProgressScrollListener(undefined, true);
}
