import $ from "jquery";

// Bind Clicks Events Listeners
$("body").on("click.lesjours.tabs", "[role=tablist] [role=tab]", function (e) {
  // Prevent Default
  e.preventDefault();

  // Variables
  const $this = $("[role=tablist] [role=tab][aria-controls=" + $(this).attr("aria-controls") + "]");
  const $links = $this.parent().siblings().find("[aria-selected]");
  const $target = $("#" + $this.attr("aria-controls"));
  const $panels = $target.siblings("[role=tabpanel]");

  // Unselect Siblings Tabs
  $links.attr("aria-selected", false);
  $links.attr("tabindex", -1);

  // Select the Clicked Tab
  $this.attr("aria-selected", true);
  $this.attr("tabindex", 0);

  // Hide Siblings Panels
  $panels.attr("aria-hidden", true);

  // Display the Current Panel
  $target.attr("aria-hidden", false);

  // Add the anchor to the URL, so the user can copy the URL
  history.pushState(null, null, "#" + $this.attr("aria-controls"));
  $(window).trigger("pushstate");
});

// Check if a hash is present so we could switch to it
if (window.location.hash && /^#[a-z0-9.:_-]+$/i.test(window.location.hash)) {
  const $target = $(window.location.hash);
  if ($target.attr("role") === "tabpanel") {
    // Check if the current Tab is in a Tab
    if ($target.parents("[role=tabpanel]").length > 0) {
      // Activate the Parent Tab
      $("#" + $target.parents("[role=tabpanel]").attr("aria-labelledby")).trigger("click");
    }

    // Activate the current Tab
    $("#" + $target.attr("aria-labelledby")).trigger("click");
  }
}
